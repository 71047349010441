import React, { useEffect, useState } from "react";
import excel from "../../../Assets/xls.png";
import pdf from "../../../Assets/Pdf.png";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import { ImSearch } from "react-icons/im";
import { useSelector } from "react-redux";
import axios from "axios";
import { getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import DownloadReport from "../DownloadReport";

const AssetsReport = () => {
  const [assets, setAssets] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [results, setResults] = useState([]);
  const branch =
    useSelector((state) => state?.main?.branch) ||
    getEncryptItems("branchDetails");

  useEffect(() => {
    fetchAssets();
  }, [branch]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
    if (value) {
      const result = assets.filter((item) =>
        item.title.toLowerCase().includes(value.toLowerCase())
      );
      setResults(result);
    } else {
      setResults([]);
    }
  };

  const handleClear = () => {
    setSearchData("");
    setResults([]);
  };

  const AssetsHeader = [
    { label: "Title", field: "title" },
    { label: "Description", field: "description" },
    { label: "Capacity", field: "capacity" },
  ];

  const fetchAssets = async () => {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branchId = getEncryptItems("branchDetails")?._id;
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/asset/all-asset?organizationId=${branchId}`,
      headers: {
        Authorization: token,
      },
    };

    try {
      const response = await axios.request(config);
      setAssets(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide md:block hidden">
          Reports
        </div>

        <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
          <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
            <ImSearch color="gray" size={15} />
          </span>
          <input
            id="searchData"
            className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
            placeholder="Search Asset"
            type="text"
            value={searchData}
            onChange={(e) => handleSearch(e)}
          />
          {searchData && (
            <span
              className="absolute right-14 top-3 cursor-pointer"
              onClick={handleClear}
            >
              X
            </span>
          )}
        </div>

        <DownloadReport
          headerData={AssetsHeader}
          rowData={searchData ? results : assets}
        />

        {/* <div>
          <img
            src={excel}
            alt="excel"
            // onClick={() => handleDownload("CSV")}
            className="cursor-pointer w-7 h-7"
            title="Download CSV"
          />
          <img
            src={pdf}
            alt="pdf"
            // onClick={() => handleDownload("PDF")}
            className="cursor-pointer w-7 h-7"
            title="Download PDF"
          />
        </div> */}
      </div>
      <ReportsDynamicTable
        headerData={AssetsHeader}
        rowData={searchData ? results : assets}
      />
    </React.Fragment>
  );
};

export default AssetsReport;
