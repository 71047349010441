import React, { useState } from "react";
import InvoiceModal from "./InvoiceModal/InvoiceModal";
import AllInvoice from "./AllInvoice";
import { useSelector } from "react-redux";
import { FaRupeeSign } from "react-icons/fa";
import { LiaMoneyBillSolid } from "react-icons/lia";
import Tostify from "../../Screens/Common/Tostify";

const InvoiceTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [AllUsers, setAllUsers] = useState([]);
  const [results, setResults] = useState([]);
  const [startfrom, setStartfrom] = useState();
  const [endfrom, setEndfrom] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [UserId, setUserId] = useState("");
  const [visible, setVisible] = useState(false);
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const branch = useSelector(state => state?.main?.branch) || "";

  const AllBills = useSelector((state) => state?.main?.billList)?.filter(ele => ele?.organizationId === branch?._id && !ele?.isDeleted);
  const today = new Date().toISOString().split("T")[0];
  let totalCardToday = 0;
  let totalCashToday = 0;
  let totalOnlineToday = 0;

  const totalPaymentReceivedToday = AllBills?.reduce((total, bill) => {
    if (bill?.paymentMethods && bill.paymentMethods?.length > 0) {
      const paymentsToday = bill?.paymentMethods?.filter(
        (payment) => payment?.paymentDate?.split("T")[0]
      );

      const totalPaymentsToday = paymentsToday.reduce((sum, payment) => {
        if (["g-pay", "phone-pay", "paytm", "UPI"].includes(payment?.payment)) {
          totalOnlineToday += payment.amount;
        }
        if (["Creditcard", "Debitcard", "Card"].includes(payment?.payment)) {
          totalCardToday += payment.amount;
        }
        if (payment?.payment === "Cash") {
          totalCashToday += payment.amount;
        }
        return sum + payment.amount;
      }, 0);
      return total + totalPaymentsToday;
    }
    return total;
  }, 0);

  function formatDateString(dateString) {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const day = String(dateObject.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  const HandleDateFilter = (e) => {
    e.preventDefault();
    const filteredData = AllUsers.filter(
      (ele) => formatDateString(ele?.createdAt) === filterDate
    );
    setResults(filteredData);
  };

  return (
    <React.Fragment>
      <Tostify />
      <InvoiceModal
        id={UserId}
        openBillingModal={openBillingModal}
        setOpenBillingModal={setOpenBillingModal}
      />

      <div
        className={`${visible ? "h-50 md:h-70" : "h-auto"
          } rounded-lg  max-w-full mt-4`}
      >
        <div className="overflow-hidden  py-2 rounded-t-lg">
          {/* <div>
            <Header handleToggle={handleToggle} visible={visible} />
          </div> */}

          <>
            <div style={{ overflowX: "auto" }}>
              <div className="flex gap-4 mb-4 ">
                <div className="text-[#2B2F32] p-2 rounded-lg flex justify-center w-full">
                  {isLoading ? (
                    <div className="flex gap-4 justify-center ">
                      {[1, 2, 3, 4, 5, 6, 7].map((v, i) => {
                        return (
                          <div
                            className="w-52 bg-white rounded-lg shadow-lg pt-1 pb-1"
                            key={i}
                          >
                            <div className="w-full flex flex-col justify-center items-center">
                              <div className="animate-pulse w-12 h-12 rounded-full bg-skeletonLoaderColor flex justify-center items-center mx-auto  mb-5">
                                <div className="h-2  bg-skeletonLoaderColor rounded "></div>
                              </div>
                              <div className="font-semibold text-md text-[#7f8296] text-center mt-1 mb-1 tracking-wide w-3/5">
                                <div className=" flex flex-col justify-center items-center">
                                  <div className="animate-pulse h-2 w-2/3 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
                                </div>
                              </div>
                            </div>

                            <div className="font-bold text-lg w-full text-center">
                              <div className=" flex flex-col justify-center items-center">
                                <div className="animate-pulse h-3 w-2/3 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="flex items-center justify-center flex-wrap  gap-4 mt-4">
                      <div className="sm:w-40 w-full bg-white rounded-lg pt-2 pb-2 cursor-pointer shadow">
                        <div className="w-full flex flex-col justify-center items-center">
                          <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                            <FaRupeeSign
                              size={25}
                              className="text-primaryColor"
                            />
                          </div>
                          <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                            {totalPaymentReceivedToday || 0}
                          </div>
                        </div>

                        <div className="font-bold text-lg w-full text-center">
                          Total Collection
                        </div>
                      </div>
                      <div className="sm:w-40 w-full  bg-white rounded-lg pt-2 pb-2 cursor-pointer shadow">
                        <div className="w-full flex flex-col justify-center items-center">
                          <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                            <LiaMoneyBillSolid
                              size={25}
                              className="text-primaryColor"
                            />
                          </div>
                          <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                            {
                              AllBills?.filter(
                                (ele) =>
                                  ele?.date ===
                                  new Date()?.toISOString()?.slice(0, 10)
                              )?.length
                           || 0 }
                          </div>
                        </div>

                        <div className="font-bold text-lg w-full text-center">
                          Total Bills
                        </div>
                      </div>
                      <div className="sm:w-40 w-full  bg-white rounded-lg pt-2 pb-2 cursor-pointer shadow">
                        <div className="w-full flex flex-col justify-center items-center">
                          <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                            <FaRupeeSign
                              size={25}
                              className="text-primaryColor"
                            />
                          </div>
                          <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                            {totalCardToday || 0}
                          </div>
                        </div>

                        <div className="font-bold text-lg w-full text-center">
                          Total Card
                        </div>
                      </div>
                      <div className="sm:w-40 w-full  bg-white rounded-lg pt-2 pb-2 cursor-pointer shadow">
                        <div className="w-full flex flex-col justify-center items-center">
                          <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                            <FaRupeeSign
                              size={25}
                              className="text-primaryColor"
                            />
                          </div>
                          <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                            {totalCashToday || 0}
                          </div>
                        </div>

                        <div className="font-bold text-lg w-full text-center">
                          Total Cash
                        </div>
                      </div>
                      <div className="sm:w-40 w-full  bg-white rounded-lg pt-2 pb-2 cursor-pointer shadow">
                        <div className="w-full flex flex-col justify-center items-center">
                          <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                            <FaRupeeSign
                              size={25}
                              className="text-primaryColor"
                            />
                          </div>
                          <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                            {totalOnlineToday || 0}
                          </div>
                        </div>

                        <div className="font-bold text-lg w-full text-center">
                          Total UPI
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>

      <AllInvoice
        searchInput={searchInput}
        startfrom={startfrom}
        endfrom={endfrom}
      />
    </React.Fragment>
  );
};

export default InvoiceTable;
