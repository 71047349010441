import React from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";

const MembershipRevenue = ({ dailyRevenueFromMembership }) => {
  const [isLoading] = useState(false);

  // Sort the dates and calculate total revenue for each date
  const sortedData = Object.entries(dailyRevenueFromMembership)
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, data]) => ({
      date,
      revenue: data.reduce((total, item) => total + item.price, 0)
    }));

  const series = [
    {
      name: "Revenue",
      data: sortedData.map(item => item.revenue),
    },
  ];

  const state = {
    series,
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      colors: ["#ff5b5b"],
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: sortedData.map(item => item.date),
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        title: {
          text: 'Revenue'
        },
        labels: {
          formatter: function (value) {
            return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value);
          }
        }
      },
    },
  };

  return (
    <React.Fragment>
      <div
        className="bg-white w-full md:w-1/2 rounded-lg shadow mt-4 py-3"
        id="chart"
      >
        {isLoading ? (
          <div className="h-96 bg-white flex flex-col items-center gap-6 px-4  justify-center">
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
          </div>
        ) : (
          <div>
            <div className="font-bold text-2xl mt-2 tracking-wide mx-4">
              Membership Revenue
            </div>

            <ReactApexChart
              options={state.options}
              series={state.series}
              type="line"
              height={350}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default MembershipRevenue;