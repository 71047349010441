import React, { useState } from 'react';
import { useSelector } from "react-redux";
import StaffData from './StaffData';
import StaffServices from './StaffServices';

const NewStaffTable = ({ staff,fetchStaffData,setStaff }) => {
    const admin = useSelector((state) => state.admin);
    const [isSelected, setIsSelected] = useState("detail");

    const classes = {
        active:
            "h-auto min-h-[10px] w-full  flex content-center items-center justify-center text-center font-bold text-md text-[#FF782D] bg-[#fff] rounded-tl-md cursor-pointer transition ease-in-out duration-300 transform text-[20px]",
        inactive:
            "h-12 min-h-[10px] w-full  flex content-center items-center justify-center text-center text-md  text-[#C0C0C0] bg-[#F3F3F3] hover:bg-[#fff] hover:text-gray-900 rounded-tr-md cursor-pointer transition ease-in-out duration-300 transform",
        // selected: "h-16 rounded-tl-md h-auto min-h-[48px] rounded-tl-md",
    };

    return (
        <React.Fragment>
            <div className=" w-full overflow-x-auto  rounded-md bg-white">
                <div className="min-w-full leading-normal">
                    <div className="flex justify-between items-center">
                        <div
                            onClick={() => setIsSelected("detail")}
                            className={
                                isSelected === "detail"
                                    ? `${classes.active} `
                                    : classes.inactive
                            }
                        >
                            Details
                        </div>
                        <div
                            onClick={() => setIsSelected("history")}
                            className={
                                isSelected === "history"
                                    ? `${classes.active} `
                                    : classes.inactive
                            }
                        >
                            Service History
                        </div>
                        <div
                            onClick={() => setIsSelected("edit")}
                            className={
                                isSelected === "edit"
                                    ? `${classes.active}`
                                    : classes.inactive
                            }
                        >
                            Edit Credentials
                        </div>
                    </div>
                    {isSelected === "detail" && (
                        <StaffData staff={staff} setStaff={setStaff} showDeleteButton={true} fetchStaffData={fetchStaffData}/>
                    )}
                    {isSelected === "history" && (
                        <StaffServices staff={staff} />
                    )}
                    {isSelected === "edit" && (
                        <StaffData staff={staff} setStaff={setStaff} showDeleteButton={false} fetchStaffData={fetchStaffData} />
                    )}
                </div>

            </div>
        </React.Fragment>
    );
}

export default NewStaffTable;
