import React, { useState } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import html2pdf from "html2pdf.js";
import axios from "axios";
import kaya from "../../../Assets/i-ber-removebg-preview.png";
import kayaJharsuguda from '../../../Assets/KayaJharsuguda-removebg-preview.png';
import { useSelector } from "react-redux";

export default function InvoiceDownload({ id }) {
  const [isLoading, setIsLoading] = useState(false);
  const branch = useSelector((state) => state?.main?.branch) || "";

  const HandleDownload = async () => {
    setIsLoading(true);
    try {
      const perticularBillData = await fetchBillData(id);
      if (perticularBillData) {
        GeneratePDF(perticularBillData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const GeneratePDF = (perticularBillData) => {
    const branchName = branch?.name || "";
    const branchLocation = branch?.address || "";
    const branchPhone = branch?.primaryPhone || "";
    const branchPic = branch?.url || kaya;


    const htmlContent = `
      <div style="width: 100%;">
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
          body {
            font-family: Nunito;
            margin: 0;
            padding: 50px;
          }
          .invoice {
            width: 700px;
            margin: 0 auto;
            border: 1px solid #ccc;
            padding: 20px;
            box-sizing: border-box;
          }
          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
          }
          .header img {
            width: 120px;
            height: auto;
          }
          .from, .to {
            width: 50%;
            box-sizing: border-box;
          }
          .from {
            float: left;
            text-align: left;
          }
          .to {
            float: right;
            text-align: right;
          }
          .clearfix::after {
            content: "";
            clear: both;
            display: table;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 20px;
          }
          th, td {
            padding: 12px 8px;
            text-align: center;
          }
          th {
            background-color: #fe8740;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
          }
          tr:nth-child(even) {
            background-color: #f2f2f2;
          }
          tr.total-row td {
            background-color: #e9ecef;
            font-weight: 700;
          }
          .total {
            text-align: right;
            margin-top: 20px;
          }
          .payment {
            margin-top: 20px;
          }
          .terms {
            margin-top: 20px;
          }
        </style>
        <div class="invoice">
          <div class="header">
            <img src="${kayaJharsuguda}" alt="Kaya Spa" style="width: 120px; height: auto;">
            <div>
              <h2 style="text-align: right;">INVOICE</h2>
              <p style="text-align: right;">Invoice No. - ${
                perticularBillData?.invoiceNumber
              }</p>
              <p style="text-align: right;">Date/Time - ${new Date()
                .toISOString()
                .slice(0, 10)}, ${new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })}</p>
            </div>
          </div>
          <div class="clearfix">
            <div class="from">
              <h3>Invoice From:</h3>
              <p>${branchName}</p>
              <p>AT - ${branchLocation}</p>
              <p>Mob - ${branchPhone}</p>
            </div>
            <div class="to">
              <h3>Invoice To:</h3>
              <p>${perticularBillData?.billingInfo?.name}</p>
              <p>Mob - ${perticularBillData?.billingInfo?.phone}</p>
              <span>Wallet Bal.: Rs.0.00/-</span>
              <p>Loyalty Point Bal: 0.00/-</p>
            </div>
          </div>
          
          <table>
            <tr>
              <th>Sl No</th>
              <th>Services</th>
              <th>Qty</th>
              <th>Amount</th>
              <th>Discount</th>
              <th>Total Amount</th>
            </tr>
        
            ${perticularBillData?.services
              ?.map(
                (service, index) => `
              <tr key=${index}>
                <td style="text-align: center;">${index + 1}</td>
                <td>${service?.serviceName} (${service?.duration})</td>
                <td style="text-align: center;">${service?.quantity}</td>
                <td style="text-align: right;">₹${
                  service?.itemPerPrice * service?.quantity
                }</td>
                <td style="text-align: right;">${
                  service?.discountType === "flat"
                    ? service?.discount
                    : service?.discountType === "percentage"
                    ? service?.discount + " %"
                    : ""
                }</td>
                <td style="text-align: right;">₹${service?.price}</td>
              </tr>
            `
              )
              .join("")}
        
            ${perticularBillData?.products
              ?.map(
                (product, index) => `
              <tr key=${index}>
                <td style="text-align: center;">${index + 1}</td>
                <td>${product?.productName}</td>
                <td style="text-align: center;">${product?.quantity}</td>
                <td style="text-align: right;">₹${
                  product?.itemPerPrice * product?.quantity
                }</td>
                <td style="text-align: right;">${
                  product?.discountType === "flat"
                    ? product?.discount
                    : product?.discountType === "percentage"
                    ? product?.discount + " %"
                    : ""
                }</td>
                <td style="text-align: right;">₹${product?.price}</td>
              </tr>
            `
              )
              .join("")}
        
            <tr>
              <td colspan="4" style="padding: 20px;"></td>
              <td colspan="2" style="padding: 20px;"></td>
            </tr>
            <tr>
              <td colspan="4" style="padding: 20px;"></td>
              <td colspan="2" style="padding: 20px;"></td>
            </tr>
            <tr>
              <td colspan="4" style="padding: 20px;"></td>
              <td colspan="2" style="padding: 20px;"></td>
            </tr>
            <tr>
              <td colspan="4" style="padding: 20px;"></td>
              <td colspan="2" style="padding: 20px;"></td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: right;">Free Services</td>
              <td colspan="2" style="text-align: left;"></td>
            </tr>
          </table>
        
          <table>
            <tr>
              <td colspan="4" style="text-align: left;">Amount:</td>
              <td colspan="2" style="text-align: right;">₹${
                perticularBillData?.finalPrice
              }</td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: left;">Total (Rounded off)</td>
              <td colspan="2" style="text-align: right;">₹${
                perticularBillData?.finalPrice
              }</td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: left;">Paid Amount</td>
              <td colspan="2" style="text-align: right;">₹${
                perticularBillData?.paidAmount
              }</td>
            </tr>
          </table>
        
          <div class="terms">
            <h3>Terms &amp; Conditions</h3>
            <ul>
              <li class="text-sm">Money Once Paid Shall not be refunded</li>
              <li class="text-sm">Services are not transferable</li>
            </ul>
          </div>
        </div>
      </div>
    `;

      html2pdf()
        .from(htmlContent)
        .set({ margin: 10, filename: "invoice.pdf" })
        .save();
  };

  const fetchBillData = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/billing/find-billing`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
      },
    };
    try {
      const res = await axios.request(config);
      const allData = res?.data?.data;
      const filterBill = allData?.find((ele) => ele._id === id);
      if (filterBill) {
        return filterBill;
      }
    } catch (error) {
      console.error("Error fetching bill data:", error);
      return null;
    }
  };

  return (
    <div>
      <button
        onClick={HandleDownload}
        className="flex items-center justify-center gap-1 text-white rounded"
        style={{ position: "relative" }}
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-6 h-6 border-2 border-t-0 border-orange-500 rounded-full animate-spin"></div>
          </div>
        )}
        {isLoading ? (
          <MdOutlineFileDownload className="w-6 h-6 text-transparent" />
        ) : (
          <MdOutlineFileDownload className="w-6 h-6 text-orange-500" />
        )}
      </button>
    </div>
  );
}
