import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import { ImSearch } from "react-icons/im";
import axios from "axios";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import NewStaffTable from "./NewStaffTable";
import PermissionScreen from "./Permission/PermissionScreen";
import NewAddStaff from "./StaffModals/NewAddStaff";
import CustomRoleName from "./Permission/CustomRoleName";
import underConstruction from "../../Assets/underConstruction.jpg";
import add from "../../Assets/AddFrame(1).png";
import ScheduledData from "./ScheduledData";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StaffDetail = ({ fetchStaffData, isLoading }) => {
  const admin = useSelector((state) => state.admin);
  const main = useSelector((state) => state.main);
  const branch =
    useSelector((state) => state?.main?.branch) ||
    getEncryptItems("branchDetails");
  const [openAddStaffModal, setOpenAddStaffModal] = useState(false);
  const [openCreateRollName, setOpenCreateRollName] = useState(false);
  const [isSelected, setIsSelected] = useState("createRole");
  const [searchData, setSearchData] = useState("");
  const [staff, setStaff] = useState(null);
  const [results, setResults] = useState([]);
  const [selectedItem, setSelectedItem] = useState("managestaff");
  const [activeChecked, setActiveChecked] = useState(false);
  const [inactiveChecked, setInactiveChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(true);
  const [customerRoles, setCustomerRoles] = useState([]);
  const [selectedCustomRole, setSelectedCustomRole] = useState({});
  let userRole = decryptData()?.user?.role;
  const branchId = getEncryptItems("branchDetails")?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const staffs =
    main?.staffList?.filter(
      (stf) =>
        stf.organizationId === branch._id && branchId === stf.organizationId
    ) || [];

  const currentItems = activeChecked
    ? staffs?.filter((item) => item?.status === "active")
    : inactiveChecked
    ? staffs?.filter?.((item) => item?.status === "inactive")
    : staffs;

  const classes = {
    active:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md text-white bg-primaryColor  cursor-pointer hover:bg-primaryHoverColor transition ease-in-out duration-300 transform",
    inactive:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md  text-[#7f8296] bg-transparent hover:bg-[#febc95] hover:text-gray-900  cursor-pointer transition ease-in-out duration-300 transform",
  };

  const fetchCustomerRoles = useCallback(async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customeRole/find-roles`,
      headers: {
        Authorization: token,
      },
    };

    try {
      const response = await axios.request(config);
      setCustomerRoles(response?.data?.customRoles);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  }, [token]);

  useEffect(() => {
    fetchStaffData();
    fetchCustomerRoles();
  }, [fetchCustomerRoles, fetchStaffData, branch]);

  const handleSearch = (e) => {
    e.preventDefault();
    const result =
      isSelected === "assignRole" || selectedItem === "managestaff"
        ? admin?.staffList?.filter((item) =>
            item?.userId?.firstName
              .toLowerCase()
              .includes(searchData.toLowerCase())
          )
        : customerRoles?.filter((item) =>
            item?.roleName
              ?.toLowerCase()
              ?.includes(searchData.toLocaleLowerCase())
          );
    setResults(result);
  };

  const handleChange = (e) => {
    setResults([]);
    setSearchData(e.target.value);
  };

  const handleClear = () => {
    setSearchData("");
    setResults([]);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    switch (value) {
      case "active":
        setActiveChecked(checked);
        setInactiveChecked(false);
        setAllChecked(false);
        break;
      case "inactive":
        setActiveChecked(false);
        setInactiveChecked(checked);
        setAllChecked(false);
        break;
      case "all":
        setActiveChecked(false);
        setInactiveChecked(false);
        setAllChecked(checked);
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <NewAddStaff
        openAddStaffModal={openAddStaffModal}
        setOpenAddStaffModal={setOpenAddStaffModal}
        staffDetails={fetchStaffData}
      />

      <CustomRoleName
        fetchCustomerRoles={fetchCustomerRoles}
        openCreateRollName={openCreateRollName}
        setOpenCreateRollName={setOpenCreateRollName}
      />

      <div className="mb-2"></div>

      <div className="mx-auto mt-4">
        <div className="scroll-container overflow-x-auto inset-x-0 rounded-xl bg-white">
          <div className="min-w-full leading-normal">
            <div className="flex justify-between items-center lg:gap-4 gap-6">
              <div
                onClick={() => {
                  setResults([]);
                  setSelectedItem("managestaff");
                }}
                className={
                  selectedItem === "managestaff"
                    ? classes.active
                    : classes.inactive
                }
              >
                Manage Staff
              </div>

              <div
                onClick={() => {
                  setResults([]);
                  setSelectedItem("permission");
                }}
                className={
                  selectedItem === "permission"
                    ? classes.active
                    : classes.inactive
                }
              >
                Permission
              </div>

              <div
                onClick={() => setSelectedItem("Schedule")}
                className={
                  selectedItem === "Schedule"
                    ? classes.active
                    : classes.inactive
                }
              >
                Schedule
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem === "managestaff" && (
        <div className="mx-auto mt-4">
          {isLoading ? (
            <React.Fragment>
              <div className="flex lg:flex-row flex-col gap-4">
                <div className="flex flex-col gap-5 bg-[#FFFFFF] rounded-md lg:w-[35%] w-full min-h-[450px]">
                  <div className="flex items-center justify-between px-3">
                    <div className="p-2 w-full">
                      <Skeleton height={40} />
                    </div>
                  </div>
                  <div className="flex justify-between px-4 w-full">
                    <Skeleton width={80} height={20} />
                    <Skeleton width={80} height={20} />
                    <Skeleton width={80} height={20} />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex justify-between bg-[#F0FAF7] w-full py-3 px-7">
                      <div className="text-[#101828] animate-pulse"></div>
                      <div className="text-[#101828] animate-pulse"></div>
                    </div>
                    <div className="overflow-auto max-h-[300px]">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <div
                          key={index}
                          className="w-full py-3 px-7 bg-gray-50 cursor-pointer"
                        >
                          <div className="flex justify-between">
                            <Skeleton width={100} />
                            <Skeleton width={50} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="lg:w-[65%] w-full">
                  <Skeleton height={450} />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="flex lg:flex-row flex-col gap-4">
              <div className="flex flex-col gap-5 bg-[#FFFFFF] rounded-md lg:w-[35%] w-full min-h-[450px]">
                <div className="flex items-center justify-between px-3">
                  <form onSubmit={(e) => handleSearch(e)} className="p-2">
                    <div className="bg-white rounded shadow w-full flex p-1 relative">
                      <input
                        id="searchData"
                        className="border-0 rounded px-4 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                        placeholder="Search By Name"
                        type="text"
                        value={searchData}
                        onChange={handleChange}
                      />
                      {searchData && (
                        <span
                          className="absolute right-14 top-3 cursor-pointer"
                          onClick={handleClear}
                        >
                          X
                        </span>
                      )}

                      <button
                        onClick={handleSearch}
                        className="flex items-center justify-center  transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
                      >
                        <ImSearch color="black" />
                      </button>
                    </div>
                  </form>
                  <div>
                    {userRole === "admin" && (
                      <button
                        onClick={() => setOpenAddStaffModal(true)}
                        className="flex gap-1 cursor-pointer"
                      >
                        <p className="text-[15px]">Add Staff</p>
                        <FaPlus size={20} color="orange" />
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex justify-between px-4 w-full">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="active"
                      onChange={handleCheckboxChange}
                      checked={activeChecked}
                    />
                    <span className="ml-1">Active</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="inactive"
                      onChange={handleCheckboxChange}
                      checked={inactiveChecked}
                    />
                    <span className="ml-1">Inactive</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="all"
                      onChange={handleCheckboxChange}
                      checked={allChecked}
                    />
                    <span className="ml-1">All Staff</span>
                  </label>
                </div>

                {searchData && results ? (
                  <div className="flex flex-col">
                    <div className="flex justify-between bg-[#F0FAF7] w-full py-3 px-7">
                      <div className="text-[#101828]">Name</div>
                      <div className="text-[#101828]">Status</div>
                    </div>
                    <div
                      className="overflow-auto max-h-[300px] "
                      // style={{ minHeight: "calc(100vh - 450px)" }}
                    >
                      {results.map((ele, ind) => {
                        return (
                          <div
                            key={ind}
                            className={`w-full py-3 px-7 capitalize ${
                              staff === ele
                                ? "bg-[#FAF0F0] cursor-pointer"
                                : "bg-gray-50 hover:bg-[#FAF0F0] cursor-pointer"
                            }`}
                          >
                            <div
                              className="flex justify-between"
                              onClick={() => setStaff(ele)}
                            >
                              <span>
                                {ele?.userId?.firstName +
                                  " " +
                                  ele?.userId?.lastName}
                              </span>
                              <span>{ele?.status}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : searchData && results?.length === 0 ? (
                  <div className="text-[#101828] text-center mt-4">
                    No Data Found
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="flex justify-between bg-[#F0FAF7] w-full py-3 px-7">
                      <div className="text-[#101828]">Name</div>
                      <div className="text-[#101828]">Status</div>
                    </div>
                    <div
                      className="overflow-auto no-scrollbar max-h-[300px]"
                      // style={{ minHeight: "200px" }}
                    >
                      {currentItems?.map((ele, ind) => {
                        return (
                          <div
                            key={ind}
                            className={`w-full py-3 px-7 capitalize ${
                              staff === ele
                                ? "bg-[#FAF0F0] cursor-pointer"
                                : "bg-gray-50 hover:bg-[#FAF0F0] cursor-pointer"
                            }`}
                          >
                            <div
                              className="flex justify-between"
                              onClick={() => setStaff(ele)}
                            >
                              <span>
                                {ele?.userId?.firstName +
                                  " " +
                                  ele?.userId?.lastName}
                              </span>
                              <span>{ele?.status}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>

              <div className="lg:w-[65%] w-full">
                <NewStaffTable
                  staff={staff}
                  setStaff={setStaff}
                  fetchStaffData={fetchStaffData}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {selectedItem === "permission" && (
        <div className="mx-auto mt-4">
          {isLoading ? (
            <React.Fragment>
              <div className="flex lg:flex-row flex-col gap-4">
                <div className="flex flex-col gap-5 bg-[#FFFFFF] rounded-md lg:w-[35%] w-full min-h-[450px]">
                  <div className="flex items-center justify-between px-3">
                    <div className="p-2 w-full">
                      <Skeleton height={40} />
                    </div>
                  </div>
                  <div className="flex justify-between px-4 w-full">
                    <Skeleton width={80} height={20} />
                    <Skeleton width={80} height={20} />
                    <Skeleton width={80} height={20} />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex justify-between bg-[#F0FAF7] w-full py-3 px-7">
                      <div className="text-[#101828] animate-pulse"></div>
                      <div className="text-[#101828] animate-pulse"></div>
                    </div>
                    <div className="overflow-auto max-h-[300px]">
                      {Array.from({ length: 5 }).map((_, index) => (
                        <div
                          key={index}
                          className="w-full py-3 px-7 bg-gray-50 cursor-pointer"
                        >
                          <div className="flex justify-between">
                            <Skeleton width={100} />
                            <Skeleton width={50} />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="lg:w-[65%] w-full">
                  <Skeleton height={450} />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="flex lg:flex-row flex-col gap-4">
              <div className="flex flex-col gap-5 bg-[#FFFFFF] rounded-md lg:w-[35%] w-full min-h-[450px]">
                {/* {(isSelected === "createRole" ||
                    isSelected === "editRole") && (
                    <div
                      onClick={() => setOpenCreateRollName(true)}
                      className="flex gap-1 cursor-pointer"
                    >
                      <p className="text-[15px]">Create Role</p>
                      <FaPlus size={20} color="orange" />
                    </div>
                  )} */}

                {/* Create Role Tab */}
                {(isSelected === "createRole" || isSelected === "editRole") && (
                  <>
                    <div className="flex items-center justify-between px-3">
                      <form onSubmit={(e) => handleSearch(e)} className="p-2">
                        <div className="bg-white rounded shadow w-full flex p-1 relative">
                          <input
                            id="searchData"
                            className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                            placeholder="Search By Name"
                            type="text"
                            value={searchData}
                            onChange={handleChange}
                          />
                          {searchData && (
                            <span
                              className="absolute right-14 top-3 cursor-pointer"
                              onClick={handleClear}
                            >
                              X
                            </span>
                          )}

                          <button
                            onClick={handleSearch}
                            className="flex items-center justify-center  transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
                          >
                            <ImSearch color="black" />
                          </button>
                        </div>
                      </form>
                      <div
                        onClick={() => setOpenCreateRollName(true)}
                        className="flex gap-1 cursor-pointer"
                      >
                        <p className="text-[15px]">Create Role</p>
                        <FaPlus size={20} color="orange" />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="flex justify-center items-center bg-[#F0FAF7] w-full py-3 px-7">
                        <div className="text-[#101828]">Role Name</div>
                      </div>
                      <div
                        className="overflow-auto no-scrollbar max-h-[300px]"
                        // style={{ maxHeight: "calc(100vh - 450px)" }}
                      >
                        <div className="flex flex-col">
                          {searchData && results ? (
                            results.map((role, index) => (
                              <div
                                key={index}
                                onClick={() => setSelectedCustomRole(role)}
                                className={`w-full py-3 px-7 capitalize ${
                                  selectedCustomRole === role
                                    ? "bg-[#FAF0F0] cursor-pointer"
                                    : "bg-gray-50 hover:bg-[#FAF0F0] cursor-pointer"
                                }`}
                              >
                                {role?.roleName}
                              </div>
                            ))
                          ) : searchData && results?.length === 0 ? (
                            <div className="text-[#101828] text-center mt-4">
                              No Data Found
                            </div>
                          ) : (
                            customerRoles?.map((role, index) => (
                              <div
                                key={index}
                                onClick={() => setSelectedCustomRole(role)}
                                className={`w-full py-3 px-7 capitalize ${
                                  selectedCustomRole === role
                                    ? "bg-[#FAF0F0] cursor-pointer"
                                    : "bg-gray-50 hover:bg-[#FAF0F0] cursor-pointer"
                                }`}
                              >
                                {role?.roleName}
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Assign Role Tab */}
                {isSelected === "assignRole" && (
                  <>
                    <form onSubmit={(e) => handleSearch(e)} className="p-2">
                      <div className="bg-white rounded shadow w-full flex p-1 relative">
                        <input
                          id="searchData"
                          className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                          placeholder="Search By Name"
                          type="text"
                          value={searchData}
                          onChange={handleChange}
                        />
                        {searchData && (
                          <span
                            className="absolute right-14 top-3 cursor-pointer"
                            onClick={handleClear}
                          >
                            X
                          </span>
                        )}

                        <button
                          onClick={handleSearch}
                          className="flex items-center justify-center  transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
                        >
                          <ImSearch color="black" />
                        </button>
                      </div>
                    </form>
                    {searchData && results ? (
                      <div className="flex flex-col">
                        <div className="flex justify-between bg-[#F0FAF7] w-full py-3 px-7">
                          <div className="text-[#101828]">Name</div>
                          <div className="text-[#101828]">Status</div>
                        </div>
                        <div
                          className="overflow-auto max-h-[300px] no-scrollbar"
                          // style={{ maxHeight: "calc(100vh - 500px)" }}
                        >
                          {results.map((ele, ind) => (
                            <div
                              key={ind}
                              className={`w-full py-3 px-7 capitalize ${
                                staff === ele
                                  ? "bg-[#FAF0F0] cursor-pointer"
                                  : "bg-gray-50 hover:bg-[#FAF0F0] cursor-pointer"
                              }`}
                            >
                              <div
                                className="flex justify-between"
                                onClick={() => setStaff(ele)}
                              >
                                <span>
                                  {ele?.userId?.firstName +
                                    " " +
                                    ele?.userId?.lastName}
                                </span>
                                <span>{ele?.status}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : searchData && results?.length === 0 ? (
                      <div className="text-[#101828] text-center mt-4">
                        No Data Found
                      </div>
                    ) : (
                      <div className="flex flex-col">
                        <div className="flex justify-between bg-[#F0FAF7] w-full py-3 px-7">
                          <div className="text-[#101828]">Name</div>
                          <div className="text-[#101828]">Status</div>
                        </div>
                        <div
                          className="overflow-auto max-h-[300px] no-scrollbar"
                          // style={{ maxHeight: "calc(100vh - 500px)" }}
                        >
                          {currentItems?.map((ele, ind) => (
                            <div
                              key={ind}
                              className={`w-full py-3 px-7 capitalize ${
                                staff === ele
                                  ? "bg-[#FAF0F0] cursor-pointer"
                                  : "bg-gray-50 hover:bg-[#FAF0F0] cursor-pointer"
                              }`}
                            >
                              <div
                                className="flex justify-between"
                                onClick={() => setStaff(ele)}
                              >
                                <span>
                                  {ele?.userId?.firstName +
                                    " " +
                                    ele?.userId?.lastName}
                                </span>
                                <span>{ele?.status}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className="lg:w-[65%] w-full">
                <PermissionScreen
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  customerRoles={customerRoles}
                  selectedCustomRole={selectedCustomRole}
                  fetchCustomerRoles={fetchCustomerRoles}
                  fetchStaffData={fetchStaffData}
                  staff={staff}
                  setResults={setResults}
                  setStaff={setStaff}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {selectedItem === "Schedule" && <ScheduledData />}
    </React.Fragment>
  );
};

export default StaffDetail;
