import React from "react";
import "./Sidebar.css";
import SidebarsContent from "./SidebarsContent";
// import { ImScissors } from 'react-icons/im';
import logo from "../../Assets/i-ber-removebg-preview.png";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <React.Fragment>
      {
        <div
          className={`no-scrollbar bg-white left-0 fixed inset-y-0 transform transition-all duration-500 ease-linear  shadow py-4 px-6 ${
            isOpen ? "w-48" : "w-20"
          }`}
          // onMouseEnter={() => setIsOpen(true)}
        >
          <div
            className={`flex justify-center mb-2 ${
              isOpen ? "mx-0  w-full" : " mx-[-60%] w-[70px]"
            }`}
          >
            <img
              src={logo}
              alt="LOGO"
              className={`${isOpen ? "w-[80%]" : "w-[100%]"}`}
            />
          </div>
          <div
            // onMouseLeave={() => setIsOpen(false)}
          >
            <SidebarsContent isOpen={isOpen} toggleSidebar={toggleSidebar} />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default Sidebar;
{
  /* {isOpen &&
      <div className='text-2xl font-bold ml-2'>
        {process.env.REACT_APP_NAME}
      </div>} */
}
