import React from "react";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import addExcel from "../../Assets/xls.png";
import pdf from "../../Assets/Pdf.png";

const DownloadTableData = ({ headerData, rowData }) => {
  // const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDownload = (format) => {
    switch (format) {
      case "CSV":
        handleDownloadCSV();
        break;
      case "PDF":
        handleDownloadPDF();
        break;
      default:
        break;
    }
    // setDropdownOpen(false);
  };

  const handleDownloadCSV = () => {
    const csv = [
      headerData.map((header) => header.label).join(","),
      ...rowData.map((row) =>
        headerData.map((header) => row[header.field]).join(",")
      ),
    ].join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "table_data.csv");
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const tableData = rowData.map((row, index) => {
      const rowDataWithIndex = { "Sl. No.": index + 1 };
      headerData.forEach((header) => {
        rowDataWithIndex[header.label] = row[header.field];
      });
      return rowDataWithIndex;
    });
    const tableHeaders = [
      "Sl. No.",
      ...headerData.map((header) => header.label),
    ];

    // Add a title to the PDF
    doc.setFontSize(16);
    doc.text("Report Table", 20, 20);

    // Add a date to the PDF
    const date = new Date().toLocaleDateString();
    doc.setFontSize(12);
    doc.text(`Date: ${date}`, 20, 30);

    // Add the table content to the PDF using autoTable
    doc.autoTable({
      startY: 40,
      head: [tableHeaders],
      body: tableData.map((row) => Object.values(row)),
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [254, 135, 64] },
      alternateRowStyles: { fillColor: [250, 250, 250] },
    });

    // Save the PDF
    doc.save("table_data.pdf");
  };

  return (
    <div>
      <div
        // onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-2"
        // className="bg-primaryColor w-48 px-2 rounded-lg hover:bg-primaryHoverColor text-white tracking-wide py-3"
      >
        <button onClick={() => handleDownload("CSV")}>
          <img
            src={addExcel}
            alt="import excel"
            className="w-7 h-7 cursor-pointer"
          />
        </button>
        <button onClick={() => handleDownload("PDF")}>
          <img src={pdf} alt="import pdf" className="w-7 h-7 cursor-pointer" />
        </button>
      </div>
      {/* {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1"></div>
        </div>
      )} */}
    </div>
  );
};

export default DownloadTableData;
