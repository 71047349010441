import React, { useEffect, useState } from 'react';
import AddLoyality from './MembershipModals/AddLoyality';
import { ImSearch } from 'react-icons/im';
import DownloadTableData from '../../Screens/Common/downloadData';
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import { getEncryptItems } from '../../Screens/Common/localStorageUtils';
import axios from 'axios';
import { useSelector } from 'react-redux';

const LoyalityPointTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loyalties, setLoyalties] = useState([]);
  const [editableLoyalty, setEditableLoyalty] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [results, setResults] = useState([]);
  const branch = useSelector(state => state?.main?.branch)?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results?.length
    ? results?.slice(
      indexOfFirstItem,
      indexOfLastItem)
    : loyalties?.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getAllLevels();
  }, [branch]);

  const getAllLevels = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/loyalty/find-loyalty/${branch}`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        setLoyalties(response?.data?.data?.loyalties);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpenEdit = (id) => {
    const loyalty = loyalties?.find(ele => ele?._id === id);
    setEditableLoyalty(loyalty);
  }

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    const result = loyalties?.filter(ele => ele?.segment?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()));
    setResults(result);
  }

  const headersData =
    ["Sl", "Segments", "Level", "Reward Type", "Discount Type", "Discount Value", "Points Percent",
      "Points Value", "Max Inactive Days", "Applicable On", "Actions"];
  const headerData = [
    { label: "Segment", field: "Segment" },
    { label: "Level", field: "Level" },
    { label: "Reward Type", field: "Reward_Type" },
    { label: "Discount Type", field: "Discount_Type" },
    { label: "Discount Value", field: "discountValue" },
    { label: "Points Percent", field: "Points_Percent" },
    { label: "Points Value", field: "Points_Value" },
    { label: "Max Inactive Days", field: "Max_Inactive_Days" },
    { label: "Applicable On", field: "Applicable_On" },
  ];
  const rowData = loyalties?.map((loyalty, index) => ({
    Segment: loyalty?.segment,
    Level: loyalty?.level,
    Reward_Type: loyalty?.rewardType,
    Discount_Type: loyalty?.discountType || "--",
    Discount_Value: loyalty?.discountValue || "--",
    Points_Percent: loyalty?.pointsPercent || "--",
    Points_Value: loyalty?.pointsValue || "--",
    Max_Inactive_Days: loyalty?.maxInactiveDays,
    Applicable_On: loyalty?.applyOn
  }));

  return (
    <div className='flex gap-4 h-full md:h-[75vh] mt-4 flex-col-reverse md:flex-row'>
      <div className='w-full md:w-1/2 flex flex-col gap-5 text-[#7f8296] bg-white shadow p-4 rounded-md'>
        <h1 className='text-justify'>
          Note: One customer will be associated with only one loyalty level at a time. (Level 1 has the lowest priority)
        </h1>

        <div>
          {loyalties?.length === 0 ? (
            <div>
              <p className='text-center'>No levels</p>
              <p className='text-center'>Gift your favourite customer</p>
            </div>
          ) : (
            <div className="mx-auto mt-4">
              <div className="px-5 py-5 bg-white text-black border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4">
                <div className="font-bold text-2xl tracking-wide">
                  Loyalty Levels
                </div>

                <div className="bg-white rounded-lg flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                  <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                    <ImSearch color="gray" size={15} />
                  </span>
                  <input
                    id="searchData"
                    className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none pr-6"
                    placeholder="Search By Segment"
                    type="text"
                    onChange={(e) => {
                      setCurrentPage(1);
                      handleSearch(e);
                    }}
                  />
                </div>

                <div className="font-bold text-2xl tracking-wide flex justify-end gap-2">
                  <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
                    <DownloadTableData
                      rowData={rowData}
                      headerData={headerData}
                    />
                  </div>
                </div>
              </div>

              {isLoading ? (
                <SkeletonTableLoader />
              ) : searchData && results?.length ? <div className="shadow rounded-t-lg overflow-x-auto">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="bg-gray-100">
                      {headersData?.map((header, index) => (
                        <th key={index} className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {results?.map((loyalty, index) => (
                      <tr key={index}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {index + 1}
                          </div>
                        </td>
                        <td className="px-5 py-5 border text-gray-900 border-gray-200 bg-white text-sm capitalize">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.segment}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.level}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.rewardType}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.discountType || "---"}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.discountValue || "---"}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.pointsPercent || "---"}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.pointsValue || "---"}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.maxInactiveDays}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {loyalty.applyOn}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="whitespace-no-wrap flex">
                            <RiEdit2Fill
                              size={15}
                              onClick={() => handleOpenEdit(loyalty._id)}
                              className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                            />
                            <RiDeleteBin5Fill
                              className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                              size={15}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> :
                searchData && results?.length === 0 ?
                  <div className='flex justify-center items-center text-xl text-black h-[40vh]'>
                    No Levels Found
                  </div> : (
                    <div className="shadow rounded-t-lg overflow-x-auto">
                      <table className="min-w-full leading-normal">
                        <thead>
                          <tr className="bg-gray-100">
                            {headersData?.map((header, index) => (
                              <th key={index} className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((loyalty, index) => (
                            <tr key={index}>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {index + 1}
                                </div>
                              </td>
                              <td className="px-5 py-5 border text-gray-900 border-gray-200 bg-white text-sm capitalize">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.segment}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.level}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.rewardType}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.discountType || "---"}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.discountValue || "---"}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.pointsPercent || "---"}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.pointsValue || "---"}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.maxInactiveDays}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="text-gray-900 whitespace-no-wrap">
                                  {loyalty.applyOn}
                                </div>
                              </td>
                              <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                <div className="whitespace-no-wrap flex">
                                  <RiEdit2Fill
                                    size={15}
                                    onClick={() => handleOpenEdit(loyalty._id)}
                                    className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                  />
                                  <RiDeleteBin5Fill
                                    className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                    size={15}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              {(loyalties?.length !== 0 && (!searchData || (searchData && results?.length > 0))) ? (
                <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                  <span className="text-xs xs:text-sm text-gray-900">
                    Showing {indexOfFirstItem + 1} to{" "}
                    {indexOfLastItem > (results?.length || loyalties?.length)
                      ? (results?.length || loyalties?.length)
                      : indexOfLastItem} of{" "}
                    {results?.length || loyalties?.length} Entries
                  </span>
                  <div className="inline-flex mt-2 xs:mt-0">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`text-sm ${currentPage === 1
                          ? "bg-gray-200"
                          : "bg-gray-300 hover:bg-gray-400"
                        } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                    >
                      Prev
                    </button>
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil((results?.length || loyalties?.length) / itemsPerPage)
                      }
                      className={`text-sm ${currentPage ===
                          Math.ceil((results?.length || loyalties?.length) / itemsPerPage)
                          ? "bg-gray-200"
                          : "bg-gray-300 hover:bg-gray-400"
                        } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <div className='w-full md:w-1/2'>
        <AddLoyality
          editableLoyalty={editableLoyalty}
          setEditableLoyalty={setEditableLoyalty}
          getAllLevels={getAllLevels}
        />
      </div>
    </div>
  );
};

export default LoyalityPointTable;
