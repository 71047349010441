import React, { useEffect, useState } from 'react';
import { tostifyErr, tostifySuccess } from '../../Screens/Common/tostifyMessages';
import axios from 'axios';
import { getEncryptItems } from '../../Screens/Common/localStorageUtils';
import { useSelector } from 'react-redux';

const AddAssets = ({ fetchAssets, singleAsset }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const initialState = {
        title: "",
        titleErr: false,
        description: "",
        descriptionErr: false,
        capacity: "",
        capacityErr: false,
    }
    const [formData, setFormData] = useState(initialState);
    const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
    const token = "Bearer " + localStorage.getItem("refresh_token");

    useEffect(() => {
        if (Object?.keys(singleAsset)?.length > 0) {
            setFormData(prev => ({
                ...prev,
                title: singleAsset?.title,
                description: singleAsset?.description,
                capacity: singleAsset?.capacity
            }));
            setIsEdit(true);
        }
    }, [singleAsset]);

    const handleInputChange = (e) => {
        setFormData(prev => ({
            ...prev,
            [`${e.target.name}Err`]: false,
            [`${e.target.name}`]: e.target.value
        }));
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!formData?.title) {
            setFormData(prev => ({
                ...prev,
                titleErr: true,
            }));
        } else if (!formData?.capacity) {
            setFormData(prev => ({
                ...prev,
                capacityErr: true,
            }));
        } else {
            let data = JSON.stringify({
                title: formData?.title,
                description: formData?.description,
                capacity: formData?.capacity,
                organizationId: branch?._id
            });

            let config = {
                method: isEdit ? "put" : "post",
                maxBodyLength: Infinity,
                url: isEdit
                    ? `${process.env.REACT_APP_API_URL}/asset/update/${singleAsset?._id}`
                    : `${process.env.REACT_APP_API_URL}/asset/add`,
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
                data: data,
            };

            setIsLoading(true);
            axios
                .request(config)
                .then((response) => {
                    tostifySuccess(response?.data?.message);
                    setFormData(initialState);
                    setIsLoading(false);
                    setIsEdit(false);
                    fetchAssets();
                })
                .catch((error) => {
                    console.log(isEdit ? "update Assets error" : "add Assets error", error);
                    tostifyErr(error?.response?.data?.message);
                    setIsLoading(false);
                });
        }
    };

    return (
        <form onSubmit={handleFormSubmit}
            className='w-full flex flex-col items-center bg-white px-4 py-10 gap-4 rounded-lg h-full shadow-md'>
            <h1 className='text-center font-bold tracking-wide text-xl'>
                {isEdit ? "Update Asset" : "Add Asset"}
            </h1>
            <div className="relative w-[80%] mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                    Title
                </div>
                <input
                    type="text"
                    autoComplete="off"
                    id="title"
                    name='title'
                    value={formData?.title}
                    onChange={handleInputChange}
                    className={
                        formData?.titleErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Title"
                />
            </div>
            <div className="relative w-[80%] mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                    Capacity
                </div>
                <input
                    type="text"
                    autoComplete="off"
                    id="Capacity"
                    value={formData?.capacity}
                    onChange={handleInputChange}
                    name='capacity'
                    className={
                        formData?.capacityErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Capacity"
                />
            </div>
            <div className="relative w-[80%] mb-3">
                <div className="text-[#132c4a] block text-sm mb-2">
                    Description
                </div>
                <textarea
                    type="text"
                    autoComplete="off"
                    id="description"
                    name='description'
                    value={formData?.description}
                    onChange={handleInputChange}
                    className={
                        formData?.descriptionErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Description"
                />
            </div>

            <div className='flex justify-end w-[80%] gap-6'>
                <button onClick={() => {
                    setFormData(initialState);
                    setIsEdit(false);
                }}>
                    Cancel
                </button>
                <button
                    type="submit"
                    disabled={isLoading}
                    className='bg-primaryColor flex justify-center items-center w-24 py-2 text-white hover:bg-primaryHoverColor rounded-lg tracking-wide'>
                    {isLoading ?
                        <div className='w-6 h-6 rounded-full animate-spin border-y-4 border-dashed border-t-transparent border-white'>
                        </div> :
                        <span>
                            {isEdit ? "Update" : "Save"}
                        </span>}
                </button>
            </div>
        </form>
    );
}

export default AddAssets;
