import React, { useEffect, useState } from "react";
import { RiEdit2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import { ImSearch } from "react-icons/im";
// import Tostify from "../../Screens/Common/Tostify";
import axios from "axios";
import DeleteProduct from "./Products Modal/DeleteProduct";
import AddProducts from "./Products Modal/AddProducts";
import EditProducts from "./Products Modal/EditProducts";
import { useDispatch, useSelector } from "react-redux";
import { allProductList } from "../../state-management/admin/actions";
import add from '../../Assets/AddFrame(1).png'
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import { useNavigate } from "react-router-dom";
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";
import DownloadTableData from "../../Screens/Common/downloadData";

const ProductsTable = () => {
  const admin = useSelector((state) => state.admin);
  const main = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);
  const [openEditProduct, setOpenEditProduct] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);
  const [results, setResults] = useState([]);
  const [isConsumable, setIsConsumable] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
  const filteredProducts = main?.productList?.filter(
    (prod) => prod?.organization?._id === branch?._id
  );

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredProducts?.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  const handleOpenEdit = (id) => {
    setId(id);
    const initial = admin?.productList?.find((product) => product._id === id);
    setInitialValue(initial);
    setOpenEditProduct(true);
  };

  const handleToggle = () => {
    setIsConsumable(!isConsumable);
  };

  useEffect(() => {
    fetchProducts();
  }, [branch]);

  const fetchProducts = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/product/find-product?populate=organization`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    try {
      const res = await axios.request(config);
      setIsLoading(false);
      dispatch(allProductList(res?.data?.data));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    const result = filteredProducts?.filter((item) =>
      item.name.toLowerCase().includes(e.target.value?.toLowerCase())
    );
    setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    setClicked(false);
    setResults([]);
  };

  const rowData = results?.length ? results?.map(data => ({
    Name: data?.name,
    Type: data?.type,
    MRP: data?.costPriceMRP,
    Quantity: data?.quantity,
    Volume: data?.volume,
    MFG: data?.mfgDate,
    EXP: data?.expDate
  })) : filteredProducts?.map(data => ({
    Name: data?.name,
    Type: data?.type,
    MRP: data?.costPriceMRP,
    Quantity: data?.quantity,
    Volume: data?.volume,
    MFG: data?.mfgDate,
    EXP: data?.expDate
  }));
  const headerData = [
    { label: "Name", field: "Name" },
    { label: "Type", field: "Type" },
    { label: "MRP", field: "MRP" },
    { label: "Quantity", field: "Quantity" },
    { label: "Volume", field: "Volume" },
    { label: "MFG", field: "MFG" },
    { label: "EXP", field: "EXP" }]

  return (
    <React.Fragment>
      <DeleteProduct
        openDeleteProduct={openDeleteProduct}
        setOpenDeleteProduct={setOpenDeleteProduct}
        fetchProducts={fetchProducts}
        id={id}
        handleClear={handleClear}
        setId={setId}
      />

      <AddProducts
        fetchProducts={fetchProducts}
        openAddProduct={openAddProduct}
        setOpenAddProduct={setOpenAddProduct}
      />

      {initialValue && (
        <EditProducts
          fetchProducts={fetchProducts}
          setOpenEditProduct={setOpenEditProduct}
          openEditProduct={openEditProduct}
          handleOpenEdit={handleOpenEdit}
          id={id}
          setId={setId}
          handleClear={handleClear}
          initialValue={initialValue}
        />
      )}
      {/* <Tostify /> */}

      <div className="mx-auto mt-4">
        <div className="px-5 py-5 bg-white w-[100%] border-t flex flex-col xs:flex-row items-center xl:justify-between rounded-t-lg">
          <div className="font-bold text-2xl tracking-wide w-full">
            Products
          </div>

          <div className="flex w-full justify-center mt-2 xs:mt-0">
            <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
              <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                <ImSearch color="gray" size={15} />
              </span>
              <input
                id="searchData"
                className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                placeholder="Search Product"
                type="text"
                value={searchData}
                onChange={(e) => {
                  setCurrentPage(1);
                  handleSearch(e);
                }}
              />
              {searchData && (
                <span
                  className="absolute right-14 top-2 cursor-pointer"
                  onClick={handleClear}
                >
                  X
                </span>
              )}
            </div>
          </div>

          <div className="font-bold text-2xl tracking-wide w-full flex  justify-end gap-2">

            <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
              <DownloadTableData
                rowData={rowData}
                headerData={headerData}
              />
              {(userRole === "admin" ||
                (userRole === "staff" &&
                  userRoleName?.roleId?.permissions?.products?.createProduct)) && (
                  <>
                    <button
                      onClick={() => setOpenAddProduct(true)}
                    >
                      {0 ? (
                        <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                      ) : (
                        <span className="flex justify-center items-center">
                          <img
                            src={add}
                            alt="add"
                            className="text-primaryColor w-7 h-7"
                          />
                        </span>
                      )}
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <SkeletonTableLoader />
        ) : filteredProducts?.filter(
          (prod) => prod?.organization?._id === branch._id
        )?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] shadow-md py-4">
            No Products found
          </div>
        ) : searchData && results.length > 0 ? (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Type
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    MRP
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    quantity
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    volume
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    EXP
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    MFG
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                    {(userRole === "admin" ||
                      (userRole === "staff" &&
                        (userRoleName?.roleId?.permissions?.products
                          ?.editProduct ||
                          userRoleName?.roleId?.permissions?.invoice
                            ?.deleteProduct))) && <span>Actions</span>}
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {currentPage * 10 + i - 9}
                        </div>
                      </td>

                      <td
                        onClick={() =>
                          navigate(`/admin/productDetail/${v?._id}`)
                        }
                        className="px-5 py-5 border text-primaryColor border-gray-200 bg-white text-sm capitalize cursor-pointer hover:underline hover:text-primaryHoverColor  "
                      >
                        <div className="text-primaryColor hover:text-primaryHoverColor whitespace-no-wrap">
                          {v?.name}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.type}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.costPriceMRP}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.quantity}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.volume}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.expDate?.toString()?.substring(0, 10)}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.mfgDate?.toString()?.substring(0, 10)}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.products
                                ?.editProduct)) && (
                              <RiEdit2Fill
                                size={15}
                                onClick={() => {
                                  handleOpenEdit(v?._id);
                                }}
                                className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                              />
                            )}

                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.products
                                ?.deleteProduct)) && (
                              <RiDeleteBin5Fill
                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                size={15}
                                onClick={() => {
                                  setId(v?._id);
                                  setOpenDeleteProduct(true);
                                }}
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : searchData && results.length === 0 && clicked ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Product found
          </div>
        ) : (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Type
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    MRP
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    quantity
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    volume
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    EXP
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    MFG
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                    {userRole === "admin" || userRole === "staff"
                      ? (userRoleName?.roleId?.permissions?.products
                        ?.editProduct ||
                        userRoleName?.roleId?.permissions?.products
                          ?.deleteProduct) && <span>Actions</span>
                      : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems
                  ?.filter((prod) => prod?.organization?._id === branch._id)
                  .map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {currentPage * 10 + i - 9}
                          </div>
                        </td>

                        <td
                          onClick={() =>
                            navigate(`/admin/productDetail/${v?._id}`)
                          }
                          className="px-5 py-5 border text-primaryColor border-gray-200 bg-white text-sm capitalize cursor-pointer hover:underline hover:text-primaryHoverColor "
                        >
                          <div className="text-primaryColor hover:text-primaryHoverColor whitespace-no-wrap">
                            {v?.name}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.type}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.costPriceMRP}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.quantity}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.volume}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.expDate?.toString()?.substring(0, 10)}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.mfgDate?.toString()?.substring(0, 10)}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="whitespace-no-wrap flex">
                            {(userRole === "admin" ||
                              (userRole === "staff" &&
                                userRoleName?.roleId?.permissions?.products
                                  ?.editProduct)) && (
                                <RiEdit2Fill
                                  size={15}
                                  onClick={() => {
                                    handleOpenEdit(v?._id);
                                  }}
                                  className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                />
                              )}

                            {(userRole === "admin" ||
                              (userRole === "staff" &&
                                userRoleName?.roleId?.permissions?.products
                                  ?.deleteProduct)) && (
                                <RiDeleteBin5Fill
                                  className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                  size={15}
                                  onClick={() => {
                                    setId(v?._id);
                                    setOpenDeleteProduct(true);
                                  }}
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
        {(results?.length === 0 && filteredProducts?.length === 0) ||
          (searchData && results?.length === 0 && clicked) ? null : (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {results?.length
                ? indexOfFirstItem + results?.length
                : indexOfFirstItem + currentItems?.length}{" "}
              of {results?.length ? results?.length : filteredProducts?.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredProducts?.length / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                  Math.ceil(filteredProducts?.length / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProductsTable;
