import { legacy_createStore, applyMiddleware, compose, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import mainReducer from "./main/reducer";
import adminReducer from "./admin/reducer";
import staffReducer from "./staff/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewareCandidates = [thunk];

const enhancer = composeEnhancers(applyMiddleware(...middlewareCandidates));

const rootReducer = combineReducers({
    main: mainReducer,
    admin: adminReducer,
    staff: staffReducer,
  });

const store = legacy_createStore(
  rootReducer,
  enhancer
);

export default store;
