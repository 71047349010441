import React from "react";
import excel from "../../Assets/xls.png";
import pdf from "../../Assets/Pdf.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { saveAs } from "file-saver";

const DownloadReport = ({ rowData, headerData }) => {
  const handleDownload = (format) => {
    switch (format) {
      case "CSV":
        handleDownloadCSV();
        break;
      case "PDF":
        handleDownloadPDF();
        break;
      default:
        break;
    }
  };

  const handleDownloadCSV = () => {
    const csv = [
      headerData.map((header) => header.label).join(","),
      ...rowData.map((row) =>
        headerData.map((header) => row[header.field]).join(",")
      ),
    ].join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "table_data.csv");
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const tableData = rowData.map((row, index) => {
      const rowDataWithIndex = { "Sl. No.": index + 1 };
      headerData.forEach((header) => {
        rowDataWithIndex[header.label] = row[header.field];
      });
      return rowDataWithIndex;
    });
    const tableHeaders = [
      "Sl. No.",
      ...headerData.map((header) => header.label),
    ];

    // Add a title to the PDF
    doc.setFontSize(16);
    doc.text("Report Table", 20, 20);

    // Add a date to the PDF
    const date = new Date().toLocaleDateString();
    doc.setFontSize(12);
    doc.text(`Date: ${date}`, 20, 30);

    // Add the table content to the PDF using autoTable
    doc.autoTable({
      startY: 40,
      head: [tableHeaders],
      body: tableData.map((row) => Object.values(row)),
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [254, 135, 64] },
      alternateRowStyles: { fillColor: [250, 250, 250] },
    });

    // Save the PDF
    doc.save("table_data.pdf");
  };
  return (
    <>
      <div className="flex gap-2">
        <img
          src={excel}
          alt="excel"
          onClick={() => handleDownload("CSV")}
          className="cursor-pointer w-7 h-7"
          title="Download CSV"
        />
        <img
          src={pdf}
          alt="pdf"
          onClick={() => handleDownload("PDF")}
          className="cursor-pointer w-7 h-7"
          title="Download PDF"
        />
      </div>
    </>
  );
};

export default DownloadReport;
