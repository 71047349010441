import React, { useEffect, useState } from "react";
import { IoIosRadioButtonOff } from "react-icons/io";
import { getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import axios from "axios";
import Tostify from "../../../Screens/Common/Tostify";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import Roles from "./Roles.json";
import { useSelector } from "react-redux";

const CreateRoles = ({ fetchCustomerRoles, selectedCustomRole }) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch =
    useSelector((state) => state?.main?.branch) ||
    getEncryptItems("branchDetails");
  const [checked, setChecked] = useState();

  const initialPermissions = {
    dashboard: Roles?.permissions?.dashboard || {},
    invoice: Roles?.permissions?.invoice || {},
    client: Roles?.permissions?.client || {},
    members: Roles?.permissions?.members || {},
    package: Roles?.permissions?.package || {},
    inventory: Roles?.permissions?.inventory || {},
    products: Roles?.permissions?.products || {},
    analytics: Roles?.permissions?.analytics || {},
    staff: Roles?.permissions?.staff || {},
    ledger: Roles?.permissions?.ledger || {},
    issue: Roles?.permissions?.issue || {},
    expenditure: Roles?.permissions?.expenditure || {},
  };

  // console.log("selectedCustomRole",selectedCustomRole);
  const [permissions, setPermissions] = useState(initialPermissions);

  useEffect(() => {
    if (selectedCustomRole) {
      setPermissions((prevPermissions) => ({
        ...prevPermissions,
        ...selectedCustomRole.permissions,
      }));
    }
  }, [selectedCustomRole]);

  console.log("permissions", permissions);

  const handleCreateRole = async () => {
    let data = JSON.stringify({
      organizationId: branch?._id,
      adminId: branch?.adminId,
      roleName: selectedCustomRole?.roleName,
      permissions: {
        dashboard: permissions.dashboard,
        invoice: permissions.invoice,
        client: permissions.client,
        members: permissions.members,
        package: permissions.package,
        inventory: permissions.inventory,
        products: permissions.products,
        analytics: permissions.analytics,
        staff: permissions.staff,
        ledger: permissions.ledger,
        issue: permissions.issue,
        expenditure: permissions.expenditure,
      },
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customeRole/update/${selectedCustomRole?._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("data---", data);
        fetchCustomerRoles();
        tostifySuccess(response?.data?.message);
      })
      .catch((error) => {
        console.log(error);
        tostifyErr(error?.response?.data?.message);
      });
  };

  const handleToggle = (section, key = null) => {
    if (key) {
      const updatedPermissions = {
        ...permissions,
        [section]: {
          ...permissions[section],
          [key]: !permissions[section][key],
        },
      };
      const anyCheckboxSelected = Object.values(
        updatedPermissions[section]
      ).some((value) => value);
      const allCheckboxesSelected = Object.values(
        updatedPermissions[section]
      ).every((value) => value);
      const firstItem = Object.keys(updatedPermissions[section])[0];
      updatedPermissions[section][firstItem] = anyCheckboxSelected;

      // Update toggle button for dashboard if all checkboxes are selected
      if (section === "dashboard" && allCheckboxesSelected) {
        updatedPermissions[section][firstItem] = true;
      }

      setPermissions(updatedPermissions);
    } else {
      const toggledState = !Object.values(permissions[section]).every(
        (value) => value
      );
      const updatedSection = Object.fromEntries(
        Object.entries(permissions[section]).map(([key, _]) => [
          key,
          toggledState,
        ])
      );
      const updatedPermissions = {
        ...permissions,
        [section]: updatedSection,
      };
      const anyCheckboxSelected = Object.values(updatedSection).some(
        (value) => value
      );
      const firstItem = Object.keys(updatedSection)[0];
      updatedPermissions[section][firstItem] = anyCheckboxSelected;

      // Update toggle button for dashboard if all checkboxes are selected
      if (section === "dashboard" && toggledState) {
        updatedPermissions[section][firstItem] = true;
      }

      setPermissions(updatedPermissions);
    }
  };

  useEffect(() => {
    setChecked(selectedCustomRole);
  }, [selectedCustomRole]);

  return (
    <React.Fragment>
      <Tostify />

      {Object.keys(selectedCustomRole).length !== 0 ? (
        <div className="bg-[#fff] min-h-[450px] overflow-y-auto">
          <div className="bg-[#fff] h-[400px] no-scrollbar px-4 overflow-y-auto ">
            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Dashboard</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("dashboard")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                  ${
                    Object.values(permissions?.dashboard).every(
                      (value) => value
                    )
                      ? "bg-blue-500"
                      : "bg-gray-400"
                  }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                  transition-transform duration-300 ease-in-out ${
                    Object.values(permissions?.dashboard).every(
                      (value) => value
                    )
                      ? "translate-x-full"
                      : "translate-x-0"
                  }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                  transform -translate-x-1/2 -translate-y-1/2 ${
                    Object.values(permissions?.dashboard).every(
                      (value) => value
                    )
                      ? "opacity-100"
                      : "opacity-0"
                  }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-1  lg:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.dashboard)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("dashboard", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Invoice</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("invoice")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out
          ${
            Object.values(permissions.invoice).every((value) => value)
              ? "bg-blue-500"
              : "bg-gray-400"
          }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
            transition-transform duration-300 ease-in-out ${
              Object.values(permissions.invoice).every((value) => value)
                ? "translate-x-full"
                : "translate-x-0"
            }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
              transform -translate-x-1/2 -translate-y-1/2 ${
                Object.values(permissions.invoice).every((value) => value)
                  ? "opacity-100"
                  : "opacity-0"
              }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions.invoice)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("invoice", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Client</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("client")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.client).every((value) => value)
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out
                ${
                  Object.values(permissions.client).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 
                    ${
                      Object.values(permissions.client).every((value) => value)
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions.client)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("client", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Members</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("members")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.members).every((value) => value)
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out ${
                  Object.values(permissions.members).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.members).every((value) => value)
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.members)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("members", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Package</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("package")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.package).every((value) => value)
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out ${
                  Object.values(permissions.package).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.package).every((value) => value)
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.package)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("package", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Inventory</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("inventory")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.inventory).every(
                        (value) => value
                      )
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out 
                ${
                  Object.values(permissions.inventory).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.inventory).every(
                        (value) => value
                      )
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.inventory)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("inventory", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Products</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("products")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.products).every(
                        (value) => value
                      )
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out ${
                  Object.values(permissions.products).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.products).every(
                        (value) => value
                      )
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions.products)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("products", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Analytics</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("analytics")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.analytics).every(
                        (value) => value
                      )
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out ${
                  Object.values(permissions.analytics).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.analytics).every(
                        (value) => value
                      )
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.analytics)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("analytics", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Staffs</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("staff")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.staff).every((value) => value)
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out ${
                  Object.values(permissions.staff).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.staff).every((value) => value)
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.staff)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("staff", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Ledger</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("ledger")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.ledger).every((value) => value)
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out ${
                  Object.values(permissions.ledger).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.ledger).every((value) => value)
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.ledger)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("ledger", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Issue</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("issue")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.issue).every((value) => value)
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out ${
                  Object.values(permissions.issue).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.issue).every((value) => value)
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.issue)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("issue", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>

            <div className="mb-4">
              <div className="p-2 flex gap-4">
                <span className="text-xl font-semibold">Expenditure</span>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleToggle("expenditure")}
                >
                  <div
                    className={`relative w-8 h-4 rounded-full bg-gray-400 transition duration-300 ease-in-out 
                    ${
                      Object.values(permissions.expenditure).every(
                        (value) => value
                      )
                        ? "bg-blue-500"
                        : "bg-gray-400"
                    }`}
                  >
                    <div
                      className={`absolute inset-y-0 left-0 w-4 h-4 bg-white rounded-full shadow-md transform 
                transition-transform duration-300 ease-in-out${
                  Object.values(permissions.expenditure).every((value) => value)
                    ? "translate-x-full"
                    : "translate-x-0"
                }`}
                    >
                      <IoIosRadioButtonOff
                        size={22}
                        className={`text-white absolute top-1/2 left-1/2 
                    transform -translate-x-1/2 -translate-y-1/2 ${
                      Object.values(permissions.expenditure).every(
                        (value) => value
                      )
                        ? "opacity-100"
                        : "opacity-0"
                    }`}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-2 py-2 ">
                {Object.entries(permissions?.expenditure)
                  .slice(1)
                  .map(([key, value], index) => (
                    <div
                      key={index}
                      className="border border-[#9F9F9F] px-4 py-2 flex gap-2 items-center justify-between rounded-md"
                    >
                      <span className="font-semibold text-sm lg:text-[16px] capitalize">
                        {key}
                      </span>
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={() => handleToggle("expenditure", key)}
                        className="h-6 w-6 text-white border-[#9F9F9F] rounded"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center pb-2">
            <button
              onClick={() => handleCreateRole()}
              className="bg-blue-500 z-10 text-white py-2 px-4 rounded transform -translate-x-1/2 "
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div
          className=" px-14 py-2 bg-white rounded-md capitalize text-2xl 
                        min-h-[450px] text-[#050F64] text-center content-center  shadow-md"
        >
          Please Select A Role
        </div>
      )}
    </React.Fragment>
  );
};

export default CreateRoles;
