import React, { useState } from "react";
import MembershipTable from "../../Components/Membership/MembershipTable";
import CuponTable from "../../Components/Membership/CuponTable";
import LoyalityPointTable from "../../Components/Membership/LoyalityPointTable";
import GiftCard from "../../Components/Membership/GiftCard";

const Membership = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Membership`;
  const [selected, setSelected] = useState("Members");

  const classes = {
    active:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md text-white bg-primaryColor rounded-xl cursor-pointer hover:bg-primaryHoverColor transition ease-in-out duration-300 transform",
    inactive:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md  text-[#7f8296] bg-transparent hover:bg-[#febc95] hover:text-gray-900 rounded-xl cursor-pointer transition ease-in-out duration-300 transform",
  };

  return (
    <>
      <div className="mx-auto mt-4">
        <div className=" overflow-x-auto inset-x-0 rounded-xl shadow bg-white">
          <div className="min-w-full leading-normal">
            <div className="flex justify-between items-center gap-4 tracking-wider">
              <div
                onClick={() => setSelected("Members")}
                className={
                  selected === "Members"
                    ? classes.active
                    : classes.inactive
                }
              >
                Member
              </div>

              <div
                onClick={() => setSelected("Coupon")}
                className={
                  selected === "Coupon" ? classes.active : classes.inactive
                }
              >
                Coupon
              </div>

              {/* <div
                onClick={() => setSelected("Package")}
                className={
                  selected === "Package" ? classes.active : classes.inactive
                }
              >
                Package
              </div> */}

              <div
                onClick={() => setSelected("Loyalty Point")}
                className={
                  selected === "Loyalty Point"
                    ? classes.active
                    : classes.inactive
                }
              >
                Loyalty Point
              </div>
              <div
                onClick={() => setSelected("Gift Card")}
                className={
                  selected === "Gift Card"
                    ? classes.active
                    : classes.inactive
                }
              >
                Gift Card
              </div>
            </div>
          </div>
        </div>
      </div>
      {selected === "Members" && <MembershipTable />}
      {selected === "Coupon" && <CuponTable />}
      {selected === "Loyalty Point" && <LoyalityPointTable />}
      {selected === "Gift Card" && <GiftCard />}
    </>
  );
};

export default Membership;
