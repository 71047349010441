import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { decryptData } from "../localStorageUtils";
import axios from "axios";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const EditProfileModal = (props) => {
  const [isLoading, setIsLoading] = useState();
  const [image, setImage] = useState();
  const [imageErr, setImageErr] = useState(false);
  const [phone,setPhone] = useState("");
  const [phoneErr,setPhoneErr] = useState(false);
  const updatedPhone = "+91" + phone;
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const id = decryptedData?.userTypeData?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const [formData, setFormData] = useState({
    firstName: "",
    firstNameErr: "",
    lastName: "",
    lastNameErr: "",
    status: "",
    statusErr: "",
    city: "",
    cityErr: "",
    state: "",
    stateErr: "",
    email: "",
    emailErr: "",
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      firstName: decryptedData?.user?.firstName || "",
      lastName: decryptedData?.user?.lastName || "",
      status: decryptedData?.userTypeData?.status || "",
      city: decryptedData?.userTypeData?.city || "",
      state: decryptedData?.userTypeData?.state || "",
      email: decryptedData?.user?.email || "",
    }));
    setImage(props?.userData?.url || decryptedData?.userTypeData?.url)
    setPhone(decryptedData?.user?.phone?.toString()?.substring(3, 13) || "");
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      [`${name}Err`]: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.firstName) {
      setFormData((prevData) => ({
        ...prevData,
        firstNameErr: true,
      }));
    } else if (!formData.lastName) {
      setFormData((prevData) => ({
        ...prevData,
        lastNameErr: true,
      }));
    } else if (!formData.status) {
      setFormData((prevData) => ({
        ...prevData,
        statusErr: true,
      }));
    } else if (!formData.city) {
      setFormData((prevData) => ({
        ...prevData,
        cityErr: true,
      }));
    } else if (!formData.state) {
      setFormData((prevData) => ({
        ...prevData,
        stateErr: true,
      }));
    } else if (!formData.email) {
      setFormData((prevData) => ({
        ...prevData,
        emailErr: true,
      }));
    } else if (!phone) {
      setPhoneErr(true);
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', image);
      formData.append('upload_preset', 'i_bar_profile');

      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dprc07xle/image/upload`,
        {
          method: 'POST',
          body: formData,
        }
      );
      const data2 = await response.json();
      let data = JSON.stringify({
        firstName: formData.firstName,
        lastname: formData.lastName,
        status: formData.status,
        city: formData.city,
        state: formData.state,
        email: formData.email,
        url: data2.secure_url,
        phone: updatedPhone
      });

      if (role !== "admin") {
        return;
      } else {
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/admin/update/${id}`,
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response.data);
            props.setOpenEditProfileModal(false);
            props?.fetchData();
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            props.setOpenEditProfileModal(false);
            setIsLoading(false);
          });
      }
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openEditProfileModal}
        onClose={() => props.setOpenEditProfileModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="no-scrollbar max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenEditProfileModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Edit your profile
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Profile Photo
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => {
                      setImageErr(false)
                      setImage(e.target.files[0]);
                    }}
                    className={
                      imageErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Choose Photo"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    First Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    value={formData.firstName}
                    name="firstName"
                    onChange={(e) => handleChange(e)}
                    className={
                      formData.firstNameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="First Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Last Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    value={formData.lastName}
                    name="lastName"
                    onChange={(e) => handleChange(e)}
                    className={
                      formData.lastNameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Last Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Status
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    value={formData.status}
                    name="status"
                    onChange={(e) => handleChange(e)}
                    className={
                      formData.statusErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Status"
                  />
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full pr-2">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      City
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      value={formData.city}
                      name="city"
                      onChange={(e) => handleChange(e)}
                      className={
                        formData.cityErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="eg:Bhubaneswar"
                    />
                  </div>

                  <div className="relative w-full">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      State
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      value={formData.state}
                      name="state"
                      onChange={(e) => handleChange(e)}
                      className={
                        formData.stateErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="State"
                    />
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Email
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    name="email"
                    className={
                      formData.emailErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter email"
                  />
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Phone
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <div className="flex justify-between w-full">
                      <div className="w-[20%] ">
                        <select
                          name="code"
                          onChange={(e) => handleChange(e)}
                          className={
                            formData.codeErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-max ease-linear transition-all duration-150  "
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-max ease-linear transition-all duration-150  "
                          }
                        >
                          <option value="+91">+91</option>
                        </select>
                      </div>
                      <div className="w-[76%]">
                        <input
                          type="text"
                          value={phone}
                          name="phone"
                          onChange={(e) => setPhone(e.target.value)}
                          className={
                            phoneErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                          }
                          placeholder="Enter Your Phone No."
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]">
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Edit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditProfileModal;
