import React, { useState } from "react";
import SalesReport from "./ReportTabsComponent/SalesReport";
import StaffReport from "./ReportTabsComponent/StaffReport";
import InvoiceReport from "./ReportTabsComponent/InvoiceReport";
import MembershipReport from "./ReportTabsComponent/MembershipReport";
import InventoryReport from "./ReportTabsComponent/InventoryReport";
import ExpenseReport from "./ReportTabsComponent/ExpenseReport";
import AssetsReport from "./ReportTabsComponent/AssetsReport";
import Tostify from "../../Screens/Common/Tostify";

const ReportView = () => {
  const [selectedItem, setSelectedItem] = useState("sale");

  const handleTabClick = (tabName) => {
    setSelectedItem(tabName);
  };

  const classes = {
    active:
      "h-10 w-full p-4  flex content-center items-center justify-center text-center text-md text-white bg-primaryColor rounded-xl cursor-pointer hover:bg-primaryHoverColor transition ease-in-out duration-300 transform",
    inactive:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md  text-[#7f8296] bg-transparent hover:bg-[#febc95] hover:text-gray-900 rounded-xl cursor-pointer transition ease-in-out duration-300 transform",
  };
  return (
    <React.Fragment>
      <div className="mx-auto mt-4">
        <Tostify />
        <div className="scroll-container overflow-x-auto rounded-xl inset-x-0   bg-white">
          <div className="min-w-full leading-normal">
            <div className="flex justify-between items-center gap-2">
              <div
                onClick={() => handleTabClick("sale")}
                className={
                  selectedItem === "sale" ? classes.active : classes.inactive
                }
              >
                Sales
              </div>

              <div
                onClick={() => handleTabClick("staff")}
                className={
                  selectedItem === "staff" ? classes.active : classes.inactive
                }
              >
                Staff
              </div>

              <div
                onClick={() => handleTabClick("invoice")}
                className={
                  selectedItem === "invoice" ? classes.active : classes.inactive
                }
              >
                Invoice
              </div>

              <div
                onClick={() => handleTabClick("member")}
                className={
                  selectedItem === "member" ? classes.active : classes.inactive
                }
              >
                Membership
              </div>

              <div
                onClick={() => handleTabClick("inventory")}
                className={
                  selectedItem === "inventory"
                    ? classes.active
                    : classes.inactive
                }
              >
                Inventory
              </div>

              <div
                onClick={() => handleTabClick("expense")}
                className={
                  selectedItem === "expense" ? classes.active : classes.inactive
                }
              >
                Expense
              </div>
              <div
                onClick={() => handleTabClick("assets")}
                className={
                  selectedItem === "assets" ? classes.active : classes.inactive
                }
              >
                Assets
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedItem === "sale" && <SalesReport />}
      {selectedItem === "staff" && <StaffReport />}
      {selectedItem === "invoice" && <InvoiceReport />}
      {selectedItem === "member" && <MembershipReport />}
      {selectedItem === "inventory" && <InventoryReport />}
      {selectedItem === "expense" && <ExpenseReport />}
      {selectedItem === "assets" && <AssetsReport />}
    </React.Fragment>
  );
};

export default ReportView;
