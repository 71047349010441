import React from 'react'

const SkeletonTableLoader = () => {
    return (
        <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                            <div className=" flex flex-row  items-center">
                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                            </div>
                        </th>
                        <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                            <div className=" flex flex-row  items-center">
                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                            </div>
                        </th>
                        <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                            <div className=" flex flex-row  items-center">
                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                            </div>
                        </th>
                        <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                            <div className=" flex flex-row  items-center">
                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                            </div>
                        </th>
                        <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                            <div className=" flex flex-row  items-center">
                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {[1, 2, 3, 4, 5].map((v, i) => {
                        return (
                            <tr key={i}>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className=" flex flex-row  items-center">
                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                    </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className=" flex flex-row  items-center">
                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                    </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className=" flex flex-row  items-center">
                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                    </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className=" flex flex-row  items-center">
                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                    </div>
                                </td>

                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className=" flex flex-row  items-center">
                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                <span className="text-xs xs:text-sm text-gray-900"></span>
                <div className="inline-flex mt-2 xs:mt-0">
                    <button className="animate-pulse h-2  flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"></button>
                    <button className="animate-pulse h-2  flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"></button>
                </div>
            </div>
        </div>
    )
}

export default SkeletonTableLoader