import React, { useState } from "react";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import excel from "../../../Assets/xls.png";
import pdf from "../../../Assets/Pdf.png";
import ReportFilterType from "../ReportFilterType";
import { useSelector } from "react-redux";
import Dropdown from "../../../Screens/Common/Dropdown";
import DownloadReport from "../DownloadReport";

const StaffReport = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const branch = useSelector((state) => state?.main?.branch);
  const allInvoices = useSelector((state) => state?.main?.billList)?.filter(
    (item) => item?.organizationId === branch?._id
  );
  const staffList = useSelector((state) => state?.main?.staffList)?.filter(
    (item) => item?.organizationId?._id === branch?.id
  );
  const serviceHistory = useSelector(
    (state) => state?.main?.serviceVsStaffData
  )?.filter((item) => item?.organizationId === branch?._id);

  const today = new Date();
  const last7days = new Date(today);
  last7days.setDate(today.getDate() - 7);
  const last30days = new Date(today);
  last30days.setDate(today.getDate() - 30);

  //<-----------------------------Staff filter Data---------------------------------->

  const filterByDayOrWeek =
    selectedCategory === "day"
      ? allInvoices?.filter(
          (item) => new Date(item?.date).toISOString() === today.toISOString()
        )
      : selectedCategory === "week"
      ? allInvoices?.filter((item) => new Date(item?.date) >= last7days)
      : selectedCategory === "month"
      ? allInvoices?.filter((item) => new Date(item?.date) >= last30days)
      : selectedCategory === "customRange"
      ? allInvoices?.filter((item) => {
          const itemDate = new Date(item?.date).getTime();
          const startTimestamp = new Date(startDate).getTime();
          const endTimestamp = new Date(endDate).getTime();
          return itemDate >= startTimestamp && itemDate <= endTimestamp;
        })
      : allInvoices;

  const filteredDayWeekMonthStaff =
    selectedCategory === "day"
      ? serviceHistory?.filter(
          (item) =>
            new Date(item?.createdAt).toISOString() === today.toISOString()
        )
      : selectedCategory === "week"
      ? serviceHistory?.filter((item) => new Date(item?.createdAt) >= last7days)
      : selectedCategory === "month"
      ? serviceHistory?.filter(
          (item) => new Date(item?.createdAt) >= last30days
        )
      : selectedCategory === "customRange"
      ? serviceHistory?.filter((item) => {
          const itemDate = new Date(item?.createdAt).getTime();
          const startTimestamp = new Date(startDate).getTime();
          const endTimestamp = new Date(endDate).getTime();
          return itemDate >= startTimestamp && itemDate <= endTimestamp;
        })
      : serviceHistory;

  const filteredStaffReport =
    subcategory === "staffByServices" || subcategory === "staffPerformance"
      ? filteredDayWeekMonthStaff
      : subcategory === "staffByProduct"
      ? filterByDayOrWeek?.filter((item) => item?.products?.length > 0)
      : subcategory === ""
      ? serviceHistory
      : [];
  console.log("filteredStaffRepor", filteredStaffReport);
  const staffProductsCount = {};
  filteredStaffReport?.forEach((item) => {
    item?.products?.forEach((s) => {
      const staffId = s?.staff;
      const staffName = staffList?.find((item) => item?._id === s?.staff)
        ?.userId?.firstName;
      const key = `${staffId} - ${staffName}`;
      if (staffId && staffName) {
        staffProductsCount[key] = (staffProductsCount[key] || 0) + 1;
      }
    });
  });

  const staffHeaderProductData = [
    { label: "Staff Name", field: "Name" },
    { label: "Products", field: "Products" },
  ];
  const filteredProductByStaffRowData = Object.entries(staffProductsCount)?.map(
    ([staffId, count]) => {
      const [id, name] = staffId?.split(" - ");
      return {
        Name: name,
        Products: count,
      };
    }
  );

  const staffNameCount = {};
  filteredStaffReport?.forEach((item) => {
    const staffId = item.staffId?._id;
    const firstName = item.staffId?.userId?.firstName;
    const key = `${staffId} - ${firstName}`;

    if (staffId && firstName) {
      staffNameCount[key] = (staffNameCount[key] || 0) + 1;
    }
  });
  const staffHeaderPerformanceData = [
    { label: "Staff Name", field: "Name" },
    { label: "Services", field: "Services" },
  ];
  const staffRowPerformanceData = Object.entries(staffNameCount)?.map(
    ([staffId, count]) => {
      const [id, name] = staffId?.split(" - ");
      return {
        Name: name,
        Services: count,
      };
    }
  );
  const staffHeaderData = [
    { label: "Staff Name", field: "staff" },
    { label: "Service Name", field: "serviceName" },
    { label: "Time", field: "time" },
    { label: "Date", field: "date" },
    { label: "Status", field: "status" },
  ];
  const staffRowData =
    subcategory === "staffByServices" && selectedStaffId !== ""
      ? filteredStaffReport
          ?.filter((ele) => ele?.staffId?._id === selectedStaffId)
          ?.map((service) => {
            const serviceNames = service?.serviceId
              ?.map((item) => item?.name)
              .join(", ");
            const dateString = service?.time;
            const date = new Date(dateString);
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const amOrPm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12 || 12;
            const timeString = `${hours}:${minutes} ${amOrPm}`;
            return {
              staff: `${service?.staffId?.userId?.firstName} ${service?.staffId?.userId?.lastName}`,
              serviceName: serviceNames,
              date: service?.createdAt?.slice(0, 10),
              time: timeString,
              status: service?.isAccepted,
            };
          })
      : subcategory === "" || selectedStaffId == ""
      ? serviceHistory?.map((service) => {
          const serviceNames = service?.serviceId
            ?.map((item) => item.name)
            .join(", ");
          const dateString = service?.time;
          const date = new Date(dateString);
          let hours = date.getHours();
          const minutes = date.getMinutes();
          const amOrPm = hours >= 12 ? "PM" : "AM";
          hours = hours % 12 || 12;
          const timeString = `${hours}:${minutes} ${amOrPm}`;
          return {
            staff: `${service?.staffId?.userId?.firstName} ${service?.staffId?.userId?.lastName}`,
            serviceName: serviceNames,
            date: service?.createdAt?.slice(0, 10),
            time: timeString,
            status: service?.isAccepted,
          };
        })
      : [];

  const handleSelect = (value) => {
    setsubcategory(value);
  };

  const handleSelectCreatedBy = (value) => {
    setSelectedStaffId(value);
  };

  return (
    <React.Fragment>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide lg:block hidden">
          Reports
        </div>

        <div>
          <ReportFilterType
            setSelectedCategory={setSelectedCategory}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            subcategory={subcategory}
          />
        </div>

        <div>
          <Dropdown
            label="All"
            items={[
              "All",
              "staffByServices",
              "staffByProduct",
              "product",
              "staffPerformance",
            ]}
            onSelect={handleSelect}
          />
        </div>

        {subcategory === "staffByServices" && (
          <Dropdown
            label="Created By"
            items={staffList}
            itemName="staffList"
            onSelect={handleSelectCreatedBy}
          />
        )}

        {/* <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
          <img
            src={excel}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download CSV"
          />
          <img
            src={pdf}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download PDF"
          />
        </div> */}

        <DownloadReport rowData={staffRowData} headerData={staffHeaderData} />
      </div>
      {subcategory === "staffByServices" && (
        <ReportsDynamicTable
          rowData={staffRowData}
          headerData={staffHeaderData}
        />
      )}
      {subcategory === "All" && (
        <ReportsDynamicTable
          rowData={staffRowData}
          headerData={staffHeaderData}
        />
      )}
      {subcategory === "staffPerformance" && (
        <ReportsDynamicTable
          rowData={staffRowPerformanceData}
          headerData={staffHeaderPerformanceData}
        />
      )}
      {subcategory === "staffByProduct" && (
        <ReportsDynamicTable
          rowData={filteredProductByStaffRowData}
          headerData={staffHeaderProductData}
        />
      )}
    </React.Fragment>
  );
};

export default StaffReport;
