import React, { useEffect } from "react";
import { useState } from "react";
import { fetchMembershipPurchase } from "../../../Screens/Common/initData";
import { useSelector } from "react-redux";
import excel from "../../../Assets/xls.png";
import pdf from "../../../Assets/Pdf.png";
import ReportFilterType from "../ReportFilterType";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import DownloadReport from "../DownloadReport";

const MembershipReport = () => {
  const [membershipData, setMembershipData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const branch = useSelector((state) => state?.main?.branch);

  const today = new Date();
  const last7days = new Date(today);
  last7days.setDate(today.getDate() - 7);
  const last30days = new Date(today);
  last30days.setDate(today.getDate() - 30);

  const getCustomersByMemberships = async () => {
    const data = await fetchMembershipPurchase();
    setMembershipData(
      data?.filter((item) => item?.organizationId === branch?._id)
    );
  };

  useEffect(() => {
    getCustomersByMemberships();
  }, [branch]);
  const filteredMemberships =
    selectedCategory === "day"
      ? membershipData?.filter(
          (item) => new Date(item?.date).toISOString() === today.toISOString()
        )
      : selectedCategory === "week"
      ? membershipData?.filter((item) => new Date(item?.date) >= last7days)
      : selectedCategory === "month"
      ? membershipData?.filter((item) => new Date(item?.date) >= last30days)
      : selectedCategory === "customRange"
      ? membershipData?.filter((item) => {
          const itemDate = new Date(item?.date).getTime();
          const startTimestamp = new Date(startDate).getTime();
          const endTimestamp = new Date(endDate).getTime();
          return itemDate >= startTimestamp && itemDate <= endTimestamp;
        })
      : membershipData;

  const filteredHeaderMembershipsData = [
    { label: "Name", field: "customerName" },
    { label: "Expire Date", field: "expireDate" },
    { label: "Purchase Date", field: "date" },
    { label: "price", field: "price" },
    { label: "packagePrice", field: "packagePrice" },
    { label: "packageName", field: "packageName" },
  ];

  const filteredRowMemberhsipsData = filteredMemberships?.map((membership) => ({
    customerName: membership?.customerName,
    expireDate: membership?.expireDate?.slice(0, 10),
    date: membership?.date,
    price: membership?.price,
    packagePrice: membership?.packagePrice,
    packageName: membership?.membershipPackageId?.packageName,
  }));

  return (
    <>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide lg:block hidden">
          Reports
        </div>

        <div>
          <ReportFilterType
            setSelectedCategory={setSelectedCategory}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>

        {/* <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
          <img
            src={excel}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download CSV"
          />
          <img
            src={pdf}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download PDF"
          />
        </div> */}
        <DownloadReport
          rowData={filteredRowMemberhsipsData}
          headerData={filteredHeaderMembershipsData}
        />
      </div>
      <ReportsDynamicTable
        rowData={filteredRowMemberhsipsData}
        headerData={filteredHeaderMembershipsData}
      />
    </>
  );
};

export default MembershipReport;
