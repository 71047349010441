import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import axios from "axios";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const EditStaffModal = (props) => {
  const [isLoading, setIsLoading] = useState();
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState("");
  const [fname, setFname] = useState("");
  const [fnameErr, setFnameErr] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [lname, setLname] = useState("");
  const [code, setCode] = useState("");
  const [codeErr, setCodeErr] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [designation, setDesignation] = useState("");
  const [designationErr, setDesignationErr] = useState(false);
  // const [features, setFeatures] = useState([]);
  // const [selectedFeatures, setSelectedFeatures] = useState([]);
  // const allFeatures =[ "appointments", "notification","products"];
  const updatedPhone = code + phone;
  const token = "Bearer " + localStorage.getItem("refresh_token");

  useEffect(() => {
    setFname(props?.initialValue?.userId?.firstName);
    setLname(props?.initialValue?.userId?.lastName);
    setStatus(props?.initialValue?.status);
    setEmail(props?.initialValue?.userId?.email);
    setDesignation(props?.initialValue?.designation || "");
    setCode(props?.initialValue?.userId?.phone?.slice(0, 3));
    setPhone(props?.initialValue?.userId?.phone?.slice(3, 14));
    // setFeatures(props?.initialValue?.features);
    // setSelectedFeatures(props?.initialValue?.features || []);
  }, [props.handleOpenEditStaff]);

  const clearInputField = () => {
    setFname("");
    setFnameErr(false);
    setLname("");
    setStatus("");
    setEmail("");
    setEmailErr(false);
    setCode("");
    setCodeErr(false);
    setPhone("");
    setPhoneErr(false);
    setDesignationErr(false)
  };

  const handleClick = () => {
    setVisible((prev) => !prev);
  };
  // const handleCheckBoxChange = (value) => {
  //   const isSelected = selectedFeatures.includes(value);
  //   if (isSelected) {
  //     setSelectedFeatures(selectedFeatures.filter(item => item !== value));
  //   } else {
  //     setSelectedFeatures([...selectedFeatures, value]);
  //   }
  // }
  console.log("this is data i want cheack---->",phoneErr);

  const handleEditStaff = async (e) => {
    e.preventDefault();
    if (!regex.nameRegEx.test(fname)) {
      setFnameErr(true);
    } else if (phone && !code) {
      setCodeErr(true);
    } else if (phone && !regex.phoneRegEx.test(phone)) {
      setPhoneErr(true)
    } else {
      let data = JSON.stringify({
        status: status,
        firstName: fname,
        lastName: lname,
        designation: designation,
        email: email,
        phone: phone?.length === 10 ? updatedPhone : "",
        password: password,
        // features: selectedFeatures
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/staff/update/${props.id}`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };
      setIsLoading(true);
      await axios
        .request(config)
        .then((response) => {
          let resStatus = response.status === 200;
          if (resStatus) {
            props.fetchStaffData();
            props?.handleClear();
            props.setOpenEditStaffModal(false);
            tostifySuccess("Details updated successfully");
            clearInputField();
            props.setId("");
            props?.setStaff({});
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("Edit Staff error:", error);
          props.setOpenEditStaffModal(false);
          tostifyErr(error?.response?.data?.message);
          setIsLoading(false);
          //   clearInputField();
        });
    }
  };

  return (
    <React.Fragment >
      <Modal
        open={props.openEditStaffModal}
        onClose={() => {
          clearInputField();
          props.setOpenEditStaffModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() =>{
                   clearInputField();
                  props.setOpenEditStaffModal(false)
                } }
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Edit Staff Details
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleEditStaff(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    First Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    value={fname}
                    onChange={(e) => {
                      setFname(e.target.value);
                      setFnameErr(false);
                    }}
                    className={
                      fnameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter First Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Last Name
                  </div>
                  <input
                    type="text"
                    id="lname"
                    value={lname}
                    onChange={(e) => {
                      setLname(e.target.value);
                    }}
                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded 
                    text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear
                     transition-all duration-150"
                    placeholder="Enter Last Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Status
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <select
                    value={status}
                    id="status"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    className={
                      "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Email
                  </div>
                  <input
                    type="text"
                    value={email}
                    id="email"
                    autoComplete="off"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailErr(false);
                    }}
                    className={
                      emailErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your email"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Designation
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="designation"
                    value={designation}
                    onChange={(e) => {
                      setDesignation(e.target.value);
                      setDesignationErr(false);
                    }}
                    className={
                      designationErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Designation"
                  />
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Phone
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <div className="flex gap-4">
                      <div>
                        <select
                          value={code}
                          id="code"
                          onChange={(e) => {
                            setCode(e.target.value);
                            setCodeErr(false);
                          }}
                          className={
                            codeErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-max ease-linear transition-all duration-150  "
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-max ease-linear transition-all duration-150  "
                          }
                        >
                          <option value="">Code</option>
                          <option value="+91">+91</option>
                        </select>
                      </div>
                      <div className="w-full">
                        <input
                          type="text"
                          id="phone"
                          autoComplete="off"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneErr(false);
                          }}
                          className={
                            phoneErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                          }
                          placeholder="Enter Your Phone"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Password
                    {/* <span className="text-red-600 text-lg"> *</span> */}
                  </div>
                  <input
                    value={password}
                    id="password"
                    onChange={(e) => {
                      setPasswordErr(false);
                      setPassword(e.target.value);
                    }}
                    type={visible ? "text" : "password"}
                    className={
                      passwordErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                    }
                    placeholder="Password"
                  />
                  {visible ? (
                    <div className="absolute right-1 top-12 ">
                      <IoMdEye
                        size={20}
                        onClick={handleClick}
                        className="mx-2 text-[#828282]"
                      />
                    </div>
                  ) : (
                    <div className="absolute right-1 top-12">
                      <IoMdEyeOff
                        size={20}
                        onClick={handleClick}
                        className="mx-2 text-[#828282]"
                      />
                    </div>
                  )}
                  <div className="text-[#828282] text-xs mt-2 w-96">
                    Password must contain at least one lowercase letter, one uppercase letter,
                    one digit, one special character, and be between 8 to 30 characters long.
                  </div>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Edit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditStaffModal;
