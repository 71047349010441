import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { decryptData, getEncryptItems } from '../../Screens/Common/localStorageUtils';
import { useSelector } from 'react-redux';
import DownloadTableData from '../../Screens/Common/downloadData';
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import Dropdown from '../../Screens/Common/Dropdown';
import cross from '../../Assets/billClear.png'

const StockLedger = () => {
  const [isLoading, setIsLoading] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [stockType, setStockType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");
  const [ledgers, setLedgers] = useState([]);
  const [createdBy, setCreatedBy] = useState("");
  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
  const staffList = useSelector(state => state?.main?.staffList)?.filter(ele => ele?.organizationId === branch?._id);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const filteredLedgerBySelectedCategory = selectedCategory === "TODAY"
    ? ledgers?.filter(item => new Date(item?.date)?.toISOString()?.slice(0, 10) === new Date()?.toISOString()?.slice(0, 10))
    : selectedCategory ? ledgers?.filter(item => item?.type === selectedCategory) : ledgers

  const filteredLedgerBystockType =
    stockType
      ? filteredLedgerBySelectedCategory?.filter(item => item?.stockType === stockType)
      : filteredLedgerBySelectedCategory;
  const filterLedgerByCreatedBY =
    createdBy
      ? filteredLedgerBystockType?.filter(ele => ele?.createdBy?.adminId?._id === createdBy || ele?.createdBy?.staffId?._id === createdBy)
      : filteredLedgerBystockType;
  const filteredLedgerByDate = from && to
    ? filterLedgerByCreatedBY?.filter(item => {
      const itemDate = new Date(item?.date);
      const fromDate = new Date(from);
      const toDate = new Date(to);
      return itemDate >= fromDate && itemDate <= new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999);
    })
    : filterLedgerByCreatedBY;
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLedgerByDate?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  currentItems?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setSelectedCategory("TODAY");
    setStockType("ADJUSTMENT");
    setCreatedBy(branch?.adminId)
    // setfrom(new Date()?.toISOString()?.slice(0, 10));
    // setTo(new Date()?.toISOString()?.slice(0, 10));
  }, [])

  useEffect(() => {
    getProductLedger();
  }, [branch])

  const getProductLedger = () => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/stockLedger/all-product`,
      headers: {
        'Authorization': token
      }
    };

    setIsLoading(true);
    axios.request(config)
      .then((response) => {
        setLedgers(response?.data?.data?.filter(item => item?.organizationId === branch?._id))
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });

  }
  const headerData = [
    { label: "Sl", field: "sl" },
    { label: "Product Name", field: "productName" },
    { label: "Type", field: "type" },
    { label: "Stock Type", field: "stockType" },
    { label: "Quantity", field: "quantity" },
    { label: "Created By", field: "createdBy" },
    { label: "Date", field: "date" },
    { label: "Stock Before/After", field: "stockBeforeAfter" }
  ];
  const rowData = filteredLedgerByDate?.map(data => ({
    stockBeforeAfter: `${data?.beforeStock} / ${data?.afterStock}`,
    createdBy: data?.createdBy?.name,
    date: data?.date?.slice(0, 10),
    productName: data?.productId?.name,
    quantity: data?.quantity,
    stockType: data?.stockType,
    type: data?.type,
  }));

  const handleSelectCategory = (value) => {
    setSelectedCategory(value);
  };
  const handleStockTypeSelect = (value) => {
    setStockType(value);
  };

  const handleSelectCreatedBy = (value) => {
    setCreatedBy(value);
  }

  return (
    <React.Fragment>
      <style>
        {`
    select, input[type=date] {
      text-align-last: center;
    }

  `}
      </style>
      <div className="mt-4 flex justify-start flex-wrap gap-4">
        <Dropdown
          label="Select Category"
          items={['TODAY', 'IN', 'OUT']}
          onSelect={handleSelectCategory}
        />
        <Dropdown
          label="Stock Type"
          items={['ADJUSTMENT','New Order', 'Return', 'Consumed', 'New Product']}
          onSelect={handleStockTypeSelect}
        />
        <Dropdown
          label="Created By"
          items={staffList}
          itemName="staffList"
          onSelect={handleSelectCreatedBy}
        />
        {/* <div>
          <select
            name="createdBy"
            id="createdBy"
            className='text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150'
            onChange={(e) => setCreatedBy(e.target.value)}
          >
            <option value="">Created By</option>
            <option value={branch?.adminId}>Admin</option>
            {decryptData()?.user?.role === "staff"
              && <option value={decryptData()?.userTypeData?._id} >{decryptData()?.user?.firstName}</option>}
            {staffList?.map((v, i) => {
              return (
                <option key={i} value={v?._id}>{v?.userId?.firstName}</option>
              )
            })}
          </select>
        </div> */}

      </div>
      <div className="mx-auto mt-4">

        <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg flex flex-wrap justify-between">

          <div className="font-bold text-2xl tracking-wide">
            Stock Ledger
          </div>

          <div className="flex items-center justify-between gap-2 flex-wrap">

            <div className='flex gap-2 items-center'>
              <span className="text-gray-600 mr-2">From</span>
              <input
                value={from}
                onChange={(e) => setfrom(e.target.value)}
                type="date" className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150" />
            </div>

            <div className='flex gap-2 items-center'>

              <div className='flex gap-2 items-center'>
                <span className="text-gray-600 mr-7 md:mr-2">To</span>
                <input
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                  type="date" className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150" />
              </div>

              <div onClick={() => {
                setfrom("");
                setTo("");
              }}
                className="cursor-pointer flex content-center items-center justify-center
                 rounded-full shadow-md shadow-[#ffdcc7] ">
                <img src={cross} alt="Clear" className='w-8 h-8' />
              </div>
            </div>

          </div>

          <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
            <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
              <DownloadTableData
                rowData={rowData}
                headerData={headerData}
              />

            </div>
          </div>

        </div>
        {isLoading ? (
          <SkeletonTableLoader />
        ) : filteredLedgerByDate?.length === 0 ?
          (<div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Ledger found
          </div>)
          : (
            <div className="shadow rounded-t-lg overflow-x-auto">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Sl
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Product Name
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Type
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Stock Type
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Quantity
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Created By
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Date
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Stock Befor/After
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {(currentPage * 10 + i) - 9}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.productId?.name}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.type}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.stockType}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.quantity || v?.volume} {v?.volume && "ml"}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.createdBy?.name}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="whitespace-no-wrap flex">
                            {v?.date?.slice(0, 10)}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.beforeStock} / {v?.afterStock}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        {(filteredLedgerByDate?.length === 0) ? null : (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {indexOfFirstItem + currentItems?.length} {" "}
              of {filteredLedgerByDate?.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredLedgerByDate?.length / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                  Math.ceil(filteredLedgerByDate?.length / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default StockLedger