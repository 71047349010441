import React from "react";

export const AnalyticsDynamicTable = ({ rowData, headerData }) => {
  return (
    <div className="min-h-[250px]  min-w-full overflow-auto divide-gray-200 bg-white shadow-md rounded-lg ">
      {" "}
      {rowData?.length === 0 ?
        <div className="h-[200px] flex justify-center items-center text-xl">
          No  Data  Found
        </div> : <div className="overflow-auto max-h-[250px] no-scrollbar">
          <table className="min-w-full divide-y  p-2  ">
            <thead className="bg-gray-50 sticky top-0">
              <tr>
                {headerData.map((header, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rowData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headerData.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ whiteSpace: "pre-line" }}
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                    >
                      {row[header.field]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>}
    </div>
  );
};
