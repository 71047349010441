import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  borderRadius: "15px",
  p: 4,
};

const AddClient = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  // const [email, setEmail] = useState("");
  // const [emailErr, setEmailErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [dob, setDob] = useState("");
  const [doa, setDoa] = useState("");
  const [remark, setRemark] = useState("");
  const updatedPhone = "+91" + phone;
  const branch = useSelector(state => state?.main?.branch);
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const clearInputField = () => {
    setName("");
    setNameErr("");
    // setEmail("");
    // setEmailErr("");
    setPhone("");
    setPhoneErr("");
    setRemark("");
    setDob("");
  };

  const handleAddClient = async (e) => {
    e.preventDefault();
    if (!regex.nameRegEx.test(name)) {
      setNameErr(true);
      // } else if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      //   setEmailErr(true);
      // } 
    } else if (!regex.phoneRegEx.test(phone)) {
      setPhoneErr(true);
    } else {
      let data = JSON.stringify({
        organizationId: branch?._id,
        name: name,
        // email: email,
        dateOfBirth: dob,
        dateOfAnniversary: doa,
        phoneNumber: updatedPhone,
        remark: remark,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/customer/add`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      setIsLoading(true);
      axios
        .request(config)
        .then((response) => {
          setIsLoading(false);
          props.fetchClients();
          tostifySuccess(response?.data?.message);
          clearInputField();
          props.setOpenAddClentModal(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          tostifyErr(error?.response?.data?.message);
          props.setOpenAddClentModal(false);
          // clearInputField();
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openAddClentModal}
        onClose={() => {
          props.setOpenAddClentModal(false);
          // clearInputField();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className=" relative">
          <div className="no-scrollbar max-h-[400px] overflow-x-auto px-4">
            <span
              onClick={() => props.setOpenAddClentModal(false)}
              className="absolute top-6  right-5 text-3xl  cursor-pointer"
            >
              x
            </span>
            <div className="btn-wrapper text-center">
              <div className="text-xl text-[#2b2f32] font-bold">Add Client</div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddClient(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Full Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    value={name}
                    autoComplete="off"
                    id="name"
                    onChange={(e) => {
                      setNameErr(false);
                      setName(e.target.value);
                    }}
                    className={
                      nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your Name"
                  />
                </div>

                {/* <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    email
                  </div>
                  <input
                    type="text"
                    id="email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => {
                      setEmailErr(false);
                      setEmail(e.target.value);
                    }}
                    className={
                      emailErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your email"
                  />
                </div> */}

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Date Of Birth
                  </div>
                  <input
                    type="date"
                    id="dob"
                    value={dob}
                    onChange={(e) => {
                      setDob(e.target.value);
                    }}
                    className={
                      "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your DOB"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Date Of Anniversary
                  </div>
                  <input
                    type="date"
                    id="dob"
                    value={doa}
                    onChange={(e) => {
                      setDoa(e.target.value);
                    }}
                    className={
                      "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Your Date Of Aniniversary"
                  />
                </div>

                {/*<div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Branch Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <select
                    value={orgId}
                    id="orgId"
                    onChange={(e) => {
                      setOrgId(e.target.value);
                      setOrgIdErr(false);
                    }}
                    className={
                      orgIdErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                    }
                  >
                    <option>Select Branch</option>
                    {orgByAdminId?.map((v, i) => {
                      return (
                        <option className="capitalize" key={i} value={v._id}>
                          {v.name}
                        </option>
                      );
                    })}
                  </select>
                </div>*/}

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Remarks
                  </div>
                  <input
                    type="text"
                    value={remark}
                    id="remark"
                    onChange={(e) => {
                      setRemark(e.target.value);
                    }}
                    className={
                      "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Remark"
                  />
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Phone
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <div className="flex justify-between w-full">
                      <div className="w-[20%] ">
                        <select
                          id="code"
                          className={
                            "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-max ease-linear transition-all duration-150  "
                          }
                        >
                          <option value="+91">+91</option>
                        </select>
                      </div>
                      <div className="w-[76%]">
                        <input
                          type="text"
                          value={phone}
                          id="phone"
                          autoComplete="off"
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneErr(false);
                          }}
                          className={
                            phoneErr
                              ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                              : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                          }
                          placeholder="Enter Your Phone No."
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    type="submit"
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default AddClient;
