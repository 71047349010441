import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  decryptData,
  getEncryptItems,
} from "../../../Screens/Common/localStorageUtils";
import { fetchCustomerWithMembership } from "../../../Screens/Common/initData";
import { allCustomerWithMembership } from "../../../state-management/main/actions";
import { useState, useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const NewPackageModal = (props) => {
  const dispatch = useDispatch();
  const decryptedData = decryptData();
  const adminId = decryptedData?.userTypeData?._id;
  const main = useSelector((state) => state?.main);
  const admin = useSelector((state) => state.admin);
  const filteredClients = main?.clientList?.filter(
    (client) => client?.organization?._id === props?.orgId
  );
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [membership, setMembership] = useState("");
  const [membershipErr, setMembershipErr] = useState("");
  const [searchData, setSearchData] = useState("");
  const [selectedMemberships, setSelectedMemberships] = useState([]);
  const [expDate, setExpDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentMethodError, setPaymentMethodErr] = useState();
  const [selectFields, setSelectFields] = useState([{ id: 0, value: "" }]);
  const branch = getEncryptItems("branchDetails");
  const filteredPackages = main?.membershipList?.filter(
    (memb) => memb?.organizationId?._id === branch?._id
  );
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString("en-CA");
  const customerData = filteredClients?.find((cus) => cus?._id === name);
  const membershipData = filteredPackages?.find(
    (pack) => pack?._id === membership
  );
  const membershipValidity = membershipData?.validity;
  const getCustmerWithMembership = async () => {
    const data = await fetchCustomerWithMembership();
    dispatch(allCustomerWithMembership(data));
  };
  useEffect(() => {
    if (
      membershipData &&
      typeof membershipValidity === "number" &&
      !isNaN(membershipValidity)
    ) {
      const expirationDate = new Date(currentDate);
      expirationDate.setMonth(expirationDate.getMonth() + membershipValidity);

      if (!isNaN(expirationDate)) {
        const formattedExpDate = expirationDate.toISOString().split("T")[0];
        setExpDate(formattedExpDate);
      }
    }
  }, [membershipData, membershipValidity]);


  const clearInputField = () => {
    setName("");
    setNameErr("");
    setResults([]);
    setSearchData("");
    setMembership("");
    setMembershipErr("");
    setExpDate("");
    setPaymentMethod("");
    setPaymentMethodErr("");
  };

  const handleAddMembership = async () => {
    props.setOpenAddMembershipModal(false);
    const selectedPackages = selectFields
      ?.map(field => props?.packageList?.find(p => p._id === field.value))
      .filter(Boolean);

    const newPackages = selectedPackages?.filter(pkg => !props?.addPackage?.some(ap => ap._id === pkg._id));
    props?.setAddPackage(prev => [...prev, ...newPackages]);
    setSelectFields([{ id: 0, value: "" }]);
  };


  const handleSearch = (e) => {
    e.preventDefault();
    const result = filteredClients?.filter((item) =>
      item.phoneNumber.toString().includes(searchData.toString())
    );
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    setResults([]);
  };

  const handleAddSelectField = () => {
    const newFieldId = selectFields.length;
    setSelectFields([
      ...selectFields,
      { id: newFieldId, value: "" }
    ]);
  };

  const handleRemoveSelectField = (id) => {
    if (selectFields.length === 1) {
      return;
    }
    const updatedFields = selectFields.filter((field) => field.id !== id);
    setSelectFields(updatedFields);
  };


  return (
    <React.Fragment>
      <Modal
        open={props.openAddMembershipModal}
        onClose={() => {
          clearInputField();
          props.setOpenAddMembershipModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddMembershipModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Package List
              </div>
            </div>

            <div className="flex-auto mt-2">
              {selectFields?.map((field) => (
                <div
                  key={field.id}
                  className="flex justify-center items-center mb-3 w-96"
                >
                  <div className="relative w-full ml-6 mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Available Packages
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <select
                      value={field.value}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const updatedFields = selectFields.map((sf) =>
                          sf.id === field.id ? { ...sf, value: newValue } : sf
                        );
                        setSelectFields(updatedFields);
                      }}
                      className={
                        membershipErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }
                    >
                      <option value="">Select Package</option>
                      {props?.packageList?.map((v, i) => (
                        <option
                          className="capitalize"
                          key={i}
                          value={v._id}
                        >
                          {v.packageName} - {v.validity} Months - Rs{v.packagePrice}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex gap-2 justify-center items-center mt-4 ml-2">
                    <div
                      className="rounded-full bg-orange-500 p-2 cursor-pointer"
                      onClick={handleAddSelectField}
                    >
                      <FaPlus style={{ color: "white" }} />
                    </div>
                    <div
                      className="rounded-full bg-orange-500 p-2 cursor-pointer"
                      onClick={() => handleRemoveSelectField(field.id)}
                    >
                      <FaMinus style={{ color: "white" }} />
                    </div>
                  </div>
                </div>
              ))}

              <div className="w-full flex items-center justify-center mt-5">
                <button
                  onClick={handleAddMembership}
                  disabled={isLoading}
                  className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                >
                  {isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span>Add</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default NewPackageModal;
