import React from "react";
import InvoiceTable from "../../Components/Invoices/InvoiceTable";

const Invoice = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Invoice`;

  return <InvoiceTable />;
};

export default Invoice;
