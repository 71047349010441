import React from "react";
import ClientTable from "../../Components/Client/ClientTable";

const Client = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Clients`;

  return <ClientTable />;
};

export default Client;
