import React, { useState } from "react";
import { RiEdit2Fill } from "react-icons/ri";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages";
import axios from "axios";

const AssignRole = ({ staff, customerRoles, fetchStaffData,setStaff }) => {
  const [role, setRole] = useState("");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  console.log("role", role)

  const handleAssignRole = async () => {
    if (!role) {
      tostifyErr("Please select a Role");
    } else {
      let data = JSON.stringify({
        "roleId": role
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/staff/update/${staff?._id}`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios
        .request(config)
        .then((response) => {
          let resStatus = response.status === 200;
          if (resStatus) {
            setStaff({});
            fetchStaffData();
            tostifySuccess("Details updated successfully");
          }
        })
        .catch((error) => {
          console.log("Edit Staff error:", error);
          tostifyErr(error?.response?.data?.message);
        });
    }
  }
  console.log("rolename", staff?.roleId)
  return (
    <React.Fragment>
      {staff ?
        (<div className=" px-14 py-2 bg-white rounded-md min-h-[450px] overflow-y-auto">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <p className="text-[#979797] font-bold text-lg">Name</p>
                <p className="text-[#282828] font-semibold text-lg capitalize">{staff?.userId?.firstName} {"  "} {staff?.userId?.lastName}</p>
              </div>
              <div className="flex flex-col">
                <p className="text-[#979797] font-bold text-lg">Phone No.</p>
                <p className="text-[#282828] font-semibold text-lg">{staff?.userId?.phone}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col">
                <p className="text-[#979797] font-bold text-lg">Email</p>
                <p className="text-[#282828] font-semibold text-lg">
                  {staff?.userId?.email}
                </p>
              </div>{" "}
              <div className="flex flex-col">
                <p className="text-[#979797] font-bold text-lg">
                  Current Role
                </p>
                <div className="text-[#050F6] font-semibold tracking-wide capitalize">{staff?.roleId?.roleName || "No RoleSelected"}</div>
              </div>
              <div className="flex flex-col mt-2">
                <p className="text-[#979797] font-bold text-lg">
                  Custom Role Name
                </p>
                <select
                  className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none 
                focus:ring-1 ring-[#845ff6] w-60 ease-linear transition-all duration-150  "
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">Please Select a Roll</option>
                  {customerRoles?.map((v, i) => {
                    return (
                      <option key={i} value={v?._id}>{v?.roleName}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="flex justify-end gap-2 items-center">
            <div className="flex py-4">
              <button
                onClick={() => handleAssignRole()}
                className={`bg-gray-300 flex items-center gap-3 px-4 py-2 rounded-md`}
              >
                AssignRole
                <RiEdit2Fill
                  size={15}
                  className="mr-4 text-[#43434A] cursor-pointer hover:text-[#232326] transition ease-in-out hover:duration-300"
                />
              </button>
            </div>
          </div>
        </div>)
        : <div className=" px-14 py-2 bg-white rounded-md capitalize text-2xl 
                        min-h-[450px] text-[#050F64] text-center content-center  shadow-md">
          Please Select A Staff
        </div>}
    </React.Fragment>
  );
};

export default AssignRole;
