import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const AddLabel = (props) => {
  return (
    <React.Fragment>
      <Modal
        open={props.openAddLabelModal}
        onClose={() => props.setOpenAddLabelModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <div className="text-xl text-[#2b2f32] font-bold">Add Label</div>
            </div>

            <div className="flex-auto mt-2">
              <div>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    className={
                      0
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Short Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    className={
                      0
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Short Name"
                  />
                  {/* <textarea
                        className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                        placeholder="Descriptions"
                        ></textarea> */}
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Description
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <textarea
                        className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                        placeholder="Descriptions"
                        ></textarea>
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Color
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <select
                      type="text"
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-44 ease-linear transition-all duration-150"
                      placeholder="00"
                    >
                      <option value="option1">Select...</option>
                      <option value="option1">red</option>
                      <option value="option2">blue</option>
                      <option value="option3">black</option>
                      <option value="option3">green</option>
                      <option value="option3">yellow</option>
                    </select>
                  </div>

                  <div className="relative w-full ml-6">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Shape
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <select
                      type="text"
                      className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-44 ease-linear transition-all duration-150"
                      placeholder="00"
                    >
                      <option value="option1">Select...</option>
                      <option value="option1">Oval</option>
                      <option value="option2">Square</option>
                      <option value="option3">Circle</option>
                    </select>
                  </div>
                </div>

                {/* <div className="relative w-full mb-5">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    SELECT..
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    className={
                      0
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Bill amount"
                  />
                </div> */}

                <div className="w-full flex items-center justify-center mt-5">
                  <button className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:text-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]">
                    {0 ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Create</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddLabel;
