export const USER_LOGIN_DATA = "USER_LOGIN_DATA";

export const ALL_ORG_LIST = "ALL_ORG_LIST";

export const ALL_MANAGER_LIST = "ALL_MANAGER_LIST";

export const ALL_STAFF_LIST = "ALL_STAFF_LIST";

export const ALL_CLIENT_LIST = "ALL_CLIENT_LIST";

export const ALL_INVENTORY_SERVICE = "ALL_INVENTORY_SERVICE";

export const All_MEMBERSHIPS_LIST = "ALL_MEMBERSHIPS_LIST";

export const ALL_LASER_LIST = "ALL_LASER_LIST";

export const ALL_APPOINTMENT_LIST = "ALL_APPOINTMENT_LIST";

export const ALL_BILL_LIST = "ALL_BILL_LIST";

export const LOGOUT_USER = "LOGOUT_USER";

export const ALL_MEMBERSHIP_REVENUE = "ALL_MEMBERSHIP_REVENUE";

export const ALL_DISCOUNT_LIST = "ALL_DISCOUNT_LIST";

export const ALL_SUPPLIER_LIST = "ALL_SUPPLIER_LIST";

export const ALL_CUSTOMER_WITH_MEMBERSHIP = "ALL_CUSTOMER_WITH_MEMBERSHIP";

export const ALL_PRODUCT_LIST = "ALL_PRODUCT_LIST";

export const ALL_PACKAGE_LIST = "ALL_PACKAGE_LIST";

export const SERVICE_VS_STAFF = "sERVICE_vS_STAFF";

export const BILL_FORM = "BILL_FORM";

export const CLEAR_BILL_FORM = "CLEAR_BILL_FORM";

export const NEW_CREATED_BILL = "NEW_CREATED_BILL";

export const CLEAR_NEW_BILL = "CLEAR_NEW_BILL";

export const APPOINTMENT_FROM_CALENDAR = "APPOINTMENT_FROM_CALENDAR"

export const SET_BRANCH = "SET_BRANCH"

export const DRAFT_BILL = "DRAFT_BILL"

export const  CLEAR_DRAFT_BILL = "CLEAR_DRAFT_BILL"

export const CLIENT_WHILE_BILLING = "CLIENT_WHILE_BILLING"

export const  CLEAR_CLIENT_WHILE_BILLING = "CLEAR_CLIENT_WHILE_BILLING"

