import React, { useState, useEffect } from "react";
import { ImSearch } from "react-icons/im";
import { useSelector } from "react-redux";
import { getEncryptItems } from "../../Screens/Common/localStorageUtils";
import Dropdown from "../Dashboard/Dropdown";
import DownloadTableData from "../../Screens/Common/downloadData";

const ScheduledData = () => {
  const allAppointmentList = useSelector(
    (state) => state?.main?.appointmentList
  );
  const [searchData, setSearchData] = useState("");
  const [results, setResults] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const branch = getEncryptItems("branchDetails");
  const staffList = useSelector((state) => state?.main?.staffList)?.filter(
    (ele) => ele?.organizationId === branch?._id
  );

  useEffect(() => {
    filterAppointments();
  }, [searchData, selectedStaff, allAppointmentList]);

  const filterAppointments = () => {
    let filteredAppointments = allAppointmentList;

    if (selectedStaff) {
      filteredAppointments = filteredAppointments.filter((appointment) =>
        appointment?.services?.some(
          (service) => service?.staffId?._id === selectedStaff
        )
      );
    }

    if (searchData) {
      const searchValue = searchData.toLowerCase().trim();
      filteredAppointments = filteredAppointments.filter(
        (ele) =>
          ele?.customerName?.toLowerCase().includes(searchValue) ||
          ele?.customerPhone?.toLowerCase().includes(searchValue)
      );
    }

    setResults(filteredAppointments);
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleClear = () => {
    setSearchData("");
    setResults([]);
    setCurrentPage(1);
  };

  const handleStaffSelect = (value) => {
    setSelectedStaff(value || "");
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const displayAppointments =
    searchData || selectedStaff ? results : allAppointmentList;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = displayAppointments?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const rowData = currentItems?.map((data) => ({
    Name: data?.customerName,
    Time: new Date(data?.appointmentDate).toLocaleTimeString(
      [],
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    ),
    Date: data?.appointmentDate?.slice(0, 10),
    Phone: data?.customerPhone,
    Services: data?.services?.map(service => service?.serviceId?.name),
  }));
  const headerData = [
    { label: "Name", field: "Name" },
    { label: "Time", field: "Time" },
    { label: "Date", field: "Date" },
    { label: "Phone", field: "Phone" },
    { label: "Services", field: "Services" },
  ];

  return (
    <div className="mt-4">
      {allAppointmentList?.length > 0 ? (
        <div>
          <div
            className="px-5 py-5 bg-white w-full border-t grid
            grid-flow-row  rounded-t-lg overflow-x-auto gap-3 
            md:grid-flow-col justify-between border border-collapse"
          >
            <div className="font-bold text-2xl tracking-wide w-full lg:block hidden">
              Appointments
            </div>

            <div>
              <div className="bg-white rounded-lg w-full md:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                  <ImSearch color="gray" size={15} />
                </span>
                <input
                  id="searchData"
                  className="border-0 rounded px-6 py-2 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                  placeholder="Search client"
                  type="text"
                  value={searchData}
                  onChange={handleSearch}
                />
                {searchData && (
                  <span
                    className="absolute right-14 top-3 cursor-pointer"
                    onClick={handleClear}
                  >
                    X
                  </span>
                )}
              </div>
            </div>

            <div>
              <Dropdown
                label="Select Staff"
                items={staffList}
                itemName="staffList"
                onSelect={handleStaffSelect}
              />
            </div>

            <div>
              <DownloadTableData rowData={rowData} headerData={headerData} />
            </div>
          </div>
          <div>
            <div className="rounded-t-lg overflow-x-auto">
              <table className="min-w-full leading-normal border border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Sl.No
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Date
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Time
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Phone
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Name
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Services
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((appo, index) => (
                    <tr
                      key={`appo-${index}`}
                      className={`${
                        appo?.isDeleted
                          ? "hover:bg-gray-100 border border-gray-200 opacity-50"
                          : "hover:bg-gray-100 border border-gray-200"
                      } py-4 cursor-pointer`}
                    >
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        {indexOfFirstItem + index + 1}
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {appo?.appointmentDate?.slice(0, 10)}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {new Date(appo?.appointmentDate).toLocaleTimeString(
                            [],
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {appo?.customerPhone?.slice(3)}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {appo.customerName}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <ul className="list-disc list-inside">
                          {appo?.services?.map((service, index) => (
                            <li
                              key={`service-${index}`}
                              className="py-1 flex items-center"
                            >
                              <span className="font-semibold uppercase">
                                {service?.serviceId?.name}
                              </span>
                              <span className="mx-2 text-gray-500"> --- </span>
                              <span className="text-blue-600">
                                {service?.staffId?.userId?.firstName}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg border border-collapse overflow-x-auto ">
              <span className="text-xs xs:text-sm text-gray-900">
                Showing {indexOfFirstItem + 1} to{" "}
                {Math.min(indexOfLastItem, displayAppointments?.length)} of{" "}
                {displayAppointments?.length} Entries
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`text-sm ${
                    currentPage === 1
                      ? "bg-gray-200"
                      : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                >
                  Prev
                </button>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(displayAppointments?.length / itemsPerPage)
                  }
                  className={`text-sm ${
                    currentPage ===
                    Math.ceil(displayAppointments?.length / itemsPerPage)
                      ? "bg-gray-200"
                      : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center text-2xl">
          {searchData && results?.length === 0
            ? "No Appointments Found"
            : "No Appointments For Today"}
        </div>
      )}
    </div>
  );
};

export default ScheduledData;
