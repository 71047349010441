import React, { useState } from 'react';
import { decryptData } from '../../Screens/Common/localStorageUtils';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProducts } from '../../Screens/Common/initData';
import { allProductList } from '../../state-management/main/actions';
import { tostifyErr, tostifySuccess } from '../../Screens/Common/tostifyMessages';
import Tostify from '../../Screens/Common/Tostify';

const Stock = ({ data }) => {
    const initialState = {
        type: data?.type,
        stockType: '',
        quantity: 0,
        date: '',
        name: '',
        phone: '',
    }
    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const { id } = useParams();
    const allProducts = useSelector(state => state?.main?.productList);
    const selectedProduct = allProducts?.find(item => item?._id === id);

    const getProducts = async () => {
        const data = await fetchProducts();
        dispatch(allProductList(data));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (!formData.stockType) {
            newErrors.stockType = true;
        }

        if (formData.quantity <= 0) {
            newErrors.quantity = true;
        }

        if (!formData.date) {
            newErrors.date = true;
        }
        if (!formData.name) {
            newErrors.nameErr = true;
        }
        if (!formData.phone) {
            newErrors.phoneErr = true;
        }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const createdBy = decryptData()?.user?.role === "admin" ? {
                "name": decryptData()?.user?.firstName + " " + decryptData()?.user?.lastName,
                "adminId": decryptData()?.userTypeData?._id
            } : {
                "name": decryptData()?.user?.firstName + decryptData()?.user?.lastName,
                "staffId": decryptData()?.userTypeData?._id
            }
            let data1 = JSON.stringify({
                "productId": selectedProduct._id,
                "quantity": formData.quantity,
                "customerName": formData.name,
                "phoneNumber": formData.phone,
                "date": formData.date,
                "totalPrice": selectedProduct?.costPriceMRP * formData?.quantity,
                "organizationId": selectedProduct?.organization?._id,
                "adminId": decryptData()?.userTypeData?._id,
                "productSellType": formData?.stockType,
                "createdBy":createdBy
            });
            setIsLoading(true);

            let config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/sellhistory/add`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                data: data1
            };

            try {
                const response = await axios(config);
                console.log(JSON.stringify(response?.data));
                tostifySuccess(response?.data?.message);
                await getProducts();
                setIsLoading(false);
                setFormData(initialState)
            } catch (error) {
                console.log(error);
                setIsLoading(false);
                tostifyErr(error?.response?.data?.message);
            }
        } else {
            console.log('Form validation failed');
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    };

    return (
        <div className='bg-white text-gray-500 p-4'>
            <Tostify />
            <form className='' onSubmit={handleSubmit}>
                <div className='grid grid-cols-2 lg:grid-cols-3 gap-3'>
                    <div className='grid grid-cols-1 items-center'>
                        <label htmlFor="">Type</label>
                        <input
                            className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white 
                            rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear 
                            transition-all duration-150"
                            type="text"
                            placeholder={data?.type === 'retail' ? 'Retail' : (data?.type === 'consumable' && 'Consumable')}
                            disabled
                        />
                    </div>

                    <div className='grid grid-cols-1 items-center'>
                        <label htmlFor="">Stock Type</label>
                        <select
                            name="stockType"
                            id="stockType"
                            className={
                                errors.stockType ?
                                    "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                            }
                            value={formData.stockType}
                            onChange={handleInputChange}
                        >
                            <option value="">Select</option>
                            {/* <option value="Sell">Sell</option> */}
                            <option value="Return">Return</option>
                        </select>
                    </div>

                    <div className='grid grid-cols-1 items-center'>
                        <label htmlFor="">Quantity</label>
                        <input
                            type="number"
                            name="quantity"
                            id="quantity"
                            className={
                                errors.quantity ?
                                    "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                            }
                            value={formData.quantity}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className='grid grid-cols-1 items-center gap-2'>
                        <label htmlFor="">Date</label>
                        <input
                            type="date"
                            name="date"
                            id="date"
                            className={
                                errors.date ?
                                    "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                            }
                            value={formData.date}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="grid grid-cols-1 h-[auto] ">
                        <label htmlFor="name">
                            Name <span className="text-red-600 text-lg"> *</span>
                        </label>
                        <input
                            onChange={handleInputChange}
                            id="name"
                            autoComplete="off"
                            name="name"
                            value={formData.name}
                            type="text"
                            className={
                                errors.nameErr
                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                            }
                            placeholder="Enter Name"
                        />
                    </div>
                    <div className="grid grid-cols-1 h-[auto] ">
                        <label htmlFor="PhoneNumber">
                            Mobile Number <span className="text-red-600 text-lg"> *</span>
                        </label>
                        <div className="flex gap-2">
                            <select
                                id="code"
                                className={
                                    "text-[#132c4a] border-0 px-3 py-3 w-fit placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6]  ease-linear transition-all duration-150"
                                }
                            >
                                <option value="+91">+91</option>
                            </select>
                            <input
                                type="text"
                                onChange={handleInputChange}
                                placeholder={"Enter Number"}
                                id="PhoneNumber"
                                autoComplete="off"
                                name="phone"
                                value={formData.phone}
                                className={
                                    errors.phoneErr
                                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className='flex items-center justify-center mt-2'>
                    <button className='px-4 py-1 bg-primaryColor rounded-md hover:bg-primaryHoverColor text-white' type="submit">

                        {isLoading ? (
                            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                        ) : (
                            <span> {formData?.stockType === "Sell" ? "Sell" : "Return"}</span>
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Stock;
