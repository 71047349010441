import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const StockHistory = () => {
  const { id } = useParams();
  const [data, setData] = useState([])
  const [ledgers, setLedgers] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const allProducts = useSelector(state => state?.main?.productList);
  const selectedProduct = allProducts?.find((ele) => ele._id === id)

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = ledgers?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  currentItems?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    getStockHistory();
    getConsumptionHistory()
  }, [])

  const token = "Bearer " + localStorage.getItem("refresh_token");
  const getStockHistory = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/sellhistory/find-product/${id}`,
      headers: {
        'Authorization': token
      }
    };
    axios.request(config)
      .then((response) => {
        setData(response?.data?.data);
      })
      .catch((error) => {
        console.log("Get Stock History error", error);
      });

  }

  const getConsumptionHistory = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/stockLedger/find-product/${id}`,
      headers: {
        'Authorization': token
      }
    };

    axios.request(config)
      .then((response) => {
        console.log("first", response?.data?.data)
        setLedgers(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div className="mx-auto mt-4">
      {/* {
        selectedProduct?.type === 'retail' &&
        <table className="min-w-full leading-normal">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">SL.</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Customer Name</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Product Sell Type</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Date</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Quantity</th>
              <th className="px-5 py-3 border-b-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((ele, ind) =>
              <tr>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {ind + 1}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {ele?.customerName}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {ele?.productSellType}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {ele?.date?.slice(0, 10)}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {ele?.quantity}
                  </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  <div className="text-gray-900 whitespace-no-wrap">
                    Rs. {ele?.totalPrice}
                  </div>
                </td>
              </tr>)
            }
          </tbody>
        </table>
      } */}

      <table className="min-w-full leading-normal">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
              Sl
            </th>
            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
              Type
            </th>
            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
              Stock Type
            </th>
            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
              Quantity
            </th>
            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
              Date
            </th>
            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
              Stock Befor/After
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((v, i) => {
            return (
              <tr key={i}>
                <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {(i + 1)}
                  </div>
                </td>
                <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {v?.type}
                  </div>
                </td>

                <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {v?.stockType}
                  </div>
                </td>

                <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {v?.quantity || v?.volume} {v?.volume && "ml"}
                  </div>
                </td>
                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                  <div className="whitespace-no-wrap flex">
                    {v?.date?.slice(0, 10)}
                  </div>
                </td>
                <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                  <div className="text-gray-900 whitespace-no-wrap">
                    {v?.beforeStock} / {v?.afterStock}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {(ledgers?.length === 0) ? null : (
        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
          <span className="text-xs xs:text-sm text-gray-900">
            Showing {indexOfFirstItem + 1} to{" "}
            {indexOfFirstItem + currentItems?.length} {" "}
            of {ledgers?.length} Entries
          </span>
          <div className="inline-flex mt-2 xs:mt-0">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`text-sm ${currentPage === 1
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-l`}
            >
              Prev
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage ===
                Math.ceil(ledgers?.length / itemsPerPage)
              }
              className={`text-sm ${currentPage ===
                Math.ceil(ledgers?.length / itemsPerPage)
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-r`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div >
  )
}

export default StockHistory