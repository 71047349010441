import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function Consuptions() {
    const [data, setData] = useState([])
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const main = useSelector((state) => state?.main);
    const Staffs = main?.staffList
    const Managers = main?.managerList
    const { id } = useParams();

    useEffect(() => {
        getConsumptions();
    }, []);

    const getConsumptions = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/productIssue/find-product/${id}`,
            headers: {
                'Authorization': token
            }
        };

        axios.request(config)
            .then((response) => {
                // console.log(response?.data?.data);
                setData(response?.data?.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    return (
        <div className='h-auto max-h-[300px] overflow-y-scroll'>
            <table className='min-w-full bg-white border border-gray-300 '>
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">SL.</th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Date</th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Issued By</th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Issued To</th>
                        <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">Consumed</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.map((ele, ind) =>
                            <tr>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className="text-gray-900 whitespace-no-wrap">
                                        {ind + 1}
                                    </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className="text-gray-900 whitespace-no-wrap">
                                        {ele?.issueDate.slice(0, 10)}
                                    </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className="text-gray-900 whitespace-no-wrap">
                                        {Managers?.find(item => item._id === ele?.issuedBy)?.userId?.firstName}
                                    </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className="text-gray-900 whitespace-no-wrap">
                                        {Staffs?.find(item => item._id === ele?.issuedTo)?.userId?.firstName}
                                    </div>
                                </td>
                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                    <div className="text-gray-900 whitespace-no-wrap">
                                        {ele?.volume} ml
                                    </div>
                                </td>

                            </tr>)
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Consuptions;
