import * as actionTypes from "./actionTypes";

const initialState = {
  branchList: null,
  managerList: null,
  staffList: null,
  clientList: null,
  inventoryService: null,
  inventoryProduct: null,
  productList: null,
  discountList: null,
  expanditureList: null,
  membershipList: null,
};

let newState;

const adminReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.ALL_BRANCH_LIST: {
      newState = {
        ...state,
        branchList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_MANAGER_LIST: {
      newState = {
        ...state,
        managerList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_STAFF_LIST: {
      newState = {
        ...state,
        staffList: action.payload,
      };
      return newState;
    }

    case actionTypes.ALL_CLIENT_LIST: {
      newState = {
        ...state,
        clientList: action.payload,
      }
      return newState;
    }

    case actionTypes.ALL_INVENTORY_SERVICE: {
      newState = {
        ...state,
        inventoryService: action.payload,
      }
      return newState;
    }

    case actionTypes.ALL_INVENTORY_PRODUCT: {
      newState = {
        ...state,
        inventoryProduct: action.payload,
      }
      return newState;
    }

    case actionTypes.ALL_PRODUCT_LIST: {
      newState = {
        ...state,
        productList: action.payload,
      }
      return newState;
    }

    case actionTypes.ALL_DISCOUNT_LIST: {
      newState = {
        ...state,
        discountList: action.payload,
      }
      return newState;
    }

    case actionTypes.ALL_EXPANDITURE_LIST: {
      newState = {
        ...state,
        expanditureList: action.payload
      }
      return newState;
    }

    case actionTypes.ALL_MEMBERSHIP_LIST: {
      newState = {
        ...state,
        membershipList: action.payload
      }
      return newState;
    }

    default:
      return state;
  }
};

export default adminReducer;
