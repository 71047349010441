import { AES, enc } from "crypto-js";

const secretKey =
  "1a4b9f8c2e6d7a5b0f3e8c7a1d9b2a4f5d6c7a8b0e3f4c7a1d9b2a4f5d6c";

export const encryptData = (data) => {
  try {
    const jsonString = JSON.stringify(data);
    const encryptedData = AES.encrypt(jsonString, secretKey).toString();
    localStorage.setItem("encryptedData", encryptedData);
  } catch (error) {
    console.error("Error encrypting data:", error);
  }
};

export const decryptData = () => {
  const encryptedData = localStorage.getItem("encryptedData");

  if (!encryptedData) {
    return null;
  } else {
    try {
      const decrypted = AES.decrypt(encryptedData, secretKey).toString(
        enc.Utf8
      );
      return JSON.parse(decrypted);
    } catch (error) {
      console.log("Decryption error:", error);
    }
  }
};

export const encryptItems = (data, name) => {
  try {
    const jsonString = JSON.stringify(data);
    const encryptedData = AES.encrypt(jsonString, secretKey).toString();
    localStorage.setItem(name, encryptedData);
  } catch (error) {
    console.log("Error encryptingData:", error);
  }
};

export const getEncryptItems = (name) => {
  const encryptedData = localStorage.getItem(name);

  if (!encryptedData) {
    return null;
  } else {
    try {
      const decryptDataItmes = AES.decrypt(encryptedData, secretKey).toString(
        enc.Utf8
      );
      return JSON.parse(decryptDataItmes);
    } catch (error) {
      console.log("Decryption error:", error);
    }
  }
};
