import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { FaMoneyBills } from "react-icons/fa6";
import axios from "axios";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import DownloadTableData from "../../Screens/Common/downloadData";
import { useSelector } from "react-redux";
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";
import clear from "../../Assets/billClear.png";

const AdminIssueTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [results, setResults] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [issues, setIssues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const adminId = decryptData()?.userTypeData?._id;
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  // const branch = getEncryptItems("branchDetails");
  const branch = useSelector((state) => state?.main?.branch);

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = issues?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(issues?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    setSearchData(new Date().toISOString().slice(0, 10));
  }, []);

  useEffect(() => {
    fetchIssue();
  }, [branch]);
  const fetchIssue = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/issue/find-issue`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (userRole === "admin") {
          setIssues(
            response?.data?.data?.filter?.(
              (ele) => ele?.organization?.organizationId === branch?._id
            )
          );
        } else if (userRole === "staff") {
          setIssues(
            response?.data?.data?.filter?.(
              (ele) =>
                ele?.organization?.organizationId ===
                userRoleName?.organizationId?._id
            )
          );
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    // setClicked(false);
    setResults([]);
    setSearchData(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    const result = issues?.filter((item) =>
      item?.createdAt?.slice(0, 10).includes(e.target.value)
    );
    // setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData(new Date().toISOString().slice(0, 10));
    // setClicked(false);
    setResults([]);
  };

  const rowData = currentItems?.map((data) => ({
    Date: data?.createdAt?.slice(0, 10),
    Cause: data?.subject,
    Status: data?.isResolved,
  }));
  const headerData = [
    { label: "Date", field: "Date" },
    { label: "Cause", field: "Cause" },
    { label: "Status", field: "Status" },
  ];

  return (
    <React.Fragment>
      {(userRole === "admin" ||
        (userRole === "staff" && userRoleName?.roleId?.permissions?.issue)) && (
        <div>
          <div className="mt-4 flex justify-between flex-wrap gap-4">
            <form></form>

            {/* <div className="flex justify-between items-center gap-5 flex-wrap">
          <button
            onClick={() => setOpenAddExpanditure(true)}
            className="h-12 w-48 flex content-center items-center justify-center text-md font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
          >
            {0 ? (
              <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
            ) : (
              <span className="flex justify-center items-center">
                <FaMoneyBills size={22} />
                &nbsp; Add Expenditure
              </span>
            )}
          </button>
        </div> */}
          </div>
          <div className="mx-auto mt-4">
            <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col gap-3 justify-between border-b">
              <div className="w-full gap-2 items-center lg:block hidden">
                <div className="font-bold text-2xl tracking-wide">Issue</div>
              </div>

             <div className="flex gap-4">
             <div className="bg-white rounded shadow w-52 flex p-1  ">
                <input
                  id="searchData"
                  className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-primaryColor w-full ease-linear transition-all duration-150 pr-6"
                  placeholder="Enter date"
                  type="date"
                  value={searchData}
                  onChange={(e) => handleSearch(e)}
                />
                {/* {searchData && (
                    <span
                      className="absolute right-14 top-3 cursor-pointer"
                      onClick={handleClear}
                    >
                      X
                    </span>
                  )} */}
                {/* <button
                    onClick={(e) => handleSearch(e)}
                    className="flex items-center justify-center transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
                  >
                    <ImSearch color="gray" />
                  </button> */}
              </div>
              <button
                onClick={handleClear}
              >
                <img src={clear} alt="clear" className="w-8 h-8" />
              </button>
             </div>

              <div className="flex gap-2">
                <DownloadTableData rowData={rowData} headerData={headerData} />
              </div>
            </div>
            {isLoading ? (
              <SkeletonTableLoader />
            ) : issues?.length === 0 ? (
              <div className="flex justify-center bg-[#fff] w-full h-[200px] items-center shadow-md py-4">
                No Issues found
              </div>
            ) : searchData && results.length > 0 ? (
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Sl
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Date
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Cause
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {results?.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {i + 1}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.createdAt?.slice(0, 10)}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.subject?.length > 30
                              ? v?.subject?.slice(0, 30) + "....."
                              : v?.subject}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.isResolved ? "Resolved" : "Unresolved"}
                          </div>
                        </td>

                        {/*<td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                  <div className="whitespace-no-wrap flex">
                    <RiDeleteBin5Fill
                      onClick={() => {
                        setId(v?._id);
                        setOpenDeleteExpenditure(true);
                      }}
                      className="text-[#FF7866] hover:text-primaryHoverColor transition ease-in-out hover:duration-300"
                      size={15}
                    />
                  </div>
                    </td>*/}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : searchData && results.length === 0 ? (
              <div className="flex justify-center bg-[#fff] w-full h-[200px] items-center shadow-md py-4">
                No Issues found
              </div>
            ) : (
              <div className="shadow rounded-t-lg overflow-x-auto">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Sl
                      </th>
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Date
                      </th>
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Cause
                      </th>
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Status
                      </th>
                      {/*<th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Actions
        </th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems?.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {currentPage * 10 + i - 9}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {v?.createdAt?.slice(0, 10)}
                            </div>
                          </td>
                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {v?.subject?.length > 30
                                ? v?.subject?.slice(0, 30) + "....."
                                : v?.subject}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div
                              className={`whitespace-no-wrap ${
                                v?.isResolved
                                  ? "text-green-500"
                                  : "text-red-500"
                              }`}
                            >
                              {v?.isResolved ? "Resolved" : "Unresolved"}
                            </div>
                          </td>
                          {/*<td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          <RiDeleteBin5Fill
                            onClick={() => {
                              setId(v?._id);
                              setOpenDeleteExpenditure(true);
                            }}
                            className="text-[#FF7866] hover:text-primaryHoverColor transition ease-in-out hover:duration-300"
                            size={15}
                          />
                        </div>
                          </td>*/}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {
            (searchData && results?.length === 0 ) ? null : (
              <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                <span className="text-xs xs:text-sm text-gray-900">
                  Showing {indexOfFirstItem + 1} to{" "}
                  {results?.length
                    ? indexOfFirstItem + results?.length
                    : indexOfFirstItem + currentItems?.length}{" "}
                  of {issues?.length} Entries
                </span>
                <div className="inline-flex mt-2 xs:mt-0">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`text-sm ${
                      currentPage === 1
                        ? "bg-gray-200"
                        : "bg-gray-300 hover:bg-gray-400"
                    } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                  >
                    Prev
                  </button>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={
                      currentPage === Math.ceil(issues?.length / itemsPerPage)
                    }
                    className={`text-sm ${
                      currentPage === Math.ceil(issues?.length / itemsPerPage)
                        ? "bg-gray-200"
                        : "bg-gray-300 hover:bg-gray-400"
                    } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminIssueTable;
