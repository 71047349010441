import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import LaserReport from "./LaserReport";
import UpdateLaser from "./UpdateLaser";
import { fetchExpanditure } from "../../Screens/Common/initData";
import clear from '../../Assets/billClear.png';
import { useSelector } from "react-redux";
import { FaDownload } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const LaserTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [laserData, setLaserData] = useState([]);
  const [expenditureData, setExpenditureData] = useState([]);
  const [openUpdateLedger, setOpenUpdateLedger] = useState(false);
  const [id, setId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  // const branch = getEncryptItems("branchDetails");
  const branch = useSelector(state => state?.main?.branch);
  const filteredLaser = laserData?.filter((item) => {
    const itemDate = new Date(item?.date);
    const fromDate = from ? new Date(from) : null;
    const toDate = to ? new Date(to) : null;

    return (
      (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate)
    );
  });

  const filteredExpenditure = expenditureData?.filter((ele) => {
    const itemDate = new Date(ele?.date);
    const fromDate = from ? new Date(from) : null;
    const toDate = to ? new Date(to) : null;

    return (
      (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate)
    );
  });

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    from && to
      ? filteredLaser?.slice(indexOfFirstItem, indexOfLastItem)
      : laserData?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setfrom(new Date().toISOString().slice(0, 10))
    setTo(new Date().toISOString().slice(0, 10))
  }, [])

  const fetchLaserData = useCallback(async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/laser/calculate/${branch?._id}`,
      headers: {
        Authorization: token,
      },
    };
    setIsLoading(true);
    try {
      const res = await axios.request(config);
      const allLesers = res?.data?.ledgerByOrg;
      setLaserData(
        allLesers?.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [branch?._id, token]);
  const getExpenditures = async () => {
    const data = await fetchExpanditure();
    setExpenditureData(data);
  };

  // const handleOpenUpdateLedger = (id) => {
  //   setId(id);
  //   setOpenUpdateLedger(true);
  // };

  useEffect(() => {
    if (branch?._id !== undefined) {
      fetchLaserData();
      getExpenditures();
    } else {
      setLaserData([]);
      setExpenditureData([]);
    }
  }, [branch?._id, fetchLaserData]);

  const rowData = from && to ? filteredLaser?.map(data => ({
    Date: data?.date,
    Cash: data?.cash,
    Card: data?.card,
    Upi: data?.upi,
    Total: data?.total,
    CashExpense: data?.cashExpense,
    BankExpense: data?.bankExpense,
    SubTotalCash: data?.cash - data?.cashExpense,
    SubTotalBank: data?.card + data?.upi - data?.bankExpense,
    SubTotal: data?.subTotal
  })) : laserData?.map(data => ({
    Date: data?.date,
    Cash: data?.cash,
    Card: data?.card,
    Upi: data?.upi,
    Total: data?.total,
    CashExpense: data?.cashExpense,
    BankExpense: data?.bankExpense,
    SubTotalCash: data?.cash - data?.cashExpense,
    SubTotalBank: data?.card + data?.upi - data?.bankExpense,
    SubTotal: data?.subTotal
  }));
  const headerData = [
    { label: "Date", field: "Date" },
    { label: "Cash", field: "Cash" },
    { label: "Card", field: "Card" },
    { label: "Upi", field: "Upi" },
    { label: "Total", field: "Total" },
    { label: "CashExpense", field: "CashExpense" },
    { label: "BankExpense", field: "BankExpense" },
    { label: "SubTotalCash", field: "SubTotalCash" },
    { label: "SubTotalBank", field: "SubTotalBank" },
    { label: "SubTotal", field: "SubTotal" },
  ];

  const handleDownloadPDF = () => {
    // Initialize jsPDF with landscape orientation
    const doc = new jsPDF('landscape');
    if (rowData?.length === 0) {
      return;
    }

    const tableData = rowData.map((row, index) => {
      const rowDataWithIndex = { "Sl. No.": index + 1 };
      headerData.forEach((header) => {
        rowDataWithIndex[header.label] = row[header.field];
      });
      return rowDataWithIndex;
    });

    const tableHeaders = [
      "Sl. No.",
      ...headerData.map((header) => header.label),
    ];

    // Add a title to the PDF
    doc.setFontSize(16);
    doc.text("Report Table", 20, 20);

    // Add a date to the PDF
    const date = new Date().toLocaleDateString();
    doc.setFontSize(12);
    doc.text(`Date: ${date}`, 20, 30);

    // Add the table content to the PDF using autoTable
    doc.autoTable({
      startY: 40,
      head: [tableHeaders],
      body: tableData.map((row) => Object.values(row)),
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [254, 135, 64] },
      alternateRowStyles: { fillColor: [250, 250, 250] },
    });

    // Save the PDF
    doc.save("LaserData.pdf");
  };

  return (
    <React.Fragment>
      {/* <ExcelDownload/> */}
      {id && (
        <UpdateLaser
          openUpdateLedger={openUpdateLedger}
          setOpenUpdateLedger={setOpenUpdateLedger}
          fetchLaserData={fetchLaserData}
          id={id}
        />
      )}

      <div className="mx-auto mt-4">
        <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col gap-3 justify-between">
          <div className="w-full gap-2 items-center lg:block hidden">
            <div className="font-bold text-2xl tracking-wide">Ledger</div>
          </div>

          <div className="flex flex-wrap items-center gap-4">
            <div className="flex items-center gap-2">
              <span className="text-gray-600">From</span>
              <input
                value={from}
                onChange={(e) => setfrom(e.target.value)}
                type="date"
                className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
              />
            </div>

            <div className="flex items-center gap-2">
              <span className="text-gray-600">To</span>
              <input
                value={to}
                onChange={(e) => setTo(e.target.value)}
                type="date"
                className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
              />
            </div>

            <span
              onClick={() => {
                setfrom(new Date().toISOString().slice(0, 10));
                setTo(new Date().toISOString().slice(0, 10));
              }}
              className="rounded-full cursor-pointer flex items-center justify-center text-md font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 shadow-lg shadow-[#ffdcc7]"
            >
              <img src={clear} alt="clear" className="w-8 h-8" />
            </span>
            {/* 
            {branch?._id && (
              <BulkPayoutExcel isLoading={isLoading} data={filteredLaser} />
            )} */}
          </div>

          <div className="flex items-center gap-2">
            <FaDownload
              onClick={handleDownloadPDF}
              size={25} className="text-primaryColor cursor-pointer" />
            <LaserReport
              from={from}
              to={to}
              laserData={filteredLaser}
              expenseData={filteredExpenditure}
              isLoading={isLoading}
            />
          </div>
        </div>
        {isLoading ? (
          <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : laserData?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Lasers found
          </div>
        ) : (
          //  : (from && to) ? (
          //   <ReportsDynamicTable rowData={ledgerRowData} headerData={ledgerHeaderData} />
          // )
          <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Date
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Total
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Cards
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Upi
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Cash
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-center text-sm font-semibold text-gray-600 tracking-wide">
                    Expense
                    <div className="flex gap-2 justify-center">
                      <div className="border-r-2 border-gray-600 pr-2">
                        Cash Expense
                      </div>
                      <div>
                        Bank Expense
                      </div>
                    </div>
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    SubTotal Cash
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    SubTotal Bank
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    SubTotal
                  </th>
                  {/* <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Received From Bank
                  </th> */}
                  {/* for review */}
                  {/* <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Remarks
                    </th> */}
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {currentPage * 10 + i - 9}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.date}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.total}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.card}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.upi}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.cash}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="flex justify-between gap-2 items-center ">
                          <div className="text-gray-900 whitespace-no-wrap text-center w-1/2">
                            {v?.cashExpense}
                          </div>
                          <div className="text-gray-900 whitespace-no-wrap text-center 00 w-1/2">
                            {v?.bankExpense}
                          </div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.cash - v?.cashExpense}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.card + v?.upi - v?.bankExpense}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.subTotal}
                        </div>
                      </td>

                      {/* <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex gap-2 items-center">
                          <span className="flex gap-2 w-16 items-center">
                            {v?.amountFromBank}{" "}
                            <FaIndianRupeeSign className="text-primaryColor" />
                          </span>{" "}
                          <RiEdit2Fill
                            size={15}
                            onClick={() => handleOpenUpdateLedger(v?._id)}
                            className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                          />
                        </div>
                      </td> */}

                      {/* for review */}
                      {/* <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.remark?.toString()?.slice(0, -1)}
                          </div>
                        </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {currentItems?.length !== 0 ? (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {indexOfFirstItem + currentItems?.length} of{" "}
              {filteredLaser?.length > 0
                ? filteredLaser?.length
                : laserData?.length}{" "}
              Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(
                    filteredLaser?.length
                      ? filteredLaser?.length / itemsPerPage
                      : laserData?.length / itemsPerPage
                  )
                }
                className={`text-sm ${currentPage ===
                  Math.ceil(
                    filteredLaser?.length
                      ? filteredLaser?.length / itemsPerPage
                      : laserData?.length / itemsPerPage
                  )
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default LaserTable;
