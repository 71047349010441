import React from "react";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import { useState } from "react";
import { useEffect } from "react";
import {
  getIssueHistory,
  getProductLedger,
} from "../../../Screens/Common/initData";
import { useSelector } from "react-redux";
import ReportFilterType from "../ReportFilterType";
import excel from "../../../Assets/xls.png";
import pdf from "../../../Assets/Pdf.png";
import Dropdown from "../../../Screens/Common/Dropdown";
import DownloadReport from "../DownloadReport";

const InventoryReport = () => {
  const [stockLedger, setStockLedger] = useState([]);
  const [subcategory, setsubcategory] = useState("");
  const [issueHistory, setIssueHistory] = useState([]);
  const staffList = useSelector((state) => state?.main?.staffList);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("month");
  const branch = useSelector((state) => state?.main?.branch);

  const today = new Date();
  const last7days = new Date(today);
  last7days.setDate(today.getDate() - 7);
  const last30days = new Date(today);
  last30days.setDate(today.getDate() - 30);

  const getStockLedger = async () => {
    const data = await getProductLedger();
    setStockLedger(
      data?.filter((item) => item?.organizationId === branch?._id)
    );
  };
  const fetchIssues = async () => {
    const data = await getIssueHistory();
    setIssueHistory(data?.filter((item) => item?.organization === branch?._id));
  };

  useEffect(() => {
    getStockLedger();
    fetchIssues();
  }, [branch]);

  const filteredDayWeekLedger =
    selectedCategory === "day"
      ? stockLedger?.filter(
          (item) => new Date(item?.date).toISOString() === today.toISOString()
        )
      : selectedCategory === "week"
      ? stockLedger?.filter((item) => new Date(item?.date) >= last7days)
      : selectedCategory === "month"
      ? stockLedger?.filter((item) => new Date(item?.date) >= last30days)
      : selectedCategory === "customRange"
      ? stockLedger?.filter((item) => {
          const itemDate = new Date(item?.date).getTime();
          const startTimestamp = new Date(startDate).getTime();
          const endTimestamp = new Date(endDate).getTime();
          return itemDate >= startTimestamp && itemDate <= endTimestamp;
        })
      : [];

  const filteredDayWeekIssues =
    selectedCategory === "day"
      ? issueHistory?.filter(
          (item) =>
            new Date(item?.issueDate).toISOString() === today.toISOString()
        )
      : selectedCategory === "week"
      ? issueHistory?.filter((item) => new Date(item?.issueDate) >= last7days)
      : selectedCategory === "month"
      ? issueHistory?.filter((item) => new Date(item?.issueDate) >= last30days)
      : selectedCategory === "customRange"
      ? issueHistory?.filter((item) => {
          const itemDate = new Date(item?.issueDate).getTime();
          const startTimestamp = new Date(startDate).getTime();
          const endTimestamp = new Date(endDate).getTime();
          return itemDate >= startTimestamp && itemDate <= endTimestamp;
        })
      : [];
  const filteredStockLedger =
    subcategory === "stockLedger"
      ? filteredDayWeekLedger
      : subcategory === "issueHistory"
      ? issueHistory
      : issueHistory;

  const filteredIssueHeaderData = [
    { label: "Issued By", field: "issuedBy" },
    { label: "Issued To", field: "issuedTo" },
    { label: "Product", field: "product" },
    { label: "Volume", field: "volume" },
  ];

  const filteredLedgerHeaderData = [
    { label: "Product", field: "product" },
    { label: "Before", field: "beforeStock" },
    { label: "After", field: "afterStock" },
    { label: "StockType", field: "stockType" },
    { label: "Type", field: "type" },
    { label: "Volume", field: "volume" },
  ];
  const filteredLedgerRowData = filteredStockLedger?.map((ledger) => ({
    product: ledger?.productId?.name,
    beforeStock: ledger?.beforeStock,
    afterStock: ledger?.afterStock,
    stockType: ledger?.stockType,
    type: ledger?.type,
    volume: ledger?.volume,
  }));

  console.log("filteredStockLedger", filteredStockLedger);
  const filteredIssueRowData = filteredStockLedger?.map((issue) => ({
    issuedBy: staffList?.find((item) => item?._id === issue?.issuedBy)?.userId
      ?.firstName,
    issuedTo: issue?.issuedTo?.userId?.firstName,
    product: issue?.productId?.name,
    volume: issue?.volume,
  }));

  const handleSelect = (value) => {
    setsubcategory(value);
  };
  return (
    <>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide lg:block hidden">
          Reports
        </div>

        <div>
          <ReportFilterType
            setSelectedCategory={setSelectedCategory}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            subcategory={subcategory}
          />
        </div>

        <div>
          <Dropdown
            label="All"
            items={["All", "stockLedger", "issueHistory"]}
            onSelect={handleSelect}
          />
        </div>

        {/* <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
          <img
            src={excel}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download CSV"
          />
          <img
            src={pdf}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download PDF"
          />
        </div> */}
        <DownloadReport
          rowData={filteredIssueRowData}
          headerData={filteredIssueHeaderData}
        />
      </div>
      {subcategory === "issueHistory" && (
        <ReportsDynamicTable
          rowData={filteredIssueRowData}
          headerData={filteredIssueHeaderData}
        />
      )}
      {subcategory === "All" && (
        <ReportsDynamicTable
          rowData={filteredIssueRowData}
          headerData={filteredIssueHeaderData}
        />
      )}
      {subcategory === "stockLedger" && (
        <ReportsDynamicTable
          rowData={filteredLedgerRowData}
          headerData={filteredLedgerHeaderData}
        />
      )}
    </>
  );
};

export default InventoryReport;
