import React from 'react';

const Tooltip = ({ text, children, showTooltip, setShowTooltip }) => {
  const toggleTooltip = () => {
    setShowTooltip((prev) => !prev);
  };

  return (
    <div className="relative w-full">
      <div
        onClick={toggleTooltip}
        className="flex items-center justify-center w-full h-full cursor-pointer"
      >
        {children}
      </div>
      {showTooltip && (
        <div
          className="absolute z-10 p-4 mt-2 bg-white rounded-lg shadow-lg border border-gray-200"
          style={{
            top: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            animation: "fadeIn 0.3s ease-in-out",
            width: "max-content",
            maxWidth: "90vw",
          }}
        >
          <h3 className="mb-3 text-lg font-semibold text-gray-800 capitalize">
            Package Details:
          </h3>
          <div className="flex flex-wrap gap-4 text-sm text-gray-700">
            <DetailItem label="Name" value={text?.packageName} />
            <DetailItem label="Cost" value={text?.price} />
            <DetailItem label="Wallet Balance" value={text?.packagePrice} />
            <DetailItem label="Validity" value={`${text?.validity || ""} months`} />
            {/* <DetailItem label="Services" value="spa" /> */}
            {/* <DetailItem label="Discount" value={text?.discount} /> */}
          </div>
        </div>
      )}
    </div>
  );
};

const DetailItem = ({ label, value }) => (
  <div className="flex flex-col items-center">
    <span className="font-semibold text-gray-800">{label}</span>
    <span>{value}</span>
  </div>
);

export default Tooltip;