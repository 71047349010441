import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { FaUserTag } from "react-icons/fa6";
import { RiEdit2Fill } from "react-icons/ri";
import NewOrder from "./Products Modal/Order Modal/NewOrder";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import EditOrder from "./Products Modal/Order Modal/EditOrder";
import ConfirmOrder from "./Products Modal/Order Modal/ConfirmOrder";
import DownloadTableData from "../../Screens/Common/downloadData";
import add from '../../Assets/AddFrame(1).png'
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";

const Order = () => {
  const [isLoading, setIsLoading] = useState("");
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [openEditOrder, setOpenEditOrder] = useState(false);
  const [openConfirmOrder, setOpenConfirmOrder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);
  const [results, setResults] = useState([]);
  const [id, setId] = useState();
  const [initialValue, setInitialValue] = useState();
  const [orders, setOrders] = useState([]);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const filteredOrders = orders?.filter(
    (item) => item.organizationId === branch?._id
  );
  const canEditOrder = userRole === "staff" &&
    (userRoleName?.roleId?.permissions?.products?.editOrder);

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrders?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchOrders();
  }, [branch]);

  const fetchOrders = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/order/find-orders`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setOrders(response?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpenEdit = (id) => {
    setId(id);
    const initial = filteredOrders?.find((product) => product._id === id);
    setInitialValue(initial);
    setOpenEditOrder(true);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    const result = filteredOrders?.filter((item) =>
      item?.supplierId?.name?.toLowerCase().includes(e.target.value?.toLowerCase())
    );
    setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    setClicked(false);
    setResults([]);
  };
  const rowData = filteredOrders?.map(data => ({
    Supplier_Name: data?.supplierId?.name,
    Amount: data?.products?.reduce(
      (acc, curr) => acc + curr.price,
      0
    ),
    Date: data?.date?.slice(0, 10),
    TaxType: data?.taxType,
    TaxGroup: data?.taxGroup,
    Status: data?.status ? "Received" : "Pending"
  }));
  const headerData = [{ label: "Supplier Name", field: "Supplier_Name" },
  { label: "Amount", field: "Amount" },
  { label: "Date", field: "Date" },
  { label: "TaxType", field: "TaxType" },
  { label: "TaxGroup", field: "TaxGroup" },
  { label: "Status", field: "Status" }];

  return (
    <React.Fragment>
      <NewOrder
        openNewOrder={openNewOrder}
        setOpenNewOrder={setOpenNewOrder}
        fetchOrders={fetchOrders}
      />

      <ConfirmOrder
        openConfirmOrder={openConfirmOrder}
        setOpenConfirmOrder={setOpenConfirmOrder}
        fetchOrders={fetchOrders}
        setId={setId}
        id={id}
      />

      {initialValue && (
        <EditOrder
          initialValue={initialValue}
          openEditOrder={openEditOrder}
          handleOpenEdit={handleOpenEdit}
          setOpenEditOrder={setOpenEditOrder}
          fetchOrders={fetchOrders}
        />
      )}

      {/* <div className="mt-4 flex justify-between flex-wrap gap-4">
        <form onSubmit={(e) => handleSearch(e)}>
          <div className="bg-white rounded shadow w-52 flex p-1 relative ">
            <input
              id="searchData"
              className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-6"
              placeholder="Search Product"
              type="text"
              value={searchData}
              onChange={handleChange}
            />
            {searchData && (
              <span
                className="absolute right-14 top-3 cursor-pointer"
                onClick={handleClear}
              >
                X
              </span>
            )}
            <button
              onClick={handleSearch}
              className="flex items-center justify-center bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
            >
              <ImSearch color="white" />
            </button>
          </div>
        </form>

        <div className="flex justify-between items-center gap-5 flex-wrap">
          {(userRole === "admin" ||
            (userRole === "staff" &&
              userRoleName?.roleId?.permissions?.products?.createOrder)) && (
            <button
              onClick={() => setOpenNewOrder(true)}
              className="h-12 w-48 flex content-center items-center justify-center text-md font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
            >
              {0 ? (
                <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
              ) : (
                <span className="flex justify-center items-center">
                  <FaUserTag size={22} />
                  &nbsp; New Order
                </span>
              )}
            </button>
          )}
        </div>
      </div> */}
      <div className="mx-auto mt-4">
        <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col justify-between">
          <div className="font-bold text-2xl tracking-wide w-full">
            Orders
          </div>

          <div className="flex w-full justify-center mt-2 xs:mt-0">
            <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
              <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                <ImSearch color="gray" size={15} />
              </span>
              <input
                id="searchData"
                className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                placeholder="Search Order"
                type="text"
                value={searchData}
                onChange={(e) => {
                  setCurrentPage(1);
                  handleSearch(e);
                }}
              />
              {searchData && (
                <span
                  className="absolute right-14 top-2 cursor-pointer"
                  onClick={handleClear}
                >
                  X
                </span>
              )}
            </div>
          </div>

          <div className="font-bold text-2xl tracking-wide w-full flex  justify-end gap-2">
            <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
              <DownloadTableData
                rowData={rowData}
                headerData={headerData}
              />
              {(userRole === "admin" ||
                (userRole === "staff" &&
                  userRoleName?.roleId?.permissions?.products?.createOrder)) && (
                  <>
                    <button
                      onClick={() => setOpenNewOrder(true)}
                    >
                      {0 ? (
                        <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                      ) : (
                        <span className="flex justify-center items-center">
                          <img
                            src={add}
                            alt="add"
                            className="text-primaryColor w-7 h-7"
                          />
                        </span>
                      )}
                    </button>
                  </>
                )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <SkeletonTableLoader />
        ) : results?.length === 0 && searchData ? (
          <div className="flex justify-center bg-[#fff] shadow-md py-4">
            No Orders found
          </div>
        ) : searchData && results.length > 0 ? (
          <div>
            <div className="shadow rounded-t-lg overflow-x-auto">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Sl
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Supplier Name
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Amount
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      TaxType
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      TaxGroup
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Date
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      Status
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                      {(userRole === "admin" || canEditOrder) ? (
                        <span>Actions</span>
                      ) : null}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {results?.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {i + 1}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.supplierId?.name}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.products?.reduce(
                              (acc, curr) => acc + curr.price,
                              0
                            )}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.date?.slice(0, 10)}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.taxType}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.taxGroup}
                          </div>
                        </td>

                        <td
                          onClick={() => {
                            if (!v?.status) {
                              setId(v?._id);
                              setOpenConfirmOrder(true);
                            }
                          }}
                          className={`px-5 py-5  underline cursor-pointer border border-gray-200 bg-white text-sm tracking-wide ${v?.status
                            ? "text-green-500"
                            : "text-primaryHoverColor"
                            }`}
                        >
                          <div className="whitespace-no-wrap">
                            {v?.status ? "Received" : "Pending"}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="whitespace-no-wrap flex">
                            {(userRole === "admin" ||
                              (userRole === "staff" &&
                                userRoleName?.roleId?.permissions?.products
                                  ?.editOrder)) && (
                                <RiEdit2Fill
                                  size={15}
                                  onClick={() => {
                                    if (!v?.status) {
                                      handleOpenEdit(v?._id);
                                    }
                                  }}
                                  className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                />
                              )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Supplier Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Amount
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Date
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    TaxType
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    TaxGroup
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    Status
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                    {(userRole === "admin" || canEditOrder) ? (
                      <span>Actions</span>
                    ) : null}
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.supplierId?.name}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.products?.reduce(
                            (acc, curr) => acc + curr.price,
                            0
                          )}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.date?.slice(0, 10)}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.taxType}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.taxGroup}
                        </div>
                      </td>

                      <td
                        onClick={() => {
                          if (!v?.status) {
                            setId(v?._id);
                            setOpenConfirmOrder(true);
                          }
                        }}
                        className={`px-5 py-5  underline cursor-pointer border border-gray-200 bg-white text-sm tracking-wide ${v?.status
                          ? "text-green-500"
                          : "text-primaryHoverColor"
                          }`}
                      >
                        <div className="whitespace-no-wrap">
                          {v?.status ? "Received" : "Pending"}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          {(userRole === "admin" ||
                            (userRole === "staff" &&
                              userRoleName?.roleId?.permissions?.products
                                ?.editOrder)) && (
                              <RiEdit2Fill
                                size={15}
                                onClick={() => {
                                  if (!v?.status) {
                                    handleOpenEdit(v?._id);
                                  }
                                }}
                                className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                              />
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {(results?.length === 0 && filteredOrders?.length === 0) ||
          (searchData && results?.length === 0) ? null : (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {results?.length
                ? indexOfFirstItem + results?.length
                : indexOfFirstItem + currentItems?.length}{" "}
              of {filteredOrders?.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredOrders?.length / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                  Math.ceil(filteredOrders?.length / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Order;
