import React from "react";
import LaserTable from "../../Components/Laser/LaserTable";

const Laser = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Laser`;

  return (
    <React.Fragment>
      <LaserTable />
    </React.Fragment>
  );
};

export default Laser;
