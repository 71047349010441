import React from "react";
import ReactApexChart from "react-apexcharts";

const DailyRevenue = ({ dailyRevenueData }) => {
  const chartData = {
    series: [
      {
        name: "Actual",
        data: Object.entries(dailyRevenueData)?.map((data) => ({
          x: data[0], // Use the date string as x value
          y: data[1][0].total,
          goals: [
            {
              name: "Expected",
              value: 1000, // Replace with the actual expected value
              strokeHeight: 5,
              strokeColor: "#740093",
            },
          ],
        })),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#fe8740"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Actual", "Expected"],
        markers: {
          fillColors: ["#fe8740", "#740093"],
        },
      },
      xaxis: {
        type: 'category',
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return new Intl.NumberFormat().format(value);
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <div
        className="bg-white w-full md:w-1/2 rounded-lg shadow mt-4 py-3"
        id="chart"
      >
        <div>
          <div className="font-bold text-2xl mt-2 tracking-wide mx-4">
            Daily Revenue
          </div>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailyRevenue;