import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { FaMoneyBills } from "react-icons/fa6";
import AddExpanditure from "./ExpanditureModal/AddExpanditure";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// import { RiDeleteBin5Fill } from "react-icons/ri";
import Tostify from "../../Screens/Common/Tostify";
import { allExpanditureList } from "../../state-management/admin/actions";
import DeleteExpenditure from "./ExpanditureModal/DeleteExpenditure";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import DownloadTableData from "../../Screens/Common/downloadData";
import add from "../../Assets/AddFrame(1).png";
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";
import clear from "../../Assets/billClear.png";

const ExpanditureTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAddExpanditure, setOpenAddExpanditure] = useState(false);
  const [openDeleteExpenditure, setOpenDeleteExpenditure] = useState(false);
  const [results, setResults] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const admin = useSelector((state) => state.admin);
  const adminId = decryptData()?.userTypeData?._id;
  // const branch = getEncryptItems("branchDetails");
  const branch = useSelector((state) => state?.main?.branch) || "";
  const dispatch = useDispatch();
  const filteredExpenditure = admin?.expanditureList?.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredExpenditure?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredExpenditure?.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    fetchExpanditure();
  }, [branch?._id, token]);

  useEffect(() => {
    setSearchData(new Date().toISOString().slice(0, 10));
  }, []);

  const fetchExpanditure = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/expenditure/find-expenditure/?filterBy=organizationId&value=${branch?._id}`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        dispatch(allExpanditureList(response?.data?.data));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    const result = filteredExpenditure?.filter((item) =>
      item?.date.includes(e.target.value)
    );
    setResults(result);
  };

  // console.log("filteredExpenditure", filteredExpenditure)

  const handleClear = () => {
    setSearchData(new Date().toISOString().slice(0, 10));
    setResults([]);
  };

  const rowData = currentItems?.map((data) => ({
    Date: data?.createdAt,
    Time: data?.currentTime,
    Expense: data?.expense,
    Remark: data?.remark,
  }));
  const headerData = [
    { label: "Date", field: "Date" },
    { label: "Time", field: "Time" },
    { label: "Expense", field: "Expense" },
    { label: "Remark", field: "Remark" },
  ];

  return (
    <React.Fragment>
      {/* <Tostify /> */}
      <AddExpanditure
        fetchExpanditure={fetchExpanditure}
        openAddExpanditure={openAddExpanditure}
        setOpenAddExpanditure={setOpenAddExpanditure}
      />

      <DeleteExpenditure
        openDeleteExpenditure={openDeleteExpenditure}
        fetchExpanditure={fetchExpanditure}
        setOpenDeleteExpenditure={setOpenDeleteExpenditure}
        id={id}
      />

      <div className="mt-4 flex justify-between flex-wrap gap-4">
        <form onSubmit={(e) => handleSearch(e)}></form>

        <div className="flex justify-between items-center gap-5 flex-wrap"></div>
      </div>
      <div className="mx-auto mt-4">
        <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row lg:grid-flow-col gap-3 justify-between border-b ">
          <div className="w-full gap-2 items-center lg:block hidden">
            <div className="font-bold text-2xl tracking-wide">Expenditure</div>
          </div>

          <div className="flex gap-2 items-center">
            <div className="bg-white rounded shadow w-52 flex p-1 relative ">
              <input
                id="searchData"
                className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-6"
                placeholder="Search..."
                type="date"
                value={searchData}
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <div>
              <button onClick={handleClear}>
                <img src={clear} alt="clear" className="w-8 h-8" />
              </button>
            </div>
          </div>

          <div className="flex gap-2">
            <DownloadTableData rowData={rowData} headerData={headerData} />
            <button
              onClick={() => setOpenAddExpanditure(true)}
              className="mb-4"
            >
              <img src={add} alt="add" className="w-8 h-8" />
            </button>
          </div>
        </div>
        {isLoading ? (
          <SkeletonTableLoader />
        ) : filteredExpenditure?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md h-[200px] items-center py-4">
            No Expenditure found
          </div>
        ) : searchData && results.length > 0 ? (
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Sl
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Date
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Time
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Expense
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Remark
                </th>
                {/*<th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
              Actions
  </th>*/}
              </tr>
            </thead>
            <tbody>
              {results?.map((v, i) => {
                return (
                  <tr key={i}>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {i + 1}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.date}
                      </div>
                    </td>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.currentTime}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.expense}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.remark}
                      </div>
                    </td>
                    {/*<td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                  <div className="whitespace-no-wrap flex">
                    <RiDeleteBin5Fill
                      onClick={() => {
                        setId(v?._id);
                        setOpenDeleteExpenditure(true);
                      }}
                      className="text-[#FF7866] hover:text-primaryHoverColor transition ease-in-out hover:duration-300"
                      size={15}
                    />
                  </div>
                    </td>*/}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : searchData && results.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md h-[200px] items-center py-4">
            No Expenditure found
          </div>
        ) : (
          <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Date
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Time
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Expense
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Remark
                  </th>
                  {/*<th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Actions
        </th>*/}
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {currentPage * 5 + i - 4}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.date}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.currentTime}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.expense}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.remark}
                        </div>
                      </td>
                      {/*<td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          <RiDeleteBin5Fill
                            onClick={() => {
                              setId(v?._id);
                              setOpenDeleteExpenditure(true);
                            }}
                            className="text-[#FF7866] hover:text-primaryHoverColor transition ease-in-out hover:duration-300"
                            size={15}
                          />
                        </div>
                          </td>*/}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {(filteredExpenditure?.length === 0) ||
          (searchData && results?.length === 0) ? null : (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {results?.length
                ? indexOfFirstItem + results?.length
                : indexOfFirstItem + currentItems?.length}{" "}
              of{" "}
              {results?.length ? results?.length : filteredExpenditure?.length}{" "}
              Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredExpenditure?.length / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                    Math.ceil(filteredExpenditure?.length / itemsPerPage)
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ExpanditureTable;
