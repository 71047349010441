import { Modal } from '@mui/material';
import React, { useEffect, useRef } from 'react';

const keyframes = `
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
`;

const NewLoader = () => {
  const styleRef = useRef(null);

  useEffect(() => {
    // Create a style element and append keyframes
    const style = document.createElement('style');
    style.innerHTML = keyframes;
    document.head.appendChild(style);

    // Set the ref to the style element
    styleRef.current = style;

    // Clean up
    return () => {
      if (styleRef.current) {
        document.head.removeChild(styleRef.current);
      }
    };
  }, []); // Empty dependency array ensures this effect runs once

  return (
    <Modal
      open={true}
      onClose={() => console.log('close')}
      aria-labelledby="Open-invoice-modal-title"
      aria-describedby="Open-invoice-modal-description"
    >
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-600 bg-opacity-70">
        <div className="flex space-x-2">
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#f95e01',
              borderRadius: '50%',
              animation: 'bounce 0.6s ease-in-out infinite',
            }}
          />
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#f95e01',
              borderRadius: '50%',
              animation: 'bounce 0.6s ease-in-out infinite',
              animationDelay: '0.2s',
            }}
          />
          <div
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#f95e01',
              borderRadius: '50%',
              animation: 'bounce 0.6s ease-in-out infinite',
              animationDelay: '0.4s',
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewLoader;