import React, { useEffect } from "react";
import { decryptData } from "../Common/localStorageUtils";
import { useNavigate } from "react-router-dom";

const FirstRenderingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const encryptedData = localStorage.getItem("encryptedData");
    if (!encryptedData) {
      setTimeout(() => {
        navigate("/login");
      }, 100);
    } else {
      const userRole = decryptData()?.user?.role;
      if (userRole) {
        setTimeout(() => {
          navigate(`/${userRole}/dashboard`);
        }, 100);
      } else {
        setTimeout(() => {
          navigate("/404");
        }, 100);
      }
    }
  }, [navigate]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-orange-500"></div>
    </div>
  );
};

export default FirstRenderingPage;
