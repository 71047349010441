import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { fetchServiceHistory } from "../../Screens/Common/initData";
import { serviceVsStaff } from "../../state-management/main/actions";
import { getEncryptItems } from "../../Screens/Common/localStorageUtils";

const ServiceVsStaffs = ({ dailyServiceVsStaff }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const branch = getEncryptItems("branchDetails")
  const serviceHistory = useSelector(state => state?.main?.serviceVsStaffData)?.filter(item => item?.organizationId === branch?._id);

  useEffect(() => {
    getServiceVsStaffData();
  }, []);

  const getServiceVsStaffData = async () => {
    setIsLoading(true);
    const data = await fetchServiceHistory();
    dispatch(serviceVsStaff(data));
    setIsLoading(false);
  }

  // Process the dailyServiceVsStaff data
  const processedData = Object.entries(dailyServiceVsStaff).flatMap(([_, dayData]) => dayData);
  
  // Sort the data by date
  processedData.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Extract unique dates and count staffs and services for each date
  const dateMap = new Map();
  processedData.forEach(item => {
    if (!dateMap.has(item.date)) {
      dateMap.set(item.date, { staffs: 0, services: 0 });
    }
    dateMap.get(item.date).staffs += 1;
    dateMap.get(item.date).services += item.serviceId.length;
  });

  const dates = Array.from(dateMap.keys());
  const staffCounts = dates.map(date => dateMap.get(date).staffs);
  const serviceCounts = dates.map(date => dateMap.get(date).services);

  const chartData = {
    series: [
      {
        name: "Staffs",
        data: staffCounts,
      },
      {
        name: "Services",
        data: serviceCounts,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: dates,
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      fill: {
        opacity: 1,
      },
      colors: ["#0023DD", "#FEE114"],
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <div
        className="bg-white w-full md:w-1/2 rounded-lg shadow mt-4 py-3"
        id="chart"
      >
        {isLoading ? (
          <div className="flex justify-between p-8 gap-4 md:gap-3 ">
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
            <div className="animate-pulse h-80 w-8 md:w-10 bg-skeletonLoaderColor rounded-md "></div>
          </div>
        ) : (
          <div>
            <div className="font-bold text-2xl mt-2 tracking-wide mx-4">
              Service Vs Staffs
            </div>

            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={350}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ServiceVsStaffs;