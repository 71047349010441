import React, { useEffect, useState, useCallback } from "react";
import Services from "../../Components/Inventory/Services";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  allInventoryProduct,
  allInventoryService,
} from "../../state-management/admin/actions";

const Inventory = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const branch = useSelector(state => state?.main?.branch);
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const fetchInventory = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/inventory/find-inventory?populate=organizationId`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      dispatch(
        allInventoryProduct(
          response?.data?.data?.filter((inv) => inv?.category === "Product")
        )
      );
      dispatch(
        allInventoryService(
          response?.data?.data?.filter((inv) => inv?.category === "Service" & !inv.isDeleted)
        )
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [dispatch, token, branch]);

  useEffect(() => {
    fetchInventory();
  }, [fetchInventory]);

  return <Services isLoading={isLoading} fetchInventory={fetchInventory} />;
};

export default Inventory;
