import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { FiDownload } from "react-icons/fi";
import { getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import { useSelector } from "react-redux";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const BulkAddInventoryModal = (props) => {
    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const branch = useSelector(state => state?.main?.branch) || "";
    const token = "Bearer " + localStorage.getItem("refresh_token");

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleUpload = () => {
        if (!file) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const importedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            const mappedData = importedData.slice(1).map((row) => ({
                serviceName: String(row[0] || ""),
                price: row[1] || "",
                duration: row[2] || "",
                description: row[3] || "",
            }));

            setData(mappedData);
            setUploadCompleted(true);
        };
        reader.readAsBinaryString(file);
    };

    const handleRemoveFile = () => {
        setFile(null);
        setData([]);
        setUploadCompleted(false);
    };

    const handleRegister = async () => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/inventory/bulk`,
                {
                    bulkImportInventory: data,
                    organizationId: branch?._id,
                    adminId: branch?.adminId,
                    category: "Service"
                },
                { headers: { Authorization: token, "Content-Type": "application/json" } }
            );
            console.log("console", response?.data)
            setLoading(false)
            setData([]);
        } catch (error) {
            console.error("API error:", error);
            setLoading(false)
        }
    };

    const downloadExampleFile = () => {
        const exampleData = [
            ["serviceName", "price", "duration", "description"],
        ];

        const ws = XLSX.utils.aoa_to_sheet(exampleData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Example Services");
        XLSX.writeFile(wb, "example_services.xlsx");
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openBulkAddServiceModal}
                onClose={() => {
                    props.setOpenBulkAddServiceModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[400px] min-w-[400px] sm:min-w-[450px] overflow-x-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={() => props.setOpenBulkAddServiceModal(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold">
                                Add Inventory Product
                            </div>
                        </div>

                        {data.length > 0 ? (
                            <div className="mt-6 relative">
                                <h2 className="text-xl font-semibold mb-2">Imported Data</h2>
                                <div className="absolute top-2 right-2">
                                    <button
                                        className="text-red-500 hover:text-red-600"
                                        onClick={handleRemoveFile}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="border overflow-x-auto overflow-y-auto h-[300px]">
                                    <table className="w-full border-collapse border border-gray-300">
                                        <thead>
                                            <tr className="bg-gray-200">
                                                <th className="border border-gray-300 p-1">
                                                    Service Name
                                                </th>
                                                <th className="border border-gray-300 p-1">Price</th>
                                                <th className="border border-gray-300 p-1">Duration</th>
                                                <th className="border border-gray-300 p-1">
                                                    Description
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((service, index) => (
                                                <tr key={index}>
                                                    <td className="border border-gray-300 p-1">
                                                        {service.serviceName}
                                                    </td>
                                                    <td className="border border-gray-300 p-1">
                                                        {service.price}
                                                    </td>
                                                    <td className="border border-gray-300 p-1">
                                                        {service.duration}
                                                    </td>
                                                    <td className="border border-gray-300 p-1">
                                                        {service.description}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center space-y-4 mt-6 px-4">
                                <h4
                                    onClick={downloadExampleFile}
                                    className="mb-6 text-base font-semibold flex gap-2 items-center text-white bg-primaryColor py-2 px-2 rounded-md cursor-pointer"
                                >
                                    Example Excel File
                                    <FiDownload className="w-6 h-6" />
                                </h4>
                                <label
                                    htmlFor="file-upload"
                                    className="border border-gray-300 p-2 rounded-lg cursor-pointer"
                                >
                                    Upload Excel file (.xlsx, .xls) only
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept=".xlsx,.xls"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />
                                </label>
                                <button
                                    className="text-sm text-black hover:text-white font-semibold px-4 py-2 border border-primaryColor rounded hover:bg-primaryColor"
                                    onClick={handleUpload}
                                >
                                    Upload
                                </button>
                            </div>
                        )}

                        <div className="flex justify-end mt-6">
                            {uploadCompleted && (
                                <button
                                    className="text-sm font-semibold ml-2 px-4 py-2 border border-primary rounded bg-primaryColor text-white"
                                    onClick={handleRegister}
                                >
                                    {loading ? <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div> : "Add"}
                                </button>
                            )}

                            <button
                                className="text-sm text-white font-semibold ml-2 px-4 py-2 rounded bg-primaryColor"
                                onClick={() => {
                                    props.setOpenBulkAddServiceModal(false);
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default BulkAddInventoryModal;