import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import excel from "../../Assets/xls.png";
import pdf from "../../Assets/Pdf.png";

const LaserReport = ({ laserData, expenseData, isLoading }) => {
  const [isGenerating, setIsGenerating] = useState(false);


  const handleGeneratePDF = () => {
    if (isGenerating) {
      return;
    }
  
    const dataToUse = laserData && laserData.length > 0 ? laserData : [];
    const expenseDataToUse = expenseData && expenseData.length > 0 ? expenseData : [];
  
    if (dataToUse.length === 0 && expenseDataToUse.length === 0) {
      console.log("No data to export");
      return;
    }
  
    setIsGenerating(true);
  
    // Laser report generation
    const monthlyLaserData = dataToUse.reduce((acc, item) => {
      const month = new Date(item.date).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});
  
    const laserReportData = Object.keys(monthlyLaserData).map((month) => {
      const monthItems = monthlyLaserData[month];
      const totalBank = monthItems.reduce(
        (sum, item) => sum + item?.amountFromBank,
        0
      );
      const totalCashExpense = monthItems.reduce((sum, item) => sum + (item?.cashExpense || 0), 0);
      const totalBankExpense = monthItems.reduce((sum, item) => sum + (item?.bankExpense || 0), 0);
      const totalCard = monthItems.reduce((sum, item) => sum + item.card, 0);
      const totalCash = monthItems.reduce((sum, item) => sum + item.cash, 0);
      const totalUPI = monthItems.reduce((sum, item) => sum + item.upi, 0);
      const totalExpense = monthItems.reduce(
        (sum, item) => sum + item.expense,
        0
      );
      const subTotal = monthItems.reduce((sum, item) => sum + item.subTotal, 0);
  
      return {
        Month: month,
        "CARD REVENUE": totalCard,
        "CASH REVENUE": totalCash,
        "UPI REVENUE": totalUPI,
        "TOTAL BANK": totalCard + totalUPI,
        "EXPENSES": totalExpense,
        "CASHEXPENSE": totalCashExpense,
        "BANKEXPENSE": totalBankExpense,
        'DRAWAMOUNT': totalCash - totalCashExpense,
        'SUBTOTALBANK': totalCard + totalUPI - totalBankExpense,
        "Sub Total": subTotal,
        "Bank Charges": "",
        // "Net Income": subTotal - (totalCard + totalUPI - totalBank),
      };
    });
  
    // Filter expense data to include only the required fields
    const filteredExpenseData = expenseDataToUse.map((item) => ({
      Date: item.date,
      "Given To": item.givenTo,
      "Payment Method": item.paymentMethod,
      "Expense Type": item.expenseType,
      Expense: item.expense,
      Remark: item.remark,
    }));
  
    const salaryExpenseData = filteredExpenseData.filter(
      (expense) =>
        expense["Expense Type"] === "Salary" ||
        expense["Expense Type"] === "AdvanceSalary"
    );
  
    // Create a new jsPDF instance in landscape mode
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });
  
    // Define styles
    const headerStyle = {
      fillColor: [254, 135, 64],
      textColor: 255,
      fontStyle: "bold",
    };
  
    // Add laser data to the PDF
    doc.autoTable({
      startY: 10,
      head: [["Laser Data"]],
      body: [],
      theme: "plain",
      styles: { fontSize: 14, fontStyle: "bold" },
    });
    
    if (laserReportData.length > 0) {
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 2,
        head: [Object.keys(laserReportData[0])],
        body: laserReportData.map(Object.values),
        headStyles: headerStyle,
        styles: { fontSize: 8 },
      });
    } else {
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 2,
        body: [["No laser data available"]],
        theme: "plain",
        styles: { fontSize: 10 },
      });
    }
  
    // Add a space between tables
    const startY = doc.previousAutoTable.finalY + 10;
  
    // Add expense data to the PDF
    if (filteredExpenseData.length > 0) {
      doc.autoTable({
        startY: startY,
        head: [["Expense Data"]],
        body: [],
        theme: "plain",
        styles: { fontSize: 14, fontStyle: "bold" },
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 2,
        head: [Object.keys(filteredExpenseData[0])],
        body: filteredExpenseData.map(Object.values),
        headStyles: headerStyle,
        styles: { fontSize: 8 },
      });
    } else {
      doc.autoTable({
        startY: startY,
        head: [["Expense Data"]],
        body: [["No expense data available"]],
        theme: "plain",
        styles: { fontSize: 12, fontStyle: "bold" },
      });
    }
  
    // Add salary data to the PDF
    const startSalaryY = doc.previousAutoTable.finalY + 10;
    if (salaryExpenseData.length > 0) {
      doc.autoTable({
        startY: startSalaryY,
        head: [["Staff Salary"]],
        body: [],
        theme: "plain",
        styles: { fontSize: 14, fontStyle: "bold" },
      });
      doc.autoTable({
        startY: doc.previousAutoTable.finalY + 2,
        head: [Object.keys(salaryExpenseData[0])],
        body: salaryExpenseData.map(Object.values),
        headStyles: headerStyle,
        styles: { fontSize: 8 },
      });
    } else {
      doc.autoTable({
        startY: startSalaryY,
        head: [["Staff Salary"]],
        body: [["No salary data available"]],
        theme: "plain",
        styles: { fontSize: 12, fontStyle: "bold" },
      });
    }
  
    // Save the PDF
    doc.save("LedgerAndExpenseData.pdf");
  
    setIsGenerating(false);
  };

  const handleGenerateExcel = () => {
    if (isGenerating) {
      console.log("Already generating");
      return;
    }
  
    const dataToUse = laserData && laserData.length > 0 ? laserData : [];
    const expenseDataToUse = expenseData && expenseData.length > 0 ? expenseData : [];
  
    if (dataToUse.length === 0 && expenseDataToUse.length === 0) {
      console.log("No data to export");
      return;
    }
  
    setIsGenerating(true);
  
    // Laser report generation
    const monthlyLaserData = dataToUse.reduce((acc, item) => {
      const month = new Date(item.date).toLocaleString("default", {
        month: "long",
        year: "numeric",
      });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(item);
      return acc;
    }, {});
  
    const laserReportData = Object.keys(monthlyLaserData).map((month) => {
      const monthItems = monthlyLaserData[month];
      const totalBank = monthItems.reduce(
        (sum, item) => sum + item?.amountFromBank,
        0
      );
      const totalCashExpense = monthItems.reduce((sum, item) => sum + (item?.cashExpense || 0), 0);
      const totalBankExpense = monthItems.reduce((sum, item) => sum + (item?.bankExpense || 0), 0);
      const totalCard = monthItems.reduce((sum, item) => sum + item.card, 0);
      const totalCash = monthItems.reduce((sum, item) => sum + item.cash, 0);
      const totalUPI = monthItems.reduce((sum, item) => sum + item.upi, 0);
      const totalExpense = monthItems.reduce(
        (sum, item) => sum + item.expense,
        0
      );
      const subTotal = monthItems.reduce((sum, item) => sum + item.subTotal, 0);
  
      return {
        Month: month,
        CARD_REVENUE: totalCard,
        CASH_REVENUE: totalCash,
        UPI_REVENUE: totalUPI,
        TOTAL_BANK: totalCard + totalUPI,
        EXPENSES: totalExpense,
        CASHEXPENSE: totalCashExpense,
        BANKEXPENSE: totalBankExpense,
        DRAWAMOUNT: totalCash - totalCashExpense,
        SUBTOTALBANK: totalCard + totalUPI - totalBankExpense,
        Sub_Total: subTotal,
        Bank_Charges: "",
        Net_Income:"",
      };
    });
  
    // Filter expense data to include only the required fields
    const filteredExpenseData = expenseDataToUse.map((item) => ({
      Date: item.date,
      "Given To": item.givenTo,
      "Payment Method": item.paymentMethod,
      "Expense Type": item.expenseType,
      Expense: item.expense,
      Remark: item.remark,
    }));
  
    const salaryExpenseData = filteredExpenseData.filter(
      (expense) =>
        expense["Expense Type"] === "Salary" ||
        expense["Expense Type"] === "AdvanceSalary"
    );
  
    // Create worksheet
    const worksheet = XLSX.utils.json_to_sheet([], { skipHeader: true });
  
    // Add laser data header and data
    XLSX.utils.sheet_add_json(worksheet, [{ A: "Laser Data" }], {
      origin: "A1",
      skipHeader: true,
    });
    if (laserReportData.length > 0) {
      XLSX.utils.sheet_add_json(worksheet, laserReportData, {
        origin: "A2",
        header: Object.keys(laserReportData[0]),
      });
    } else {
      XLSX.utils.sheet_add_json(worksheet, [{ A: "No laser data available" }], {
        origin: "A2",
        skipHeader: true,
      });
    }
  
    // Add a blank row
    XLSX.utils.sheet_add_aoa(worksheet, [[], [], []], { origin: -1 });
  
    // Add expense data header and data
    XLSX.utils.sheet_add_json(worksheet, [{ A: "Expense Data" }], {
      origin: -1,
      skipHeader: true,
    });
    if (filteredExpenseData.length > 0) {
      XLSX.utils.sheet_add_json(worksheet, filteredExpenseData, {
        origin: -1,
        header: Object.keys(filteredExpenseData[0]),
      });
    } else {
      XLSX.utils.sheet_add_json(worksheet, [{ A: "No expense data available" }], {
        origin: -1,
        skipHeader: true,
      });
    }
  
    // Add a blank row
    XLSX.utils.sheet_add_aoa(worksheet, [[], [], []], { origin: -1 });
  
    // For salary expense data
    XLSX.utils.sheet_add_json(worksheet, [{ A: "Staff Salary" }], {
      origin: -1,
      skipHeader: true,
    });
    if (salaryExpenseData.length > 0) {
      XLSX.utils.sheet_add_json(worksheet, salaryExpenseData, {
        origin: -1,
        header: Object.keys(salaryExpenseData[0]),
      });
    } else {
      XLSX.utils.sheet_add_json(worksheet, [{ A: "No salary data available" }], {
        origin: -1,
        skipHeader: true,
      });
    }
  
    // Apply styles to headers
    const headerStyles = {
      font: { bold: true, color: { rgb: "FFFFFF" } },
      fill: { fgColor: { rgb: "FE8740" } },
    };
  
    // Adjust column widths
    const allData = [...laserReportData, ...filteredExpenseData, ...salaryExpenseData];
    const allKeys = [...new Set(allData.flatMap(Object.keys))];
    const colWidths = allKeys.map(key =>
      allData.reduce((acc, row) => Math.max(acc, String(row[key] || "").length), key.length)
    );
  
    worksheet["!cols"] = colWidths.map(width => ({ wch: width + 2 }));
  
    // Create workbook and append worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Laser and Expense Report"
    );
  
    // Generate and save the workbook
    XLSX.writeFile(workbook, "LaserAndExpenseData.xlsx");
  
    setIsGenerating(false);
  };

  return (
    <div className="flex justify-between items-center gap-2">
      <button
        disabled={isGenerating}
        onClick={() => handleGenerateExcel()}
      >
        <img src={excel} alt="excel" className="w-7 h-7" />
      </button>
      <button
        disabled={isGenerating}
        onClick={() => handleGeneratePDF()}
      >
        <img src={pdf} alt="pdf" className="w-7 h-7" />
      </button>

      {isGenerating && (
        <div className="w-8 h-8 rounded-full animate-spin border-4 border-dashed border-white border-t-transparent"></div>
      )}
    </div>
  );
};

export default LaserReport;