import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { getEncryptItems } from "../../Screens/Common/localStorageUtils";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const UpdateLaser = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [amount, setAmount] = useState(0);
    const [amountErr, setAmountErr] = useState(false);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = getEncryptItems("branchDetails");

    const handleUpdateLedger = async (e) => {
        e.preventDefault();
        if (!amount) {
            setAmountErr(true);
            return
        }
        let data = {
            amountFromBank: amount
        }
        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/laser/update/${props?.id}`,
            headers: {
                Authorization: token,
            },
            data: data
        };
        setIsLoading(true);
        try {
            const res = await axios.request(config);
            props?.fetchLaserData(branch?._id);
            props?.setOpenUpdateLedger(false);
            setAmount(0);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            props?.setOpenUpdateLedger(false);
        }
    }

    return (
        <React.Fragment>
            <Modal
                open={props.openUpdateLedger}
                onClose={() => {
                    props.setOpenUpdateLedger(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[600px] min-w-[400px] sm:min-w-[450px] overflow-x-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={() => props.setOpenUpdateLedger(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold">
                                Add Bank Amount Of The Day
                            </div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={(e) => handleUpdateLedger(e)}>
                                <div className="relative w-full mb-3">
                                    <div className="text-[#132c4a] block text-sm mb-2">
                                        Amount After Gst
                                        <span className="text-red-600 text-lg"> *</span>
                                    </div>
                                    <input
                                        type="text"
                                        name="Amount"
                                        value={amount}
                                        onChange={(e) => { setAmountErr(false); setAmount(e.target.value) }}
                                        className={
                                            amountErr
                                                ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                        }
                                        placeholder="Enter Bank Amount"
                                    ></input>
                                </div>

                                <div className="w-full flex items-center justify-center mt-5">
                                    <button
                                        disabled={isLoading}
                                        type="submit"
                                        className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                                    >
                                        {isLoading ? (
                                            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                        ) : (
                                            <span>Add</span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default UpdateLaser;
