import React, { useEffect, useState } from "react";
import { RiEdit2Fill, RiDeleteBin5Fill, RiEye2Fill } from "react-icons/ri";
import { ImSearch } from "react-icons/im";
import AddClient from "./ClientModals/AddClient";
import AddLabel from "./ClientModals/AddLabel";
import axios from "axios";
import Tostify from "../../Screens/Common/Tostify";
import DeleteClientModal from "./ClientModals/DeleteClient";
import EditClient from "./ClientModals/EditClient";
import { useSelector, useDispatch } from "react-redux";
import { allClientList } from "../../state-management/main/actions";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import BillingModal from "./ClientModals/BillingModal";
import DownloadTableData from "../../Screens/Common/downloadData";
import { HiUserPlus } from "react-icons/hi2";
import { PiUserPlusFill } from "react-icons/pi";
import { FaChalkboardUser } from "react-icons/fa6";
import { FaEye, FaFemale } from "react-icons/fa";
import add from "../../Assets/AddFrame(1).png";
import excel from "../../Assets/addExcel.png";
import SkeletonTableLoader from "../../Screens/Common/Loading/SkeletonTableLoader";
import BulkImportClientModal from "./ClientModals/BulkImportClientModal";
import ClientDetails from "../Dashboard/ClientDetails";

const ClientTable = () => {
  const main = useSelector((state) => state.main);
  const dispatch = useDispatch();
  const [openAddClentModal, setOpenAddClentModal] = useState(false);
  const [openAddLabelModal, setOpenAddLabelModal] = useState(false);
  const [openBulkAddClientModal, setOpenBulkAddClientModal] = useState(false);
  const [openDeleteClient, setOpenDeleteClient] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [openEditClientModal, setOpenEditClientModal] = useState(false);
  const [results, setResults] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [client, setClient] = useState({});
  const [openBillingModal, setOpenBillingModal] = useState(false);
  const [openClientDetailsModal, setOpenClientDetailsModal] = useState(false);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");
  const branch = getEncryptItems("branchDetails");
  const filteredCustomers = main?.clientList
    ? main.clientList.filter((cli) => cli?.organization?._id === branch?._id)
    : [];

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const filteredItems =
    filterType === "due"
      ? filteredCustomers?.filter((ele) => ele?.dueAmount > 0)
      : filterType === "recent"
        ? filteredCustomers?.filter((ele) => {
          const createdAtDate = new Date(ele?.createdAt);
          return createdAtDate >= thirtyDaysAgo && createdAtDate <= new Date();
        })
        : filterType === "male"
          ? filteredCustomers?.filter((ele) => ele?.gender === "male")
          : filterType === "female"
            ? filteredCustomers?.filter((ele) => ele?.gender === "female")
            : filterType === "lostClient"
              ? filteredCustomers?.filter((ele) => {
                if (!ele?.lastVisitedDate) return false;
                const lastVisitedDate = new Date(ele.lastVisitedDate);
                return lastVisitedDate < thirtyDaysAgo;
              })
              : filteredCustomers;

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchData && results?.length
    ? results?.slice(indexOfFirstItem, indexOfLastItem)
    : filteredItems?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleOpenEditClient = (id) => {
    setId(id);
    const initial = filteredCustomers?.filter((ele) => ele._id === id);
    setInitialValue(initial);
    setOpenEditClientModal(true);
  };

  const fetchClients = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/customer/find-customer?populate=organization`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    try {
      const res = await axios.request(config);
      dispatch(allClientList(res?.data?.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleChange = (e) => {
  //   setClicked(false);
  //   setResults([]);
  //   setSearchData(e.target.value);
  // };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchData(e.target.value);
    const result = filteredCustomers?.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    setClicked(false);
    setResults([]);
  };
  const headerData = [
    { label: "Name", field: "name" },
    { label: "Phone", field: "phoneNumber" },
    { label: "email", field: "email" },
    { label: "Wallet Balance", field: "walletBalance" },
    { label: "No. Of Services", field: "numberOfServices" },
    { label: "Due Amount", field: "dueAmount" },
  ];

  const rowData = currentItems?.map((item) => ({
    name: item?.name,
    phoneNumber: item?.phoneNumber,
    email: item?.email,
    walletBalance: item?.walletBalance,
    numberOfServices: Object.values(item.services)?.reduce(
      (acc, cur) => acc + cur,
      0
    ),
    dueAmount: item?.dueAmount,
  }));

  const handleViewClick = (client) => {
    setClient(client);
    setOpenClientDetailsModal(true);
  }

  return (
    <React.Fragment>
      <BillingModal
        openBillingModal={openBillingModal}
        setOpenBillingModal={setOpenBillingModal}
        fetchClients={fetchClients}
      />

      <AddClient
        openAddClentModal={openAddClentModal}
        setOpenAddClentModal={setOpenAddClentModal}
        fetchClients={fetchClients}
      />

      <BulkImportClientModal
        openBulkAddClientModal={openBulkAddClientModal}
        setOpenBulkAddClientModal={setOpenBulkAddClientModal}
      />
      <AddLabel
        openAddLabelModal={openAddLabelModal}
        setOpenAddLabelModal={setOpenAddLabelModal}
      />

      <DeleteClientModal
        openDeleteClient={openDeleteClient}
        setOpenDeleteClient={setOpenDeleteClient}
        fetchClients={fetchClients}
        id={id}
        handleClear={handleClear}
        setId={setId}
      />

      {initialValue && (
        <EditClient
          openEditClientModal={openEditClientModal}
          setOpenEditClientModal={setOpenEditClientModal}
          fetchClients={fetchClients}
          initialValue={initialValue}
          id={id}
          setId={setId}
          handleClear={handleClear}
          handleOpenEditClient={handleOpenEditClient}
        />
      )}
      <ClientDetails
        openClientDetailsModal={openClientDetailsModal}
        setOpenClientDetailsModal={setOpenClientDetailsModal}
        client={client}
      />

      <Tostify />

      <div className="mt-4 flex justify-between items-center flex-wrap gap-4"></div>

      <div className=" flex justify-center flex-wrap items-center gap-4">
        <div
          className="xl:w-48 w-36  bg-white rounded-lg pt-1 pb-1 cursor-pointer"
          style={{ boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className="w-full flex flex-col justify-center items-center py-2"
            onClick={() => setFilterType("all")}
          >
            <div className="w-10 h-10 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
              <PiUserPlusFill size={20} className="text-primaryColor" />
            </div>
            <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
              All Clients
            </div>
            <div className="font-bold text-lg w-full text-center">
              {filteredCustomers?.length || 0}
            </div>
          </div>
        </div>

        <div
          className="xl:w-48 w-36  bg-white rounded-lg pt-1 pb-1 cursor-pointer "
          style={{ boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className="w-full flex flex-col justify-center items-center py-2 "
            onClick={() => {
              setCurrentPage(1);
              setFilterType("due");
            }}
          >
            <div className="w-10 h-10 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
              <FaChalkboardUser size={20} className="text-primaryColor" />
            </div>
            <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
              Due Client
            </div>
            <div className="font-bold text-lg w-full text-center">
              {filteredCustomers?.filter((ele) => ele?.dueAmount > 0)?.length}
            </div>
          </div>
        </div>

        <div
          className="xl:w-48 w-36  bg-white rounded-lg pt-1 pb-1 cursor-pointer"
          style={{ boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className="w-full flex flex-col justify-center items-center py-2"
            onClick={() => {
              setCurrentPage(1);
              setFilterType("male");
            }}
          >
            <div className="w-10 h-10 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
              <PiUserPlusFill size={20} className="text-primaryColor" />
            </div>
            <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
              Male
            </div>
            <div className="font-bold text-lg w-full text-center">
              {filteredCustomers?.filter((ele) => ele?.gender === "male")
                ?.length || 0}
            </div>
          </div>
        </div>

        <div
          className="xl:w-48 w-36  bg-white rounded-lg pt-1 pb-1 cursor-pointer"
          style={{ boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className="w-full flex flex-col justify-center items-center py-2"
            onClick={() => {
              setCurrentPage(1);
              setFilterType("female");
            }}
          >
            <div className="w-10 h-10 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
              <FaFemale size={20} className="text-primaryColor" />
            </div>
            <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
              Females
            </div>

            <div className="font-bold text-lg w-full text-center">
              {filteredCustomers?.filter((ele) => ele?.gender === "female")
                ?.length || 0}
            </div>
          </div>
        </div>

        <div
          className="xl:w-48 w-36  bg-white rounded-lg pt-1 pb-1 cursor-pointer"
          style={{ boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className="w-full flex flex-col justify-center items-center py-2"
            onClick={() => {
              setCurrentPage(1);
              setFilterType("female");
            }}
          >
            <div className="w-10 h-10 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
              <HiUserPlus size={20} className="text-primaryColor" />
            </div>
            <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
              Repeat Client
            </div>
            <div className="font-bold text-lg w-full text-center">
              {filteredCustomers?.filter((ele) => ele?.gender === "female")
                ?.length || 0}
            </div>
          </div>
        </div>

        <div
          className="xl:w-48 w-36  bg-white rounded-lg pt-1 pb-1 cursor-pointer"
          style={{ boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.1)" }}
        >
          <div
            className="w-full flex flex-col justify-center items-center py-2"
            onClick={() => {
              setCurrentPage(1);
              setFilterType("recent");
            }}
          >
            <div className="w-10 h-10 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
              <HiUserPlus size={20} className="text-primaryColor" />
            </div>
            <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
              New Client
            </div>
            <div className="font-bold text-lg w-full text-center">
              {filteredCustomers?.filter((ele) => {
                const createdAtDate = new Date(ele?.createdAt);
                return (
                  createdAtDate >= thirtyDaysAgo && createdAtDate <= new Date()
                );
              })?.length || 0}
            </div>
          </div>
        </div>

      </div>

      <div className="mx-auto mt-4">
        {isLoading ? (
          <SkeletonTableLoader />
        ) : filteredCustomers?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Client found
          </div>
        ) : (
          <>
            <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col justify-between">
              <div className="font-bold text-2xl tracking-wide w-full hidden md:block">
                Clients
              </div>

              <div className="flex w-full justify-center mt-2 xs:mt-0">
                <div className="bg-white rounded-lg w-full lg:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                  <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                    <ImSearch color="gray" size={15} />
                  </span>
                  <input
                    id="searchData"
                    className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                    placeholder="Search client"
                    type="text"
                    value={searchData}
                    onChange={(e) => {
                      setCurrentPage(1);
                      handleSearch(e);
                    }}
                  />
                  {searchData && (
                    <span
                      className="absolute right-14 top-3 cursor-pointer"
                      onClick={handleClear}
                    >
                      X
                    </span>
                  )}
                </div>
              </div>

              <div className="font-bold text-2xl tracking-wide w-full flex flex-wrap justify-center md:justify-end gap-2 mt-2 md:mt-0">
                <button onClick={() => setOpenBulkAddClientModal(true)}>
                  <img
                    src={excel}
                    alt="excel"
                    className="w-auto h-7 cursor-pointer mb-2"
                  />
                </button>
                <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
                  <DownloadTableData
                    rowData={rowData}
                    headerData={headerData}
                  />
                  {(userRole === "admin" ||
                    (userRole === "staff" &&
                      userRoleName?.roleId?.permissions?.client
                        ?.createClient)) && (
                      <>
                        <button onClick={() => setOpenAddClentModal(true)}>
                          {0 ? (
                            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                          ) : (
                            <span className="flex justify-center items-center">
                              <img
                                src={add}
                                alt="add"
                                className="text-primaryColor w-7 h-7"
                              />
                            </span>
                          )}
                        </button>
                      </>
                    )}
                </div>
              </div>
            </div>

            {searchData && results?.length === 0 ? <div className="text-xl flex justify-center bg-white items-center h-[200px]">
              No Data Found
            </div> : <div className="shadow rounded-t-lg overflow-x-auto">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Sl
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Name
                    </th>
                    {/* <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Email
                    </th> */}
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Number
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Balance
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      No. of Services
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Due Amount
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Last Visited On
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-sm font-semibold text-gray-600 tracking-wide">
                      {(userRole === "admin" ||
                        (userRole === "staff" &&
                          (userRoleName?.roleId?.permissions?.client
                            ?.editClient ||
                            userRoleName?.roleId?.permissions?.client
                              ?.deleteClient))) && <span>Actions</span>}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {[1, 2].map((v, i) => {
                          return ( */}
                  {currentItems?.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {currentPage * 10 + i - 9}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.name}
                          </div>
                        </td>
                        {/* 
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.email || "--------"}
                          </div>
                        </td> */}

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.phoneNumber}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.walletBalance || 0}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {Object.values(v?.services)?.reduce(
                              (acc, cur) => acc + cur,
                              0
                            ) || 0}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.dueAmount}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.lastVisitedDate || "---"}
                          </div>
                        </td>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="whitespace-no-wrap flex">
                            {(userRole === "admin" ||
                              (userRole === "staff" &&
                                userRoleName?.roleId?.permissions?.client
                                  ?.editClient)) && (
                                <RiEdit2Fill
                                  size={15}
                                  onClick={() => handleOpenEditClient(v?._id)}
                                  className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                />
                              )}

                            {(userRole === "admin" ||
                              (userRole === "staff" &&
                                userRoleName?.roleId?.permissions?.client
                                  ?.deleteClient)) && (
                                <RiDeleteBin5Fill
                                  className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                  size={15}
                                  onClick={() => {
                                    setId(v?._id);
                                    setOpenDeleteClient(true);
                                  }}
                                />
                              )}
                              <FaEye
                              onClick={() => handleViewClick(v)}
                              className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300 ml-2"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>}
          </>
        )}
        {(filteredItems?.length !== 0 && (!searchData || (searchData && results?.length > 0))) ? (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {Math.min(
                indexOfLastItem,
                (searchData && clicked ? results.length : filteredItems.length)
              )} of{" "}
              {searchData && clicked ? results.length : filteredItems.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil((searchData && clicked ? results.length : filteredItems.length) / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                  Math.ceil((searchData && clicked ? results.length : filteredItems.length) / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default ClientTable;
