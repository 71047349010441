import React, { useState } from "react";
import ProductsTable from "../../Components/Products/ProductsTable";
import Order from "../../Components/Products/Order";
import Supplier from "../../Components/Products/Supplier";
import StockLedger from "../../Components/Products/StockLedger";
import StockAdjustment from "../../Components/Products/StockAdjustment";
import AssetScreen from "../../Components/asset/AssetScreen";
import Tostify from "../Common/Tostify";

const Products = () => {
  const [isSelected, setIsSelected] = useState("productTable");
  const [selectedScreen, setSelectedScreen] = useState("Products");
  document.title = `${process.env.REACT_APP_NAME} | Admin - Products`;

  const classes = {
    active:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md text-white bg-primaryColor rounded-xl cursor-pointer hover:bg-primaryHoverColor transition ease-in-out duration-300 transform",
    inactive:
      "h-10 w-full p-4 flex content-center items-center justify-center text-center text-md  text-[#7f8296] bg-transparent hover:bg-[#febc95] hover:text-gray-900 rounded-xl cursor-pointer transition ease-in-out duration-300 transform",
  };

  return (
    <React.Fragment>
      <style>
       
      </style>
      <Tostify />
      <div className="mx-auto mt-4 gap-6 flex">
        <div className="flex gap-2 items-center cursor-pointer">
          <input
            type="radio"
            name="category"
            id="Product"
            value="Products"
            checked={selectedScreen === "Products"}
            onChange={(e) => setSelectedScreen(e.target.value)}
          />
          <span>Products</span>
        </div>
        <div className="flex gap-2 items-center cursor-pointer">
          <input
            type="radio"
            name="category"
            id="Assets"
            value="Assets"
            checked={selectedScreen === "Assets"}
            onChange={(e) => setSelectedScreen(e.target.value)}
          />
          <span>Assets</span>
        </div>
      </div>
      {selectedScreen === "Products" &&
        <div className="mx-auto mt-4">
          <div className=" overflow-x-auto inset-x-0 rounded-xl bg-white">
            <div className="min-w-full leading-normal">
              <div className="flex justify-between items-center gap-4">
                <div
                  onClick={() => setIsSelected("productTable")}
                  className={
                    isSelected === "productTable"
                      ? classes.active
                      : classes.inactive
                  }
                >
                  Products
                </div>

                <div
                  onClick={() => setIsSelected("supplier")}
                  className={
                    isSelected === "supplier" ? classes.active : classes.inactive
                  }
                >
                  Suppliers
                </div>

                <div
                  onClick={() => setIsSelected("order")}
                  className={
                    isSelected === "order" ? classes.active : classes.inactive
                  }
                >
                  Orders
                </div>

                <div
                  onClick={() => setIsSelected("stockAdjustment")}
                  className={
                    isSelected === "stockAdjustment"
                      ? classes.active
                      : classes.inactive
                  }
                >
                  Adjustment
                </div>
                <div
                  onClick={() => setIsSelected("stockLedger")}
                  className={
                    isSelected === "stockLedger"
                      ? classes.active
                      : classes.inactive
                  }
                >
                  Stock Ledger
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {selectedScreen === "Assets" && <AssetScreen />}

      {selectedScreen === "Products" && isSelected === "productTable" && <ProductsTable />}
      {selectedScreen === "Products" && isSelected === "order" && <Order />}
      {selectedScreen === "Products" && isSelected === "supplier" && <Supplier />}
      {selectedScreen === "Products" && isSelected === "stockLedger" && <StockLedger />}
      {selectedScreen === "Products" && isSelected === "stockAdjustment" && <StockAdjustment />}
    </React.Fragment>
  );
};

export default Products;
