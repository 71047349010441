import React from "react";
import DailyRevenue from "./DailyRevenue";
import DailyAppointments from "./DailyAppointments";
import StaffsStatus from "./StaffsStatus";
import Payments from "./Payments";
import MembershipRevenue from "./MembershipRevenue";
import ServiceVsStaffs from "./ServiceVsStaffs";

export const AnalyticsGraphsView = ({
  dailyRevenueData,
  last7DaysAppointments,
  dailyServiceVsStaff,
  activeStaff,
  inactiveStaff,
  dailySumsForPayments,
  dailyRevenueFromMembership,
  selectedFilter,
}) => {

  const renderGraphContent = () => {
    switch (selectedFilter) {
      case "Daily Revenue Filter":
        return (
          <div className="w-full py-4">
            <DailyRevenue dailyRevenueData={dailyRevenueData} />
          </div>
        );
      case "Appointments Filter":
        return (
          <div className="w-full py-4">
            <DailyAppointments last7DaysAppointments={last7DaysAppointments} />
          </div>
        );
      case "Staff Status Filter":
        return (
          <div className="w-full py-4">
            <StaffsStatus
              inactiveStaff={inactiveStaff}
              activeStaff={activeStaff}
            />
          </div>
        );
      case "Payments Filter":
        return (
          <div className="w-full py-4">
            <Payments dailySumsForPayments={dailySumsForPayments} />
          </div>
        );
      case "Membership Filter":
        return (
          <div className="w-full py-4">
            <ServiceVsStaffs />
          </div>
        );
      case "Employee Vs Service Filter":
        return (
          <div className="w-full py-4">
            <MembershipRevenue
              dailyRevenueFromMembership={dailyRevenueFromMembership}
            />
          </div>
        );
      case "All":
      default:
        return (
          <>
            <div className="md:flex gap-4 w-full md:justify-between items-center max-w-screen">
              <DailyRevenue dailyRevenueData={dailyRevenueData} />
              <DailyAppointments
                last7DaysAppointments={last7DaysAppointments}
              />
            </div>

            <div className="md:flex gap-4 w-full md:justify-between items-center max-w-screen">
              <StaffsStatus
                inactiveStaff={inactiveStaff}
                activeStaff={activeStaff}
              />
              <Payments dailySumsForPayments={dailySumsForPayments} />
            </div>

            <div className="md:flex gap-4 w-full md:justify-between items-center max-w-screen">
              <ServiceVsStaffs
                dailyServiceVsStaff={dailyServiceVsStaff}
              />
              <MembershipRevenue
                dailyRevenueFromMembership={dailyRevenueFromMembership}
              />
              {/* <ServiceVsStaffs /> */}
            </div>
          </>
        );
    }
  };

  return (
    <>
      {renderGraphContent()}
    </>
  );
};
