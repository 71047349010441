import React from "react";
import ExpanditureTable from "../../Components/Expanditure/ExpanditureTable";

const Expenditure = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Expenditure`;

  return <ExpanditureTable />;
};

export default Expenditure;
