import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const EditProducts = (props) => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [company, setCompany] = useState("");
  const [companyErr, setCompanyErr] = useState("");
  const [mrp, setMrp] = useState("");
  const [mrpErr, setMrpErr] = useState("");
  const [priceProcurement, setPriceProcurement] = useState("");
  const [priceProcurementErr, setPriceProcurementErr] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityErr, setQuantityErr] = useState("");
  const [volume, setVolume] = useState("");
  const [volumeErr, setVolumeErr] = useState("");
  const [code, setCode] = useState("");
  const [codeErr, setCodeErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const token = "Bearer " + localStorage.getItem("refresh_token");


  useEffect(() => {
    setName(props?.initialValue?.name);
    setCompany(props?.initialValue?.company);
    setMrp(props?.initialValue?.costPriceMRP);
    setPriceProcurement(props?.initialValue?.priceProcurementPrice);
    setQuantity(props?.initialValue?.quantity);
    setVolume(props?.initialValue?.volume);
    setCode(props?.initialValue?.code);
  }, [props.handleOpenEdit]);

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameErr(true);
    } else if (!company) {
      setCompanyErr(true);
    } else if (!regex.priceRegex.test(mrp)) {
      setMrpErr(true);
    } else if (!priceProcurement) {
      setPriceProcurementErr(true);
    } else if (!regex.priceRegex.test(quantity)) {
      setQuantityErr(true);
    } else if (!volume) {
      setVolumeErr(true);
    } else if (!code) {
      setCodeErr(true);
    } else {
      let data = JSON.stringify({
        name: name,
        company: company,
        costPriceMRP: mrp,
        priceProcurementPrice: priceProcurement,
        quantity: quantity,
        volume: volume,
        code: code,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/product/update/${props.id}`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };
      setIsLoading(true);
      await axios
        .request(config)
        .then((response) => {
          let resStatus = response.status === 200;
          if (resStatus) {
            props.fetchProducts();
            props?.handleClear();
            tostifySuccess(response?.data?.message);
            props.setOpenEditProduct(false);
            clearInputField();
            setIsLoading(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("edit Product error:", error);
          props.setOpenEditProduct(false);
          tostifyErr(error?.response?.data?.message);
          clearInputField();
          setIsLoading(false);
        });
    }
  };

  const clearInputField = () => {
    setName("");
    setNameErr("");
    setCompany("");
    setCompanyErr("");
    setMrp("");
    setMrpErr("");
    setPriceProcurement("");
    setPriceProcurementErr("");
    setQuantity("");
    setQuantityErr("");
    setVolume("");
    setVolumeErr("");
    setCode("");
    setCodeErr("");
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openEditProduct}
        onClose={() => {
          clearInputField();
          props.setOpenEditProduct(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="no-scrollbar max-h-[400px] min-w-[400px] sm:min-w-[450px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenEditProduct(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Edit Product Details
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleUpdateProduct(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setNameErr(false);
                      setName(e.target.value);
                    }}
                    className={
                      nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Product Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Company
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="company"
                    autoComplete="off"
                    value={company}
                    onChange={(e) => {
                      setCompanyErr(false);
                      setCompany(e.target.value);
                    }}
                    className={
                      companyErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Product Company"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    MRP
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="mrp"
                    value={mrp}
                    onChange={(e) => {
                      setMrpErr(false);
                      setMrp(e.target.value);
                    }}
                    className={
                      mrpErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="MRP"
                  ></input>
                </div>

                <div className="relative w-full mb-5">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    PriceProcurement
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    id="priceprocurement"
                    value={priceProcurement}
                    onChange={(e) => {
                      setPriceProcurement(e.target.value);
                      setPriceProcurementErr(false);
                    }}
                    className={
                      priceProcurementErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150 pr-10"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-10"
                    }
                    placeholder="Price procurement"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Quantity
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="quantity"
                    value={quantity}
                    onChange={(e) => {
                      setQuantityErr(false);
                      setQuantity(e.target.value);
                    }}
                    className={
                      quantityErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Qantity"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Volume
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="volume"
                    value={volume}
                    onChange={(e) => {
                      setVolumeErr(false);
                      setVolume(e.target.value);
                    }}
                    className={
                      volumeErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Volume"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Code
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="code"
                    value={code}
                    onChange={(e) => {
                      setCodeErr(false);
                      setCode(e.target.value);
                    }}
                    className={
                      codeErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Code"
                  ></input>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Edit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditProducts;
