import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const StaffsStatus = ({activeStaff,inactiveStaff}) => {
  const [isLoading, setIsLoading] = useState(false);
  const chartData = {
    series: [activeStaff?.length + inactiveStaff?.length,activeStaff?.length,inactiveStaff?.length ],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Total",
              formatter: function (w) {
                // By default, this function returns the average of all series. The below is just an example to show the use of a custom formatter function
                return activeStaff?.length + inactiveStaff?.length;
              },
            },
          },
        },
      },
      labels: ["Total Staff", "Active Staff", "Inactive Staff"],
    },
  };

  return (
    <React.Fragment>
      <div
        className="bg-white w-full md:w-1/2 rounded-lg shadow mt-4 py-3"
        id="chart"
      >
        {isLoading ? (
          <div className="mt-4  rounded-lg  h-96 w-full flex flex-col items-center justify-center">
            <div className="animate-spin w-40 h-40 rounded-full bg-skeletonLoaderColor  flex justify-center items-center mx-auto  mb-5">
              <div className="h-2  bg-skeletonLoaderColor rounded "></div>
            </div>
          </div>
    
        ) : (
          <div>
            {/*<div className="font-bold text-2xl mt-2 tracking-wide mx-4">
              Staffs Status
            </div>

            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={350}
        />*/}
            <div className="font-bold text-2xl pt-4 tracking-wide pl-4 w-full">
             Staffs Status
            </div>
            <div id="chart">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="radialBar"
                height={377}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default StaffsStatus;
