import React, { useEffect, useState } from 'react'
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import AddAssets from './AddAssets';
import { ImSearch } from 'react-icons/im';
import { getEncryptItems } from '../../Screens/Common/localStorageUtils';
import axios from 'axios';
import DownloadTableData from '../../Screens/Common/downloadData';
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import DeleteAsset from './DeleteAsset';
import { useSelector } from 'react-redux';

const AssetScreen = () => {
    const [assets, setAssets] = useState([]);
    const [searchData, setSearchData] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [openDeleteAsset, setOpenDeleteAsset] = useState(false);
    const [results, setResults] = useState([]);
    const [singleAsset, setSingleAsset] = useState("");
    const [id, setId] = useState("");
    const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");

    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = assets?.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchAssets();
    }, [branch]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setSearchData(value);
        if (value) {
            const result = assets?.filter((item) =>
                item?.title?.toLowerCase()?.includes(value.toLowerCase())
            );
            setResults(result);
        } else {
            setResults([]);
        }
    };

    const handleClear = () => {
        setSearchData("");
        setResults([]);
    };

    const handleEdit = (id) => {
        const asset = assets?.find(ele => ele?._id === id);
        setSingleAsset(asset);
    }
    const handleOpenDelete = (id) => {
        setId(id);
        setOpenDeleteAsset(true);
    }

    const fetchAssets = async () => {
        setIsLoading(true);
        const token = "Bearer " + localStorage.getItem("refresh_token");
        const branchId = getEncryptItems("branchDetails")?._id;
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/asset/all-asset?organizationId=${branchId}`,
            headers: {
                Authorization: token,
            },
        };

        try {
            const response = await axios.request(config);
            setAssets(response?.data?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const headerData = [
        { label: "Title", field: "Title" },
        { label: "Description", field: "Description" },
        { label: "Capacity", field: "Capacity" },
    ];
    const rowData = results?.length ? results?.map(data => ({
        Title: data?.title,
        Capacity: data?.capacity,
        description: data?.description,
    })) : assets?.map(data => ({
        Title: data?.title,
        Capacity: data?.capacity,
        Description: data?.description,
    }));

    const renderTableRows = (data) => {
        return data?.map((v, i) => (
            <tr key={i}>
                <td className="px-5 py-5 border-b text-primaryColor border-gray-200 bg-white text-sm capitalize">
                    <div className="text-gray-900 whitespace-no-wrap capitalize">
                        {v?.title}
                    </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="text-gray-900 whitespace-no-wrap">
                        {v?.description}
                    </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="text-gray-900 whitespace-no-wrap">
                        {v?.capacity}
                    </div>
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <div className="whitespace-no-wrap flex">
                        <RiEdit2Fill
                            onClick={() => handleEdit(v?._id)}
                            size={15}
                            className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                        />
                        <RiDeleteBin5Fill
                            onClick={() => { handleOpenDelete(v?._id) }}
                            className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                            size={15}
                        />
                    </div>
                </td>
            </tr>
        ));
    };

    return (
        <div className='flex gap-4 my-4 items-start flex-col-reverse md:flex-row md:h-[505px]'>

            <DeleteAsset
                openDeleteAsset={openDeleteAsset}
                fetchAssets={fetchAssets}
                setOpenDeleteAsset={setOpenDeleteAsset}
                id={id}
                setId={setId}
                setCurrentPage={setCurrentPage}
            />
            <div className='w-full md:w-[65%] shadow-md h-full flex flex-col bg-white rounded-md'>
                <div className="px-5 py-5 bg-white w-full border-t rounded-t-lg grid grid-flow-row md:grid-flow-col justify-between">
                    <div className="font-bold text-2xl tracking-wide w-full">
                        Assets
                    </div>
                    <div className="flex w-full justify-center mt-2 xs:mt-0">
                        <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                            <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                                <ImSearch color="gray" size={15} />
                            </span>
                            <input
                                id="searchData"
                                className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                                placeholder="Search Asset"
                                type="text"
                                value={searchData}
                                onChange={(e) => {
                                    setCurrentPage(1);
                                    handleSearch(e);
                                }}
                            />
                            {searchData && (
                                <span
                                    className="absolute right-14 top-3 cursor-pointer"
                                    onClick={handleClear}
                                >
                                    X
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="font-bold text-2xl tracking-wide w-full flex justify-end gap-2">
                        <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
                            <DownloadTableData
                                rowData={rowData}
                                headerData={headerData}
                            />
                        </div>
                    </div>
                </div>
                <div className="shadow rounded-t-lg overflow-x-auto overflow-y-auto">
                    {isLoading ? (
                        <SkeletonTableLoader />
                    ) : (
                        <table className="min-w-full leading-normal h-full">
                            <thead>
                                <tr className="bg-gray-100">
                                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                        Title
                                    </th>
                                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                        Description
                                    </th>
                                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                        Capacity
                                    </th>
                                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchData && results.length > 0 ? (
                                    renderTableRows(results)
                                ) : !searchData && (
                                    renderTableRows(currentItems)
                                )}
                                {(searchData && results?.length === 0) || (assets?.length === 0) && (
                                    <tr>
                                        <td colSpan="4" className="text-center py-5">
                                            No Asset Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
                {(results.length === 0 && assets.length === 0) ||
                    (searchData && results.length === 0) ? null : (
                    <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between mt-auto rounded-b-lg">
                        <span className="text-xs xs:text-sm text-gray-900">
                            Showing {indexOfFirstItem + 1} to{" "}
                            {results?.length ? indexOfFirstItem + results?.length : indexOfFirstItem + currentItems?.length}{" "}
                            of {results?.length ? results?.length : assets?.length} Entries
                        </span>
                        <div className="inline-flex mt-2 xs:mt-0">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`text-sm ${currentPage === 1
                                    ? "bg-gray-200"
                                    : "bg-gray-300 hover:bg-gray-400"
                                    } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                            >
                                Prev
                            </button>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={
                                    currentPage ===
                                    Math.ceil(assets?.length / itemsPerPage)
                                }
                                className={`text-sm ${currentPage ===
                                    Math.ceil(assets?.length / itemsPerPage)
                                    ? "bg-gray-200"
                                    : "bg-gray-300 hover:bg-gray-400"
                                    } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div className='w-full md:w-[35%] h-full'>
                <AddAssets fetchAssets={fetchAssets} singleAsset={singleAsset} />
            </div>
        </div>
    );
};

export default AssetScreen;
