import React, { useState, useEffect } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import AdminProfileDropDown from "./AdminProfileDropDown";
// import NotificationDropdown from "./NotificationDropdown";
import Issue from "./Issue";
// import Tostify from "../Common/Tostify";
import { BsStopwatchFill } from "react-icons/bs";
import { decryptData } from "../Common/localStorageUtils";
import AcceptRequest from "./AcceptRequest";
import axios from "axios";
import Pusher from "pusher-js";
import BranchSwitch from "./BranchSwitch";
import InvoiceModalTable from "../../Components/Invoices/InvoiceModal/InvoiceTableModal";
import AppoinementTableModal from "../../Components/AppointMent/AppoinementTableModal";
import { RiBillLine } from "react-icons/ri";
import { SlCalender } from "react-icons/sl";
import { BiError } from "react-icons/bi";
import { RiCustomerService2Fill } from "react-icons/ri";

const Navbar = ({ toggleSidebar }) => {
  const [openIssue, setOpenIssue] = useState(false);
  const [openAcceptService, setOpenAcceptService] = useState(false);
  const [openInvoiceTable, setOpenInvoiceTable] = useState(false);
  const [openAppointmentTable, setOpenAppointmentTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [service, setService] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const role = decryptData()?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const staffId = decryptData()?.userTypeData?._id;

  useEffect(() => {
    const pusher = new Pusher("785220f142c162f4967d", {
      cluster: "ap2",
      useTLS: true,
    });

    const channel = pusher.subscribe("notifications");

    channel.bind("new-notification", function (data) {
      if (data.staffId === staffId) {
        setIsAvailable(true);
      }
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [staffId]);

  useEffect(() => {
    getNotification();
  }, []);

  const handleButtonClick = () => {
    setLoading(true);
    getNotification();
  };

  const getNotification = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/notification/get-notification`,
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const acceptObject = response?.data?.data?.filter(
          (item) => item?.services?.staffId === staffId
        );
        setService(acceptObject);
        setLoading(false);
        setOpenAcceptService(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div
      id="nav"
      // Sticky navbar removed
      //  z-50 sticky
      className="w-full h-16 bg-white flex flex-row items-center justify-between text-gray-600 shadow top-4 overflow-x-auto xs:overflow-x-hidden"
    >
      {service.length > 0 && (
        <AcceptRequest
          openAcceptService={openAcceptService}
          setOpenAcceptService={setOpenAcceptService}
          setIsAvailable={setIsAvailable}
          setService={setService}
          service={service}
        />
      )}

      <div className="ml-5 flex  gap-1 items-center">
        <AiOutlineMenuUnfold
          className="transition ease-in-out hover:text-primaryColor hover:duration-300 mr-5"
          onClick={toggleSidebar}
          size={25}
        />

        <div className="hidden md:flex flex-col gap-2 items-center md:justify-center lg:flex-row">
          <RiCustomerService2Fill size={20} />
          <div className="flex flex-col">
            <span className="hidden lg:flex text-xs text-[#8A8A8A]">
              Customer Care
            </span>
            <span className="text-xs text-[#4B465C]">+916483984738</span>
          </div>
        </div>
      </div>

      <div className="hidden md:flex">
        <BranchSwitch />
      </div>

      {/* <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
                <button
                  type="button"
                  className="relative rounded-full p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <RiBillLine  className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="relative rounded-full bg-gray-300 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <SlCalender  className="h-6 w-6" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="relative rounded-full bg-gray-300 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BiError  className="h-6 w-6" aria-hidden="true" />
                </button>
                </div> */}

      <div className=" flex flex-row items-center gap-3 mr-5 relative ">
        <InvoiceModalTable
          openInvoiceTable={openInvoiceTable}
          setOpenInvoiceTable={setOpenInvoiceTable}
        />

        <AppoinementTableModal
          openAppointmentTable={openAppointmentTable}
          setOpenAppointmentTable={setOpenAppointmentTable}
        />

        <div className="hidden md:flex gap-2 lg:gap-6">
          <button
            type="button"
            className="relative rounded-full text-[#4b5563] flex items-center justify-center h-8 w-8 focus:outline-none hover:text-primaryColor hover:duration-300"
            onClick={() => setOpenInvoiceTable(true)}
          >
            <span className="absolute -inset-1.5" />
            {/* <span className="sr-only">View notifications</span> */}
            <RiBillLine size={26} aria-hidden="true" />
          </button>

          <button
            type="button"
            className="relative rounded-full text-[#4b5563] flex items-center justify-center h-8 w-8 focus:outline-none hover:text-primaryColor hover:duration-300"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
              setOpenIssue(true);
            }}
          >
            <span className="absolute -inset-1.5" />
            {/* <span className="sr-only">View notifications</span> */}
            <BiError size={28} aria-hidden="true" />
          </button>
          <button
            type="button"
            className="relative rounded-full text-[#4b5563] flex items-center justify-center h-8 w-8 focus:outline-none hover:text-primaryColor hover:duration-300"
            onClick={() => setOpenAppointmentTable(true)}
          >
            <span className="absolute -inset-1.5" />
            {/* <span className="sr-only">View notifications</span> */}
            <SlCalender size={24} aria-hidden="true" />
          </button>

          {/* 
          <RiBillLine className='cursor-pointer hover:text-primaryColor hover:duration-300 rounded-3xl'  onClick={() => setOpenInvoiceTable(true)}  size={24}/>

          <SlCalender className='cursor-pointer hover:text-primaryColor hover:duration-300 rounded-3xl'  onClick={() => setOpenAppointmentTable(true)}  size={24} /> */}

          {role === "staff" && (
            <BsStopwatchFill
              size={24}
              onClick={() => {
                handleButtonClick();
                setIsAvailable(false);
              }}
              className="hover:text-primaryColor hover:duration-300 cursor-pointer rounded-full"
            />
          )}

          {isAvailable && role === "staff" && (
            <span className="bg-green-500 bottom-4 w-2.5 h-2.5 rounded-full absolute top-1 right-28 "></span>
          )}

          {/* {role !== "staff" &&
            // <BiSolidMessageRoundedError
            <BiError  
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              size={27} onClick={() => { setOpenIssue(true) }}
              className='hover:text-primaryColor hover:duration-300 cursor-pointer'
            />
            
            } */}

          {isHovered && (
            <div className="absolute top-0 right-24 w-28 transform -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow text-center z-10">
              Having an Issue?
            </div>
          )}

          <Issue openIssue={openIssue} setOpenIssue={setOpenIssue} />
          {/* <Tostify /> */}
          {/* <div className="hidden lg:flex">
            <NotificationDropdown />
          </div> */}
        </div>
        <AdminProfileDropDown />
      </div>
    </div>
  );
};

export default Navbar;
