import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const ViewPackages = (props) => {
  return (
    <React.Fragment>
      <Modal
        open={props.openViewPackageModal}
        onClose={() => props.setOpenViewPackageModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Subscription Plan
              </div>
            </div>

            <span
              onClick={() => props.setOpenAddStaffModal(false)}
              className="absolute top-6  left-5 text-3xl  cursor-pointer"
            >
              x
            </span>

            <div className="flex justify-end">
              <div className="bg-white rounded shadow w-52 flex p-1 mt-4 ">
                <input
                  className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                  placeholder="Enter your plan"
                  type="text"
                />
                <button className="flex items-center justify-center text-sm text-white px-2 bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2">
                  Apply
                </button>
              </div>
            </div>

            <div className="mx-auto mt-4">
              <div className="shadow rounded-lg overflow-x-auto">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Sl
                      </th>
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Amt
                      </th>
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Duration
                      </th>
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Inclusion
                      </th>
                      <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        Purchase
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {[1, 2].map((v, i) => {
                      return ( */}
                    <tr>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">1</div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">100</div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          10d
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          Premium
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <button className="flex items-center justify-center text-sm text-white px-2 bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2">
                          Buy
                        </button>
                      </td>
                    </tr>

                    <tr>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">2</div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">1005</div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          10d
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          Premium
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <button className="flex items-center justify-center text-sm text-white px-2 bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2">
                          Buy
                        </button>
                      </td>
                    </tr>

                    {/* ); */}
                    {/* })} */}
                  </tbody>
                </table>

              </div>
            </div>

          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ViewPackages;
