export const ALL_BRANCH_LIST = "ALL_BRANCH_LIST";

export const ALL_MANAGER_LIST = "ALL_MANAGER_LIST";

export const ALL_STAFF_LIST = "ALL_STAFF_LIST";

export const ALL_CLIENT_LIST = "ALL_CLIENT_LIST";

export const ALL_INVENTORY_SERVICE = "ALL_INVENTORY_SERVICE";

export const ALL_INVENTORY_PRODUCT = "ALL_INVENTORY_PRODUCT";

export const ALL_PRODUCT_LIST = "ALL_PRODUCT_LIST";

export const ALL_DISCOUNT_LIST = "ALL_DISCOUNT_LIST";

export const ALL_EXPANDITURE_LIST = "ALL_EXPANDITURE_LIST";

export const ALL_MEMBERSHIP_LIST = "ALL_MEMBERSHIP_LIST";

