import React from 'react';
import { Navigate } from 'react-router-dom';
import { decryptData } from '../../Screens/Common/localStorageUtils';

const NonAuthLayout = (props) => {
  let userRole = decryptData()?.user?.role;

  if (localStorage.getItem("encryptedData") && userRole) {
    return (
      <Navigate to={{ pathname: `/${userRole}` }} />
    );
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

export default NonAuthLayout