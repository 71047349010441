import React, { useEffect, useState } from 'react'
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import DownloadTableData from '../../Screens/Common/downloadData';
import { ImSearch } from 'react-icons/im';
import add from '../../Assets/AddFrame(1).png'
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import AddCoupon from './MembershipModals/AddCoupon';
import { getEncryptItems } from '../../Screens/Common/localStorageUtils';
import axios from 'axios';
import EditCoupon from './MembershipModals/EditCoupon';
import { Description } from '@mui/icons-material';
import { useSelector } from 'react-redux';

const CuponTable = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [openAddCouponModal, setOpenAddCouponModal] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [openEditCouponModal, setOpenEditCouponModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchData, setSearchData] = useState("");
    const [results, setResults] = useState([]);
    const [editableCoupon, setEditableCoupon] = useState({});
    const branch = useSelector(state => state?.main?.branch)?._id;
    const token = "Bearer " + localStorage.getItem("refresh_token");

    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = results?.length
        ? results?.slice(
            indexOfFirstItem,
            indexOfLastItem)
        : coupons?.slice(
            indexOfFirstItem,
            indexOfLastItem
        );

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        getAllCoupons();
    }, [branch])

    const handleOpenEdit = (id) => {
        const currentCoupon = coupons?.find(ele => ele?._id === id);
        setEditableCoupon(currentCoupon)
        setOpenEditCouponModal(true);
    }

    const getAllCoupons = async () => {
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/coupon/find-coupons/${branch}`,
            headers: {
                Authorization: token,
            },
        };

        setIsLoading(true);
        await axios
            .request(config)
            .then((response) => {
                setCoupons(response?.data?.data?.coupons);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }


    const handleSearch = (e) => {
        setSearchData(e.target.value);
        const result = coupons?.filter(ele => ele?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()));
        setResults(result);
    }
    const headers = ["Sl", "Name", "Coupon Code", "Discount", "Description", "Offer Period", "Reddemed Amount", "Min Bill / Max Discount", "Action"]
    const headerData = [
        { label: "Name", field: "Name" },
        { label: "Coupon Code", field: "Coupon_code" },
        { label: "Discount", field: "Discount" },
        { label: "Description", field: "Description" },
        { label: "Offer Period", field: "Offer_Period" },
        { label: "MinBill/Maxdiscount", field: "Min_Bill_Max_discount" },
    ];
    const rowData = coupons?.map(data => ({
        Name: data?.name,
        CouponCode: data?.couponCode,
        Description: data?.description,
        Discount: data?.discountType + " / " + data?.discountValue,
        Offer_Period: data?.startDate + "-" + data?.endDate,
        Min_Bill_Max_discount: data?.minAmount + " /" + data?.maxDiscount
    }));
    return (
        <React.Fragment>
            <AddCoupon
                openAddCouponModal={openAddCouponModal}
                setOpenAddCouponModal={setOpenAddCouponModal}
                getAllCoupons={getAllCoupons}
            />
            {editableCoupon &&
                <EditCoupon
                    openEditCouponModal={openEditCouponModal}
                    setOpenEditCouponModal={setOpenEditCouponModal}
                    editableCoupon={editableCoupon}
                    handleOpenEdit={handleOpenEdit}
                    getAllCoupons={getAllCoupons}
                />}
            <div className="mx-auto mt-4">
                <div className="px-5 py-5 bg-white w-[100%] border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4">
                    <div className="font-bold text-2xl tracking-wide">
                        Coupons
                    </div>

                    <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
                        <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
                            <ImSearch color="gray" size={15} />
                        </span>
                        <input
                            id="searchData"
                            className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
                            placeholder="Search Cupon"
                            type="text"
                            value={searchData}
                            onChange={(e) => {
                                setCurrentPage(1);
                                handleSearch(e);
                            }}
                        />
                    </div>

                    <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
                        <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
                            <DownloadTableData
                                rowData={rowData}
                                headerData={headerData}
                            />

                            <>
                                <button
                                    onClick={() => setOpenAddCouponModal(true)}
                                >
                                    <span className="flex justify-center items-center">
                                        <img
                                            src={add}
                                            alt="add"
                                            className="text-primaryColor w-7 h-7"
                                        />
                                    </span>
                                </button>
                            </>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <SkeletonTableLoader />
                ) : searchData && results?.length ? (
                    <div className="shadow rounded-t-lg overflow-x-auto">
                        <table className="min-w-full leading-normal">
                            <thead>
                                <tr className="bg-gray-100">
                                    {headers?.map((header, index) => (
                                        <th key={index} className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {results?.map((v, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {i + 1}
                                                </div>
                                            </td>

                                            <td
                                                className="px-5 py-5 border text-gray-900r border-gray-200 bg-white text-sm capitalize "
                                            >
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.name}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.couponCode}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="text-gray-900 whitespace-no-wrap ">
                                                    {v?.discountType} {"--"} {v?.discountValue}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.description || "-----"}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.startDate} / {v?.endDate}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {"---"}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="text-gray-900 whitespace-no-wrap">
                                                    {v?.minAmount} / {v?.maxDiscount}
                                                </div>
                                            </td>

                                            <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                <div className="whitespace-no-wrap flex">

                                                    <RiEdit2Fill
                                                        size={15}
                                                        onClick={() => handleOpenEdit(v?._id)}
                                                        className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                                    />


                                                    <RiDeleteBin5Fill
                                                        className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                                        size={15}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                ) : searchData && results?.length === 0 ?
                    <div className='flex justify-center items-center text-xl h-[200px]  tracking-wider bg-white pt-2 pb-6'>
                        No Coupons Found
                    </div>
                    : (
                        <div className="shadow rounded-t-lg overflow-x-auto">
                            <table className="min-w-full leading-normal">
                                <thead>
                                    <tr className="bg-gray-100">
                                        {headers?.map((header, index) => (
                                            <th key={index} className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentItems?.map((v, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="text-gray-900 whitespace-no-wrap">
                                                        {i + 1}
                                                    </div>
                                                </td>

                                                <td
                                                    className="px-5 py-5 border text-gray-900r border-gray-200 bg-white text-sm capitalize "
                                                >
                                                    <div className="text-gray-900 whitespace-no-wrap">
                                                        {v?.name}
                                                    </div>
                                                </td>

                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="text-gray-900 whitespace-no-wrap">
                                                        {v?.couponCode}
                                                    </div>
                                                </td>

                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="text-gray-900 whitespace-no-wrap ">
                                                        {v?.discountType} {"--"} {v?.discountValue}
                                                    </div>
                                                </td>

                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="text-gray-900 whitespace-no-wrap">
                                                        {v?.description || "-----"}
                                                    </div>
                                                </td>

                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="text-gray-900 whitespace-no-wrap">
                                                        {v?.startDate} / {v?.endDate}
                                                    </div>
                                                </td>

                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="text-gray-900 whitespace-no-wrap">
                                                        {"---"}
                                                    </div>
                                                </td>

                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="text-gray-900 whitespace-no-wrap">
                                                        {v?.minAmount} / {v?.maxDiscount}
                                                    </div>
                                                </td>

                                                <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                    <div className="whitespace-no-wrap flex">

                                                        <RiEdit2Fill
                                                            size={15}
                                                            onClick={() => handleOpenEdit(v?._id)}
                                                            className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                                                        />


                                                        <RiDeleteBin5Fill
                                                            className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                                            size={15}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                {(coupons?.length !== 0 && (!searchData || (searchData && results?.length > 0))) ? (
                    <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                        <span className="text-xs xs:text-sm text-gray-900">
                            Showing {indexOfFirstItem + 1} to{" "}
                            {Math.min(indexOfLastItem, (searchData ? results.length : coupons.length))} of{" "}
                            {searchData ? results.length : coupons.length} Entries
                        </span>
                        <div className="inline-flex mt-2 xs:mt-0">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`text-sm ${currentPage === 1
                                        ? "bg-gray-200"
                                        : "bg-gray-300 hover:bg-gray-400"
                                    } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                            >
                                Prev
                            </button>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={
                                    currentPage ===
                                    Math.ceil((searchData ? results.length : coupons.length) / itemsPerPage)
                                }
                                className={`text-sm ${currentPage ===
                                        Math.ceil((searchData ? results.length : coupons.length) / itemsPerPage)
                                        ? "bg-gray-200"
                                        : "bg-gray-300 hover:bg-gray-400"
                                    } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    )
}

export default CuponTable