import React, { useState } from "react";
import Dropdown from "../../Screens/Common/Dropdown";

export const AnalyticsHeader = ({
  setIsGraphView,
  isGraphView,
  formattedDateBeforeSevenDay,
  formattedCurrentDate,
  from,
  to,
  setFrom,
  setTo,
  selectedFilter,
  setSelectedFilter,
  filterOptions,

}) => {
  const [warning, setWarning] = useState("");

  const handleFromDateChange = (e) => {
    const fromDate = e.target.value;
    if (fromDate > to) {
      setWarning("'From' date cannot be after to 'To' date.");
    } else if (new Date(fromDate) > new Date()) {
      setWarning("'From' date cannot be after current date.");
    } else {
      setWarning("");
      setFrom(fromDate);
    }
  };

  const handleToDateChange = (e) => {
    const toDate = e.target.value;
    if (from > toDate) {
      setWarning("'To' date cannot be before  to 'From' date.");
    } else if (new Date(toDate) > new Date()) {
      setWarning("'To' date cannot be after current date.");
    } else {
      setWarning("");
      setTo(toDate);
    }
  };

  const handleSelect = (item) => {
    setSelectedFilter(item);
  };


  return (
    <>
      <style>
        {`
          input[type="radio"] {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 2px solid gray;
            outline: none;
            cursor: pointer;
            position: relative;
          }

          input[type="radio"]:checked {
            border: 2px solid #fe8740;
          }

          input[type="radio"]:checked::after {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #fe8740;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}
      </style>

      <div className="mx-auto mt-4 gap-6 flex">
        <div className="flex gap-2 items-center cursor-pointer">
          <input
            type="radio"
            name="category"
            id="Table View"
            value="Table View"
            checked={!isGraphView}
            onChange={() => setIsGraphView(false)}
          />
          <span>Table View</span>
        </div>
        <div className="flex gap-2 items-center cursor-pointer">
          <input
            type="radio"
            name="category"
            id="Graph View"
            value="Graph View"
            checked={isGraphView}
            onChange={() => setIsGraphView(true)}
          />
          <span>Graph View</span>
        </div>
      </div>

      <div
        className="bg-white w-full md:w-full rounded-lg shadow mt-4"
        id="controller"
      >
        <div className="flex md:flex-row flex-col gap-2 justify-between w-full px-5 py-5">
          <div className=" md:w-[60%] w-full">
            <div className="flex gap-2 items-center flex-wrap">
              <div>
                <span className="text-gray-600 mr-2">From</span>
                <input
                  value={from}
                  onChange={handleFromDateChange}
                  type="date"
                  className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
                />
              </div>
              <div>
                <span className="text-gray-600 mr-7 md:mr-2">To</span>
                <input
                  value={to}
                  onChange={handleToDateChange}
                  type="date"
                  className="p-2 border rounded-md border-gray-300 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
                />
              </div>
              <span>
                <svg
                  onClick={() => {
                    setWarning("");
                    setFrom(formattedCurrentDate);
                    setTo(formattedCurrentDate);
                  }}
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M9 12l-4.463 4.969-4.537-4.969h3c0-4.97 4.03-9 9-9 2.395 0 4.565.942 6.179 2.468l-2.004 2.231c-1.081-1.05-2.553-1.699-4.175-1.699-3.309 0-6 2.691-6 6h3zm10.463-4.969l-4.463 4.969h3c0 3.309-2.691 6-6 6-1.623 0-3.094-.65-4.175-1.699l-2.004 2.231c1.613 1.526 3.784 2.468 6.179 2.468 4.97 0 9-4.03 9-9h3l-4.537-4.969z" />
                </svg>
              </span>
            </div>
            {warning && <p className="text-red-500">{warning}</p>}
          </div>
          <div className="md:w-[40%] w-full flex justify-end items-center">
            <Dropdown
              label={selectedFilter}
              items={filterOptions}
              onSelect={handleSelect}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsHeader;
