import * as React from "react";
import { useState } from "react";
import axios from "axios";
import { decryptData, getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import { useEffect } from "react";
import { useRef } from "react";

const AddLoyality = (props) => {
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = getEncryptItems("branchDetails");
    const [isLoading, setIsLoading] = useState(false);
    const [isApplicableOnOpen, setIsApplicableOnOpen] = useState(false);
    const applicableOnRef = useRef(null);
    const initialState = {
        segment: "",
        level: "",
        rewardType: "",
        discountType: "",
        discountValue: "",
        pointsPercent: "",
        pointsValue: "",
        maxInactiveDays: "",
        applyOn: [],
        segmentErr: "",
        levelErr: "",
        rewardTypeErr: "",
        discountTypeErr: "",
        discountValueErr: "",
        pointsPercentErr: "",
        pointsValueErr: "",
        maxInactiveDaysErr: "",
        applyOnErr: "",
    }
    const [formData, setFormData] = useState(initialState);
    const [rewardOption, setRewardOption] = useState("discount");

    useEffect(() => {
        const loyalty = props?.editableLoyalty;
        if (loyalty) {
            setFormData({
                ...formData,
                segment: loyalty?.segment,
                level: loyalty?.level,
                rewardType: loyalty?.rewardType,
                discountType: loyalty?.discountType,
                discountValue: loyalty?.discountValue,
                pointsPercent: loyalty?.pointsPercent,
                pointsValue: loyalty?.pointsValue,
                maxInactiveDays: loyalty?.maxInactiveDays,
                applyOn: loyalty?.applyOn
            })
        }
    }, [props?.editableLoyalty])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            [`${name}Err`]: ""
        });
    };

    const toggleApplicableOnDropdown = () => setIsApplicableOnOpen(!isApplicableOnOpen);

    const handleApplicableOnClick = (option) => {
        const currentApplyOn = Array.isArray(formData.applyOn) ? formData.applyOn : [];
        let newSelected;
        if (currentApplyOn.includes(option)) {
            newSelected = currentApplyOn.filter(item => item !== option);
        } else {
            newSelected = [...currentApplyOn, option];
        }
        setFormData(prevData => ({
            ...prevData,
            applyOn: newSelected,
            applyOnErr: newSelected.length === 0 ? "Apply On is required" : ""
        }));
    };

    const removeApplicableOn = (option) => {
        const newSelected = formData.applyOn.filter(item => item !== option);
        setFormData({
            ...formData,
            applyOn: newSelected,
            applicableOnErr: newSelected.length === 0
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (applicableOnRef.current && !applicableOnRef.current.contains(event.target)) {
                setIsApplicableOnOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const validateForm = () => {
        let hasError = false;
        const errors = {};

        if (!formData.segment) {
            errors.segmentErr = "Segment is required";
            hasError = true;
        }
        if (!formData.level) {
            errors.levelErr = "Level is required";
            hasError = true;
        }
        if (!formData.rewardType) {
            errors.rewardTypeErr = "Reward Type is required";
            hasError = true;
        }
        if (rewardOption === "discount" && !formData.discountType) {
            errors.discountTypeErr = "Discount Type is required";
            hasError = true;
        }
        if (rewardOption === "discount" && !formData.discountValue) {
            errors.discountValueErr = "Discount Value is required";
            hasError = true;
        }
        if (rewardOption === "points" && !formData.pointsPercent) {
            errors.pointsPercentErr = "Points Percent is required";
            hasError = true;
        }
        if (rewardOption === "points" && !formData.pointsValue) {
            errors.pointsValueErr = "Points Value is required";
            hasError = true;
        }
        if (!formData.maxInactiveDays) {
            errors.maxInactiveDaysErr = "Max Inactive Days is required";
            hasError = true;
        }
        if (!formData.applyOn) {
            errors.applyOnErr = "Apply On is required";
            hasError = true;
        }

        setFormData(prev => ({
            ...prev,
            ...errors,
        }));

        return !hasError;
    };

    const handleAddLevel = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            let data = JSON.stringify({
                organizationId: branch?._id,
                segment: formData.segment,
                level: formData.level,
                rewardType: formData.rewardType,
                discountType: rewardOption === "discount" ? formData.discountType : undefined,
                discountValue: rewardOption === "discount" ? formData.discountValue : undefined,
                pointsPercent: rewardOption === "points" ? formData.pointsPercent : undefined,
                pointsValue: rewardOption === "points" ? formData.pointsValue : undefined,
                maxInactiveDays: formData.maxInactiveDays,
                applyOn: formData.applyOn,
            });

            let config = {
                method: Object.keys(props?.editableLoyalty)?.length > 0 ? "put" : "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/loyalty/${Object.keys(props?.editableLoyalty)?.length > 0 ? `update/${props?.editableLoyalty?._id}` : "create"}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                data: data,
            };

            setIsLoading(true);
            axios
                .request(config)
                .then((response) => {
                    props?.getAllLevels();
                    setIsLoading(false);
                    setFormData(initialState);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                });
        }
    };

    return (
        <React.Fragment>
            <div className="no-scrollbar h-full bg-white shadow rounded-md overflow-y-scroll overflow-x-auto px-8 py-4">
                <div className="btn-wrapper text-center">
                    <div className="text-xl text-primaryColor font-bold">
                        {Object.keys(props?.editableLoyalty)?.length > 0 ? "Update Level" : "Add Level"}
                    </div>
                </div>

                <div className="flex-auto mt-2">
                    <form onSubmit={(e) => handleAddLevel(e)}>
                        <div className="relative w-full mb-3">
                            <div className="text-[#132c4a] block text-sm mb-2">
                                Segment
                                <span className="text-red-600 text-lg"> *</span>
                            </div>
                            <select
                                name="segment"
                                id="segment"
                                value={formData.segment}
                                onChange={handleInputChange}
                                className={
                                    formData.segmentErr
                                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                }
                            >
                                <option value="" disabled>Select</option>
                                <option value="All Customer">All Customer</option>
                                <option value="All Male Customer">All Male Customer</option>
                                <option value="All Female Customer">All Female Customer</option>
                                <option value="All Members">All Members</option>
                                <option value="All Customers Visited in Last 30 Days">All Customers Visited in Last 30 Days</option>
                                <option value="All Customers Visited in Last 60 Days">All Customers Visited in Last 60 Days</option>
                                <option value="Inactive Customer">Inactive Customer</option>
                                <option value="High Ticket Customer">High Ticket Customer</option>
                                <option value="Low Ticket Customer">Low Ticket Customer</option>
                                <option value="Regular Customer">Regular Customer</option>
                                <option value="New Customer">New Customer</option>
                                <option value="All Customer With Valid Birthday">All Customer With Valid Birthday</option>
                                <option value="All Customer With Valid Aniversary">All Customer With Valid Aniversary</option>
                            </select>
                        </div>

                        <div className="flex gap-4">
                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Level
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <input
                                    type="text"
                                    id="level"
                                    autoComplete="off"
                                    name="level"
                                    value={formData.level}
                                    onChange={handleInputChange}
                                    className={
                                        formData.levelErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                    }
                                    placeholder="Enter Level"
                                ></input>
                            </div>

                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Reward Type
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <select
                                    id="rewardType"
                                    name="rewardType"
                                    value={formData.rewardType}
                                    onChange={handleInputChange}
                                    className={
                                        formData.rewardTypeErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                    }
                                >
                                    <option value="" disabled>Select Reward Type</option>
                                    <option value="Fixed">Fixed</option>
                                    <option value="Tiered">Tiered</option>
                                    <option value="Item-wise">Item-wise</option>
                                </select>
                            </div>
                        </div>

                        <div className="relative w-full mb-3 flex justify-start gap-10">
                            <div className="flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    id="rewardOption"
                                    name="rewardOption"
                                    className="mr-2"
                                    value={rewardOption}
                                    checked={rewardOption === "discount"}
                                    onChange={() => {
                                        setRewardOption("discount");
                                        setFormData({
                                            ...formData,
                                            pointsPercent: "",
                                            pointsPercentErr: "",
                                            pointsValue: "",
                                            pointsValueErr: ""
                                        })
                                    }}
                                />
                                <label htmlFor="discount" className="text-[#132c4a] block text-sm">
                                    Discount
                                </label>
                            </div>
                            <div className="flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    id="rewardOption"
                                    name="rewardOption"
                                    className="mr-2"
                                    value={rewardOption}
                                    checked={rewardOption === "points"}
                                    onChange={() => {
                                        setRewardOption("points");
                                        setFormData({
                                            ...formData,
                                            discountType: "",
                                            discountTypeErr: "",
                                            discountValue: "",
                                            discountValueErr: ""
                                        })
                                    }}
                                />
                                <label htmlFor="points" className="text-[#132c4a] block text-sm">
                                    Points
                                </label>
                            </div>
                        </div>

                        <div className="flex gap-4">
                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    {rewardOption === "discount" ? "Discount Type" : "Points Percent"}
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                {rewardOption === "discount" ? (
                                    <select
                                        id="discountType"
                                        name="discountType"
                                        value={formData.discountType}
                                        onChange={handleInputChange}
                                        className={
                                            formData.discountTypeErr
                                                ? "text-[#132c4a] border-0 px-3 py-3 bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                : "text-[#132c4a] border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                        }
                                    >
                                        <option value="">Select Discount Type</option>
                                        <option value="flat">Flat</option>
                                        <option value="percentage">Percentage</option>
                                    </select>
                                ) : (
                                    <input
                                        type="text"
                                        id="pointsPercent"
                                        autoComplete="off"
                                        name="pointsPercent"
                                        value={formData.pointsPercent}
                                        onChange={handleInputChange}
                                        className={
                                            formData.pointsPercentErr
                                                ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                        }
                                        placeholder="Enter Points Percent"
                                    />
                                )}
                            </div>

                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    {rewardOption === "discount" ? "Discount Value" : "Points Value"}
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <input
                                    type="text"
                                    id={rewardOption === "discount" ? "discountValue" : "pointsValue"}
                                    autoComplete="off"
                                    name={rewardOption === "discount" ? "discountValue" : "pointsValue"}
                                    value={formData[rewardOption === "discount" ? "discountValue" : "pointsValue"]}
                                    onChange={handleInputChange}
                                    className={
                                        formData[`${rewardOption === "discount" ? "discountValue" : "pointsValue"}Err`]
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                    }
                                    placeholder={`Enter ${rewardOption === "discount" ? "Discount Value" : "Points Value"}`}
                                />
                            </div>
                        </div>

                        <div className="flex gap-4">
                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Applicable On
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <div className="mb-2 flex flex-wrap">
                                    {formData?.applyOn?.map(option => (
                                        <span key={option} className="inline-flex items-center bg-primaryColor text-white rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2">
                                            {option}
                                            <button onClick={() => removeApplicableOn(option)} className="ml-2 text-white ">
                                                <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </span>
                                    ))}
                                </div>
                                <div className="relative" ref={applicableOnRef}>
                                    <button
                                        type="button"
                                        onClick={toggleApplicableOnDropdown}
                                        className={`bg-white w-full text-left px-4 py-2 rounded border ${formData.applyOnErr ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                    >
                                        {formData?.applyOn?.length > 0 ? `${formData?.applyOn?.length} selected` : 'Select options'}
                                    </button>
                                    {isApplicableOnOpen && (
                                        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded shadow-lg">
                                            {['All Service', 'All Product'].map(option => (
                                                <label key={option} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                                                    <input
                                                        type="checkbox"
                                                        checked={formData?.applyOn?.includes(option)}
                                                        onChange={() => handleApplicableOnClick(option)}
                                                        className="form-checkbox h-5 w-5 text-blue-600"
                                                    />
                                                    <span className="ml-2">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Max Inactive Days
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <input
                                    type="text"
                                    id="maxInactiveDays"
                                    autoComplete="off"
                                    name="maxInactiveDays"
                                    value={formData.maxInactiveDays}
                                    onChange={handleInputChange}
                                    className={
                                        formData.maxInactiveDaysErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                    }
                                    placeholder="Enter Max Inactive Days"
                                ></input>
                            </div>

                        </div>


                        <div className="w-full flex items-center justify-center mt-2 gap-4">
                            <button
                                type="button"
                                onClick={() => {
                                    props?.setEditableLoyalty({});
                                    setFormData(initialState);
                                }}
                                className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold transition ease-in-out hover:duration-300 rounded-xl"
                            >
                                Cancel
                            </button>
                            <button
                                disabled={isLoading}
                                className="h-12 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                            >
                                {isLoading ? (
                                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>{Object.keys(props?.editableLoyalty)?.length > 0 ? "Update" : "Create"}</span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <style>
                {`
          input[type="checkbox"]{
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid gray;
            outline: none;
            cursor: pointer;
            position: relative;
          }

          input[type="checkbox"]:checked {
            border: 2px solid #fe8740;
          }

          input[type="checkbox"]:checked::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: #fe8740;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}</style>
        </React.Fragment>
    );
};

export default AddLoyality;

{/* <div className="relative w-full mb-3">
                                <div className="text-[#132c4a] block text-sm mb-2">
                                    Apply On
                                    <span className="text-red-600 text-lg"> *</span>
                                </div>
                                <select
                                    name="applyOn"
                                    id="applyOn"
                                    autoComplete="off"
                                    value={formData.applyOn}
                                    onChange={handleInputChange}
                                    className={
                                        formData.applyOnErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                    }
                                >
                                    <option value="" disabled>Select</option>
                                    <option value="All Services">All Services</option>
                                    <option value="All Products">All Products</option>
                                </select>
                            </div> */}
