/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { decryptData } from "./localStorageUtils";
import profile_pic from "../../Assets/user.png";
import EditProfileModal from "./EditProfileModal/EditProfileModal";
import axios from "axios";

const Profile = () => {
  document.title = `${process.env.REACT_APP_NAME} | User Profile`;

  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [userData, setUserData] = useState({});
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const decryptedData = decryptData();
  const id = decryptedData?.userTypeData?._id;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      if (decryptedData?.user?.role === "admin") {
        const data = await fetchAdminData();
        setUserData(data);
      } else {
        setUserData(decryptedData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchAdminData = async () => {
    const qs = require("qs");
    try {
      let data = qs.stringify({});

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/admin/${id}`,
        headers: {
          Authorization: token,
        },
        data: data,
      };

      const response = await axios.request(config);
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };
  const fullName =
    decryptedData?.user?.firstName + " " + decryptedData?.user?.lastName;
  const dateOfJoin = new Date(
    decryptedData?.user?.createdAt
  )?.toLocaleDateString();

  return (
    <React.Fragment>
      <EditProfileModal
        openEditProfileModal={openEditProfileModal}
        setOpenEditProfileModal={setOpenEditProfileModal}
        fetchData={fetchData}
        userData={userData}
      />
      <div className="font-bold text-2xl mt-4 tracking-wide">Profile</div>

      <div className="w-full bg-white flex flex-wrap rounded-lg p-4 mt-4">
        <div className="py-3 md:flex items-center w-full border border-[#979797] rounded-lg my-auto">
          <div className="flex justify-center items-center mx-8">
            <div
              className={`w-16 h-16 rounded-full overflow-hidden border-2 border-gray-400`}
            >
              <img
                src={userData?.url || decryptedData?.userTypeData?.url || profile_pic}
                alt="Profile Picture"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="md:py-4 text-center md:text-start">
            <div className="flex items-center md:justify-start justify-center">
              <span className="font-extrabold text-2xl text-[#2B2F32] uppercase">
                {userData?.userId?.firstName && userData?.userId?.lastName
                  ? `${userData?.userId?.firstName} ${userData?.userId?.lastName}`
                  : fullName}
                &nbsp;
              </span>

              <span className="font-semibold text-xl text-[#979797] capitalize">
                ({userData?.userId?.role || decryptedData?.user?.role})
              </span>
            </div>

            <div className="font-semibold text-xl text-[#979797] capitalize">
              {userData?.city && userData?.city}{" "}
              {userData?.state && <span>, {userData?.state}</span>}
            </div>
          </div>
        </div>

        <div className="flex w-full p-8 flex-wrap shadow rounded-lg mt-4 border border-[#979797] ">
          <div className="flex justify-between w-full">
            <div className="font-bold text-2xl px-5">Profile Details</div>

            {decryptedData?.user?.role === "admin" && (
              <div
                onClick={() => setOpenEditProfileModal(true)}
                className="flex bg-gray-200 hover:bg-gray-300 p-1 pr-2 rounded-md cursor-pointer transition ease-in-out h-max hover:duration-300"
              >
                <MdOutlineEdit size={20} className="mt-1" />
                &nbsp; <span className="text-lg">Edit</span>
              </div>
            )}
          </div>

          <div className="text-justify md:flex gap-20 w-full md:justify-start items-center max-w-screen">
            <div className="px-5">
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Name
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  {userData?.userId?.firstName && userData?.userId?.lastName
                    ? `${userData?.userId?.firstName} ${userData?.userId?.lastName}`
                    : fullName}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Gender
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  {userData?.gender || decryptedData?.userTypeData?.gender}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Employment Time
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  Full Time
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Date of join
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  {userData?.userId?.createdAt &&
                  !isNaN(new Date(userData?.userId?.createdAt))
                    ? new Date(userData?.userId?.createdAt).toLocaleDateString()
                    : dateOfJoin}
                </span>
              </div>
            </div>

            <div className="px-5">
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Email
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  {userData?.userId?.email || decryptedData?.user?.email}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Phone Number
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg">
                  {userData?.userId?.phone || decryptedData?.user?.phone}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Status
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  {userData?.status || decryptedData?.userTypeData?.status}
                </span>
              </div>
              <div className="flex flex-col my-4">
                <span className="text-[#979797] font-semibold text-lg">
                  Role
                </span>
                <span className="text-[#2B2F32] font-semibold text-lg capitalize">
                  {userData?.userId?.role || decryptedData?.user?.role}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
