import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { decryptData, getEncryptItems } from "../../../../Screens/Common/localStorageUtils";
import { useSelector } from "react-redux";
import regex from "../../../../Screens/Common/validation";
import axios from "axios";
import { tostifyErr, tostifySuccess } from "../../../../Screens/Common/tostifyMessages";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  outline: "none",
};

const AddSupplier = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const initialState = {
    name: "",
    phone: "",
    email: "",
    website: "",
    company: "",
    city: "",
    state: "",
    postalCode: "",
    remark: "",
    nameErr: "",
    phoneErr: "",
    emailErr: "",
    websiteErr: "",
    companyErr: "",
    cityErr: "",
    stateErr: "",
    postalCodeErr: "",
    remarkErr: "",
  };
  const [formData, setFormData] = useState(initialState);
  const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      [`${name}Err`]: ""
    });
  };

  const clearInputField = () => {
    setFormData(
      {
        name: "",
        phone: "",
        email: "",
        website: "",
        company: "",
        city: "",
        state: "",
        postalCode: "",
        remark: "",
        nameErr: "",
        phoneErr: "",
        emailErr: "",
        websiteErr: "",
        companyErr: "",
        cityErr: "",
        stateErr: "",
        postalCodeErr: "",
        remarkErr: "",
      }
    )
  };

  const handleAddSupplier = async (e) => {
    e.preventDefault();
    if (!formData.name.trim()) {
      setFormData(prev => ({
        ...prev,
        nameErr: true
      }));
    } else if (!regex.phoneRegEx.test(formData.phone.trim())) {
      setFormData(prev => ({
        ...prev,
        phoneErr: true
      }));
    } else if (formData.email && !regex.emailRegExp.test(formData.email.trim())) {
      setFormData(prev => ({
        ...prev,
        emailErr: true
      }));
    } else if (formData.state && !regex.indianStateRegex.test(formData.state)) {
      setFormData(prev => ({
        ...prev,
        stateErr: true,
      }))
    } else {
      let data = JSON.stringify({
        name: formData.name,
        phone: "+91" + formData.phone,
        email: formData.email,
        city: formData.city,
        website: formData.website,
        state: formData.state,
        company: formData.company,
        adminId: branch?.adminId,
        postalCode: formData.postalCode,
        remark: formData.remark,
        organizationId: branch?._id
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/supplier/add`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        data: data
      };

      setIsLoading(true);
      axios.request(config)
        .then((response) => {
          // console.log(response.data);
          tostifySuccess(response?.data?.message);
          props?.fetchSupplier();
          clearInputField();
          setIsLoading(false);
          props.setOpenAddSupplier(false);
        })
        .catch((error) => {
          console.log(error);
          tostifyErr(error?.response?.data?.message);
          setIsLoading(false);
          props.setOpenAddSupplier(false);
        });
    }
  }
  return (
    <React.Fragment >
      <Modal
        open={props.openAddSupplier}
        onClose={() => {
          //   clearInputField();
          props.setOpenAddSupplier(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[500px] w-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddSupplier(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Supplier
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddSupplier(e)}>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Full Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className={
                      formData.nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Full Name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Phone
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <div className="flex gap-4">
                    <div >
                      <select
                        id="code"
                        className={
                          0
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-max ease-linear transition-all duration-150  "
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-max ease-linear transition-all duration-150  "
                        }
                      >
                        <option value="+91">+91</option>
                      </select>
                    </div>
                    <div className="w-full">
                      <input
                        type="text"
                        id="mobile"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        autoComplete="off"
                        className={
                          formData.phoneErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                        }
                        placeholder="Enter Your Phone No."
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between gap-4">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Email
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      autoComplete="off"
                      className={
                        formData.emailErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Email"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Website
                    </div>
                    <input
                      type="text"
                      id="website"
                      name="website"
                      value={formData.website}
                      onChange={handleInputChange}
                      autoComplete="off"
                      className={
                        formData.websiteErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter website"
                    />
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Company
                  </div>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className={
                      formData.companyErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Company Name"
                  />
                </div>

                <div className="flex justify-between gap-4 ">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      City
                    </div>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      autoComplete="off"
                      className={
                        formData.cityErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter City Name"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      State
                    </div>
                    <input
                      type="text"
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      autoComplete="off"
                      className={
                        formData.stateErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter State Name"
                    />
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Postal Code
                  </div>
                  <input
                    type="text"
                    id="postal"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className={
                      formData.postalCodeErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Postal Code"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Remark
                  </div>
                  <textarea
                    type="text"
                    id="remark"
                    name="remark"
                    value={formData.remark}
                    onChange={handleInputChange}
                    autoComplete="off"
                    className={
                      formData.remarkErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Remark"
                  />
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddSupplier;
