import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import errImg from "../../Assets/404.svg";
import { decryptData } from "../../Screens/Common/localStorageUtils";

const PageNotFound = () => {
  const [btnName, setBtnName] = useState("Login");
  const [navigateRoute, setNavigateRoute] = useState("/login");
  const navigate = useNavigate();

  useEffect(() => {
    const encryptedData = localStorage.getItem("encryptedData");
    if (!encryptedData) {
      setBtnName("Login");
      setNavigateRoute("/login");
    } else {
      const decryptedData = decryptData();
      const userRole = decryptedData?.user?.role || "dashboard";
      setBtnName("Dashboard");
      setNavigateRoute(`/${userRole}/dashboard`);
    }
  }, []);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_NAME} | 404-Error`;
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen">
      <img className="h-[40%] w-[40%]" src={errImg} alt="Page Not Found" />
      <div className="text-center my-8">
        <div className="text-xl text-[#2b2f32] font-bold">
          Oops..! Page not Found.
        </div>
      </div>
      <button
        onClick={() => navigate(navigateRoute)}
        className={`h-12 ${
          btnName === "Dashboard" ? "w-44" : "w-1/5"
        } flex items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]`}
      >
        Go to {btnName}
      </button>
    </div>
  );
};

export default PageNotFound;
