import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { getEncryptItems } from '../../Screens/Common/localStorageUtils';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "70%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const AppoinementTableModal = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [appointments, setAppointments] = useState([]);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branchId = getEncryptItems("branchDetails")?._id;

    const itemsPerPage = 4;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = appointments?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(appointments?.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    useEffect(() => {
        fetchAppointments();
    }, []);

    const fetchAppointments = async () => {
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/appointment/find-appointment`,
            headers: {
                Authorization: token,
            },
        };

        setIsLoading(true);
        await axios
            .request(config)
            .then((response) => {
                const filteredAppointments = response?.data?.data?.filter(
                    (appn) => appn?.organization === branchId
                );
                setAppointments(filteredAppointments);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("appointments error:", error);
                setIsLoading(false);
            });
    };


    return (
        <React.Fragment >
            <Modal
                open={props.openAppointmentTable}
                onClose={() => {
                    props.setOpenAppointmentTable(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[450px] overflow-x-auto px-4">
                        <div className="btn-wrapper text-center ">
                            <span
                                onClick={() => props.setOpenAppointmentTable(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold">Appointment List</div>
                        </div>


                        <div className="mx-auto mt-4">
                            {isLoading ? (
                                <div className="shadow rounded-t-lg overflow-x-auto">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    <div className=" flex flex-row  items-center">
                                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                    </div>
                                                </th>
                                                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    <div className=" flex flex-row  items-center">
                                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                    </div>
                                                </th>
                                                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    <div className=" flex flex-row  items-center">
                                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                    </div>
                                                </th>
                                                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    <div className=" flex flex-row  items-center">
                                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                    </div>
                                                </th>
                                                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    <div className=" flex flex-row  items-center">
                                                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[1, 2, 3, 4, 5].map((v, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className=" flex flex-row  items-center">
                                                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                            </div>
                                                        </td>

                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className=" flex flex-row  items-center">
                                                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                            </div>
                                                        </td>

                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className=" flex flex-row  items-center">
                                                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                            </div>
                                                        </td>

                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className=" flex flex-row  items-center">
                                                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                            </div>
                                                        </td>

                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className=" flex flex-row  items-center">
                                                                <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : appointments?.length === 0 ? (
                                <div className="flex justify-center bg-[#fff] shadow-md py-4">
                                    No Appointments found
                                </div>
                            ) : (
                                <div className="shadow rounded-t-lg overflow-x-auto">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Sl
                                                </th>
                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Customer Name
                                                </th>
                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Phone No.
                                                </th>

                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    AppointmentDate
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems?.map((v, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {(currentPage * 10 + i) - 9}
                                                            </div>
                                                        </td>

                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.customerName}
                                                            </div>
                                                        </td>

                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.customerPhone}
                                                            </div>
                                                        </td>

                                                        {/* <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.startTime?.toString()?.substring(11, 16)}
                                                            </div>
                                                        </td>

                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.endTime?.toString()?.substring(11, 16)}
                                                            </div>
                                                        </td> */}
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.appointmentDate?.toString()?.substring(0,10)}
                                                            </div>
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {currentItems?.length !== 0 ? (<div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                                <span className="text-xs xs:text-sm text-gray-900">
                                    Showing {indexOfFirstItem + 1} to {indexOfFirstItem + currentItems?.length} of {appointments?.length}  Entries
                                </span>
                                <div className="inline-flex mt-2 xs:mt-0">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className={`text-sm ${currentPage === 1 ? 'bg-gray-200' : 'bg-gray-300 hover:bg-gray-400'} text-gray-800 font-semibold py-2 px-4 rounded-l`}
                                    >
                                        Prev
                                    </button>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={
                                            currentPage === Math.ceil(appointments?.length / itemsPerPage)
                                        }
                                        className={`text-sm ${currentPage === Math.ceil(appointments?.length / itemsPerPage) ? 'bg-gray-200' : 'bg-gray-300 hover:bg-gray-400'} text-gray-800 font-semibold py-2 px-4 rounded-r`}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>) : null}
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default AppoinementTableModal;
