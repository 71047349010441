import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import {
  decryptData,
  getEncryptItems,
} from "../../../Screens/Common/localStorageUtils";
import { useState } from "react";
import axios from "axios";
import {
  tostifyErr,
  tostifySuccess,
} from "../../../Screens/Common/tostifyMessages";
import regex from "../../../Screens/Common/validation";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const AddPackages = (props) => {
  const decryptedData = decryptData();
  const adminId = decryptedData?.userTypeData?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = getEncryptItems("branchDetails");
  const serviceList = useSelector(
    (state) => state?.main?.inventoryService
  )?.filter((item) => item?.organizationId?._id === branch?._id);
  const organization = useSelector((state) => state?.main?.orgList);
  const orgByAdminId = organization?.filter((org) => org._id === branch?._id);
  const [isLoading, setIsLoading] = useState(false);
  const membershipType = props.membershipType;
  const [combinedPrice,setCombinedPrice]=useState(0)
  const [formData, setFormData] = useState({
    name: "",
    nameErr: "",
    orgId: branch?._id,
    price: "",
    priceErr: "",
    packagePrice: "",
    packagePriceErr: "",
    validity: "",
    validityErr: "",
    category: "",
    categoryErr: "",
    discount: "",
    discountErr: "",
    services: [],
    servicesErr: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      [`${name}Err`]: "",
    });
  };

  // useEffect(() => {
  //   formData?.category = props?.membershipType
  // }, [props?.membershipType])

  const handleCheckBoxChange = (item) => {
    const isSelected = formData?.services?.some(
      (service) => service.serviceId === item._id
    );
    if (isSelected) {
      const updatedServices = formData.services.filter(
        (service) => service.serviceId !== item._id
      );
      setFormData((prev) => ({
        ...prev,
        services: updatedServices,
      }));
      setCombinedPrice((prev) => prev - item.price);
    } else {
      const updatedServices = [
        ...formData.services,
        { serviceId: item._id, serviceName: item.name },
      ];
      setFormData((prev) => ({
        ...prev,
        services: updatedServices,
      }));
      setCombinedPrice(prv=>prv+item.price)
    }
  };

  const clearInputField = () => {
    setFormData({});
    setCombinedPrice(0)
  };

  const handleAddPackage = async (e) => {
    e.preventDefault();
    if (!formData.name) {
      setFormData((prev) => ({
        ...prev,
        nameErr: true,
      }));
    } else if (!formData.category) {
      setFormData((prev) => ({
        ...prev,
        categoryErr: true,
      }));
    } else if (
      formData.category === "Service Based" &&
      formData.services.length === 0
    ) {
      setFormData((prev) => ({
        ...prev,
        servicesErr: true,
      }));
    } else if (
      (formData.category === "Fixed Discount" && !formData.discount) ||
      formData.discount > 100
    ) {
      setFormData((prev) => ({
        ...prev,
        discountErr: true,
      }));
    } else if (formData.category === "Fixed Wallet" && !formData.packagePrice) {
      setFormData((prev) => ({
        ...prev,
        packagePriceErr: true,
      }));
    } else if (!regex.priceRegex.test(formData.price)) {
      setFormData((prev) => ({
        ...prev,
        priceErr: true,
      }));
    } else if (!regex.priceRegex.test(formData.validity)) {
      setFormData((prev) => ({
        ...prev,
        validityErr: true,
      }));
    } else {
      let data = JSON.stringify({
        packageName: formData.name,
        price: formData.price,
        validity: formData.validity,
        organizationId: branch?._id,
        category: formData.category,
        adminId: adminId,
        packagePrice: formData.packagePrice,
        discount: formData.discount,
        services: formData.services,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/membership-package/add`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      setIsLoading(true);
      axios
        .request(config)
        .then((response) => {
          props?.fetchMembershipData();
          tostifySuccess(response?.data?.message);
          props?.setOpenAddPackageModal(false);
          console.log("response", response?.data?.data);
          clearInputField();
          setIsLoading(false);
        })
        .catch((error) => {
          props?.setOpenAddPackageModal(false);
          tostifyErr(error?.response?.data?.message);
          // clearInputField();
          setIsLoading(false);
          console.log(error);
        });
    }
  };
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      category: membershipType,
      discount: "",
      packagePrice: "",
      services: [],
      categoryErr: false,
    }));
  }, [membershipType]);
console.log(combinedPrice);

  return (
    <React.Fragment>
      <Modal
        open={props.openAddPackageModal}
        onClose={() => {
          props.setOpenAddPackageModal(false);
          // clearInputField();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar h-[70%] overflow-y-scroll overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddPackageModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Membership
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddPackage(e)}>
                {/* <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Branch Name
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <select
                      value={formData.orgId}
                      name="orgId"
                      id="orgId"
                      onChange={handleInputChange}
                      className={
                        "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }
                    >
                      {orgByAdminId?.map((v, i) => {
                        return (
                          <option className="capitalize" key={i} value={v._id}>
                            {v?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={
                      formData.nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Package Name"
                  ></input>
                </div>
                {membershipType == "All" && (
                  <div className="flex justify-center items-center mb-3 w-full">
                    <div className="relative w-full mb-3">
                      <div className="text-[#132c4a] block text-sm mb-2">
                        Category
                        <span className="text-red-600 text-lg"> *</span>
                      </div>
                      <select
                        value={formData.category}
                        name="category"
                        id="category"
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            category: e.target.value,
                            discount: "",
                            packagePrice: "",
                            services: [],
                            categoryErr: false,
                          }));
                        }}
                        className={
                          formData.categoryErr
                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                        }
                      >
                        <option value="">Select Category</option>
                        <option value="Fixed Wallet">Fixed Wallet</option>
                        <option value="Fixed Discount">Fixed Discount</option>
                        <option value="Service Based">Service Based</option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="grid grid-cols-2 gap-4">
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Price
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      id="price"
                      value={formData.price}
                      name="price"
                      onChange={handleInputChange}
                      className={
                        formData.priceErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Price"
                    ></input>
<p className="text-sm text-gray-400 mt-1"> {combinedPrice ? (`Total Price:${combinedPrice}`):''}</p>
                  </div>
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Package Validity (No. Of Months)
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="number"
                      id="validity"
                      name="validity"
                      min="0"
                      value={formData.validity}
                      onChange={handleInputChange}
                      className={
                        formData.validityErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter No. of Months"
                    ></input>
                  </div>
                </div>

                {formData.category === "Fixed Wallet" && (
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Wallet Balance
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      id="packagePrice"
                      value={formData.packagePrice}
                      name="packagePrice"
                      onChange={handleInputChange}
                      className={
                        formData.packagePriceErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Wallet Balance"
                    ></input>
                  </div>
                )}

                {formData.category === "Service Based" && (
                  <div className="relative w-full mb-3">
                    {serviceList?.length > 0 ? (
                      <div className="text-[#132c4a] block text-sm mb-2">
                        Services
                        <span className="text-red-600 text-lg"> *</span>
                      </div>
                    ) : (
                      <div className="text-[#132c4a] block text-sm mb-2">
                        Please add Services in the Inventory First to create a
                        package
                      </div>
                    )}

                    <div
                      className={
                        formData.servicesErr
                          ? "text-[#132c4a] border-0 px-3 py-3 max-h-24 overflow-y-scroll placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 max-h-24 overflow-y-scroll placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                    >
                      <div className="grid grid-cols-2">
                        {serviceList?.map((item) => (
                          <div key={item?._id} className="flex">
                            <input
                              type="checkbox"
                              name="service"
                              checked={formData?.services?.some(
                                (selectedService) =>
                                  selectedService.serviceId === item._id
                              )}
                              onChange={(e) => {
                                handleCheckBoxChange(item);
                              }}
                            />
                            <div className="flex justify-between items-center px-4 py-2">
                              <div className="flex items-center">
                                <span className="font-bold mr-2">
                                  {item.name}
                                </span>
                                <span className="text-gray-500 mr-2">
                                  Rs. {item.price}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {formData.category === "Fixed Discount" && (
                  <div className="relative w-full mb-3">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Discount Percentage
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="Number"
                      id="discount"
                      name="discount"
                      min="0"
                      value={formData.discount}
                      onChange={handleInputChange}
                      className={
                        formData.discountErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      }
                      placeholder="Enter Discount Amount"
                    ></input>
                    {formData.discountErr && (
                      <div className="text-[#828282] text-xs mt-2">
                        Discount value must be between 0 to 100
                      </div>
                    )}
                  </div>
                )}

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Create</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddPackages;
