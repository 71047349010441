import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useEffect } from 'react';
import { useState } from 'react';
import axios from "axios";
import { decryptData, getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import InvoiceDownload from "../InvoiceDownload/InvoiceDownload";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "75%",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const InvoiceModalTable = (props) => {
    const [AllBillData, setAllBillData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branchId = getEncryptItems("branchDetails")?._id;

    useEffect(() => {
        FetchBillingData();
    }, [branchId]);

    const filteredBills = AllBillData?.filter((item) => {
        const itemDate = new Date(item.createdAt);
        const fromDate = props?.from ? new Date(props?.from) : null;
        const toDate = props?.to ? new Date(props?.to) : null;

        return (
            (!fromDate || itemDate >= fromDate) &&
            (!toDate || itemDate <= toDate)
        );
    });

    const itemsPerPage = 4;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredBills?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredBills?.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const FetchBillingData = async () => {
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/billing/find-billing`,
            headers: {
                Authorization: token,
            },
        };

        setIsLoading(true);
        try {
            const res = await axios.request(config);
            const filteredBills = res?.data?.data?.filter(item => item.organizationId === branchId && !item?.isDeleted);
            setAllBillData(filteredBills.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <React.Fragment >
            <Modal
                open={props.openInvoiceTable}
                onClose={() => {
                    props.setOpenInvoiceTable(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className=" overflow-x-auto px-4">
                        <div className="btn-wrapper text-center ">
                            <span
                                onClick={() => props.setOpenInvoiceTable(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold">Invoice List</div>
                        </div>

                        <div className='mt-4 mx-auto no-scrollbar max-h-[400px]'>
                            {isLoading ? (<div className="shadow rounded-t-lg overflow-x-auto">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="px-5 py-5 border-2 border-gray-200  text-gray-600 tracking-wide">
                                                <div className=" flex flex-row  items-center">
                                                    <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                </div>
                                            </th>
                                            <th className="px-5 py-5 border-2 border-gray-200  text-gray-600 tracking-wide">
                                                <div className=" flex flex-row  items-center">
                                                    <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                </div>
                                            </th>
                                            <th className="px-5 py-5 border-2 border-gray-200  text-gray-600 tracking-wide">
                                                <div className=" flex flex-row  items-center">
                                                    <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                </div>
                                            </th>
                                            <th className="px-5 py-5 border-2 border-gray-200  text-gray-600 tracking-wide">
                                                <div className=" flex flex-row  items-center">
                                                    <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                </div>
                                            </th>
                                            <th className="px-5 py-5 border-2 border-gray-200  text-gray-600 tracking-wide">
                                                <div className=" flex flex-row  items-center">
                                                    <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[1, 2, 3, 4, 5].map((v, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className=" flex flex-row  items-center">
                                                            <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className=" flex flex-row  items-center">
                                                            <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className=" flex flex-row  items-center">
                                                            <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className=" flex flex-row  items-center">
                                                            <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className=" flex flex-row  items-center">
                                                            <div className="animate-pulse h-2 w-1/2 flex items-center   bg-skeletonLoaderColor rounded "></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>) : currentItems?.length === 0 ? (
                                <div className="flex justify-center bg-[#fff] w-full shadow-lg text-xl font-bold text-[#2B2F32] py-4">No Invoice found</div>)
                                : (<div className="shadow rounded-t-lg overflow-x-auto">
                                    <table className="min-w-full leading-normal">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Sl
                                                </th>

                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Name
                                                </th>

                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Number
                                                </th>
                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Date
                                                </th>
                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Payment Method
                                                </th>
                                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentItems?.map((ele, ind) => (
                                                <tr key={ele._id}>
                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {(currentPage * 10 + ind) - 9}
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {ele?.billingInfo?.name}
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {ele?.billingInfo?.phone}
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {ele?.createdAt?.slice(0, 10)}
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {ele?.paymentMethod}
                                                        </div>
                                                    </td>


                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                                                        <div className="whitespace-no-wrap flex">
                                                            <InvoiceDownload id={ele._id} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>)}
                            {currentItems?.length !== 0 ? (<div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                                <span className="text-xs xs:text-sm text-gray-900">
                                    Showing {indexOfFirstItem + 1} to {indexOfFirstItem + currentItems?.length} of {filteredBills?.length}  Entries
                                </span>
                                <div className="inline-flex mt-2 xs:mt-0">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                        className={`text-sm ${currentPage === 1 ? 'bg-gray-200' : 'bg-gray-300 hover:bg-gray-400'} text-gray-800 font-semibold py-2 px-4 rounded-l`}
                                    >
                                        Prev
                                    </button>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={
                                            currentPage === Math.ceil(filteredBills?.length / itemsPerPage)
                                        }
                                        className={`text-sm ${currentPage === Math.ceil(filteredBills?.length / itemsPerPage) ? 'bg-gray-200' : 'bg-gray-300 hover:bg-gray-400'} text-gray-800 font-semibold py-2 px-4 rounded-r`}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>) : null}
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default InvoiceModalTable;
