import React, { useState } from "react";
import HeaderCards from "../../Components/Dashboard/HeaderCards";
import { allLeser, calculateLaser } from "../Common/initData";
import {
  allLaserList,
  setAppointmentFromCalendar,
} from "../../state-management/main/actions";
import { useEffect } from "react";
import { decryptData, getEncryptItems } from "../Common/localStorageUtils";
import { useDispatch } from "react-redux";
import BillModal from "../../Components/Invoices/InvoiceModal/BillModal";
import Tostify from "../Common/Tostify";

const Dashboard = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Dashboard`;
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState("Bill");
  const branch = getEncryptItems("branchDetails");
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");

  useEffect(() => {
    dispatch(
      setAppointmentFromCalendar({
        selectedItem: "Bill",
        selectedDate: new Date(),
      })
    );
  }, [dispatch]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const decryptedData = decryptData();
        const role = decryptedData?.user?.role;
        const orgId =
          role === "admin"
            ? branch?._id
            : decryptedData?.userTypeData?.organizationId?._id;
        await calculateLaser(orgId._id);
        const data = await allLeser();
        dispatch(allLaserList(data));
      } catch (error) {
        console.error("Error fetching and processing data:", error);
      }
    };

    fetchData();
  }, [branch?._id, dispatch]);

  return (
    <div>
      <Tostify />
      <div className="flex flex-col">
        <div>
          <HeaderCards/>
        </div>
        {(userRole === "admin" ||
          (userRole === "staff" &&
            userRoleName?.roleId?.permissions?.dashboard
              ?.dashboardBilling)) && (
          <div>
            <BillModal
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
