import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import axios from "axios";
import Tostify from "../../../Screens/Common/Tostify";
import InvoiceDownload from "../InvoiceDownload/InvoiceDownload";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  borderRadius: "15px",
  p: 4,
};
const InvoiceModal = (props) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch) || "";
  const [filterDate, setFilterDate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [AllBillData, setAllBillData] = useState([]);
  const FilterBillByUser = AllBillData.filter(
    (ele) => ele.customer === props.id
  );
  const filterByDate = FilterBillByUser.filter(
    (ele) => ele.date === filterDate
  );

  useEffect(() => {
    FetchBillingData();
  }, [branch]);

  const FetchBillingData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/billing/find-billing`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    try {
      const res = await axios.request(config);
      setAllBillData(res?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <Modal
        open={props.openBillingModal}
        onClose={() => {
          props.setOpenBillingModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <Tostify />

          {FilterBillByUser.length !== 0 ? (
            <div className="overflow-x-auto">
              {/*<input
                type="date"
                onChange={(e) => setFilterDate(e.target.value)}
          />*/}
              <span
                onClick={() => props.setOpenBillingModal(false)}
                className="absolute top-0  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-3 py-2 text-center">
                      Sl.
                    </th>
                    <th scope="col" className="px-3 py-2 text-center">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-2 text-center">
                      Mobile No.
                    </th>
                    <th scope="col" className="px-3 py-2 text-center">
                      Payment Method
                    </th>
                    <th scope="col" className="px-3 py-2 text-center">
                      Amount
                    </th>
                    <th scope="col" className="px-3 py-2 text-center">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="sm:border border-red-500 max-h-[300px] overflow-y-auto">
                  {FilterBillByUser &&
                    FilterBillByUser?.map((ele, ind) => (
                      <tr key={ind} className="text-center">
                        <td className="px-3 py-2">{ind + 1}</td>
                        <td className="px-3 py-2">{ele?.billingInfo?.email}</td>
                        <td className="px-3 py-2">{ele?.billingInfo?.phone}</td>
                        <td className="px-3 py-2">{ele?.paymentMethod}</td>
                        <td className="px-3 py-2">{ele?.finalPrice}</td>
                        <td className="px-3 py-2 flex justify-center">
                          <InvoiceDownload id={ele._id} />
                        </td>
                      </tr>
                    ))}
                  {/*filterByDate &&
                    filterByDate?.map((ele, ind) => (
                      <tr key={ind} className="text-center">
                        <td className="px-3 py-2">{ind + 1}</td>
                        <td className="px-3 py-2">{ele?.billingInfo?.email}</td>
                        <td className="px-3 py-2">{ele?.billingInfo?.phone}</td>
                        <td className="px-3 py-2">{ele?.paymentMethod}</td>
                        <td className="px-3 py-2">{ele?.finalPrice}</td>
                        <td className="px-3 py-2 flex justify-center">
                          <InvoiceDownload id={ele._id} />
                        </td>
                      </tr>
                    ))*/}
                </tbody>
              </table>
            </div>
          ) : (
            <div>
              <h1 className="text-center">Bills not found</h1>
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default InvoiceModal;
