import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";

const InvoiceDropdown = ({ label, items, onSelect, selectedItem, itemName, className, showDuration }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openUpwards, setOpenUpwards] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(items);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleDropdown = useCallback(() => {
        if (!isOpen) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const spaceBelow = windowHeight - buttonRect.bottom;
            const spaceAbove = buttonRect.top;
            const menuHeight = Math.min(items.length * 40, 200);

            setOpenUpwards(spaceBelow < menuHeight && spaceAbove > spaceBelow);
        }
        setIsOpen(!isOpen);
    }, [isOpen, items?.length]);

    const handleSelect = useCallback((item) => {
        onSelect(item);
        setIsOpen(false);
        setSearchTerm('');
    }, [onSelect]);

    const handleSearch = useCallback((e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = items?.filter(item =>
            (typeof item === 'object' ? item?.name : item).toLowerCase().includes(term)
        );
        setFilteredItems(filtered);
        setIsOpen(true);
    }, [items]);

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);

    useEffect(() => {
        if (label === "type" && items.length > 0 && !selectedItem) {
            onSelect(items[0]);
        }
    }, [label, items, selectedItem, onSelect]);

    return (
        <div className={`relative inline-block text-left ${className}`} ref={dropdownRef}>
            <div>
                <div className="relative">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={() => setIsOpen(true)}
                        placeholder={selectedItem || label}
                        className="w-full py-3 mt-[2px] pl-4 placeholder-black capitalize pr-10 rounded-md border shadow bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-1 focus:ring-primaryColor"
                    />
                    <button
                        ref={buttonRef}
                        type="button"
                        onClick={toggleDropdown}
                        className="absolute inset-y-0 right-0 flex items-center px-2"
                    >
                        <IoMdArrowDropdown size={25} />
                    </button>
                </div>
            </div>

            {isOpen && (
                <div
                    className={`absolute ${openUpwards ? 'bottom-full mb-2' : 'top-full mt-2'} no-scrollbar right-0 w-full rounded-md bg-white ring-1 z-10 ring-black ring-opacity-5`}
                    style={{
                        maxHeight: '200px',
                        overflowY: 'auto'
                    }}
                >
                    <div className="" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {filteredItems?.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleSelect(item)}
                                className={`text-[#132c4a] text-left hover:text-white cursor-pointer bg-white hover:bg-primaryColor py-2 px-4 block whitespace-no-wrap`}
                            >
                                {typeof item === 'object' ? (
                                    <>
                                        {item.name}
                                        {showDuration && item?.duration && <span className="ml-2 text-sm">({item?.duration})</span>}
                                    </>
                                ) : (
                                    item
                                )}
                            </div>
                        ))}
                        {filteredItems.length === 0 && (
                            <div className="text-gray-500 text-center py-2">No results found</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(InvoiceDropdown);