import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { decryptData } from "../../../../Screens/Common/localStorageUtils";
import { useSelector } from "react-redux";
import axios from "axios";
import { tostifyErr, tostifySuccess } from "../../../../Screens/Common/tostifyMessages";
import { useEffect } from "react";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    outline: "none",
};

const EditOrder = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [itemQuantities, setItemQuantities] = useState({});
    const [formData, setFormData] = useState({
        date: "",
        dateErr: false,
        remark: "",
        orgId: "",
        orgIdErr: false,
        supplier: "",
        supplierErr: false,
    });

    useEffect(() => {
        const initialFormData = props?.initialValue || {};
        setFormData({
            ...formData,
            remark: initialFormData?.remark || "",
            date: initialFormData?.date?.slice(0, 10) || "",
            orgId: initialFormData?.organizationId || "",
            supplier: initialFormData?.supplierId || ""
        });
        const productIdAndQuantityArray = initialFormData?.products?.reduce((acc, obj) => {
            const { productId, quantity } = obj;
            acc[productId] = String(quantity);
            return acc;
        }, {});
        setItemQuantities(productIdAndQuantityArray);
    }, [props?.handleOpenEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            [`${name}Err`]: ""
        });
    };

    const handleQuantityChange = (itemId, quantity, costPriceMRP) => {
        setItemQuantities((prevQuantities) => ({
            ...prevQuantities,
            [itemId]: quantity
        }));
    };

    const adminId = decryptData().userTypeData?._id;
    const productList = useSelector((state) => state?.admin?.productList);
    const suppliers = useSelector((state) => state?.main?.supplierList);
    const organization = useSelector((state) => state?.main?.orgList);
    const orgByAdminId = organization?.filter((org) => org.adminId === adminId);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const filteredProducts = productList?.filter((item) => item?.organization?.adminId === adminId);
    const matchingProducts = [];
    props?.initialValue?.products?.forEach(product => {
        const matchingProduct = filteredProducts.find(item => item._id === product.productId);
        if (matchingProduct) {
            matchingProducts.push(matchingProduct);
        }
    });
    const filteredSuppliers = suppliers?.filter((item) => item?.organizationId === formData.orgId);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.orgId) {
            setFormData(prev => ({
                ...prev,
                orgIdErr: true,
            }))
        } else if (!formData.date) {
            setFormData(prev => ({
                ...prev,
                dateErr: true
            }))
        }
        else if (!formData.supplier) {
            setFormData(prev => ({
                ...prev,
                supplierErr: true,
            }))
        } else {
            const selectedItems = Object.entries(itemQuantities)
                .filter(([itemId, quantity]) => quantity > 0)
                .map(([productId, quantity]) => ({
                    productId,
                    quantity: parseInt(quantity),
                    price: filteredProducts.find(product => product._id === productId)?.costPriceMRP * quantity
                }));

            let data = JSON.stringify({
                "supplierId": formData.supplier,
                "organizationId": formData.orgId,
                "date": formData.date,
                "products": selectedItems,
                "remark": formData.remark
            });

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/order/update/${props?.initialValue?._id}`,
                headers: { 
                  'Content-Type': 'application/json', 
                    'Authorization': token
                },
                data: data
            };

            setIsLoading(true);
            axios.request(config)
                .then((response) => {
                    console.log(response.data);
                    tostifySuccess(response?.data?.message);
                    props?.setOpenEditOrder(false);
                    props?.fetchOrders();
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    tostifyErr(error?.response?.data?.message);
                    props?.setOpenEditOrder(false);
                });
        }
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openEditOrder}
                onClose={() => {
                    props.setOpenEditOrder(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[400px] overflow-x-auto px-4 overflow-y-scroll">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={() => props.setOpenEditOrder(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold mb-5">
                                Edit Order
                            </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="flex justify-between gap-4 mb-4">
                                <select
                                    value={formData.orgId}
                                    name="orgId"
                                    id="orgId"
                                    onChange={handleInputChange}
                                    className={
                                        formData.orgIdErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                                    }
                                >
                                    <option value="">Select Branch</option>
                                    {orgByAdminId?.map((v, i) => {
                                        return (
                                            <option className="capitalize" key={i} value={v._id}>
                                                {v.name}
                                            </option>
                                        );
                                    })}
                                </select>

                                <input
                                    name="date"
                                    type="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className={
                                        formData.dateErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                                    }
                                />
                                <select
                                    value={formData.supplier}
                                    name="supplier"
                                    id="supplier"
                                    onChange={handleInputChange}
                                    className={
                                        formData.supplierErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                                    }
                                >
                                    <option value="">Select supplier</option>
                                    {filteredSuppliers?.map((v, i) => {
                                        return (
                                            <option className="capitalize" key={i} value={v._id}>
                                                {v.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="no-scrollbar shadow max-h-[200px] overflow-y-scroll rounded-t-lg overflow-x-auto ">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                Sl
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                Product Name
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                Price
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                current Stock
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                Quantity
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {matchingProducts?.map((v, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {i + 1}
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {v?.name}
                                                        </div>
                                                    </td>
                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {v?.costPriceMRP}
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            {v?.quantity}
                                                        </div>
                                                    </td>

                                                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                        <div className="text-gray-900 whitespace-no-wrap">
                                                            <input
                                                                className="btext-[#132c4a] border-0 px-1 py-1 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                                                type="number"
                                                                min="0"
                                                                value={itemQuantities[v._id] || ""}
                                                                onChange={(e) => handleQuantityChange(v._id, e.target.value, v.costPriceMRP)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-end gap-2 items-center">
                                <input
                                    type="text"
                                    name="remark"
                                    value={formData.remark}
                                    onChange={handleInputChange}
                                    placeholder="Enter remark"
                                    className="text-[#132c4a] border-0 px-2 py-2 my-2 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-2/3 ease-linear transition-all duration-150  " />

                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className=" px-2 py-2 my-2 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-md shadow-[#ffdcc7]"
                                >{isLoading ? (
                                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>Change Order</span>
                                )}
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default EditOrder;
