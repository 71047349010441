import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import icon from "../../../../Assets/imgpsh_fullsize_anim.png";
import { tostifyErr, tostifySuccess } from "../../../../Screens/Common/tostifyMessages";
import { decryptData, getEncryptItems } from "../../../../Screens/Common/localStorageUtils";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const ConfirmOrder = (props) => {
    const [isLoading, setIsLoading] = useState();
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = getEncryptItems("branchDetails");

    const handleDelete = async (_id) => {
        let config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/order/delete/${_id}`,
            headers: {
                Authorization: token,
            },
        };

        setIsLoading(true);
        await axios
            .request(config)
            .then((response) => {
                let resStatus = response.status === 200;
                if (resStatus) {
                    props.fetchOrders();
                    //   props?.handleClear();
                    tostifySuccess(response?.data?.message);
                    props.setOpenConfirmOrder(false);
                    props.setId("");
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Delete Order error:", error);
                props.setOpenConfirmOrder(false);
                setIsLoading(false);
                tostifyErr(error?.response?.data?.message);
            });
    };

    const handleConfirm = (_id) => {
        const createdBy = decryptData()?.user?.role === "admin" ? {
            "name": decryptData()?.user?.firstName + " " + decryptData()?.user?.lastName,
            "adminId": decryptData()?.userTypeData?._id
        } : {
            "name": decryptData()?.user?.firstName + decryptData()?.user?.lastName,
            "staffId": decryptData()?.userTypeData?._id
        }
        let data = JSON.stringify({
            "status": true,
            "adminId": branch?.adminId,
            "organizationId": branch?._id,
            "createdBy": createdBy
        });

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/order/update/${_id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                props.fetchOrders();
                tostifySuccess(response?.data?.message);
                props.setOpenConfirmOrder(false);
            })
            .catch((error) => {
                console.log(error);
                props.setOpenConfirmOrder(false);
                setIsLoading(false);
                tostifyErr(error?.response?.data?.message);
            });

    }

    return (
        <React.Fragment>
            <Modal
                open={props.openConfirmOrder}
                onClose={() => props.setOpenConfirmOrder(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="bg-white rounded-lg max-w-md w-full">
                        <div className="flex flex-col items-center">
                            <div className="flex justify-center items-center">
                                <img src={icon} alt="delete img" className="h-16 w-16" />
                            </div>
                            <div className="text-2xl text-[#2b2f32] font-bold my-4">Are you sure?</div>
                        </div>
                        <div className="text-center text-[#828282] mb-6">
                            Do you want to Update this order status?
                        </div>
                        <div className="flex justify-center gap-4">
                            <button
                                onClick={() => props.setOpenConfirmOrder(false)}
                                className="h-10 w-2/5 flex items-center justify-center text-lg font-semibold text-gray-700 bg-gray-200 hover:bg-gray-300 transition ease-in-out duration-300 rounded-lg shadow-md"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => handleDelete(props.id)}
                                disabled={isLoading}
                                className="h-10 w-2/5 flex items-center justify-center text-lg font-semibold text-white bg-red-500 hover:bg-red-600 transition ease-in-out duration-300 rounded-lg shadow-md"
                            >
                                {isLoading ? (
                                    <div className="w-6 h-6 rounded-full animate-spin border-2 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>Delete</span>
                                )}
                            </button>
                            <button
                                onClick={() => handleConfirm(props.id)}
                                disabled={isLoading}
                                className="h-10 w-2/5 flex items-center justify-center text-lg font-semibold text-white bg-green-500 hover:bg-green-600 transition ease-in-out duration-300 rounded-lg shadow-md"
                            >
                                {isLoading ? (
                                    <div className="w-6 h-6 rounded-full animate-spin border-2 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>Confirm</span>
                                )}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default ConfirmOrder;

