import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Payments = ({ dailySumsForPayments }) => {
  const [isLoading] = useState(false);

  const categoryOrder = ["card", "upi", "cash", "expense", "total", "subTotal"];

  const sortedData = Object.entries(dailySumsForPayments).sort(([dateA], [dateB]) => 
    new Date(dateA) - new Date(dateB)
  );

  const series = categoryOrder.map((category) => ({
    name: category,
    data: sortedData.map(([, dayData]) => dayData[0][category] || 0),
  }));

  const state = {
    series,
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: sortedData.map(([, dayData]) => dayData[0].date),
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },
    },
  };

  return (
    <React.Fragment>
      <div
        className="bg-white w-full md:w-1/2 rounded-lg shadow mt-4 py-3"
        id="chart"
      >
        {isLoading ? (
          <div className="h-96 bg-white flex flex-col items-center gap-6 px-4  justify-center">
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
          </div>
        ) : (
          <div>
            <div className="font-bold text-2xl mt-2 tracking-wide mx-4">
              Payments
            </div>
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="line"
              height={350}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Payments;