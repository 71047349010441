import React from "react";
import AnalyticsView from "../../Components/Analytics/AnalyticsView";
// import AnalyticsView from "../../Analytics/AnalyticsView";

const Analytics = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Analytics`;

  return <AnalyticsView />;
};

export default Analytics;
