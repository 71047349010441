import React from "react";
import { FaHandHoldingUsd } from "react-icons/fa";
import { BsBuildingFillCheck } from "react-icons/bs";
import { FaCreditCard } from "react-icons/fa";
import { useSelector } from "react-redux";
import HeaderLoader from "./HeaderLoader";
import DashBoardCard from "./DashBoardCard";
import { RiUserUnfollowFill } from "react-icons/ri";

const MonthHeaderCards = ({ setShoAppointments, monthsStatisticsData, isLoading, visible }) => {
  const main = useSelector((state) => state.main);
  const branch = useSelector(state => state?.main?.branch) || "";
  const active = main?.staffList?.filter(item => item?.organizationId === branch?._id && item?.status === "active")?.length;
  // const [appointments, setAppointments] = useState([]);

  // const formattedDateMonth = currentDate.getMonth()
  // const monthsAppointment = appointments?.filter((app) => new Date(app?.appointmentDate).getMonth() === formattedDateMonth);

  // const monthSum = main?.laserList?.filter(item => item?.organizationId === branch?._id)
  //   ?.filter(item => item?.organizationId === branch?._id)?.reduce((sum, organization) => {
  //     const organizationTotal = organization.checkLaser
  //       .filter((entry) => new Date(entry.date).getMonth() === formattedDateMonth)
  //       .reduce((orgSum, entry) => orgSum + entry.total, 0);

  //     return sum + organizationTotal;
  //   }, 0);

  // const totalMonthsCash = main?.laserList?.filter(item => item?.organizationId === branch?._id)?.reduce((sum, item) => {
  //   item?.checkLaser?.forEach((laser) => {
  //     if (new Date(laser.date).getMonth() === formattedDateMonth) {
  //       sum += laser.cash;
  //     }
  //   });
  //   return sum;
  // }, 0);

  // const totalCardMonth = main?.laserList?.filter(item => item?.organizationId === branch?._id)?.filter(item => item?.organizationId === branch?._id)?.reduce((sum, item) => {
  //   item?.checkLaser?.forEach((laser) => {
  //     if (new Date(laser.date)?.getMonth() === formattedDateMonth) {
  //       sum += laser.card + laser.upi;
  //     }
  //   });
  //   return sum;
  // }, 0);

  // const netRevenue = main?.laserList?.filter(item => item?.organizationId === branch?._id)?.reduce((sum, item) => {
  //   item?.checkLaser?.forEach((laser) => {
  //     sum += laser?.total;
  //   });
  //   return sum;
  // }, 0);

  // const filteredCustomers = main?.clientList
  //   ? main.clientList.filter((cli) => cli?.organization?._id === branch?._id)
  //   : [];

  // const today = new Date();
  // const thirtyDaysAgo = new Date();
  // thirtyDaysAgo.setDate(today.getDate() - 30);

  // const newClient = filteredCustomers?.filter(item => {
  //   const createdAtDate = new Date(item?.createdAt);
  //   return createdAtDate >= thirtyDaysAgo && createdAtDate <= today && item?.organization?._id === branch?._id;
  // });

  // const oldClient = (filteredCustomers?.length || 0) - (newClient?.length || 0);

  // useEffect(() => {
  //   fetchAppointment();
  // }, []);
  // const fetchAppointment = async () => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_API_URL}/appointment/find-appointment?populate=organization`,
  //     headers: {
  //       Authorization: token,
  //     },
  //   };

  //   setIsLoading(true);
  //   try {
  //     const res = await axios.request(config);
  //     setIsLoading(false);
  //     setAppointments(
  //       res?.data?.data
  //         ?.filter((item) => item?.organization?._id === branch?._id)
  //         ?.filter(
  //           (item) =>
  //             item?.appointmentDate?.toString()?.substring(0, 10) === new Date()?.toISOString()?.slice(0, 10))
  //     );

  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  const dashboardData = [
    { icon: BsBuildingFillCheck, title: "Appointments", value: monthsStatisticsData?.appointmentData?.length || 0, onClick: () => setShoAppointments(prev => !prev) },
    { icon: RiUserUnfollowFill, title: "Active Staffs", value: active?.length || 0 },
    { icon: FaHandHoldingUsd, title: "Months's Revenue", value: monthsStatisticsData?.monthsRevenue || 0 },
    { icon: FaHandHoldingUsd, title: "Months's Cash", value: monthsStatisticsData?.monthsCash || 0 },
    { icon: FaHandHoldingUsd, title: "Realized Revenue", value: monthsStatisticsData?.realizedRevenue || 0 },
    { icon: FaHandHoldingUsd, title: "Net Revenue", value: monthsStatisticsData?.netRevenue || 0 },
    { icon: FaCreditCard, title: "Credit/Debit/UPI", value: monthsStatisticsData?.monthsOnline || 0 },
    { icon: FaCreditCard, title: "New Clients", value: monthsStatisticsData?.newClients || 0 },
    { icon: FaCreditCard, title: "Old Clients", value: monthsStatisticsData?.oldClients || 0 }
  ];

  return (
    <div style={{ overflowX: 'auto' }} className="no-scrollbar">
      <div className="flex gap-4 mb-4 w-screen">
        <div className="text-[#2B2F32] bg-[#fff] p-2 rounded-lg ">
          {isLoading ? (
            <div className="flex gap-4 justify-center ">
              {[1, 2, 3, 4, 5, 6, 7].map((v, i) => {
                return (
                  <HeaderLoader key={i} />
                );
              })}
            </div>
          ) : (
            <div style={{ overflowX: "auto" }} className="no-scrollbar">
              <div className="flex gap-4 mb-4">
                <div className="text-[#2B2F32] bg-[#fff] p-2 rounded-lg">
                  {isLoading ? (
                    <div className="flex gap-4 justify-center">
                      {[...Array(7)].map((_, i) => (
                        <HeaderLoader key={i} />
                      ))}
                    </div>
                  ) : (
                    <div
                      style={{ opacity: visible ? 1 : 0 }}
                      className={`flex gap-4 mt-2 ${visible ? "animate-fadeIn" : "animate-fadeOut"} transition-opacity duration-500`}
                    >
                      {dashboardData?.map((item, index) => (
                        <DashBoardCard onClick={() => setShoAppointments(prev => !prev)} key={index} {...item} />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MonthHeaderCards