import React from "react";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";

const DailyAppointments = ({ last7DaysAppointments }) => {
  const [isLoading] = useState(false);

  const chartData = {
    series: [
      {
        name: "Appointments",
        data: Object.entries(last7DaysAppointments)?.map(([date, appointments]) => ({
          x: new Date(date).getTime(),
          y: appointments.length
        }))
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd MMM',
          rotate: -45,
          rotateAlways: true,
          offsetY: 5,
        },
        tickAmount: 'dataPoints',
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      yaxis: {
        title: {
          text: 'Number of Appointments'
        },
        min: 0,
        forceNiceScale: true,
      },
      tooltip: {
        x: {
          format: 'dd MMM yyyy'
        }
      },
      markers: {
        size: 5,
      },
    },
  };

  return (
    <React.Fragment>
      <div className="bg-white w-full md:w-1/2 rounded-lg shadow mt-4 py-3" id="chart">
        {isLoading ? (
          <div className="h-96 bg-white flex flex-col items-center gap-6 px-4  justify-center">
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
            <div className="animate-pulse h-12 my-2  w-full bg-skeletonLoaderColor rounded-md  "></div>
          </div>
        ) : (
          <div>
            <div className="font-bold text-2xl mt-2 tracking-wide mx-4">
              Appointments per day
            </div>
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="line"
              height={350}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DailyAppointments;