import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import icon from '../../Assets/imgpsh_fullsize_anim.png'
import { tostifyErr, tostifySuccess } from "../Common/tostifyMessages";
import { decryptData } from "../Common/localStorageUtils";
import { useSelector } from "react-redux";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const AcceptRequest = (props) => {
    const serviceList = useSelector((state) => state?.main?.inventoryService)
    const [isLoading, setIsLoading] = useState(false);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const staffId = decryptData()?.userTypeData?._id;

    const serviceIds = serviceList?.filter(item => props?.service?.[0]?.services.serviceId === item?._id)

    const handleAccept = async (id, isAccepted) => {
        setIsLoading(true);
        let data = JSON.stringify({
            "isAccepted": isAccepted ? "accepted" : "rejected"
        });

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/notification/update/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            tostifySuccess(response?.data?.message);
            props?.setOpenAcceptService(false);
            props?.setService("");
        } catch (error) {
            console.error('Error accepting request:', error);
            tostifyErr(error?.response?.data?.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Modal
                open={props.openAcceptService}
                onClose={() => props.setOpenAcceptService(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="max-h-[400px] overflow-x-auto">
                        <div className="btn-wrapper text-center">
                            <div className="flex justify-center items-center">
                                <img
                                    src={icon}
                                    alt="delete img"
                                    className="h-12 w-12" />
                            </div>
                            <div className="text-xl text-[#2b2f32] font-bold my-4">
                                Are you sure ?
                            </div>
                        </div>

                        <div className="flex-auto"></div>
                        <span className="block text-[#828282] text-center capitalize">
                            Do you want to Accept {serviceIds?.map(item => item.name).join(', ')} Service.
                        </span>

                        <div className="flex justify-center gap-4 mt-6 mb-2">
                            <button
                                onClick={() => handleAccept(props?.service?.[0]._id, false)}
                                className="h-10 w-2/5 flex content-center items-center justify-center text-lg font-semibold bg-gray-200 hover:bg-gray-300 transition ease-in-out hover:duration-300 rounded-xl shadow-md shadow-[#ffdcc7]"
                            >{isLoading ? (<div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>) : <span>Reject</span>}

                            </button>

                            <button
                                disabled={isLoading}
                                onClick={() => handleAccept(props?.service?.[0]._id, true)}
                                className="h-10 w-2/5  flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                            >
                                {isLoading ? (
                                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>Accept</span>
                                )}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default AcceptRequest;
