import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const StaffServices = ({ staff }) => {
  const serviceHistory = useSelector(state => state?.main?.serviceVsStaffData)
    ?.filter(item => item?.staffId?._id === staff?._id);
  const [currentPage, setCurrentPage] = useState(1);
  const serviceList = useSelector(state => state?.main?.inventoryService);

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = serviceHistory?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(serviceHistory?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className='min-h-[450px] flex flex-col mt-4'>
      {serviceHistory?.length === 0 ? (
        <div className="mt-4 px-14 py-2 bg-white rounded-md capitalize text-2xl 
        min-h-[310px] text-[#050F64] text-center content-center  shadow-m">
          No Service History found
        </div>
      ) : (
        <div className="shadow rounded-t-lg overflow-y-auto">
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Sl
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Service Name
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Time
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Date
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((v, i) => {
                const serviceNames = v?.serviceId?.reduce((accumulator, currentValue) => {
                  if (accumulator === '') {
                    return currentValue.name;
                  } else {
                    return accumulator + ', ' + currentValue.name;
                  }
                }, '');
                const dateString = v?.time;
                const date = new Date(dateString);
                let hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                const amOrPm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12 || 12;
                const timeString = `${hours}:${minutes} ${amOrPm}`;

                return (
                  <tr key={i}>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {(currentPage * 10 + i) - 9}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {serviceNames}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {timeString}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.createdAt?.slice(0, 10)}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.isAccepted}
                      </div>
                    </td>

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {currentItems?.length !== 0 && (
        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
          <span className="text-xs xs:text-sm text-gray-900">
            Showing {indexOfFirstItem + 1} to{" "}
            {indexOfFirstItem + currentItems?.length} of {serviceHistory?.length}{" "}
            Entries
          </span>
          <div className="inline-flex mt-2 xs:mt-0">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`text-sm ${currentPage === 1
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-l`}
            >
              Prev
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(serviceHistory?.length / itemsPerPage)
              }
              className={`text-sm ${currentPage === Math.ceil(serviceHistory?.length / itemsPerPage)
                ? "bg-gray-200"
                : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-r`}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default StaffServices;
