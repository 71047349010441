import * as actionTypes from "./actionTypes";

export const allBranchList = (branches) => {
    return {
        type: actionTypes.ALL_BRANCH_LIST,
        payload: branches,
    };
};

export const allManagerList = (managers) => {
    return {
        type: actionTypes.ALL_MANAGER_LIST,
        payload: managers,
    }
}

export const allStaffList = (staffs) => {
    return {
        type: actionTypes.ALL_STAFF_LIST,
        payload: staffs,
    }
}

export const allClientList = (clients) => {
    return {
        type: actionTypes.ALL_CLIENT_LIST,
        payload: clients,
    }
}

export const allInventoryService = (service) => {
    return {
        type: actionTypes.ALL_INVENTORY_SERVICE,
        payload: service,
    }
}

export const allInventoryProduct = (products) => {
    return {
        type: actionTypes.ALL_INVENTORY_PRODUCT,
        payload: products,
    }
}

export const allProductList = (product) => {
    return {
        type: actionTypes.ALL_PRODUCT_LIST,
        payload: product,
    }
}

export const allDiscountList = (discounts) => {
    return {
        type: actionTypes.ALL_DISCOUNT_LIST,
        payload: discounts,
    }
}

export const allExpanditureList = (exp) => {
    return {
        type: actionTypes.ALL_EXPANDITURE_LIST,
        payload: exp,
    }
}

export const allMembershipList = (memb) => {
    return {
        type: actionTypes.ALL_MEMBERSHIP_LIST,
        payload: memb,
    }
}

