import React from 'react'

const HeaderLoader = ({key}) => {
  return (
    <div
    className="w-44 bg-white rounded-lg shadow-lg pt-1 pb-1"
    key={key}
  >
    <div className="w-full flex flex-col justify-center items-center">
      <div className="animate-pulse w-12 h-12 rounded-full bg-skeletonLoaderColor flex justify-center items-center mx-auto  mb-5">
        <div className="h-2  bg-skeletonLoaderColor rounded "></div>
      </div>
      <div className="font-semibold text-md text-[#7f8296] text-center mt-1 mb-1 tracking-wide w-3/5">
        <div className=" flex flex-col justify-center items-center">
          <div className="animate-pulse h-2 w-2/3 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
        </div>
      </div>
    </div>

    <div className="font-bold text-lg w-full text-center">
      <div className=" flex flex-col justify-center items-center">
        <div className="animate-pulse h-3 w-2/3 flex items-center justify-center text-sm font-semibold bg-skeletonLoaderColor rounded mb-3 "></div>
      </div>
    </div>
  </div>
  )
}

export default HeaderLoader