import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import lgnBackPic from "../../Assets/Login/LogsPic.webp";
import axios from "axios";
import { encryptData, encryptItems } from "../Common/localStorageUtils";
import Tostify from "../Common/Tostify";
import { toast } from "react-toastify";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useDispatch } from "react-redux";
import { setBranch, userLoginData } from "../../state-management/main/actions";
// import { MuiTelInput } from "mui-tel-input";

const Login = () => {
  document.title = `${process.env.REACT_APP_NAME} | Login`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("+91");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [errors, setErrors] = useState({});
  // const [value, setValue] = React.useState('+91')
  // console.log("value++++++",value)
  useEffect(() => {
    const storedEmail = localStorage.getItem("rememberedEmail");
    const storedPhone = localStorage.getItem("rememberedPhone");
    const storedPassword = localStorage.getItem("rememberedPassword");
    if (storedEmail) setEmail(storedEmail);
    if (storedPhone) setPhone(storedPhone?.slice(3));
    if (storedPassword) setPassword(storedPassword);
  }, []);

  // const handleChange = (newValue) => {
  //   setValue(newValue)
  // }
  const validateInputs = () => {
    const newErrors = {};
    if (!phone || phone.length !== 10)
      newErrors.phone = "A valid phone number is required.";
    if (!password) newErrors.password = "Password is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validateInputs()) return;

    const updatedPhone = country + phone;
    const data = JSON.stringify({ phone: updatedPhone, password });
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/auth/login`,
      headers: { "Content-Type": "application/json" },
      data,
    };

    if (rememberMe) {
      localStorage.setItem("rememberedEmail", email);
      localStorage.setItem("rememberedPhone", updatedPhone);
      localStorage.setItem("rememberedPassword", password);
    }

    setIsLoading(true);
    try {
      const res = await axios.request(config);
      const resData = res.data.data;
      setUserData(resData);

      const resUserRole = resData.user.role;
      const refreshToken = res.data.refreshToken;

      if (refreshToken) {
        localStorage.setItem("refresh_token", refreshToken);
        encryptData(resData);
        dispatch(userLoginData(resData));
        navigate(`/${resUserRole}/dashboard`);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toastError(error?.response?.data?.message);
    }
  };

  const toastError = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const setUserData = (data) => {
    if (!data) return;

    const resUserRole = data.user.role;
    const userTypeData = data.userTypeData;
    const organizationData = data.organizationData;

    if (resUserRole === "staff") {
      const { roleId, organizationId, userId, _id: staffId } = userTypeData;
      const userRoleName = { roleId, organizationId, userId, staffId };
      encryptItems(userRoleName, "customRoles");
      encryptItems(userTypeData.organizationId, "branchDetails");
    } else if (resUserRole === "admin") {
      dispatch(setBranch(organizationData?.[0]))
      encryptItems(organizationData?.[0], "branchDetails");
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(${lgnBackPic})` }}
      className="bg-center h-screen object-cover"
    >
      <Tostify />

      <div className="flex justify-center items-center h-full">
        <div className="w-full md:w-6/12 lg:w-4/12 px-2 py-8 md:p-0">
          <div className="relative flex flex-col min-w-0 break-words mb-6 shadow-lg rounded-2xl bg-white border-0 p-8">
            <div className="text-center text-xl text-[#2b2f32] font-bold mb-4">
              Welcome Back
            </div>

            <form onSubmit={handleLogin}>
              <div className="relative mb-5">
                <label className="text-black block text-sm mb-2">
                  Mobile Number
                  <span className="text-orange-600 text-lg"> *</span>
                </label>
                <div className="flex gap-2 items-center">
                  <select
                    name="Country code"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    className={`text-black border px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-orange-500 ${errors.phone ? "ring-orange-600" : ""
                      }`}
                  >
                    <option value="+91">+91</option>
                    <option value="+1">+1</option>
                    <option value="+44">+44</option>
                    <option value="+61">+61</option>
                  </select>

                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className={`flex-grow text-black border px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-orange-500 ${errors.phone ? "ring-orange-600" : ""
                      }`}
                    placeholder="Enter Your Phone No."
                  />
                </div>

                {/* <MuiTelInput value={value} onChange={handleChange} /> */}

                {errors.phone && (
                  <p className="text-orange-600 text-sm mt-1">{errors.phone}</p>
                )}
              </div>

              <div className="relative mb-5">
                <label className="text-black block text-sm mb-2">
                  Password
                  <span className="text-orange-600 text-lg"> *</span>
                </label>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={visible ? "text" : "password"}
                  className={`text-black border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-orange-500 w-full transition-all duration-150 pr-10 ${errors.password ? "ring-orange-600" : ""
                    }`}
                  placeholder="Password"
                />
                {visible ? (
                  <IoMdEye
                    size={20}
                    onClick={() => setVisible(!visible)}
                    className="absolute right-3 top-12 mx-2 text-black cursor-pointer"
                  />
                ) : (
                  <IoMdEyeOff
                    size={20}
                    onClick={() => setVisible(!visible)}
                    className="absolute right-3 top-12 mx-2 text-black cursor-pointer"
                  />
                )}
                {errors.password && (
                  <p className="text-orange-600 text-sm mt-1">
                    {errors.password}
                  </p>
                )}
              </div>

              <div className="flex items-center mb-5 cursor-pointer">
                <input
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  type="checkbox"
                  className="border-black rounded h-5 w-5 mr-2"
                />
                <span className="text-black text-sm">Remember me</span>
              </div>

              <div className="flex items-center justify-center">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="h-12 w-3/5 flex items-center justify-center text-lg font-semibold text-white bg-orange-500 hover:bg-orange-600 transition ease-in-out duration-300 rounded-xl shadow-lg"
                >
                  {isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    "Log In"
                  )}
                </button>
              </div>
            </form>

            {/* <div className="flex items-center justify-center text-[#718092] mt-4">
              <button className="text-primaryColor hover:text-primaryHoverColor transition ease-in-out duration-300 font-medium hover:underline">
                Forgot Password?
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
