import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { decryptData, getEncryptItems } from "../../../Screens/Common/localStorageUtils";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
};

const AddGiftCard = (props) => {
    const decryptedData = decryptData();
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = getEncryptItems("branchDetails");
    const [isLoading, setIsLoading] = useState(false);
    const initialState = {
        name: "",
        nameErr: "",
        code: "",
        codeErr: "",
        amount: "",
        amountErr: "",
        price: "",
        priceErr: "",
        startDate: "",
        startDateErr: "",
        endDate: "",
        endDateErr: "",
        description: "",
    }
    const [formData, setFormData] = useState(initialState);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let updatedFormData = { ...formData };
        updatedFormData = {
            ...updatedFormData,
            [`${name}Err`]: false,
            [name]: value,
        };
        setFormData(updatedFormData);
    };

    const handleAddGiftCard = async (e) => {
        e.preventDefault();
        if (!formData?.name) {
            setFormData({
                ...formData,
                nameErr: true,
            })
        }
        else if (!formData?.code) {
            setFormData({
                ...formData,
                codeErr: true
            })
        }
        else if (!formData?.amount) {
            setFormData({
                ...formData,
                amountErr: true
            })
        }
        else if (!formData?.price) {
            setFormData({
                ...formData,
                priceErr: true
            })
        }
        else if (!formData?.startDate) {
            setFormData({
                ...formData,
                startDateErr: true
            })
        }
        else if (!formData?.endDate) {
            setFormData({
                ...formData,
                endDateErr: true
            })
        }
        else {
            let data = JSON.stringify({
                organizationId: branch?._id,
                name: formData?.name,
                code: formData?.code,
                amount: formData.amount,
                price: formData?.price,
                startDate: formData?.startDate,
                endDate: formData?.endDate,
                description: formData?.description,
            });
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/giftCard/create`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                data: data,
            };
            setIsLoading(true);
            axios
                .request(config)
                .then((response) => {
                    console.log("response?.data", response?.data)
                    setIsLoading(false);
                    setFormData({
                        ...initialState
                    });
                    props?.getAllFiftCards();
                    props?.setOpenAddCouponModal(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                });
        }
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openAddFiftCard}
                onClose={() => {
                    props.setOpenAddGiftCard(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar h-[500px] md:w-[650px] overflow-y-scroll overflow-x-auto px-4">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={() => props.setOpenAddGiftCard(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-primaryColor font-bold">
                                Add Gift Card
                            </div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={handleAddGiftCard}>
                                <div className="flex gap-4 flex-col md:flex-row">
                                    {/* Name */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Name
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="text"
                                            id="name"
                                            autoComplete="off"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            className={
                                                formData.nameErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter GiftCard Name"
                                        ></input>
                                    </div>

                                    {/* Coupon Code */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Code
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="text"
                                            id="code"
                                            autoComplete="off"
                                            name="code"
                                            value={formData.code}
                                            onChange={handleInputChange}
                                            className={
                                                formData.codeErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Code"
                                        ></input>
                                    </div>
                                </div>

                                <div className="flex gap-4 flex-col md:flex-row">
                                    {/* Amount */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Amount
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="text"
                                            id="amount"
                                            autoComplete="off"
                                            name="amount"
                                            value={formData.amount}
                                            onChange={handleInputChange}
                                            className={
                                                formData.amountErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Amount"
                                        ></input>
                                    </div>

                                    {/* Price */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Price
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="text"
                                            id="price"
                                            autoComplete="off"
                                            name="price"
                                            value={formData.price}
                                            onChange={handleInputChange}
                                            className={
                                                formData.priceErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter price"
                                        ></input>
                                    </div>
                                </div>

                                <div className="flex gap-4 flex-col md:flex-row">
                                    {/* Start Date */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Start Date
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="date"
                                            id="startDate"
                                            autoComplete="off"
                                            name="startDate"
                                            value={formData.startDate}
                                            onChange={handleInputChange}
                                            className={
                                                formData.startDateErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                        ></input>
                                    </div>
                                    {/* End Date */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            End Date
                                            <span className="text-red-600 text-lg"> *</span>
                                        </div>
                                        <input
                                            type="date"
                                            id="endDate"
                                            autoComplete="off"
                                            name="endDate"
                                            value={formData.endDate}
                                            onChange={handleInputChange}
                                            className={
                                                formData.endDateErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                        ></input>
                                    </div>
                                </div>

                                <div className="flex gap-4 flex-col md:flex-row">
                                    {/* Description */}
                                    <div className="relative w-full mb-3">
                                        <div className="text-[#132c4a] block text-sm mb-2">
                                            Description
                                        </div>
                                        <textarea
                                            id="description"
                                            autoComplete="off"
                                            name="description"
                                            value={formData.description}
                                            onChange={handleInputChange}
                                            className={
                                                formData.descriptionErr
                                                    ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                    : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                            }
                                            placeholder="Enter Description"
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="w-full flex items-center justify-end mt-5 gap-4">
                                    <button onClick={() => props?.setOpenAddGiftCard(false)} className="py-2 px-6">
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-primaryColor text-white py-2 w-28 flex justify-center rounded hover:bg-primaryHoverColor focus:outline-none"
                                    >
                                        {isLoading ?
                                            <div className="border-white animate-spin border-y-4 w-6 h-6 rounded-full border-dashed border-t-transparent">
                                            </div>
                                            : "Add Gift Card"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
            <style>
                {`
          input[type="checkbox"]{
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid gray;
            outline: none;
            cursor: pointer;
            position: relative;
          }

          input[type="checkbox"]:checked {
            border: 2px solid #fe8740;
          }

          input[type="checkbox"]:checked::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            background: #fe8740;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        `}</style>
        </React.Fragment>
    );
};

export default AddGiftCard;
