import React, { useState, useEffect } from "react";
import { ImSearch } from "react-icons/im";
import { useSelector } from "react-redux";
import { getEncryptItems } from "../../Screens/Common/localStorageUtils";
import Dropdown from "./Dropdown";
import DownloadTableData from "../../Screens/Common/downloadData";
import InvoiceDropdown from "../Invoices/InvoiceModal/InvoiceDropdown";

const AppointmentTable = ({ handleRowClick, formatTime }) => {
  const allAppointmentList = useSelector(
    (state) => state?.main?.appointmentList
  );
  const [searchData, setSearchData] = useState("");
  const [status, setStatus] = useState("");
  const [results, setResults] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState(null);
  const itemsPerPage = 3;

  const appointmentDetailFromCalendar = useSelector(
    (state) => state?.main?.appointmentDetailFromCalendar
  );

  const branch = getEncryptItems("branchDetails");
  const staffList = useSelector((state) => state?.main?.staffList)?.filter(
    (ele) => ele?.organizationId === branch?._id
  );

  const selectedDate = new Date(appointmentDetailFromCalendar?.selectedDate);
  const selectedDateISOString = selectedDate;

  const filterDate = `${new Date(
    selectedDateISOString
  ).getFullYear()}-${new Date(selectedDateISOString).getMonth() + 1}-${new Date(
    selectedDateISOString
  ).getDate()}`;



  const filteredData = allAppointmentList?.filter((appointment) => {
    const appointmentDate = `${new Date(
      appointment?.appointmentDate
    ).getUTCFullYear()}-${new Date(
      appointment?.appointmentDate
    ).getUTCMonth() + 1}-${new Date(appointment?.appointmentDate).getUTCDate()}`;

    return appointmentDate === filterDate;
  });

  const filteredAppointments = filteredData?.length > 0 ? filteredData : [];

  const appointmentsByStaff = filteredAppointments?.filter((appointment) =>
    appointment.services?.some(
      (service) => service.staffId?._id === selectedStaff
    )
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase().trim();
    const searchResults = filteredAppointments?.filter(
      (ele) =>
        ele?.customerName?.toLowerCase().includes(searchValue) ||
        ele?.customerPhone?.toLowerCase().includes(searchValue)
    );
    setSearchData(e.target.value);
    setResults(searchResults);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleClear = () => {
    setSearchData("");
    setResults([]);
    setCurrentPage(1); // Reset to first page on clear
  };

  const handleAppointment = (value) => {
    let filterAppointment;
    setStatus(value)

    if (value === "Quick inquiry") {
      filterAppointment = filteredAppointments.filter((item) => {
        return item.inquiry === true
      });
      setSelectedAppointmentType("Quick inquiry");
    } else {
      filterAppointment = filteredAppointments;
      setSelectedAppointmentType(null);
    }

    setSelectedStaff(filterAppointment);
    setCurrentPage(1); // Reset to first page on staff select
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  console.log("selectedStaff selectedStaff selectedStaff", selectedStaff)

  const displayAppointments = selectedAppointmentType === "Quick inquiry"
    ? selectedStaff
    : filteredAppointments;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = displayAppointments?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const rowData = currentItems?.map((data) => ({
    Name: data?.customerName,
    Time: new Date(data?.appointmentDate).toLocaleTimeString(
      [],
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    ),
    Date: data?.appointmentDate?.slice(0, 10),
    Phone: data?.customerPhone,
    Services: data?.services?.map(service => service?.serviceId?.name),
  }));
  const headerData = [
    { label: "Name", field: "Name" },
    { label: "Time", field: "Time" },
    { label: "Date", field: "Date" },
    { label: "Phone", field: "Phone" },
    { label: "Services", field: "Services" },
  ];

  return (
    <div>
      {filteredAppointments?.length > 0 ? (
        <div>
          <div
            className="px-5 py-5 bg-white w-full flex justify-between border border-collapse"
          >
            <div className="font-bold text-2xl tracking-wide w-full pb-10 md:pb-0">
              Appointments
            </div>


            <div className="w-30 absolute right-10 top-32 md:top-16">
              <InvoiceDropdown
                items={["Appointment", "Quick inquiry"]}
                onSelect={handleAppointment}
                autoComplete="off"
                selectedItem={status}
                label={"Select Appointment"}
                className="w-full"
              />
            </div>
          </div>
          <div>
            <div className="rounded-t-lg overflow-x-auto">
              <table className="min-w-full leading-normal border border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Date
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Time
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Phone
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Name
                    </th>
                    <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                      Services
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems?.map((appo, index) => (
                    <tr
                      key={`appo-${index}`}
                      className={`${appo?.isDeleted
                        ? "hover:bg-gray-100 border-b border-gray-200 opacity-50"
                        : "hover:bg-gray-100 border-b border-gray-200"
                        } py-4 cursor-pointer`}
                      onClick={(e) => handleRowClick(e, appo)}
                    >
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {appo?.appointmentDate?.slice(0, 10)}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {formatTime(appo?.appointmentDate)}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {appo?.customerPhone?.slice(3)}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {appo.customerName}
                        </div>
                      </td>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <ul className="list-disc list-inside">
                          {appo?.services?.map((service, index) => (
                            <li
                              key={`service-${index}`}
                              className="py-1 flex items-center"
                            >
                              <span className="font-semibold uppercase">
                                {service?.serviceId?.name}
                              </span>
                              <span className="mx-2 text-gray-500"> --- </span>
                              <span className="textlue-600">
                                {service?.staffId?.userId?.firstName}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg border border-collapse overflow-x-auto ">
              <span className="text-xs xs:text-sm text-gray-900">
                Showing {indexOfFirstItem + 1} to{" "}
                {Math.min(indexOfLastItem, displayAppointments?.length)} of{" "}
                {displayAppointments?.length} Entries
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className={`text-sm ${currentPage === 1
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                    } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                >
                  Prev
                </button>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={
                    currentPage ===
                    Math.ceil(displayAppointments?.length / itemsPerPage)
                  }
                  className={`text-sm ${currentPage ===
                    Math.ceil(displayAppointments?.length / itemsPerPage)
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                    } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center text-xl font-bold">
          {searchData && results?.length === 0
            ? "No Appointments Found"
            : selectedStaff && appointmentsByStaff?.length === 0
              ? "No Appointments Found For The Selected Staff"
              : "No Appointments For Today"}
        </div>
      )}
    </div>
  );
};

export default AppointmentTable;
