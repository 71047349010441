import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import axios from "axios";
import regex from "../../../Screens/Common/validation";
// import { clear } from "@testing-library/user-event/dist/clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const EditDiscount = (props) => {
  const [isLoading, setIsLoading] = useState();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [active, setActive] = useState("");
  const [activeErr, setActiveErr] = useState("");
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const clearInputField = () => {
    setName("");
    setNameErr("");
    setAmount("");
    setAmountErr("");
    setDescription("");
    setDescriptionErr("");
  };

  useEffect(() => {
    setName(props?.initialValue?.name || "");
    setActive(props?.initialValue?.isActive || "");
    setAmount(props?.initialValue?.amount);
    setDescription(props?.initialValue?.description || "");
  }, [props.handleOpenEditDiscount] || "");

  const handleEdit = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameErr(true);
    } else if (!regex.priceRegex.test(amount)) {
      setAmountErr(true);
    } else if (!description) {
      setDescriptionErr(true);
    } else if (active === "") {
      setActiveErr(true);
    } else {
      let data = JSON.stringify({
        name: name,
        isActive: active,
        amount: amount,
        description: description,
      });

      let config = {
        method: "put",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/discount/update/${props.id}`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      setIsLoading(true);
      axios
        .request(config)
        .then((response) => {
          props?.fetchDiscounts();
          props?.handleClear();
          props?.setOpenEditDiscount(false);
          props?.tostifySuccess(response?.data?.message);
          setIsLoading(false);
          clearInputField();
        })
        .catch((error) => {
          setIsLoading(false);
          props?.setOpenEditDiscount(false);
          props?.tostifyErr(error?.response?.data?.error);
          clearInputField();
        });
    }
  };

  return (
    <React.Fragment >
      <Modal
        open={props.openEditDiscount}
        onClose={() => {
          clearInputField();
          props.setOpenEditDiscount(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[400px] min-w-[380px] sm:min-w-[450px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenEditDiscount(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Edit Discount Details
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleEdit(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErr(false);
                    }}
                    className={
                      nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Name Of Discount"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Amount
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setAmountErr(false);
                    }}
                    className={
                      amountErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Discount Amount"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    description
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setDescriptionErr(false);
                    }}
                    className={
                      descriptionErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Discount Description"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Status
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <select
                    id="status"
                    value={active}
                    onChange={(e) => {
                      setActive(e.target.value);
                      setActiveErr(false);
                    }}
                    className={
                      activeErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Edit</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default EditDiscount;
