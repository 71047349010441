import React, { useState } from "react";
import { PiListMagnifyingGlassFill } from "react-icons/pi";
import { RiEdit2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import ViewPackages from "./SubscriptionModal/ViewPackages";

const SubscriptionsTable = () => {
  const [openViewPackageModal, setOpenViewPackageModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <React.Fragment>
      <ViewPackages
        openViewPackageModal={openViewPackageModal}
        setOpenViewPackageModal={setOpenViewPackageModal}
      />

      {/* <AddDiscount
                openAddDiscountModal={openAddDiscountModal}
                setOpenAddDiscountModal={setOpenAddDiscountModal}
            /> */}

      <div className="mb-2 flex justify-end">
        <button
          onClick={() => setOpenViewPackageModal(true)}
          className="mt-4 h-12 w-48 flex content-center items-center justify-center text-md font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
        >
          {0 ? (
            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
          ) : (
            <span className="flex justify-center items-center">
              <PiListMagnifyingGlassFill size={22} />
              &nbsp; View Packages
            </span>
          )}
        </button>
      </div>

      <div className="mx-auto mt-4">
        {isLoading ? (
          <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900"></span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="animate-pulse h-2  flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l"></button>
                <button className="animate-pulse h-2  flex items-center text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r"></button>
              </div>
            </div>
          </div>
        ) : (
          <div className="shadow rounded-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Type
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Start Date
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Validity
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Transaction
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Amount
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          Diwali
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          10 Jul 2023
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          1 year
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          Card
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          2000
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          Paid
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          <RiEdit2Fill
                            size={15}
                            className="mr-4 text-[#43434A] hover:text-[#232326] transition ease-in-out hover:duration-300"
                          />
                          <RiDeleteBin5Fill
                            className="text-[#FF7866] hover:text-primaryHoverColor transition ease-in-out hover:duration-300"
                            size={15}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
              <span className="text-xs xs:text-sm text-gray-900">
                Showing 1 to 4 of 50 Entries
              </span>
              <div className="inline-flex mt-2 xs:mt-0">
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                  Prev
                </button>
                <button className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SubscriptionsTable;
