import React from 'react';
import DiscountsTable from '../../Components/Discounts/DiscountsTable';

const Discounts = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Discounts`;

  return (
    <React.Fragment>
      <div className="font-bold text-2xl mt-4 tracking-wide w-full">
        Discounts
      </div>

      <DiscountsTable />
    </React.Fragment>
  )
}

export default Discounts
