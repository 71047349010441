import React, { useState } from "react";
import { FaSortDown } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { decryptData, encryptItems, getEncryptItems } from "../Common/localStorageUtils";
import kaya from "../../Assets/Frame 427321207.png";
import { tostifySuccess } from "../Common/tostifyMessages";
import { useDispatch } from "react-redux";
import { setBranch } from "../../state-management/main/actions";


const BranchSwitch = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const allBranch = decryptData()?.organizationData;
    const decryptedBranch = getEncryptItems("branchDetails");
    const dispatch = useDispatch();

    const profileDropdown = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const profileDropdownClose = () => {
        setAnchorEl(null);
    };

    const handleSwitchBranch = (v) => {
        if (decryptedBranch?._id === v?._id) {
            tostifySuccess("Already In The Same Branch");
        } else {
            dispatch(setBranch(v));
            encryptItems(v, "branchDetails");
            tostifySuccess("Branch Switched Successfully");
            // setTimeout(() => {
            //     window.location.reload();
            // }, 1000);
        }
    }

    return (
        <div>
            <div
                onClick={profileDropdown}
                className="flex items-center justify-center transition ease-in-out hover:text-primaryColor hover:duration-300 cursor-pointer"
            >
                <span className="capitalize flex gap-2 hover:text-primaryColor text-xl text-[#4B465C] font-bold ">
                    <img src={decryptedBranch?.url || kaya} alt="" height={20} width={25} className="h-8 w-auto md:flex hidden" />
                    {decryptedBranch?.name || allBranch?.[0]?.name}
                </span>


                <FaSortDown size={20} className="mb-2" />
            </div>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={profileDropdownClose}
            >
                {allBranch?.map((v, i) => {
                    const isSelected = decryptedBranch?._id === v?._id;
                    return (
                        <MenuItem key={v?._id} onClick={() => {
                            handleSwitchBranch(v);
                            profileDropdownClose();
                        }}
                            style={{ backgroundColor: isSelected ? "#EFD3C3" : "white" }}
                        >
                            <img src={v?.url || kaya} alt="branch Logo" height={20} width={25} className="h-7 w-7 object-cover  " />
                            <span className="ml-3 capitalize ">
                                {v?.name}
                            </span>
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    );
};

export default BranchSwitch;
