import * as actionTypes from "./actionTypes";

const initialState = {
  appointmentList: null,
  productList: null,
};

let newState;

const staffReducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.ALL_APPOINTMENT_LIST: {
      newState = {
        ...state,
        appointmentList: action.payload,
      }
      return newState;
    }

    case actionTypes.ALL_PRODUCT_LIST: {
      newState = {
        ...state,
        productList: action.payload,
      }
      return newState;
    }

    default:
      return state;
  }
};

export default staffReducer;