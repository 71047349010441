import { jsPDF } from "jspdf";
import "jspdf-autotable";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import excel from "../../Assets/xls.png";
import pdf from "../../Assets/Pdf.png";
import ReportFilterType from "./ReportFilterType";

export const ReportsDynamicTable = ({ rowData, headerData ,}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rowData?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDownload = (format) => {
    switch (format) {
      case "CSV":
        handleDownloadCSV();
        break;
      case "PDF":
        handleDownloadPDF();
        break;
      default:
        break;
    }
  };

  const handleDownloadCSV = () => {
    const csv = [
      headerData.map((header) => header.label).join(","),
      ...currentItems.map((row) =>
        headerData.map((header) => row[header.field]).join(",")
      ),
    ].join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "table_data.csv");
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const tableData = rowData.map((row, index) => {
      const rowDataWithIndex = { "Sl. No.": index + 1 };
      headerData.forEach((header) => {
        rowDataWithIndex[header.label] = row[header.field];
      });
      return rowDataWithIndex;
    });
    const tableHeaders = [
      "Sl. No.",
      ...headerData.map((header) => header.label),
    ];

    // Add a title to the PDF
    doc.setFontSize(16);
    doc.text("Report Table", 20, 20);

    // Add a date to the PDF
    const date = new Date().toLocaleDateString();
    doc.setFontSize(12);
    doc.text(`Date: ${date}`, 20, 30);

    // Add the table content to the PDF using autoTable
    doc.autoTable({
      startY: 40,
      head: [tableHeaders],
      body: tableData.map((row) => Object.values(row)),
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [254, 135, 64] },
      alternateRowStyles: { fillColor: [250, 250, 250] },
    });

    // Save the PDF
    doc.save("table_data.pdf");
  };

  if (rowData?.length === 0) {
    return (
      <div className="flex justify-center bg-[#fff] shadow-md py-4 capitalize border-t">
        No reports available{" "}
      </div>
    );
  }

  return (
    <>
      <div className=" min-w-full mx-auto divide-gray-200 shadow-md">
        {/* <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4">
          <div className="font-bold text-2xl tracking-wide">Reports</div>

          <div>
            <ReportFilterType />
          </div>

          <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
            <img
              src={excel}
              alt="pdf"
              onClick={() => handleDownload("CSV")}
              className="cursor-pointer w-7 h-7"
              title="Download CSV"
            />
            <img
              src={pdf}
              alt="pdf"
              onClick={() => handleDownload("PDF")}
              className="cursor-pointer w-7 h-7"
              title="Download PDF"
            />
          </div>
        </div> */}

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y  p-2 dynamic-table">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left border-2 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sl. No.
                </th>
                {headerData?.map((header, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-left border-2 text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm border-[1px] text-gray-900">
                    {indexOfFirstItem + rowIndex + 1}
                  </td>
                  {headerData?.map((header, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ whiteSpace: "pre-line" }}
                      className="px-6 py-4 whitespace-nowrap text-sm border-[1px] text-gray-900"
                    >
                      {row[header?.field]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
          <span className="text-xs xs:text-sm text-gray-900">
            Showing {indexOfFirstItem + 1} to{" "}
            {Math.min(indexOfLastItem, rowData?.length)} of {rowData?.length}{" "}
            Entries
          </span>
          <div className="inline-flex mt-2 xs:mt-0">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`text-sm ${
                currentPage === 1
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
              } text-gray-800 font-semibold py-2 px-4 rounded-l`}
            >
              Prev
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(rowData?.length / itemsPerPage)
              }
              className={`text-sm ${
                currentPage === Math.ceil(rowData?.length / itemsPerPage)
                  ? "bg-gray-200"
                  : "bg-gray-300 hover:bg-gray-400"
              } text-gray-800 font-semibold py-2 px-4 rounded-r`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
