import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { decryptData, getEncryptItems } from "../../Screens/Common/localStorageUtils";
import { tostifyErr, tostifySuccess } from "../../Screens/Common/tostifyMessages";
import regex from "../../Screens/Common/validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const AddPackage = (props) => {
  const decryptedData = decryptData();
  const adminId = decryptedData?.userTypeData?._id;
  const branch = getEncryptItems("branchDetails");
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const serviceList = useSelector((state) => state?.main?.inventoryService)
    ?.filter(item => item?.organizationId?._id === branch?._id);
  const organization = useSelector((state) => state?.main?.orgList);
  const orgByAdminId = organization?.filter((org) => org._id === branch?._id);
  const [isLoading, setIsLoading] = useState(false);
  const [durations, setDurations] = useState([{ time: '', quantity: '' }]);
  const [formData, setFormData] = useState({
    name: "",
    nameErr: "",
    orgId: branch?._id || "",
    packagePrice: "",
    packagePriceErr: "",
    validity: "",
    validityErr: "",
    NumberOfServices: "",
    NumberOfServicesErr: "",
  });

  const handleAddDuration = () => {
    setDurations([...durations, { min: '', quantity: '' }]);
  };

  const handleDeleteDuration = (index) => {
    const updatedDurations = [...durations];
    updatedDurations.splice(index, 1);
    setDurations(updatedDurations);
  };

  console.log("duration", durations);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedDurations = [...durations];
    updatedDurations[index][name] = value;
    setDurations(updatedDurations);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      [`${name}Err`]: ""
    });
  };

  const clearInputField = () => {
    setFormData({});
  };

  const handleAddPackage = async (e) => {
    e.preventDefault();
    if (!formData.name) {
      setFormData(prev => ({
        ...prev,
        nameErr: true,
      }))
    } else if (!regex.priceRegex.test(formData.packagePrice)) {
      setFormData(prev => ({
        ...prev,
        packagePriceErr: true,
      }))
    } else if (!regex.priceRegex.test(formData.validity)) {
      setFormData(prev => ({
        ...prev,
        validityErr: true,
      }))
    } else {
      let data = JSON.stringify({
        packageName: formData.name,
        validity: formData.validity,
        organizationId: branch?._id,
        adminId: adminId,
        packagePrice: formData.packagePrice,
        NumberOfServices: formData.NumberOfServices,
        duration: durations
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/package/add`,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        data: data,
      };

      setIsLoading(true);
      axios
        .request(config)
        .then((response) => {
          props?.fetchPackageData();
          props?.setOpenAddPackageModal(false);
          tostifySuccess(response?.data?.message);
          console.log("response", response?.data?.data);
          clearInputField();
          setIsLoading(false);
        })
        .catch((error) => {
          props?.setOpenAddPackageModal(false);
          tostifyErr(error?.response?.data?.message);
          // clearInputField();
          setIsLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openAddPackageModal}
        onClose={() => {
          props.setOpenAddPackageModal(false);
          // clearInputField();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className=" h-auto min-w-[500px] max-h-[450px] overflow-y-scroll overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddPackageModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Package
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddPackage(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Package Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={
                      formData.nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Package Name"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Package Price
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="price"
                    value={formData.packagePrice}
                    name="packagePrice"
                    onChange={handleInputChange}
                    className={
                      formData.packagePriceErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter Price"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Package Validity (No. Of Months)
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="number"
                    id="validity"
                    name="validity"
                    min="0"
                    value={formData.validity}
                    onChange={handleInputChange}
                    className={
                      formData.validityErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter No. of Months"
                  ></input>
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    No. Of Services
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="number"
                    id="noOfSErvices"
                    name="NumberOfServices"
                    min="0"
                    value={formData.NumberOfServices}
                    onChange={handleInputChange}
                    className={
                      formData.NumberOfServicesErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Enter No. of Services"
                  ></input>
                </div>
                {durations.map((duration, index) => (
                  <div key={index} className="flex flex-col md:flex-row md:items-center md:gap-6">
                    <div className="mb-4 md:mb-0">
                      <label htmlFor="service" className="text-[#132c4a] block text-sm mb-2">Duration Description:</label>
                      <input
                        type="text"
                        name="time"
                        value={duration.time}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Enter duration"
                        className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      />
                    </div>
                    <div className="grid grid-cols-1">
                      <label htmlFor="staff" className="text-[#132c4a] block text-sm mb-2">Quantity:</label>
                      <input
                        type="text"
                        name="quantity"
                        value={duration.quantity}
                        onChange={(e) => handleChange(index, e)}
                        placeholder="Enter Quantity"
                        className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                      />
                    </div>
                    <div className="flex items-center mt-5">
                      {index > 0 && (
                        <span onClick={() => handleDeleteDuration(index)} className="text-3xl cursor-pointer text-[#FF782D] hover:text-red-600 transition-colors">
                          -
                        </span>
                      )}
                      <span className="ml-2  text-3xl cursor-pointer text-[#FF782D] hover:text-red-600 transition-colors" onClick={handleAddDuration}>
                        +
                      </span>
                    </div>
                  </div>
                ))}

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Create</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddPackage;
