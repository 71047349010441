import React from 'react'
import AdminNotificationHistoryTable from '../../Components/NotificationHistory/AdminNotificationHistoryTable'

const AdminNotificationHistory = () => {
    document.title = `${process.env.REACT_APP_NAME} | Admin - Service Status`;
    return (<React.Fragment>
        <div className="font-bold text-2xl mt-4 tracking-wide w-full">
            Service Status
        </div>
        <AdminNotificationHistoryTable />
    </React.Fragment>
    )
}

export default AdminNotificationHistory