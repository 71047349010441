import React, { useState } from "react";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import excel from "../../../Assets/xls.png";
import pdf from "../../../Assets/Pdf.png";
import html2pdf from "html2pdf.js";
import ReportFilterType from "../ReportFilterType";
import { useSelector } from "react-redux";
import Dropdown from "../../../Screens/Common/Dropdown";
import DownloadReport from "../DownloadReport";
import { FaFileInvoiceDollar } from "react-icons/fa";

const SalesReport = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const branch = useSelector((state) => state?.main?.branch);
  const allInvoices = useSelector((state) => state?.main?.billList)?.filter(
    (item) => item?.organizationId === branch?._id
  );
  const branchName = branch?.name || "";
  const branchLocation = branch?.address || "";
  const branchPhone = branch?.primaryPhone || "";
  const branchPic = branch?.url;

  const today = new Date();
  const last7days = new Date(today);
  last7days.setDate(today.getDate() - 7);
  const last30days = new Date(today);
  last30days.setDate(today.getDate() - 30);

  //<-----------------------------Sale filter Data ----------------------->

  const filterByDayOrWeek =
    selectedCategory === "day"
      ? allInvoices?.filter(
        (item) => item?.date === new Date()?.toISOString()?.slice(0, 10)
      )
      : selectedCategory === "week"
        ? allInvoices?.filter((item) => new Date(item?.date) >= last7days)?.sort((a, b) => new Date(b.date) - new Date(a.date))
        : selectedCategory === "month"
          ? allInvoices?.filter((item) => new Date(item?.date) >= last30days)?.sort((a, b) => new Date(b.date) - new Date(a.date))
          : selectedCategory === "customRange"
            ? allInvoices?.filter((item) => {
              const itemDate = new Date(item?.date).getTime();
              const startTimestamp = new Date(startDate).getTime();
              const endTimestamp = new Date(endDate).getTime();
              return itemDate >= startTimestamp && itemDate <= endTimestamp;
            })
            : allInvoices;

  const filteredInvoices =
    subcategory === "daily"
      ? filterByDayOrWeek?.filter(
        (item) => new Date(item?.date).getDate() === new Date().getDate()
      )
      : subcategory === "service"
        ? filterByDayOrWeek?.filter((item) => item?.services?.length > 0)
        : subcategory === "product"
          ? filterByDayOrWeek?.filter((item) => item?.products?.length > 0)
          : subcategory === "members"
            ? filterByDayOrWeek?.filter((item) => item?.membership)
            : filterByDayOrWeek;

  const invoiceHeaderData = [
    { label: "Customer", field: "customer" },
    { label: "Phone", field: "phone" },
    { label: "Date", field: "date" },
    { label: "Billed", field: "paidAmount" },
  ];
  const invoiceRowData = filteredInvoices?.map((invoice) => ({
    customer: ` ${invoice?.billingInfo?.name}`,
    phone: ` ${invoice?.billingInfo?.phone}`,
    date: invoice?.date,
    paidAmount: invoice?.paidAmount,
  }));

  const handleSelect = (value) => {
    setsubcategory(value);
  };

  const downloadInvoicesAsPDF = () => {
    const container = document.createElement('div');

    filteredInvoices.forEach((invoice, index) => {
      const invoiceHTML = `
        <div class="invoice" style="page-break-after: always; font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px;">
          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
            <img src='${branchPic}' alt="Kaya Spa" style="max-width: 100px;">
            <div style="text-align: right;">
              <h2 style="margin: 0;">INVOICE</h2>
              <p style="margin: 5px 0;">Invoice No. - ${invoice?.invoiceNumber}</p>
              <p style="margin: 5px 0;">Date/Time - ${new Date(invoice?.date).toLocaleDateString()}, ${new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })}</p>
            </div>
          </div>
          
          <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
            <div>
              <h3 style="margin: 0 0 10px 0;">Invoice From:</h3>
              <p style="margin: 0;">${branchName}</p>
              <p style="margin: 0;">AT - ${branchLocation}</p>
              <p style="margin: 0;">Mob - ${branchPhone}</p>
            </div>
            <div style="text-align: right;">
              <h3 style="margin: 0 0 10px 0;">Invoice To:</h3>
              <p style="margin: 0;">${invoice?.billingInfo?.name || ""}</p>
              <p style="margin: 0;">Mob - ${invoice?.billingInfo?.phone || ""}</p>
              <p style="margin: 0;">Wallet Bal.: Rs.${invoice?.walletApplied?.toFixed(2) || "0.00"}/-</p>
              <p style="margin: 0;">Loyalty Point Bal: 0.00/-</p>
            </div>
          </div>
          
                  <table style="width: 100%; border-collapse: collapse; margin-bottom: 20px; border: 1px solid #ddd;">
          <tr style="background-color: #f2994a; color: white;">
            <th style="padding: 10px; text-align: left; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">SL NO</th>
            <th style="padding: 10px; text-align: left; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">SERVICES</th>
            <th style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">QTY</th>
            <th style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">AMOUNT</th>
            <th style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">DISCOUNT</th>
            <th style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">TOTAL AMOUNT</th>
          </tr>
          ${invoice?.services?.map((service, index) => `
            <tr style="background-color: ${index % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.serviceName} (${service?.duration})</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${service?.itemPerPrice * service?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${service?.discount} ${service?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${service?.price}</td>
            </tr>
          `).join('')}
          ${invoice?.products?.map((product, index) => `
            <tr style="background-color: ${(invoice?.services?.length + index) % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${invoice?.services?.length + index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.productName}</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${product?.itemPerPrice * product?.quantity}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${product?.discount} ${product?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${product?.price}</td>
            </tr>
          `).join('')}
          ${invoice?.membershipIds?.map((member, index) => `
            <tr style="background-color: ${(invoice?.services?.length + invoice?.products?.length + index) % 2 === 0 ? '#f9f9f9' : 'white'};">
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${invoice?.services?.length + invoice?.products?.length + index + 1}</td>
              <td style="padding: 10px; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${member?.packageName || ""}</td>
              <td style="padding: 10px; text-align: center; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">1</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">₹${member?.price || 0}</td>
              <td style="padding: 10px; text-align: right; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;">${member?.discount || 0} ${member?.discountType === "percentage" ? "%" : ""}</td>
              <td style="padding: 10px; text-align: right; border-bottom: 1px solid #ddd;">₹${(member?.price || 0) - (member?.discount || 0)}</td>
            </tr>
          `).join('')}
        </table>
          
          <div style="margin-bottom: 20px;">
            <p style="margin: 5px 0; text-align: right;">Amount: ₹${invoice?.finalPrice}</p>
            <p style="margin: 5px 0; text-align: right;">Total (Rounded off): ₹${invoice?.finalPrice}</p>
            <p style="margin: 5px 0; text-align: right;">Paid Amount: ₹${invoice?.paidAmount}</p>
          </div>
          
          <div>
            <h3 style="margin: 0 0 10px 0;">Terms & Conditions</h3>
            <ul style="margin: 0; padding-left: 20px;">
              <li>Money Once Paid Shall not be refunded</li>
              <li>Services are not transferable</li>
            </ul>
          </div>
        </div>
      `;

      container.innerHTML += invoiceHTML;
    });

    const opt = {
      margin: 10,
      filename: 'invoices.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(container).set(opt).save();
  };

  return (
    <React.Fragment>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide lg:block hidden">
          Reports
        </div>

        <div>
          <ReportFilterType
            setSelectedCategory={setSelectedCategory}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            subcategory={subcategory}
          />
        </div>

        <div>
          <Dropdown
            label="All"
            items={[
              "All",
              "daily",
              "customer",
              "service",
              "product",
              "members",
            ]}
            onSelect={handleSelect}
          />
        </div>

        {/* <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
          <img
            src={excel}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download CSV"
          />
          <img
            src={pdf}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download PDF"
          />
        </div> */}

        <div className="flex gap-2">
          {selectedCategory === "day" && <button
            onClick={downloadInvoicesAsPDF} className="btn btn-primary">
            <FaFileInvoiceDollar size={28} className="text-primaryColor" />
          </button>}

          <DownloadReport
            rowData={invoiceRowData}
            headerData={invoiceHeaderData}
          />
        </div>
      </div>
      <ReportsDynamicTable
        rowData={invoiceRowData}
        headerData={invoiceHeaderData}
      />
    </React.Fragment>
  );
};

export default SalesReport;
