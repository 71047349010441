import React, { useCallback } from "react";
import { decryptData } from "../Common/localStorageUtils";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { allStaffList } from "../../state-management/admin/actions";
import StaffDetail from "../../Components/Staff/StaffDetail";

const Staff = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Staff`;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const branch = useSelector(state => state?.main?.branch);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const decryptedData = decryptData();
  const adminId = decryptedData?.userTypeData?._id;

  const fetchStaffData = useCallback(async () => {
    setIsLoading(true);
    try {
      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/staff/find-staff`,
        headers: {
          Authorization: token,
        },
      };

      const res = await axios.request(config);
      const staffWithAdmin = res?.data?.data?.filter(
        (stf) => stf.adminId === adminId
      );
      dispatch(allStaffList(staffWithAdmin));
      setIsLoading(false);
    } catch (error) {
      console.log("Staffs Error:", error);
      setIsLoading(false);
    }
  }, [dispatch, token, adminId,branch]);

  useEffect(() => {
    fetchStaffData();
  }, [fetchStaffData]);

  return <StaffDetail fetchStaffData={fetchStaffData} isLoading={isLoading} />;
};

export default Staff;
