import React from 'react'

const DashBoardCard = ({ icon: Icon, title, value, onClick }) => {
    return (
        <div
            onClick={title === "Appointments" ? onClick : null}
            className={`w-44 bg-white rounded-lg pt-1 pb-1 px-2 ${title === "Appointments" ? 'cursor-pointer': 'cursor-default'}`}
            style={{ boxShadow: "8px 8px 20px rgba(0, 0, 0, 0.1)" }}
        >
            <div className="w-full flex flex-col items-center">
                <div className="w-12 h-12 flex justify-center items-center bg-[#ffebdf] shadow rounded-full">
                    <Icon size={25} className="text-primaryColor" />
                </div>
                <div className="font-semibold text-md text-[#7f8296] text-center mt-3 mb-1 tracking-wide">
                    {title}
                </div>
            </div>
            <div className="font-bold text-lg w-full text-center">
                {title !== "Appointments" && title !== "Active Staffs" && title !=="New Clients" && title !== "Old Clients" ? Number(value)?.toFixed(2) : value}
            </div>
        </div>
    )
}

export default DashBoardCard
