import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "../../../Screens/Common/localStorageUtils";
import regex from "../../../Screens/Common/validation";
import { allDiscountList } from "../../../state-management/main/actions";
import { fetchDiscounts } from "../../../Screens/Common/initData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const AddDiscount = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [type, setType] = useState();
  const [typeErr, setTypeErr] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [expireDateErr, setExpireDateErr] = useState("");
  const [orgId, setOrgId] = useState("");
  const [orgIdErr, setOrgIdErr] = useState("");
  const decryptedData = decryptData();
  const adminId = decryptedData?.userTypeData?._id;
  const organization = useSelector((state) => state?.main?.orgList);
  const orgByAdminId = organization?.filter((org) => org.adminId === adminId);
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const getAllDiscounts = async () => {
    const data = await fetchDiscounts();
    dispatch(allDiscountList(data));
  }

  const clearInputField = () => {
    setOrgId("");
    setOrgIdErr("");
    setName("");
    setNameErr("");
    setAmount("");
    setAmountErr("");
    setType("");
    setTypeErr("");
    setDescription("");
    setDescriptionErr("");
    setExpireDate("");
    setExpireDateErr("");
  };

  const handleAddDiscount = async (e) => {
    e.preventDefault();
    if (!name) {
      setNameErr(true);
    } else if (!description) {
      setDescriptionErr(true);
    } else if (!orgId) {
      setOrgIdErr(true);
    } else if (!type) {
      setTypeErr(true);
    } else if (!regex.priceRegex.test(amount)) {
      setAmountErr(true);
    } else if (!expireDate) {
      setExpireDateErr(true);
    } else {
      let data = JSON.stringify({
        name: name,
        amount: amount,
        type: type,
        description: description,
        organization: orgId,
        expireDate: expireDate,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/discount/create`,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: data,
      };

      setIsLoading(true);
      await axios
        .request(config)
        .then((response) => {
          props.fetchDiscounts();
          props.tostifySuccess(response?.data?.message);
          props.setOpenAddDiscountModal(false);
          getAllDiscounts();
          clearInputField();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("add discount error:", error);
          props.tostifyErr(error?.response?.data?.message);
          setIsLoading(false);
          // clearInputField();
          props.setOpenAddDiscountModal(false);
        });
    }
  };

  return (
    <React.Fragment >
      <Modal
        open={props.openAddDiscountModal}
        onClose={() => {
          // clearInputField();
          props.setOpenAddDiscountModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar max-h-[400px] overflow-x-auto px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenAddDiscountModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">
                Add Discount
              </div>
            </div>

            <div className="flex-auto mt-2">
              <form onSubmit={(e) => handleAddDiscount(e)}>
                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Discount Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErr(false);
                    }}
                    className={
                      nameErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Discount name"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Discount Description
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    id="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setDescriptionErr(false);
                    }}
                    className={
                      descriptionErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Description"
                  />
                </div>

                <div className="relative w-full mb-3">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Branch Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <select
                    id="orgId"
                    value={orgId}
                    onChange={(e) => {
                      setOrgId(e.target.value);
                      setOrgIdErr(false);
                    }}
                    className={
                      orgIdErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                    }
                  >
                    <option>Select Branch</option>

                    {orgByAdminId?.map((v, i) => {
                      return (
                        <option className="capitalize" key={i} value={v._id}>
                          {v.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="flex justify-center items-center mb-3 w-96">
                  <div className="relative w-full">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Discount Type
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <select
                      id="type"
                      value={type}
                      onChange={(e) => {
                        setType(e.target.value);
                        setTypeErr(false);
                      }}
                      className={
                        typeErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }
                    >
                      <option value="">Type</option>
                      <option value="value">Value</option>
                      <option value="percentage">Percentage</option>
                    </select>
                  </div>

                  <div className="relative w-full ml-6">
                    <div className="text-[#132c4a] block text-sm mb-2">
                      Discount Amount
                      <span className="text-red-600 text-lg"> *</span>
                    </div>
                    <input
                      type="text"
                      id="amount"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setAmountErr(false);
                      }}
                      className={
                        amountErr
                          ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                          : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                      }
                      placeholder="00"
                    />
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="text-[#132c4a] block text-sm mb-2">
                    Expiry Date
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="date"
                    id="expDate"
                    value={expireDate}
                    onChange={(e) => {
                      setExpireDate(e.target.value);
                      setExpireDateErr(false);
                    }}
                    className={
                      expireDateErr
                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    placeholder="Select Date"
                  />
                </div>

                <div className="w-full flex items-center justify-center mt-5">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                  >
                    {isLoading ? (
                      <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                    ) : (
                      <span>Add</span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddDiscount;
