import React, { useEffect, useState } from 'react'
import SkeletonTableLoader from '../../Screens/Common/Loading/SkeletonTableLoader';
import { ImSearch } from 'react-icons/im';
import add from '../../Assets/AddFrame(1).png'
import DownloadTableData from '../../Screens/Common/downloadData';
import { RiDeleteBin5Fill, RiEdit2Fill } from 'react-icons/ri';
import AddGiftCard from './MembershipModals/AddGiftCard';
import { getEncryptItems } from '../../Screens/Common/localStorageUtils';
import axios from 'axios';
import EditGiftCard from './MembershipModals/EditGiftCard';
import { useSelector } from 'react-redux';

const GiftCard = () => {
  const [openAddFiftCard, setOpenAddGiftCard] = useState(false);
  const [openEditFiftCard, setOpenEditGiftCard] = useState(false);
  const [giftCards, setGiftCards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [results, setResults] = useState([]);
  const [editableGiftCard, setEditableGiftCard] = useState({});
  const branch = useSelector(state => state?.main?.branch)?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = results?.length
    ? results?.slice(
      indexOfFirstItem,
      indexOfLastItem)
    : giftCards?.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  useEffect(() => {
    getAllFiftCards()
  }, [branch])

  const handleOpenEdit = (id) => {
    const gift = giftCards?.find(ele => ele?._id === id);
    setEditableGiftCard(gift);
    setOpenEditGiftCard(true);
  }

  const getAllFiftCards = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/giftCard/find-giftCards/${branch}`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        setGiftCards(response?.data?.data?.giftCards);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  const handleSearch = (e) => {
    setSearchData(e.target.value);
    const result = giftCards?.filter(ele => ele?.name?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()));
    setResults(result);
  }

  const headers = ['Sl', 'Name', 'Code', 'Amount', 'Description', 'Offer Period', 'Price', 'Actions']
  const headerData = [
    { label: "Name", field: "Name" },
    { label: "Code", field: "Code" },
    { label: "Amount", field: "Amount" },
    { label: "Description", field: "Description" },
    { label: "OfferPeriod", field: "OfferPeriod" },
    { label: "Price", field: "Price" },
  ];
  const rowData = giftCards?.map(data => ({
    Name: data?.name,
    Code: data?.code,
    Amount: data?.amount,
    Description: data?.description,
    OfferPeriod: data?.startDate?.slice(0, 10) + " - " + data?.endDate?.slice(0, 10),
    Price: data?.price
  }));
  return (
    <>
      <AddGiftCard
        openAddFiftCard={openAddFiftCard}
        setOpenAddGiftCard={setOpenAddGiftCard}
        getAllFiftCards={getAllFiftCards}
      />

      {editableGiftCard &&
        <EditGiftCard
          openEditFiftCard={openEditFiftCard}
          setOpenEditGiftCard={setOpenEditGiftCard}
          editableGiftCard={editableGiftCard}
          getAllFiftCards={getAllFiftCards}
          handleOpenEdit={handleOpenEdit}
        />}
      <div className="mx-auto mt-4">
        <div className="px-5 py-5 bg-white w-[100%] border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap">
          <div className="font-bold text-2xl tracking-wider">
            GiftCards
          </div>

          <div className="bg-white rounded-lg w-full xs:w-72 flex p-1 relative border border-gray-200 hover:border-primaryHoverColor hover:border-1">
            <span className="flex items-center justify-center transition ease-in-out duration-300 ml-2">
              <ImSearch color="gray" size={15} />
            </span>
            <input
              id="searchData"
              className="border-0 rounded px-4 py-1 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none w-full pr-6"
              placeholder="Search Gift Card"
              type="text"
              onChange={(e) => handleSearch(e)}
            />
          </div>

          <div className="font-bold text-2xl tracking-wider flex  justify-end gap-2">

            <div className="flex flex-row items-center justify-center gap-2 flex-wrap mb-2">
              <DownloadTableData
                rowData={rowData}
                headerData={headerData}
              />

              <>
                <button
                  onClick={() => setOpenAddGiftCard(true)}
                >
                  {0 ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span className="flex justify-center items-center">
                      <img
                        src={add}
                        alt="add"
                        className="text-primaryColor w-7 h-7"
                      />
                    </span>
                  )}
                </button>
              </>
            </div>
          </div>
        </div>
        {isLoading ? (
          <SkeletonTableLoader />
        ) : searchData && results?.length === 0 ?
          <div className='text-xl tracking-wide justify-center h-[200px] flex bg-white items-center'>
            No Gift Cards Found
          </div>
          : searchData && results?.length > 0 ? (
            <div className="shadow rounded-t-lg overflow-x-auto">
              <table className="min-w-full leading-normal">
                <thead>
                  <tr className="bg-gray-100">
                    {headers?.map((header, index) => (
                      <th key={index} className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {results?.map((v, i) => {
                    return (
                      <tr key={i}>
                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {i + 1}
                          </div>
                        </td>

                        <td
                          className="px-5 py-5 border text-primaryColor border-gray-200 bg-white text-sm capitalize cursor-pointer hover:underline hover:text-primaryHoverColor "
                        >
                          <div className="text-primaryColor hover:text-primaryHoverColor whitespace-no-wrap">
                            {v?.name}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.code}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.amount}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.price}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.startDate?.toString()?.substring(0, 10)} / {v?.endDate?.toString()?.substring(0, 10)}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="text-gray-900 whitespace-no-wrap">
                            {v?.price}
                          </div>
                        </td>

                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                          <div className="whitespace-no-wrap flex">

                            <RiEdit2Fill
                              size={15}
                              onClick={() => handleOpenEdit(v?._id)}
                              className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                            />


                            <RiDeleteBin5Fill
                              className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                              size={15}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) :
            (
              <div className="shadow rounded-t-lg overflow-x-auto">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr className="bg-gray-100">
                      {headers?.map((header, index) => (
                        <th key={index} className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {giftCards?.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {i + 1}
                            </div>
                          </td>

                          <td
                            className="px-5 py-5 border text-primaryColor border-gray-200 bg-white text-sm capitalize cursor-pointer hover:underline hover:text-primaryHoverColor "
                          >
                            <div className="text-primaryColor hover:text-primaryHoverColor whitespace-no-wrap">
                              {v?.name}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {v?.code}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {v?.amount}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {v?.price}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {v?.startDate?.toString()?.substring(0, 10)} / {v?.endDate?.toString()?.substring(0, 10)}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="text-gray-900 whitespace-no-wrap">
                              {v?.price}
                            </div>
                          </td>

                          <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                            <div className="whitespace-no-wrap flex">

                              <RiEdit2Fill
                                size={15}
                                onClick={() => handleOpenEdit(v?._id)}
                                className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                              />


                              <RiDeleteBin5Fill
                                className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                                size={15}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
        {(giftCards?.length !== 0 && (!searchData || (searchData && results?.length > 0))) ? (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {Math.min(indexOfLastItem, (searchData ? results.length : giftCards.length))} of{" "}
              {searchData ? results.length : giftCards.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${currentPage === 1
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil((searchData ? results.length : giftCards.length) / itemsPerPage)
                }
                className={`text-sm ${currentPage ===
                    Math.ceil((searchData ? results.length : giftCards.length) / itemsPerPage)
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                  } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default GiftCard