import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { userRoutes, authRoutes } from "./Routes/AllRoutes";
import NonAuthLayout from "./Routes/middleware/NonAuthLayout";
import AuthLayout from "./Routes/middleware/AuthLayout";
import Layout from "./Screens/Layout/Layout";
import FirstRenderingPage from "./Screens/Common/FirstRenderingPage";
import PageNotFound from "./Screens/Common/404";

const App = () => {
  const renderUserRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        path={route.path}
        element={
          <AuthLayout>
            <Layout>{route.component}</Layout>
          </AuthLayout>
        }
        key={idx}
      />
    ));
  };

  const renderAuthRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        path={route.path}
        element={<NonAuthLayout>{route.component}</NonAuthLayout>}
        key={idx}
      />
    ));
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FirstRenderingPage />} />
          {renderAuthRoutes(authRoutes)}
          {renderUserRoutes(userRoutes)}
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
