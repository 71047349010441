import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import icon from "../../Assets/imgpsh_fullsize_anim.png";
import { tostifyErr, tostifySuccess } from "../../Screens/Common/tostifyMessages";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const DeleteAsset = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const token = "Bearer " + localStorage.getItem("refresh_token");

    const handleDelete = async (_id) => {
        if (!reason.trim()) {
            setError("Please provide a reason for deletion.");
            return;
        }

        let config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/asset/delete/${_id}`,
            headers: {
                Authorization: token,
            },
            data: { reason }, // Sending reason along with delete request
        };

        setIsLoading(true);
        await axios
            .request(config)
            .then((response) => {
                let resStatus = response.status === 200;
                if (resStatus) {
                    tostifySuccess(response?.data?.message);
                    props?.fetchAssets();
                    props?.setId("");
                    props?.setCurrentPage(1);
                    props?.setOpenDeleteAsset(false);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("Delete Asset error:", error);
                props?.setOpenDeleteAsset(false);
                tostifyErr(error?.response?.data?.message);
                setIsLoading(false);
            });
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openDeleteAsset}
                onClose={() => { props?.setId(""); props.setOpenDeleteAsset(false); setReason(""); setError(""); }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="max-h-[400px] overflow-x-auto">
                        <div className="btn-wrapper text-center">
                            <div className="flex justify-center items-center">
                                <img
                                    src={icon}
                                    alt="delete img"
                                    className="h-12 w-12"
                                />
                            </div>
                            <div className="text-xl text-[#2b2f32] font-bold my-4">
                                Are you sure ?
                            </div>
                        </div>

                        <div className="flex-auto"></div>
                        <span className="block text-[#828282]">
                            Do you want to delete this Asset.
                        </span>

                        <div className="mt-4">
                            <textarea
                                placeholder="Reason for deletion"
                                value={reason}
                                onChange={(e) => { setReason(e.target.value); setError(""); }}
                                className="w-full p-2 border border-gray-300 rounded-md"
                                rows="4"
                            />
                            {error && (
                                <div className="text-red-600 mt-2 text-sm">
                                    {error}
                                </div>
                            )}
                        </div>

                        <div className="flex justify-center gap-4 mt-6 mb-2">
                            <button
                                onClick={() => { props?.setId(""); props.setOpenDeleteAsset(false); setReason(""); setError(""); }}
                                className="h-10 w-2/5 flex content-center items-center justify-center text-lg font-semibold bg-gray-200 hover:bg-gray-300 transition ease-in-out hover:duration-300 rounded-xl shadow-md shadow-[#ffdcc7]"
                            >
                                Cancel
                            </button>
                            <button
                                disabled={isLoading}
                                onClick={() => handleDelete(props?.id)}
                                className="h-10 w-2/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-md shadow-[#ffdcc7]"
                            >
                                {isLoading ? (
                                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>Delete</span>
                                )}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default DeleteAsset;
