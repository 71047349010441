import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReportFilterType from "../ReportFilterType";
import Dropdown from "../../../Screens/Common/Dropdown";
import excel from "../../../Assets/xls.png";
import pdf from "../../../Assets/Pdf.png";
import { ReportsDynamicTable } from "../ReportsDynamicTable";
import DownloadReport from "../DownloadReport";

const InvoiceReport = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subcategory, setsubcategory] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const branch = useSelector((state) => state?.main?.branch);
  const allInvoices = useSelector((state) => state?.main?.billList)?.filter(
    (item) => item?.organizationId === branch?._id
  );
  const today = new Date();
  const last7days = new Date(today);
  last7days.setDate(today.getDate() - 7);
  const last30days = new Date(today);
  last30days.setDate(today.getDate() - 30);
  const filterByDayOrWeek =
    selectedCategory === "day"
      ? allInvoices?.filter(
          (item) => new Date(item?.date).toISOString() === today.toISOString()
        )
      : selectedCategory === "week"
      ? allInvoices?.filter((item) => new Date(item?.date) >= last7days)
      : selectedCategory === "month"
      ? allInvoices?.filter((item) => new Date(item?.date) >= last30days)
      : selectedCategory === "customRange"
      ? allInvoices?.filter((item) => {
          const itemDate = new Date(item?.date).getTime();
          const startTimestamp = new Date(startDate).getTime();
          const endTimestamp = new Date(endDate).getTime();
          return itemDate >= startTimestamp && itemDate <= endTimestamp;
        })
      : allInvoices;

  const filteredInvoiceReports =
    subcategory === "taxInvoice"
      ? filterByDayOrWeek?.filter((obj) => parseFloat(obj.gstChargeAmount) > 0)
      : subcategory === "unpaid"
      ? filterByDayOrWeek?.filter((obj) => obj?.paymentStatus === "due")
      : filterByDayOrWeek;

  const invoicefilterHeaderData = [
    { label: "Customer", field: "customer" },
    { label: "Phone", field: "phone" },
    { label: "Date", field: "date" },
    { label: "Billed", field: "paidAmount" },
    { label: "DueAmount", field: "dueAmount" },
    { label: "Gst", field: "gstChargeAmount" },
  ];
  const invoicefilterRowData = filteredInvoiceReports?.map((invoice) => ({
    customer: ` ${invoice?.billingInfo?.name}`,
    phone: ` ${invoice?.billingInfo?.phone}`,
    date: invoice?.date,
    paidAmount: invoice?.paidAmount,
    dueAmount: invoice?.dueAmount,
    gstChargeAmount: invoice?.gstChargeAmount,
  }));

  const handleSelect = (value) => {
    setsubcategory(value);
  };

  return (
    <React.Fragment>
      <div className="px-5 py-5 bg-white min-w-full border-t flex flex-col xs:flex-row items-center justify-between rounded-t-lg flex-wrap gap-4 mt-6">
        <div className="font-bold text-2xl tracking-wide lg:block hidden">
          Reports
        </div>

        <div>
          <ReportFilterType
            setSelectedCategory={setSelectedCategory}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            subcategory={subcategory}
          />
        </div>

        <div>
          <Dropdown
            label="All"
            items={[
              "All",
              "taxInvoice",
              "unpaid",
              "product",
              // "staffPerformance",
            ]}
            onSelect={handleSelect}
          />
        </div>

        {/* <div className="font-bold text-2xl tracking-wide flex  justify-end gap-2">
          <img
            src={excel}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download CSV"
          />
          <img
            src={pdf}
            alt="pdf"
            className="cursor-pointer w-7 h-7"
            title="Download PDF"
          />
        </div> */}
        <DownloadReport
          rowData={invoicefilterRowData}
          headerData={invoicefilterHeaderData}
        />
      </div>
      {subcategory === "taxInvoice" && (
        <ReportsDynamicTable
          rowData={invoicefilterRowData}
          headerData={invoicefilterHeaderData}
        />
      )}
      {subcategory === "unpaid" && (
        <ReportsDynamicTable
          rowData={invoicefilterRowData}
          headerData={invoicefilterHeaderData}
        />
      )}
      {subcategory === "All" && (
        <ReportsDynamicTable
          rowData={invoicefilterRowData}
          headerData={invoicefilterHeaderData}
        />
      )}
    </React.Fragment>
  );
};

export default InvoiceReport;
