import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allClients,
  allService,
  allStaffs,
  allLeser,
  calculateLaser,
  appointmentWithoutLimit,
  allBillData,
  allMembershipRevenueData,
  fetchDiscounts,
  fetchSuppliers,
  fetchCustomerWithMembership,
  fetchProducts,
  fetchServiceHistory,
  getCustomerRoleById,
} from "../../Screens/Common/initData";
import {
  allClientList,
  allStaffList,
  allInventoryService,
  allLaserList,
  allAppointmentList,
  allBillList,
  allMembershipRevenue,
  allDiscountList,
  allSupplierList,
  allCustomerWithMembership,
  allProductList,
  serviceVsStaff,
  setBranch,
} from "../../state-management/main/actions";
import {
  decryptData,
  encryptItems,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";

const AuthLayout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const decryptedData = decryptData();
  const branch = useSelector(state => state?.main?.branch);
  const userRole = decryptedData?.user?.role || "dashboard";
  const { roleId, organizationId, userId, _id: staffId } =
    decryptedData?.userTypeData || {};
  const [userRoleName, setUserRoleName] = useState({
    roleId,
    organizationId,
    userId,
    staffId,
  });
  const refreshToken = localStorage.getItem("refresh_token");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const branchFromLocalStorage = getEncryptItems('branchDetails');
    dispatch(setBranch(branchFromLocalStorage));
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          staff,
          clients,
          services,
          products,
          lasers,
          appointments,
          bills,
          membershipRevenue,
          discounts,
          suppliers,
          customersWithMembership,
          serviceHistory,
        ] = await Promise.all([
          allStaffs(),
          allClients(),
          allService(),
          fetchProducts(),
          allLeser(),
          appointmentWithoutLimit(),
          allBillData(),
          allMembershipRevenueData(),
          fetchDiscounts(),
          fetchSuppliers(),
          fetchCustomerWithMembership(),
          fetchServiceHistory(),
        ]);

        dispatch(allStaffList(staff));
        dispatch(allClientList(clients));
        dispatch(allInventoryService(services));
        dispatch(allProductList(products));
        dispatch(allLaserList(lasers));
        dispatch(allAppointmentList(appointments));
        dispatch(allBillList(bills));
        dispatch(allMembershipRevenue(membershipRevenue));
        dispatch(allDiscountList(discounts));
        dispatch(allSupplierList(suppliers));
        dispatch(allCustomerWithMembership(customersWithMembership));
        dispatch(serviceVsStaff(serviceHistory));

        const role = decryptedData?.user?.role;
        const orgArray = decryptedData?.organizationData?.map(
          (element) => element._id
        );
        const orgIds =
          role === "admin"
            ? orgArray
            : [decryptedData?.userTypeData?.organizationId?._id];
        orgIds.forEach((org) => {
          calculateLaser(org);
        });

        if (role === "staff") {
          const customRole = await getCustomerRoleById(roleId);
          const updatedUserRoleName = { ...userRoleName, roleId: customRole };
          setUserRoleName(updatedUserRoleName);
          encryptItems(updatedUserRoleName);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (refreshToken) {
      fetchData();
    }
  }, [
    refreshToken,
    dispatch,
    branch,
    roleId,
    userRoleName,
    decryptedData?.user?.role,
    decryptedData?.organizationData,
    decryptedData?.userTypeData?.organizationId?._id,
  ]);

  if (!localStorage.getItem("encryptedData") && loading) {
    return <Navigate to="/login" />;
  }
  const pathSegments = currentPath.split("/");
  const extractedPath =
    pathSegments.length > 2 ? pathSegments[1] : pathSegments[1];

  if (!(extractedPath === userRole)) {
    navigate(`/${userRole}/dashboard`);
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthLayout;
