import React, { useState, useEffect, useRef } from 'react';
import { decryptData } from './localStorageUtils';

const Dropdown = ({ label, items, onSelect, itemName }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [element, setElement] = useState("");
    const dropdownRef = useRef(null);
    const branch = decryptData();

    useEffect(()=>{
        onSelect(items?.[0]);
    },[])

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (item) => {
        onSelect(item);
        setIsOpen(false);
        setElement(itemName === "staffList" ? item?.userId?.firstName : item);
    };

    const handleStaffSelect = (item, name) => {
        setElement(name);
        onSelect(item);
        setIsOpen(false);
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="px-2" ref={dropdownRef}>
            <div className="dropdown inline-block relative rounded-lg">
                <button
                    onClick={toggleDropdown}
                    className="text-[#132c4a] border-0 placeholder-[#718092] min-w-24 bg-white text-sm shadow font-semibold py-3 px-5 rounded inline-flex text-left gap-4 focus:outline-none focus:ring-1 ring-[#845ff6] ease-linear transition-all duration-150"
                >
                    <span className="mr-1">{element || label}</span>
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </button>
                <ul className={`dropdown-menu absolute no-scrollbar top-12 ${isOpen ? '' : 'hidden'} pt-1 shadow min-w-40 max-h-60 z-40 overflow-y-auto rounded-lg bg-white`}>
                    {itemName === "staffList" && (
                        <li
                            onClick={() => handleStaffSelect(branch?.adminId, "Admin")}
                            className={`text-[#132c4a] hover:text-white cursor-pointer bg-white hover:bg-primaryColor py-2 px-4 block whitespace-no-wrap rounded-t`}
                        >
                            Admin
                        </li>
                    )}
                    {itemName === "staffList"
                        ? items?.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => handleStaffSelect(item?._id, item?.userId?.firstName)}
                                className={`text-[#132c4a] hover:text-white ${index === 0 && !branch?.adminId ? 'rounded-t' : index === items?.length - 1 ? 'rounded-b' : ''} cursor-pointer bg-white hover:bg-primaryColor py-2 px-4 block whitespace-no-wrap`}
                            >
                                {item?.userId?.firstName}
                            </li>
                        ))
                        : items?.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => handleSelect(item)}
                                className={`text-[#132c4a] hover:text-white ${index === 0 ? 'rounded-t' : index === items?.length - 1 ? 'rounded-b' : ''} cursor-pointer bg-white hover:bg-primaryColor py-2 px-4 block whitespace-no-wrap`}
                            >
                                {item}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export default Dropdown;
