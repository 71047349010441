import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { decryptData } from '../../Screens/Common/localStorageUtils';
import axios from 'axios';

const AdminNotificationHistoryTable = () => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const serviceList = useSelector(state => state?.main?.inventoryService);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const adminId = decryptData()?.userTypeData?._id;

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = history?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(history?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    let isMounted = true;

    const fetchHistory = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_API_URL}/history/find`,
        headers: {
          'Authorization': token
        }
      };

      try {
        setIsLoading(true);
        const response = await axios.request(config);
        if (isMounted) {
          setHistory(response?.data?.data?.filter(item => item?.adminId === adminId));
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }

    fetchHistory();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="mx-auto mt-4">
      {isLoading ? (
        <div className="shadow rounded-t-lg overflow-x-auto">
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  <div className=" flex flex-row  items-center">
                    <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                  </div>
                </th>
                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  <div className=" flex flex-row  items-center">
                    <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                  </div>
                </th>
                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  <div className=" flex flex-row  items-center">
                    <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                  </div>
                </th>
                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  <div className=" flex flex-row  items-center">
                    <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                  </div>
                </th>
                <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  <div className=" flex flex-row  items-center">
                    <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5].map((v, i) => {
                return (
                  <tr key={i}>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded tracking-wider "></div>
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded tracking-wider "></div>
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded tracking-wider "></div>
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded tracking-wider "></div>
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className=" flex flex-row  items-center">
                        <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : history?.length === 0 ? (
        <div className="flex justify-center bg-[#fff] shadow-md py-4">
          No History found
        </div>
      ) : (
        <div className="shadow rounded-t-lg overflow-x-auto">
          <table className="min-w-full leading-normal">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Sl
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Service Name
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Time
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Date
                </th>
                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {history?.map((v, i) => {
                return (
                  <tr key={i}>
                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                      {(currentPage * 10 + i)-9}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {serviceList?.filter(item => v?.serviceId?.includes(item?._id))?.map(item => item?.name).join(',')}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.time}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.createdAt?.slice(0, 10)}
                      </div>
                    </td>

                    <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                      <div className="text-gray-900 whitespace-no-wrap">
                        {v?.isAccepted}
                      </div>
                    </td>

                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {currentItems?.length !== 0 ? (<div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
        <span className="text-xs xs:text-sm text-gray-900">
          Showing {indexOfFirstItem + 1} to{" "}
          {indexOfFirstItem + currentItems?.length} of {history?.length}{" "}
          Entries
        </span>
        <div className="inline-flex mt-2 xs:mt-0">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`text-sm ${currentPage === 1
              ? "bg-gray-200"
              : "bg-gray-300 hover:bg-gray-400"
              } text-gray-800 font-semibold py-2 px-4 rounded-l`}
          >
            Prev
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(history?.length / itemsPerPage)
            }
            className={`text-sm ${currentPage === Math.ceil(history?.length / itemsPerPage)
              ? "bg-gray-200"
              : "bg-gray-300 hover:bg-gray-400"
              } text-gray-800 font-semibold py-2 px-4 rounded-r`}
          >
            Next
          </button>
        </div>
      </div>) : null}
    </div>
  );
}

export default AdminNotificationHistoryTable