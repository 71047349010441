// Layout.js
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <React.Fragment>
      <div className="no-scrollbar h-screen">
        <div className="flex w-full">
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} setIsOpen={setSidebarOpen} />
        </div>

        <div
          id="children's"
          className={`flex-1 transform transition-transform duration-300 h-full min-w-[18rem]  ${!isSidebarOpen ? "ml-[6rem] mt-4 mr-4 mb-4 " : "ml-[13rem] mt-4 mr-4 mb-4"
            }`}
        >
          <Navbar toggleSidebar={toggleSidebar} />
          <div className="w-full transform">{children}</div>
          <div className="pb-4">
          <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
