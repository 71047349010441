import axios from "axios";
import { decryptData, getEncryptItems } from "./localStorageUtils";

export async function allOrgData() {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  } else if (role !== "admin") {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/organization/find-organization`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
}

export const allManager = async () => {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  } else if (role === "staff") {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/manager/find-manager`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const allStaffs = async () => {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/staff/get-staff`,
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const allClients = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/customer/find-customer?populate=organization`,
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const allService = async () => {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/inventory/find-inventory?populate=organizationId`,
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await axios.request(config);
    return res?.data?.data?.filter((inv) => inv?.category === "Service" && !inv.isDeleted);
  } catch (error) {
    console.log(error);
  }
};

export const allMembershipData = async () => {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const branch = getEncryptItems("branchDetails")?._id || decryptData()?.organizationData?.[0]?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  } else if (role === "staff") {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/membership-package/get-membership/${branch}`,
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const allLeser = async () => {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const adminId = decryptData()?.userTypeData?._id;
  const data = await allOrgData()
  const filteredData = await data?.filter(ele => ele.adminId === adminId)
  const orgArray = decryptedData?.organizationData?.map(
    (element) => element._id
  ).length > 0 ? decryptedData?.organizationData?.map(
    (element) => element._id
  ) : filteredData?.map(ele => ele._id)
  const orgIds =
    role === "admin"
      ? orgArray
      : [decryptedData?.userTypeData?.organizationId?._id];
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");

  if (!refreshToken) {
    return;
  } else if (role === "staff") {
    return;
  } else {
    try {
      if (orgIds.length > 0) {
        const queryParams = orgIds
          .map((id) => `organizationIds=${id}`)
          .join("&");
        const url = `${process.env.REACT_APP_API_URL}/laser/findLaser?${queryParams}`;

        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: url,
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        };
        const res = await axios.request(config);
        return res?.data?.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  }
};

export const calculateLaser = async (orgId) => {
  if (!orgId) {
    return
  }
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  } else if (role === "staff") {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/laser/calculate/${orgId}`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
  } catch (error) {
    console.log(error);
  }
};

export const getAppointments = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/appointment/find-appointment?populate=services&populate=organization`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const allBillData = async () => {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  } else if (role === "staff") {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/billing/find-billing?populate=membershipIds&populate=products`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;

  } catch (error) {
    console.log(error);
  }
};

export const allMembershipRevenueData = async () => {
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const branch = getEncryptItems("branchDetails")?._id || decryptData()?.organizationData?.[0]?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  } else if (role === "staff") {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/membership-revenue/allRevenue/${branch}`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const appointmentWithoutLimit = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/appointment/all-appointments`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDiscounts = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/discount/find-discount?populate=organization`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSuppliers = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const decryptedData = decryptData();
  const role = decryptedData?.user?.role;
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  } else if (role === "staff") {
    return;
  }
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/supplier/find-supplier`,
    headers: {
      'Authorization': token
    }
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchCustomerWithMembership = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/customer/find-customer-membership`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchProducts = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/product/find-product?populate=organization`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await axios.request(config);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchServiceHistory = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/history/find`,
    headers: {
      'Authorization': token
    }
  };

  try {
    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
}

export const getProductLedger = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/stockLedger/all-product`,
    headers: {
      'Authorization': token
    }
  };

  try {
    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
}

export const fetchSupplier = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/supplier/find-supplier`,
    headers: {
      'Authorization': token
    }
  };


  try {
    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }

}

export const fetchOrders = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/order/find-orders`,
    headers: {
      'Authorization': token
    }
  };

  try {
    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
}

export const getIssueHistory = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/productIssue/allHistory`,
    headers: {
      'Authorization': token
    }
  };

  try {
    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
}

export const fetchExpanditure = async () => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = getEncryptItems("branchDetails")?._id || decryptData()?.organizationData?.[0]?._id;
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/expenditure/find-expenditure/?filterBy=organizationId&value=${branch}`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchMembershipPurchase = async () => {
  const branch = getEncryptItems("branchDetails")?._id || decryptData()?.organizationData?.[0]?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/membership-revenue/allRevenue/${branch}`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await axios.request(config);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
};


export const getCustomerRoleById = async (id) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const refreshToken = localStorage.getItem("refresh_token");
  if (!refreshToken) {
    return;
  }

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/customeRole/find/${id}`,
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await axios.request(config);
    return response?.data?.customRole;
  } catch (error) {
    console.log(error);
  }
};

export const updateCustomerWallet = async (walletAmount, customerId, dueAmount) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/customer/update_wallet/${customerId}`,
      {
        amount: walletAmount,
        dueAmount: dueAmount,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    console.log("updattedCustomer", response?.data)
    return response.data;
  } catch (error) {
    console.error("Error updating customer wallet:", error);
    return null;
  }
};

export const updateBilling = async (billingId, updatedBillingData) => {
  try {
    const restrictedFields = ["manager", "organizationId", "customer", "admin"];

    restrictedFields.forEach((field) => {
      if (updatedBillingData.hasOwnProperty(field)) {
        delete updatedBillingData[field];
      }
    });

    const token = "Bearer " + localStorage.getItem("refresh_token");
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/billing/update/${billingId}`,
      updatedBillingData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );

    return response?.data?.data?.Bill;
  } catch (error) {
    console.error("Error updating billing:", error);

    if (error.response) {
      console.error("Server error:", error.response.data);
      return { success: false, message: error.response.data.message };
    } else if (error.request) {
      console.error("Network error:", error.request);
      return { success: false, message: "Network error" };
    } else {
      console.error("Error:", error.message);
      return { success: false, message: error.message };
    }
  }
};

export const handleSell = async ({ product, createdBy, draftBill, branch }) => {
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const data1 = JSON.stringify({
    productId: product.productId,
    quantity: product.quantity,
    customerName: draftBill?.name,
    phoneNumber: draftBill?.phoneNumber,
    date: draftBill?.dateTime,
    totalPrice: product?.price,
    organizationId: branch?._id,
    adminId: branch?.adminId,
    productSellType: "Sell",
    createdBy: createdBy,
  });

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/sellhistory/add`,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    data: data1,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
