import React, { useEffect, useState } from "react";
import { TbDiscount2 } from "react-icons/tb";
import { ImSearch } from "react-icons/im";
import AddDiscount from "./DiscountModals/AddDiscount";
import axios from "axios";
import { RiEdit2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { allDiscountList } from "../../state-management/admin/actions";
import DeleteDiscount from "./DiscountModals/DeleteDiscount";
import Tostify from "../../Screens/Common/Tostify";
import { toast } from "react-toastify";
import EditDiscount from "./DiscountModals/EditDiscount";
import { decryptData } from "../../Screens/Common/localStorageUtils";

const DiscountsTable = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);
  const [openDeleteDiscount, setOpenDeleteDiscount] = useState(false);
  const [openAddDiscountModal, setOpenAddDiscountModal] = useState(false);
  const [openEditDiscount, setOpenEditDiscount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValue, setInitialValue] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [clicked, setClicked] = useState(false);
  const [id, setId] = useState("");
  const decryptedData = decryptData();
  const adminId = decryptedData?.userTypeData?._id;
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const filteredDiscounts = admin?.discountList?.filter(
    (dis) => dis?.organization?.adminId === adminId
  );

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDiscounts?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (
    let i = 1;
    i <= Math.ceil(filteredDiscounts?.length / itemsPerPage);
    i++
  ) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    fetchDiscounts();
  }, []);

  const handleChange = (e) => {
    setClicked(false);
    setResults([]);
    setSearchData(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const result = filteredDiscounts?.filter((item) =>
      item.name.toLowerCase().includes(searchData.toLowerCase())
    );
    setClicked(true);
    setResults(result);
  };

  const handleClear = () => {
    setSearchData("");
    setClicked(false);
    setResults([]);
  };

  const fetchDiscounts = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/discount/find-discount?populate=organization`,
      headers: {
        Authorization: token,
      },
    };

    setIsLoading(true);
    await axios
      .request(config)
      .then((response) => {
        dispatch(allDiscountList(response?.data?.data));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleOpenEditDiscount = (id) => {
    setId(id);
    const initial = admin?.discountList?.find((dis) => dis._id === id);
    setInitialValue(initial);
    setOpenEditDiscount(true);
  };

  const tostifyErr = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const tostifySuccess = (msg) => {
    toast.success(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <React.Fragment>
      <Tostify />

      <AddDiscount
        openAddDiscountModal={openAddDiscountModal}
        setOpenAddDiscountModal={setOpenAddDiscountModal}
        tostifyErr={tostifyErr}
        tostifySuccess={tostifySuccess}
        fetchDiscounts={fetchDiscounts}
      />

      <DeleteDiscount
        setOpenDeleteDiscount={setOpenDeleteDiscount}
        openDeleteDiscount={openDeleteDiscount}
        tostifySuccess={tostifySuccess}
        tostifyErr={tostifyErr}
        fetchDiscounts={fetchDiscounts}
        handleClear={handleClear}
        id={id}
        setId={setId}
      />

      {initialValue && (
        <EditDiscount
          openEditDiscount={openEditDiscount}
          setOpenEditDiscount={setOpenEditDiscount}
          initialValue={initialValue}
          id={id}
          setId={setId}
          tostifySuccess={tostifySuccess}
          tostifyErr={tostifyErr}
          fetchDiscounts={fetchDiscounts}
          handleClear={handleClear}
          handleOpenEditDiscount={handleOpenEditDiscount}
        />
      )}
      <div className="mt-4 flex justify-between flex-wrap gap-4">
        <form onSubmit={(e) => handleSearch(e)}>
          <div className="bg-white rounded shadow w-52 flex p-1 relative ">
            <input
              id="searchData"
              className="border-0 rounded px-3 py-3 h-10 placeholder-[#718092] text-[#132c4a] bg-transparent text-sm focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150 pr-6"
              placeholder="Search Discount"
              type="text"
              value={searchData}
              onChange={handleChange}
            />
            {searchData && (
              <span
                className="absolute right-14 top-3 cursor-pointer"
                onClick={handleClear}
              >
                X
              </span>
            )}
            <button
              onClick={handleSearch}
              className="flex items-center justify-center bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 h-10 w-12 rounded ml-2"
            >
              <ImSearch color="white" />
            </button>
          </div>
        </form>

        <div className="flex justify-between items-center gap-5 flex-wrap">
          <button
            onClick={() => setOpenAddDiscountModal(true)}
            className="h-12 w-48 flex content-center items-center justify-center text-md font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
          >
            {0 ? (
              <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
            ) : (
              <span className="flex justify-center items-center">
                <TbDiscount2 size={22} />
                &nbsp; Add Discount
              </span>
            )}
          </button>
        </div>
      </div>

      <div className="mx-auto mt-4">
        {isLoading ? (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                  <th className="px-5 py-5 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    <div className=" flex flex-row  items-center">
                      <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3, 4, 5].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className=" flex flex-row  items-center">
                          <div className="animate-pulse h-2 w-1/2 flex items-center  text-sm font-semibold bg-skeletonLoaderColor rounded "></div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
           
          </div>
        ) : filteredDiscounts?.length === 0 ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Discounts found
          </div>
        ) : searchData && results.length > 0 ? (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Amount
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Expire Date
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Description
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {i + 1}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.name}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                        {v?.type === "percentage" ? `${v?.amount}%` : v?.amount}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.expireDate.toString().substring(0, 10)}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.isActive ? "Active" : "Inactive"}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.description}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          <RiEdit2Fill
                            size={15}
                            className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                            onClick={() => {
                              handleOpenEditDiscount(v?._id);
                            }}
                          />
                          <RiDeleteBin5Fill
                            className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                            size={15}
                            onClick={() => {
                              setId(v?._id);
                              setOpenDeleteDiscount(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : searchData && results.length === 0 && clicked ? (
          <div className="flex justify-center bg-[#fff] w-full shadow-md py-4">
            No Discounts found
          </div>
        ) : (
          <div className="shadow rounded-t-lg overflow-x-auto">
            <table className="min-w-full leading-normal">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Sl
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Name
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Amount
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Expire Date
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Status
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Description
                  </th>
                  <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wide">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                        {(currentPage * 10 + i)-9}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.name}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                        {v?.type === "percentage" ? `${v?.amount}%` : v?.amount}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.expireDate.toString().substring(0, 10)}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.isActive ? "Active" : "Inactive"}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm capitalize">
                        <div className="text-gray-900 whitespace-no-wrap">
                          {v?.description}
                        </div>
                      </td>

                      <td className="px-5 py-5 border border-gray-200 bg-white text-sm">
                        <div className="whitespace-no-wrap flex">
                          <RiEdit2Fill
                            size={15}
                            className="mr-4 text-[#43434A] hover:text-[#232326] cursor-pointer transition ease-in-out hover:duration-300"
                            onClick={() => {
                              handleOpenEditDiscount(v?._id);
                            }}
                          />
                          <RiDeleteBin5Fill
                            className="text-[#FF7866] hover:text-primaryHoverColor cursor-pointer transition ease-in-out hover:duration-300"
                            size={15}
                            onClick={() => {
                              setId(v?._id);
                              setOpenDeleteDiscount(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {(results?.length === 0 && filteredDiscounts?.length === 0) ||
        (searchData && results?.length === 0 && clicked) ? null : (
          <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
            <span className="text-xs xs:text-sm text-gray-900">
              Showing {indexOfFirstItem + 1} to{" "}
              {results?.length
                ? indexOfFirstItem + results?.length
                : indexOfFirstItem + currentItems?.length}{" "}
              of {filteredDiscounts?.length} Entries
            </span>
            <div className="inline-flex mt-2 xs:mt-0">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`text-sm ${
                  currentPage === 1
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-l`}
              >
                Prev
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={
                  currentPage ===
                  Math.ceil(filteredDiscounts?.length / itemsPerPage)
                }
                className={`text-sm ${
                  currentPage ===
                  Math.ceil(filteredDiscounts?.length / itemsPerPage)
                    ? "bg-gray-200"
                    : "bg-gray-300 hover:bg-gray-400"
                } text-gray-800 font-semibold py-2 px-4 rounded-r`}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default DiscountsTable;
