import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { decryptData, getEncryptItems } from "../Common/localStorageUtils";
import axios from "axios";
import { tostifySuccess, tostifyErr } from "../Common/tostifyMessages"
import { useSelector } from "react-redux";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const Issue = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [subject, setSubject] = useState("");
    const [subjectErr, setSubjectErr] = useState();
    const decryptedData = decryptData();
    const role = decryptedData?.user?.role;
    const token = "Bearer " + localStorage.getItem("refresh_token");
    // const branch = getEncryptItems("branchDetails");
    const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
    const handleCreateIssue = async (e) => {
        e.preventDefault();
        if (!subject) {
            setSubjectErr(true);
        } else {
            let data = JSON.stringify({
                "admin": {
                    "adminId": decryptedData?.userTypeData?.adminId
                },
                "organization": {
                    "organizationId": decryptedData?.userTypeData?.organizationId?._id
                },
                "subject": subject
            });
            let data2 = JSON.stringify({
                "admin": {
                    "adminId": decryptedData?.userTypeData?._id
                },
                "organization": {
                    "organizationId": branch?._id,
                },
                "subject": subject
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/issue/add`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                data: role === "admin" ? data2 : data
            };

            setIsLoading(true);
            axios.request(config)
                .then((response) => {
                    setIsLoading(false);
                    console.log("issue data", response?.data?.data);
                    setSubject("");
                    props?.setOpenIssue(false);
                    tostifySuccess(response?.data?.message)
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    props?.setOpenIssue(false);
                    tostifyErr(error?.response?.data?.message)
                });
        }
    }

    return (
        <React.Fragment>
            <Modal
                open={props.openIssue}
                onClose={() => {
                    props.setOpenIssue(false);
                    setSubjectErr("");
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="max-h-[400px] overflow-x-auto px-4">
                        <span
                            onClick={() => props.setOpenIssue(false)}
                            className="absolute top-6  right-5 text-3xl  cursor-pointer"
                        >
                            x
                        </span>
                        <div className="btn-wrapper text-center">
                            <div className="text-xl text-[#2b2f32] font-bold">
                                Raise Your Issues
                            </div>
                        </div>

                        <div className="flex-auto mt-2">
                            <form onSubmit={(e) => { handleCreateIssue(e) }}>

                                <div className="relative w-full mb-3">
                                    <div className="text-[#132c4a] block font-semibold text-sm mb-2">
                                        Cause Of Issue
                                        <span className="text-red-600 text-lg"> *</span>
                                    </div>
                                    <textarea
                                        id="subject"
                                        type="text"
                                        value={subject}
                                        onChange={(e) => {
                                            setSubject(e.target.value);
                                            setSubjectErr(false);
                                        }}
                                        className={
                                            subjectErr ?
                                                "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                                : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                        }
                                        placeholder="Enter Your Issue"
                                    />
                                </div>

                                <div className="w-full flex items-center justify-center mt-5">
                                    <button
                                        disabled={isLoading}
                                        type="submit"
                                        className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                                    >
                                        {isLoading ? (
                                            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                        ) : (
                                            <span>Submit </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default Issue;
