import React, { useState } from 'react';
import { fetchProducts } from '../../Screens/Common/initData';
import { allProductList } from '../../state-management/main/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { decryptData } from '../../Screens/Common/localStorageUtils';
import { tostifyErr, tostifySuccess } from '../../Screens/Common/tostifyMessages';

function Issue({ data }) {
    const initialState = {
        date: '',
        volume: 0,
        issuedBy: '',
        issuedTo: '',
    }
    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const adminId = decryptData()?.userTypeData?._id;
    const allProducts = useSelector(state => state?.main?.productList);
    const selectedProduct = allProducts?.find(item => item?._id === id);
    const staffByAdmin = useSelector(state => state?.main?.staffList)
        ?.filter(item => item?.adminId === adminId && selectedProduct?.organization?._id === item?.organizationId);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const managerByAdmin = useSelector(state => state?.main?.managerList)
        ?.filter(item => item?.adminId === adminId && item?.organizationId === selectedProduct?.organization?._id)

    const getProducts = async () => {
        const data = await fetchProducts();
        dispatch(allProductList(data));
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        let newErrors = {};
        if (!formData.date) {
            newErrors.date = true;
        }

        if (formData.volume <= 0) {
            newErrors.volume = true;
        }

        if (!formData.issuedBy) {
            newErrors.issuedBy = true;
        }

        if (!formData.issuedTo) {
            newErrors.issuedTo = true;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleIssueButtonClick = async () => {
        if (validateForm()) {
            console.log('Form Data:', formData);
            const createdBy = decryptData()?.user?.role === "admin" ? {
                "name": decryptData()?.user?.firstName + " " + decryptData()?.user?.lastName,
                "adminId": decryptData()?.userTypeData?._id
            } : {
                "name": decryptData()?.user?.firstName + decryptData()?.user?.lastName,
                "staffId": decryptData()?.userTypeData?._id
            }
            let data = JSON.stringify({
                "productId": id,
                "quantity": selectedProduct?.quantity,
                "issueDate": formData.date,
                "organizationId": selectedProduct?.organization?._id,
                "adminId": adminId,
                "issuedBy": formData.issuedBy,
                "issuedTo": formData.issuedTo,
                "volume": formData.volume,
                "createdBy":createdBy
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/productIssue/add`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                data: data
            };
            setIsLoading(true);
            try {
                const response = await axios(config);
                console.log(JSON.stringify(response?.data));
                tostifySuccess(response?.data?.message);
                await getProducts();
                setIsLoading(false);
                setFormData(initialState)
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
                tostifyErr(error?.response?.data?.message);
                setIsLoading(false);
            }
        } else {
            console.log('Form validation failed');
        }
    };

    return (
        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center justify-center '>
            <div className='grid grid-cols-1 text-gray-500'>
                <label htmlFor="date">Date</label>
                <input
                    type="date"
                    name="date"
                    id="date"
                    className={
                        errors.date ?
                            "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    value={formData.date}
                    onChange={handleInputChange}
                />
            </div>
            <div className='grid grid-cols-1 text-gray-500'>
                <label htmlFor="volume">Volume</label>
                <input
                    type="number"
                    name="volume"
                    id="volume"
                    className={
                        errors.volume ?
                            "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    value={formData.volume}
                    onChange={handleInputChange}
                />
            </div>
            <div className='grid grid-cols-1 text-gray-500'>
                <label htmlFor="issuedBy">Issued By</label>
                <select
                    name="issuedBy"
                    id="issuedBy"
                    className={
                        errors.issuedBy ?
                            "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    value={formData.issuedBy}
                    onChange={handleInputChange}
                >
                    <option value="">choose</option>
                    {managerByAdmin?.map((v, i) => {
                        return (
                            <option className="capitalize" key={i} value={v?._id}>
                                {v?.userId?.firstName + " " + v?.userId?.lastName}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className='grid grid-cols-1 text-gray-500'>
                <label htmlFor="issuedTo">Issued To</label>
                <select
                    name="issuedTo"
                    id="issuedTo"
                    className={
                        errors.issuedTo ?
                            "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    }
                    value={formData.issuedTo}
                    onChange={handleInputChange}
                >
                    <option>Select Staff</option>

                    {staffByAdmin?.map((v, i) => {
                        return (
                            <option className="capitalize" key={i} value={v?._id}>
                                {v?.userId?.firstName + " " + v?.userId?.lastName}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div className='grid grid-cols-1 text-gray-500 items-center justify-center'>
                <label htmlFor="issuedTo">Action</label>
                <button
                    className='border px-2 py-1 text-orange-500 border-orange-500 flex justify-center hover:bg-orange-500 hover:text-white'
                    onClick={handleIssueButtonClick}
                >{isLoading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                ) : (
                    <span> Issue</span>
                )}
                </button>
            </div>
        </div>
    );
}

export default Issue;
