import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { decryptData, getEncryptItems } from "../../../../Screens/Common/localStorageUtils";
import { useSelector } from "react-redux";
import axios from "axios";
import { tostifyErr, tostifySuccess } from "../../../../Screens/Common/tostifyMessages";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: 4,
    outline: "none",
};

const NewOrder = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        orgId: "",
        orgIdErr: false,
        supplier: "",
        supplierErr: false,
        date: new Date()?.toISOString()?.slice(0, 10) || "",
        dateErr: false,
        remark: "",
        taxType: "inclusive",
        taxTypeErr: "",
        taxGroup: "Gst",
        taxGroupErr: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
            [`${name}Err`]: ""
        });
    };

    const [itemQuantities, setItemQuantities] = useState({});
    const adminId = decryptData().userTypeData?._id;
    const productList = useSelector((state) => state?.admin?.productList);
    const suppliers = useSelector((state) => state?.main?.supplierList);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
    const filteredProducts = productList?.filter(
        (item) => item?.organization?.adminId === adminId && item?.organization?._id === branch?._id
    );
    const filteredSuppliers = suppliers?.filter(
        (item) => item?.organizationId === branch?._id
    );

    const handleQuantityChange = (itemId, quantity) => {
        setItemQuantities((prevQuantities) => ({
            ...prevQuantities,
            [itemId]: quantity
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.date) {
            setFormData(prev => ({
                ...prev,
                dateErr: true
            }))
        }
        else if (!formData.supplier) {
            setFormData(prev => ({
                ...prev,
                supplierErr: true,
            }))
        } else if (!formData?.taxType) {
            setFormData(prev => ({
                ...prev,
                taxTypeErr: true,
            }))
        } else if (!formData?.taxGroup) {
            setFormData(prev => ({
                ...prev,
                taxGroupErr: true
            }))
        } else {
            const selectedItems = Object.entries(itemQuantities)
                .filter(([itemId, quantity]) => quantity > 0)
                .map(([productId, quantity]) => ({
                    productId,
                    quantity: parseInt(quantity),
                    price: filteredProducts.find(product => product._id === productId)?.costPriceMRP * quantity
                }));

            let data = JSON.stringify({
                "supplierId": formData.supplier,
                "organizationId": branch?._id,
                "date": formData.date,
                "adminId": branch?.adminId,
                "products": selectedItems,
                "remark": formData.remark,
                "taxType": formData?.taxType,
                "taxGroup": formData?.taxGroup
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/order/create`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                data: data
            };

            setIsLoading(true);
            axios.request(config)
                .then((response) => {
                    console.log(response.data);
                    tostifySuccess(response?.data?.message);
                    props?.setOpenNewOrder(false);
                    props?.fetchOrders();
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    tostifyErr(error?.response?.data?.message);
                    props?.setOpenNewOrder(false);
                });
        }
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openNewOrder}
                onClose={() => {
                    props.setOpenNewOrder(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="relative">
                    <div className="no-scrollbar max-h-[550px] overflow-y-scroll  overflow-x-auto px-4 ">
                        <div className="btn-wrapper text-center">
                            <span
                                onClick={() => props.setOpenNewOrder(false)}
                                className="absolute top-6  right-5 text-3xl  cursor-pointer"
                            >
                                x
                            </span>
                            <div className="text-xl text-primaryColor font-bold mb-5">
                                New Order
                            </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="flex justify-between gap-4 mb-4">
                                {/* <input
                                    value={branch?.name}
                                    name="orgId"
                                    id="orgId"
                                    disabled={true}
                                    className={
                                        0
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                                    }
                                >
                                </input> */}

                                <input
                                    name="date"
                                    type="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    className={
                                        formData.dateErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                                    }
                                />
                                <select
                                    value={formData.supplier}
                                    name="supplier"
                                    id="supplier"
                                    onChange={handleInputChange}
                                    className={
                                        formData.supplierErr
                                            ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150  "
                                            : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150  "
                                    }
                                >
                                    <option value="">Select supplier</option>
                                    {filteredSuppliers?.map((v, i) => {
                                        return (
                                            <option className="capitalize" key={i} value={v._id}>
                                                {v.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="shadow rounded-t-lg overflow-hidden">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider w-10">
                                                Sl
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                Product Name
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                Price
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                                Current Stock
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider w-40">
                                                Tax
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider w-36">
                                                Tax Group
                                            </th>
                                            <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider w-28">
                                                Quantity
                                            </th>
                                        </tr>
                                    </thead>
                                </table>
                                <div className="no-scrollbar max-h-[300px] overflow-y-scroll">
                                    <table className="min-w-full leading-normal">
                                        <tbody>
                                            {filteredProducts?.map((v, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide w-10">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {i + 1}
                                                            </div>
                                                        </td>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.name}
                                                            </div>
                                                        </td>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.costPriceMRP}
                                                            </div>
                                                        </td>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                {v?.quantity}
                                                            </div>
                                                        </td>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide w-40">
                                                            <select
                                                                onChange={handleInputChange}
                                                                className={
                                                                    formData?.taxTypeErr ? 
                                                                    "text-[#132c4a] border-0 px-2 py-2 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#f7003f] w-fit ease-linear transition-all duration-150":
                                                                    "text-[#132c4a] border-0 px-2 py-2 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-fit ease-linear transition-all duration-150"
                                                                } name="taxType" id="TaxType">
                                                                <option value="inclusive">Inclusive</option>
                                                                <option value="exclusive">Exclusive</option>
                                                            </select>
                                                        </td>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide w-36">
                                                            <select
                                                                className={
                                                                    formData?.taxGroupErr ?
                                                                    "text-[#132c4a] border-0 px-2 py-2 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#f7003f] w-fit ease-linear transition-all duration-150":
                                                                    "text-[#132c4a] border-0 px-2 py-2 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-fit ease-linear transition-all duration-150"
                                                               } name="taxGroup" id="TaxGroup">
                                                                <option value="Gst">Gst</option>
                                                            </select>
                                                        </td>
                                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide w-28">
                                                            <div className="text-gray-900 whitespace-no-wrap">
                                                                <input
                                                                    className="text-[#132c4a] border-0 px-1 py-1 placeholder-[#718092] bg-[#DBDADE] rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-20 ease-linear transition-all duration-150"
                                                                    type="number"
                                                                    min="0"
                                                                    placeholder="Quantity"
                                                                    value={itemQuantities[v._id] || ""}
                                                                    onChange={(e) => handleQuantityChange(v._id, e.target.value)}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="flex justify-between gap-4 mt-2 items-center">
                                <input
                                    type="text"
                                    name="remark"
                                    value={formData.remark}
                                    onChange={handleInputChange}
                                    placeholder="Enter remark"
                                    className="text-[#132c4a] border-0 px-2 py-2 my-2 placeholder-[#718092] bg-[#DBDADE] rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-2/3 ease-linear transition-all duration-150  " />

                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-32 py-2 my-2 flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-lg shadow-md shadow-[#ffdcc7]"
                                >{isLoading ? (
                                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>Place Order</span>
                                )}
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default NewOrder;
