import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import axios from "axios";
import { getEncryptItems } from "../../../Screens/Common/localStorageUtils";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages";
import { useSelector } from "react-redux";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
};

const CustomRoleName = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [rollName, setRollName] = useState("");
    const [rollNameErr, setRollNameErr] = useState("");
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");

    const handleCreateRollName = async () => {
        if (!rollName) {
            setRollNameErr(true);
        } else {
            let data = JSON.stringify({
                "organizationId": branch?._id,
                "adminId": branch?.adminId,
                "roleName": rollName,
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_API_URL}/customeRole/add`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                data: data
            };

            setIsLoading(true);
            axios.request(config)
                .then((response) => {
                    props.fetchCustomerRoles();
                    tostifySuccess(response?.data?.message);
                    props?.setOpenCreateRollName(false);
                    setIsLoading(false);
                    setRollName("");
                    setRollNameErr("");
                })
                .catch((error) => {
                    console.log(error);
                    tostifyErr(error?.response?.data?.message);
                    props?.setOpenCreateRollName(false);
                    setIsLoading(false);
                });
        }
    };

    return (
        <React.Fragment>
            <Modal
                open={props.openCreateRollName}
                onClose={() => props.setOpenCreateRollName(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="max-h-[400px] min-w-[320px] overflow-x-auto">
                        <div className="btn-wrapper text-center">
                            {/* <div className="flex justify-center items-center">
                                <img
                                    src={icon}
                                    alt="delete img"
                                    className="h-12 w-12" />
                            </div> */}
                            <div className="text-xl text-[#2b2f32] font-bold my-4">
                                Custom RollName
                            </div>
                        </div>

                        {/* <span className="block text-[#828282]">
              Do you want to Continue?
            </span> */}

                        <div className="relative px-2 w-full mb-3">
                            <div className="text-[#132c4a] block text-sm mb-2">
                                Enter Roll Name
                                <span className="text-red-600 text-lg"> *</span>
                            </div>
                            <input
                                type="text"
                                id="fname"
                                value={rollName}
                                onChange={(e) => setRollName(e.target.value)}
                                className={
                                    rollNameErr
                                        ? "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow outline-none ring-1 ring-[#f7003f] w-full ease-linear transition-all duration-150"
                                        : "text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                                }
                                placeholder="Enter Roll Name"
                            />
                        </div>

                        <div className="flex justify-center gap-4 mt-6 mb-2">
                            <button
                                onClick={() => props.setOpenCreateRollName(false)}
                                className="h-10 w-2/5 flex content-center items-center justify-center text-lg font-semibold bg-gray-200 hover:bg-gray-300 transition ease-in-out hover:duration-300 rounded-xl shadow-md shadow-[#ffdcc7]"
                            >
                                Cancel
                            </button>

                            <button
                                disabled={isLoading}
                                onClick={() => handleCreateRollName()}
                                className="h-10 w-2/5  flex content-center items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                            >
                                {isLoading ? (
                                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                                ) : (
                                    <span>Create</span>
                                )}
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default CustomRoleName;
