import React from 'react';
import SubscriptionsTable from '../../Components/Subscription/SubscriptionsTable';

const Subscriptions = () => {
  document.title = `${process.env.REACT_APP_NAME} | Admin - Subscription`;

  return (
    <React.Fragment>
      <div className="font-bold text-2xl mt-4 tracking-wide w-full">
        Subscription
      </div>

      <SubscriptionsTable />
    </React.Fragment>
  )
}

export default Subscriptions
