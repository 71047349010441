import React, { useState } from 'react'
import { decryptData, getEncryptItems } from '../../Screens/Common/localStorageUtils';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { allProductList } from '../../state-management/main/actions';
import { fetchProducts } from '../../Screens/Common/initData';

const StockAdjustment = () => {
    const [itemQuantities, setItemQuantities] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const productList = useSelector((state) => state?.admin?.productList);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const branch = useSelector(state => state?.main?.branch) || getEncryptItems("branchDetails");
    const filteredProducts = productList?.filter(item => item?.organization?._id === branch?._id);
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts?.slice(indexOfFirstItem, indexOfLastItem);
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getAllProducts = async () => {
        const data = await fetchProducts();
        dispatch(allProductList(data));
    }

    const handleQuantityChange = (itemId, currentQuantity, quantity) => {
        setItemQuantities((prevQuantities) => ({
            ...prevQuantities,
            [itemId]: {
                currentQuantity: currentQuantity,
                quantity: quantity
            }
        }));
    };

    const adjustStock = async (e) => {
        e.preventDefault();
        const selectedItems = Object.entries(itemQuantities)
            ?.filter(([itemId, obj]) => obj?.quantity > 0)
            ?.map(([itemId, obj]) => ({
                _id: itemId,
                quantity: parseInt(obj?.quantity),
                currentQuantity: parseInt(obj?.currentQuantity)
            }));

        if (selectedItems?.length === 0) {
            toast.error("Please select atleast one item for adjustment");
            return
        }
        const createdBy = decryptData()?.user?.role === "admin" ? {
            "name": decryptData()?.user?.firstName + " " + decryptData()?.user?.lastName,
            "adminId": decryptData()?.userTypeData?._id
        } : {
            "name": decryptData()?.user?.firstName + decryptData()?.user?.lastName,
            "staffId": decryptData()?.userTypeData?._id
        }
        setLoading(true);
        let data = JSON.stringify({
            "adminId": branch?.adminId,
            "organizationId": branch?._id,
            "productQuantityWithId": selectedItems,
            "createdBy": createdBy
        });

        let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/productIssue/adjustStock`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            data: data
        };

        axios.request(config)
            .then(async (response) => {
                await getAllProducts();
                setLoading(false);
                setItemQuantities({});
                toast.success(response?.data?.message);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                toast.success(error?.response?.data?.message);
            });

    }
    return (
        <React.Fragment>

            <div className="shadow rounded-t-lg overflow-hidden mt-4">
                <div className="px-5 py-5 bg-white w-[100%] border-t flex flex-col xs:flex-row items-center xl:justify-between rounded-t-lg">
                    <div className="font-bold text-2xl tracking-wide w-full">
                        Stock Adjustment
                    </div>
                </div>
                <div className="no-scrollbar">
                    <table className="min-w-full leading-normal">
                        <thead>
                            <tr className="bg-gray-100">
                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                    Sl
                                </th>
                                <th className="px-5 w-[40%] py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                    <span className='sm:ml-5 text-wrap text-justify'> Product Name</span>
                                </th>
                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                    Current Stock
                                </th>
                                <th className="px-5 py-3 border-2 border-gray-200 text-left text-sm font-semibold text-gray-600 tracking-wider">
                                    New Quantity
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems?.map((v, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="px-5 py-5 border border-gray-200 bg-white text-sm tracking-wide">
                                            <div className="text-gray-900 whitespace-no-wrap">
                                                {i + 1}
                                            </div>
                                        </td>
                                        <td className="px-5 py-5 border border-gray-200 text-left bg-white text-sm tracking-wide">
                                            <div className="text-gray-900 whitespace-no-wrap">
                                                {v?.name}
                                            </div>
                                        </td>

                                        <td className="px-5 py-5 border border-gray-200 text-left bg-white text-sm tracking-wide">
                                            <div className="text-gray-900 whitespace-no-wrap">
                                                {v?.quantity}
                                            </div>
                                        </td>
                                        <td className="px-5 py-5 border border-gray-200 text-left bg-white text-sm tracking-wide">
                                            <div className="text-gray-900 whitespace-no-wrap">
                                                <input
                                                    className="btext-[#132c4a] border-0 px-1 py-1 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none ring-1 ring-primaryColor focus:ring-[#845ff6] w-20 ease-linear transition-all duration-150"
                                                    type="number"
                                                    min="0"
                                                    placeholder='Quantity'
                                                    value={itemQuantities[v._id]?.quantity || ""}
                                                    onChange={(e) => handleQuantityChange(v._id, v?.quantity, e.target.value)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                {currentItems?.length !== 0 ? (
                    <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between rounded-b-lg">
                        <span className="text-xs xs:text-sm text-gray-900">
                            Showing {indexOfFirstItem + 1} to{" "}
                            {indexOfFirstItem + currentItems?.length} of {filteredProducts?.length}{" "}
                            Entries
                        </span>
                        <div className='flex gap-4 items-center'>
                            <div disabled={loading}
                                onClick={(e) => adjustStock(e)}
                                className='flex justify-end cursor-pointer'>
                                <div className='h-10 w-24 flex  items-center justify-center text-lg font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-lg shadow-lg shadow-[#ffdcc7]'>
                                    {loading ? <div className='animate-spin border-t-4 border-dashed h-8 w-8 rounded-full border-white border-b-transparent'></div> : <span>Submit</span>}
                                </div>
                            </div>
                            <div className="inline-flex mt-2 xs:mt-0">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                    className={`text-sm ${currentPage === 1
                                        ? "bg-gray-200"
                                        : "bg-gray-300 hover:bg-gray-400"
                                        } text-gray-800 font-semibold py-2 px-4 rounded-l`}
                                >
                                    Prev
                                </button>
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={
                                        currentPage === Math.ceil(filteredProducts?.length / itemsPerPage)
                                    }
                                    className={`text-sm ${currentPage === Math.ceil(filteredProducts?.length / itemsPerPage)
                                        ? "bg-gray-200"
                                        : "bg-gray-300 hover:bg-gray-400"
                                        } text-gray-800 font-semibold py-2 px-4 rounded-r`}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>) : null}
            </div>
        </React.Fragment>
    )
}

export default StockAdjustment