import React, { useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { decryptData, getEncryptItems } from "../Common/localStorageUtils";
import Tostify from "../Common/Tostify";
import AddPackage from "../../Components/Package/AddPackage";
import DeletePackage from "../../Components/Package/DeletePackage";
import EditPackage from "../../Components/Package/EditPackage";
import { allPackageList } from "../../state-management/main/actions";

const ViewPackages = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allPackages, setAllPackages] = useState([]);
  const [initialValue, setInitialValue] = useState();
  const [openDeletePackage, setOpenDeletePackage] = useState(false);
  const [openAddPackageModal, setOpenAddPackageModal] = useState(false);
  const [openEditPackage, setOpenEditPackage] = useState(false);
  const token = "Bearer " + localStorage.getItem("refresh_token");
  const branch = useSelector(state => state?.main?.branch);

  useEffect(() => {
    fetchPackageData();
  }, []);

  const handleOpenEdit = (id) => {
    setId(id);
    const initial = allPackages?.find((pack) => pack._id === id);
    setInitialValue(initial);
    setOpenEditPackage(true);
  };

  const fetchPackageData = async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/package/get-package`,
      headers: {
        'Authorization': token
      }
    };

    axios.request(config)
      .then((response) => {
        dispatch(allPackageList(response?.data?.data));
        setAllPackages(response?.data?.data?.filter(item => item?.organizationId?._id === branch?._id && item?.isDeleted === false))
      })
      .catch((error) => {
        console.log(error);
      });

  };

  return (
    <React.Fragment>
      <Tostify />
      <AddPackage
        openAddPackageModal={openAddPackageModal}
        setOpenAddPackageModal={setOpenAddPackageModal}
        fetchPackageData={fetchPackageData} />

      <DeletePackage
        openDeletePackage={openDeletePackage}
        setOpenDeletePackage={setOpenDeletePackage}
        fetchPackageData={fetchPackageData}
        id={id} />

      <EditPackage
        openEditPackage={openEditPackage}
        setOpenEditPackage={setOpenEditPackage}
        fetchPackageData={fetchPackageData}
        id={id}
        setId={setId}
        initialValue={initialValue}
        handleOpenEdit={handleOpenEdit} />

      <div className="flex items-center">
        <IoMdArrowRoundBack
          size={25}
          className="mt-3"
          onClick={() => navigate("/admin/membership")}
        />
        <div className="font-bold text-2xl mt-4 tracking-wide w-full ml-2">
          Packages
        </div>
      </div>

      <div className="flex justify-end items-center">
        <button
          onClick={() => setOpenAddPackageModal(true)}
          className="mt-4 h-12 w-48 flex content-center items-center justify-center text-md font-semibold text-white bg-primaryColor hover:bg-primaryHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
        >
          {0 ? (
            <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
          ) : (
            <span className="flex justify-center items-center">
              &nbsp; Create Package
            </span>
          )}
        </button>
      </div>

      <div className="flex flex-wrap mt-4 gap-8 w-full justify-center items-center ">
        {isLoading ? (
          <div className="flex flex-wrap mt-4 gap-8 w-full justify-center items-center ">
            {[1, 2, 3].map((v, i) => (
              <div key={i} className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 max-w-48">
                <div className="bg-white rounded-md shadow-md tracking-wide">
                  <div className="w-full h-40 bg-skeletonLoaderColor p-4 rounded-t-md"></div>

                  <div className="p-4">
                    <div className="h-4 w-2/3  bg-skeletonLoaderColor rounded mb-4 "></div>
                    <div className="h-4 w-2/3  bg-skeletonLoaderColor rounded "></div>

                    <div className="mb-2 flex justify-between gap-4">
                      <button className="mt-4 h-10 w-28 flex content-center items-center justify-center  animate-pulse bg-skeletonLoaderColor ring-2 ring-primaryColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]">
                      </button>

                      <button className="mt-4 h-10 w-28 flex content-center items-center justify-center  animate-pulse bg-skeletonLoaderColor ring-2 ring-primaryColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex flex-wrap mt-4 gap-8 w-full justify-center items-center ">
            {allPackages?.map((crds, i) => (
              <div
                key={i}
                className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 min-w-52"
              >
                <div className="bg-white rounded-md shadow-md tracking-wide">
                  <div className="text-center w-full bg-primaryColor p-4 rounded-t-md">
                    <h2 className="text-xl font-bold capitalize">{crds?.packageName}</h2>

                    <div className="text-gray-600 text-lg my-1 font-semibold capitalize">
                      {crds?.organizationId?.name}
                    </div>

                    <div className="font-extrabold text-3xl text-white">
                      {crds?.price}
                    </div>
                  </div>

                  <div className="p-4">
                    <div className="">
                      <div className="text-center text-lg font-semibold ml-4">
                        Wallet Balance
                      </div>

                      <div className="text-center text-xl font-bold ml-4">
                        {crds?.packagePrice}
                      </div>
                    </div>

                    <div className="">
                      <div className="text-center text-lg font-semibold ml-4 capitalize">
                        Validity {crds?.validity} Months
                      </div>
                    </div>

                    <div>
                      <div className="mt-6 text-center text-lg font-semibold ml-4">
                        Total No. of Services <span className="mx-1">{crds?.noOfServices}</span>
                      </div>

                      <div className="text-center text-xl font-bold ml-4">
                        {crds?.duration?.map((v, index) => (
                          <div key={index}>
                            <span className="mx-1">{v?.time}</span>
                            {v?.quantity && "x"}
                            {v?.quantity && <span className="mx-1">{v?.quantity}</span>}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mb-2 flex justify-between gap-4">
                      <button
                        onClick={() => {
                          handleOpenEdit(crds?._id);
                        }}
                        className="mt-4 h-10 w-28 flex content-center items-center justify-center text-md font-semibold text-primaryColor ring-2 ring-primaryColor hover:bg-primaryColor hover:text-white transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                      >
                        {0 ? (
                          <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-primaryColor border-t-transparent"></div>
                        ) : (
                          <span className="flex justify-center items-center">
                            <FaEdit size={20} />
                            &nbsp; Edit
                          </span>
                        )}
                      </button>

                      <button
                        onClick={() => {
                          setId(crds?._id);
                          setOpenDeletePackage(true);
                        }}
                        className="mt-4 h-10 w-28 flex content-center items-center justify-center text-md font-semibold text-primaryColor ring-2 ring-primaryColor hover:bg-primaryColor hover:text-white transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]"
                      >
                        {0 ? (
                          <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-primaryColor border-t-transparent"></div>
                        ) : (
                          <span className="flex justify-center items-center">
                            <RiDeleteBin5Line size={20} />
                            &nbsp; Delete
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewPackages;
