import React from "react";
import { AnalyticsDynamicTable } from "./AnalyticsDynamicTable";
import * as XLSX from "xlsx";
import excel from "../../Assets/xls.png";
import pdf from "../../Assets/Pdf.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const AnalyticsTablesView = ({
  dailyRevenueData,
  last7DaysAppointments,
  activeStaff,
  inactiveStaff,
  dailySumsForPayments,
  dailyRevenueFromMembership,
  dailyServiceVsStaff,
  selectedFilter,
  from,
  setFrom,
  to,
  setTo,
}) => {
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  // console.log("dailyServiceVsStaff",dailyServiceVsStaff)

  // DailyRevenue data of Table view start
  const dailyRevenueHeaderData = [
    { field: "id", label: "ID" },
    { field: "date", label: "Date" },
    { field: "rupees", label: "rupees" },
  ];

  const sameDateDailyRevenueData = [
    { field: "id", label: "ID" },
    { field: "rupees", label: "rupees" },
  ];

  const dailyRevenueRowData = [];
  let count1 = 0;
  for (const date in dailyRevenueData) {
    const dailyRevenue = dailyRevenueData[date] || [];
    const totalDailyRevenue = dailyRevenue[0]?.total;
    if (from === to) {
      if (totalDailyRevenue > 0) {
        dailyRevenueRowData.push({
          id: count1 + 1,
          rupees: totalDailyRevenue,
        });

        count1++;
      }
    } else {
      if (totalDailyRevenue > 0) {
        dailyRevenueRowData.push({
          id: count1 + 1,
          date: date,
          rupees: totalDailyRevenue,
        });

        count1++;
      }
    }
  }

  // DailyRevenue data of Table view end

  // DailyAppointments data of Table view start
  const last7DaysAppointmentsHeaderData = [
    { field: "id", label: "ID" },
    { field: "date", label: "Date" },
    { field: "total", label: "Total" },
  ];

  const sameLast7DaysAppointmentsHeaderData = [
    { field: "id", label: "ID" },
    { field: "total", label: "Total" },
  ];

  const last7DaysAppointmentsRowData = Object?.keys(last7DaysAppointments)?.map(
    (date, index) => ({
      id: index + 1,
      date: date,
      total: last7DaysAppointments[date]?.length,
    })
  );

  // const daysOfWeek = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];
  // daysOfWeek.forEach((dayName, index) => {
  //   const appointments = last7DaysAppointments[dayName] || [];
  //   const totalAppointments = appointments.length;
  //   if (totalAppointments > 0) {
  //     last7DaysAppointmentsRowData.push({
  //       id: count + 1, // increment count for each entry
  //       dayName,
  //       total: totalAppointments,
  //     });
  //     count++;
  //   }
  // });

  // DailyAppointments data of Table view end

  // StaffsStatus data of Table view start
  const staffStatusHeaderData = [
    { field: "Active", label: "ACTIVE" },
    { field: "InActive", label: "INACTIVE" },
  ];
  const staffStatusRowData = [
    { Active: activeStaff?.length, InActive: inactiveStaff?.length },
  ];
  // StaffsStatus data of Table view end

  // Payments data of Table view start
  const paymentsHeaderData = [
    { field: "id", label: "ID" },
    { field: "Date", label: "DATE" },
    // { field: "PaymentMethod", label: "Payment Method" },
    { field: "Cash", label: "CASH" },
    { field: "Card", label: "CARD" },
    { field: "Upi", label: "UPI" },
    { field: "Total", label: "TOTAL" },
    { field: "Expenses", label: "Expenses" },
    { field: "SubTotal", label: "SubTotal" },
  ];

  const sameDatePaymentsHeaderData = [
    { field: "id", label: "ID" },
    // { field: "PaymentMethod", label: "Payment Method" },
    { field: "Cash", label: "CASH" },
    { field: "Card", label: "CARD" },
    { field: "Upi", label: "UPI" },
    { field: "Total", label: "TOTAL" },
    { field: "Expenses", label: "Expenses" },
    { field: "SubTotal", label: "SubTotal" },
  ];

  let paymentsRowData;

  const paymentsDataByDate = {};
  Object.keys(dailySumsForPayments).forEach((day) => {
    dailySumsForPayments[day].forEach((payment) => {
      const date = payment.date;
      if (!paymentsDataByDate[date]) {
        paymentsDataByDate[date] = [];
      }
      paymentsDataByDate[date].push(payment);
    });
  });
  // paymentsRowData = Object.keys(paymentsDataByDate).map((date, index) => {
  //   const paymentMethods = paymentsDataByDate[date]
  //     ?.map((data) => {
  //       return `card = ${data.card}\r\nupi = ${data.upi}\r\ncash = ${data.cash}\r\nexpense = ${data.expense}\r\nsubTotal = ${data.subTotal}\r\ntotal = ${data.total}`;
  //     })
  //     ?.join("\r\n\r\n");
  //   return {
  //     id: index + 1,
  //     Date: date,
  //     PaymentMethod: paymentMethods,
  //   };
  // });
  // paymentsRowData = paymentsRowData.sort((a, b) => {
  //   const dateA = parseDate(a.Date);
  //   const dateB = parseDate(b.Date);
  //   return dateA - dateB;
  // });
  // for (let i = 0; i < paymentsRowData.length; i++) {
  //   paymentsRowData[i].id = i + 1;
  // }
  paymentsRowData = Object?.keys(paymentsDataByDate)?.flatMap((date, index) => {
    const paymentData = paymentsDataByDate[date][0];

    return {
      id: index + 1,
      Date: date,
      Cash: paymentData?.cash || 0,
      Card: paymentData?.card || 0,
      Upi: paymentData?.upi || 0,
      Total: paymentData?.total || 0,
      Expenses: paymentData?.expense || 0,
      SubTotal: paymentData?.subTotal || 0,
    };
  });
  // Payments data of Table view end

  // MembershipRevenue data of Table view start
  const membershipHeaderData = [
    { field: "id", label: "ID" },
    { field: "Date", label: "DATE" },
    { field: "Price", label: "PRICE" },
  ];

  const sameDateMembershipHeaderData = [
    { field: "id", label: "ID" },
    { field: "Date", label: "DATE" },
    { field: "Price", label: "PRICE" },
  ];

  let membershipRowData;
  const dataByDate = {};
  Object.keys(dailyRevenueFromMembership).forEach((day) => {
    dailyRevenueFromMembership[day].forEach((payment) => {
      const date = payment.date;
      if (!dataByDate[date]) {
        dataByDate[date] = [];
      }
      dataByDate[date].push(payment);
    });
  });
  membershipRowData = Object.keys(dataByDate).map((date, index) => {
    let price = dataByDate[date].reduce((total, data) => total + data.price, 0);
    return {
      id: index + 1,
      Date: date,
      Price: price,
    };
  });
  membershipRowData = membershipRowData.sort((a, b) => {
    const dateA = parseDate(a.Date);
    const dateB = parseDate(b.Date);
    return dateA - dateB;
  });
  for (let i = 0; i < membershipRowData.length; i++) {
    membershipRowData[i].id = i + 1;
  }
  // MembershipRevenue data of Table view end

  // Staff vs services data of Table view end
  const getServiceName = (serviceId) => {
    // Assuming serviceId is an array with one object in the current structure
    if (serviceId && serviceId.length > 0) {
      return serviceId[0].name;
    }
    return "";
  };

  const headerData = [
    { field: "id", label: "ID" },
    { field: "name", label: "Name" },
    { field: "serviceName", label: "Service Name" },
    { field: "service", label: "No.Of Service" },
    { field: "date", label: "date" },
  ];

  const sameDateHeaderData = [
    { field: "id", label: "ID" },
    { field: "name", label: "Name" },
    { field: "serviceName", label: "Service Name" },
    { field: "service", label: "No.Of Service" },
  ];

  const rowData = [];
  Object.values(dailyServiceVsStaff).forEach((dayData) => {
    const servicesGiven = {};

    dayData.forEach((data) => {
      const { staffId, serviceId } = data;
      const key = `${staffId.employeeId}_${data.date}`;

      if (servicesGiven[key]) {
        servicesGiven[key].service += 1;
      } else {
        servicesGiven[key] = {
          service: 1,
          name: staffId.userId.firstName,
          serviceName: getServiceName(serviceId),
        };
      }
    });

    Object.entries(servicesGiven).forEach(
      ([key, { service, name, serviceName }]) => {
        const [empId, date] = key.split("_");
        rowData.push({
          id: empId,
          name: name || "",
          date: date || "",
          service: service,
          serviceName: serviceName || "",
        });
      }
    );
  });

  let serviceVsStaffRowData;
  let dateByService = {};
  Object.keys(dailyServiceVsStaff)?.forEach((day) => {
    dailyServiceVsStaff[day].forEach((entry) => {
      const date = entry.date;
      if (!dateByService[date]) {
        dateByService[date] = [];
      }
      dateByService[date].push(entry);
    });
  });
  serviceVsStaffRowData = Object.keys(dateByService)?.map((date, index) => {
    let serviceLength = dateByService[date]?.length;
    return {
      id: index + 1,
      Date: date,
      service: serviceLength,
    };
  });
  serviceVsStaffRowData = serviceVsStaffRowData?.sort((a, b) => {
    const dateA = parseDate(a.Date);
    const dateB = parseDate(b.Date);
    return dateA - dateB;
  });
  for (let i = 0; i < serviceVsStaffRowData?.length; i++) {
    serviceVsStaffRowData[i].id = i + 1;
  }

  const DownloadExcelData = (data, fileName) => {
    if (data?.length === 0) {
      return
    }
    let filteredData;
    if (Array.isArray(data)) {
      filteredData = data?.map((item) => ({
        ...item,
      }));
    } else if (
      typeof data === "object" &&
      data !== null &&
      !Array.isArray(data)
    ) {
      filteredData = {
        ...data,
      };
    } else {
      console.log("data not found");
      return;
    }
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const colWidths = Object.keys(filteredData[0]).map((key) =>
      filteredData.reduce(
        (acc, row) => Math.max(acc, String(row[key]).length),
        key.length
      )
    );
    const gapWidth = 2;
    const adjustedColWidths = colWidths.map((width) => width + gapWidth);
    worksheet["!cols"] = adjustedColWidths.map((width) => ({ wch: width }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  const DownloadPdfData = (data, headerData, fileName) => {
    if (data?.length === 0) {
      return
    }
    const doc = new jsPDF();
    const tableHeaders = headerData.map((header) => header.label);

    // Add a title to the PDF
    doc.setFontSize(16);
    doc.text("Report Table", 20, 20);

    // Add a date to the PDF
    const date = new Date().toLocaleDateString();
    doc.setFontSize(12);
    doc.text(`Date: ${date}`, 20, 30);

    // Add the table content to the PDF using autoTable
    doc.autoTable({
      startY: 40,
      head: [tableHeaders],
      body: data.map((row) =>
        headerData.map((header) => row[header.field] || "")
      ),
      styles: { cellPadding: 2, fontSize: 10 },
      headStyles: { fillColor: [254, 135, 64] },
      alternateRowStyles: { fillColor: [250, 250, 250] },
    });

    // Save the PDF
    doc.save(`${fileName}.pdf`);
  };

  const renderFilteredData = () => {
    switch (selectedFilter) {
      case "Daily Revenue Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Daily Revenue</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        dailyRevenueRowData,
                        "Daily Revenue.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        dailyRevenueRowData,
                        dailyRevenueHeaderData,
                        "Daily Revenue"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={dailyRevenueRowData}
                headerData={dailyRevenueHeaderData}
              />
            </span>
          </div>
        );
      case "Appointments Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">
                  Appointments per day
                </h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        last7DaysAppointmentsRowData,
                        "Appointments per day.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        last7DaysAppointmentsRowData,
                        last7DaysAppointmentsHeaderData,
                        "Appointment Data"
                      )
                    }
                  />
                </div>
              </div>
              <AnalyticsDynamicTable
                rowData={last7DaysAppointmentsRowData}
                headerData={last7DaysAppointmentsHeaderData}
              />
            </span>
          </div>
        );
      case "Staff Status Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-3 flex items-center justify-between bg-white">
                <h4 className="text-2xl font-bold mr-2">Staffs Status</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        staffStatusRowData,
                        "Staffs Status.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        staffStatusRowData,
                        staffStatusHeaderData,
                        "Staffs Status"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={staffStatusRowData}
                headerData={staffStatusHeaderData}
              />
            </span>
          </div>
        );
      case "Payments Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Payments</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(paymentsRowData, "Payments.xlsx")
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        paymentsRowData,
                        paymentsHeaderData,
                        "payments Data"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={paymentsRowData}
                headerData={paymentsHeaderData}
              />
            </span>
          </div>
        );
      case "Membership Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Membership Revenue</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(
                        membershipRowData,
                        "Membership Revenue.xlsx"
                      )
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        membershipRowData,
                        membershipHeaderData,
                        "Membership revenue"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={membershipRowData}
                headerData={membershipHeaderData}
              />
            </span>
          </div>
        );
      case "Employee Vs Service Filter":
        return (
          <div className="w-full py-4">
            <span className="lg:w-[49%] w-full py-4">
              <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                <h4 className="text-2xl font-bold mr-2">Employee vs Service</h4>
                <div className="flex gap-2">
                  <img
                    src={excel}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadExcelData(rowData, "Employee vs Service.xlsx")
                    }
                  />
                  <img
                    src={pdf}
                    className="w-7 h-7 cursor-pointer"
                    onClick={() =>
                      DownloadPdfData(
                        rowData,
                        headerData,
                        "Employee vs Service"
                      )
                    }
                  />
                </div>
              </div>

              <AnalyticsDynamicTable
                rowData={rowData}
                headerData={headerData}
              />
            </span>
          </div>
        );
      case "All":
      default:
        return (
          <>
            <div className="flex lg:flex-row flex-col gap-6 pt-4">
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Daily Revenue</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          dailyRevenueRowData,
                          "Daily Revenue.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          dailyRevenueRowData,
                          dailyRevenueHeaderData,
                          "Daily Revenue"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={dailyRevenueRowData}
                  headerData={
                    from === to
                      ? sameDateDailyRevenueData
                      : dailyRevenueHeaderData
                  }
                />
              </span>
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">
                    Appointments per day
                  </h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          last7DaysAppointmentsRowData,
                          "Appointments per day.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          last7DaysAppointmentsRowData,
                          last7DaysAppointmentsHeaderData,
                          "Appointment Data"
                        )
                      }
                    />
                  </div>
                </div>
                <AnalyticsDynamicTable
                  rowData={last7DaysAppointmentsRowData}
                  headerData={
                    from === to
                      ? sameLast7DaysAppointmentsHeaderData
                      : last7DaysAppointmentsHeaderData
                  }
                />
              </span>
            </div>

            <div className="flex lg:flex-row flex-col gap-6">
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Staffs Status</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          staffStatusRowData,
                          "Staffs Status.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          staffStatusRowData,
                          staffStatusHeaderData,
                          "Staffs Status"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={staffStatusRowData}
                  headerData={staffStatusHeaderData}
                />
              </span>
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Payments</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(paymentsRowData, "Payments.xlsx")
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          paymentsRowData,
                          paymentsHeaderData,
                          "payments Data"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={paymentsRowData}
                  headerData={
                    from === to
                      ? sameDatePaymentsHeaderData
                      : paymentsHeaderData
                  }
                />
              </span>
            </div>

            <div className="flex lg:flex-row flex-col gap-6">
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">Membership Revenue</h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(
                          membershipRowData,
                          "Membership Revenue.xlsx"
                        )
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          membershipRowData,
                          membershipHeaderData,
                          "Membership revenue"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={membershipRowData}
                  headerData={
                    from === to
                      ? sameDateMembershipHeaderData
                      : membershipHeaderData
                  }
                />
              </span>
              <span className="lg:w-[49%] w-full py-4">
                <div className="text-center p-4 flex items-center justify-between bg-white rounded-t-lg shadow">
                  <h4 className="text-lg font-bold mr-2">
                    Employee vs Service
                  </h4>
                  <div className="flex gap-2">
                    <img
                      src={excel}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadExcelData(rowData, "Employee vs Service.xlsx")
                      }
                    />
                    <img
                      src={pdf}
                      className="w-7 h-7 cursor-pointer"
                      onClick={() =>
                        DownloadPdfData(
                          rowData,
                          headerData,
                          "Employee vs Service"
                        )
                      }
                    />
                  </div>
                </div>

                <AnalyticsDynamicTable
                  rowData={rowData}
                  headerData={from === to ? sameDateHeaderData : headerData}
                />
              </span>
            </div>
          </>
        );
    }
  };

  return <>{renderFilteredData()}</>;
};
