import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsFillHandbagFill } from "react-icons/bs";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { BiSolidDashboard, BiSolidUser } from "react-icons/bi";
import { FaUserTie, FaFileInvoice } from "react-icons/fa";
import { MdInventory, MdCardMembership } from "react-icons/md";
import { FaTriangleExclamation } from "react-icons/fa6";
import { GiExpense } from "react-icons/gi";
import { IoMdAnalytics } from "react-icons/io";
import { TbReportSearch } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import { decryptData, getEncryptItems } from "../Common/localStorageUtils";
import { useSelector } from "react-redux";
import { FaBoxes } from "react-icons/fa";

const SidebarsContent = ({ isOpen }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [setselectedItem, setSetselectedItem] = useState(location.pathname);
  const [user, setUser] = useState({});
  let userRole = decryptData()?.user?.role;
  const userId = decryptData()?.userTypeData?._id;
  const managerList = useSelector((state) => state?.main?.managerList);
  const staffList = useSelector((state) => state.main?.staffList);
  const features = decryptData()?.userTypeData?.features;
  const userRoleName = getEncryptItems("customRoles");

  useEffect(() => {
    userRole === "manager"
      ? setUser(managerList?.find((item) => item._id === userId))
      : setUser(staffList?.find((item) => item?._id === userId));
  }, [userRole, managerList, staffList, features, user?.features]);

  const classes = {
    active: `flex items-center block ${
      isOpen ? "px-4 py-2 mt-2" : "px-0 py-1 mt-5 justify-center"
    }  text-md font-semibold tracking-wide text-white bg-primaryColor hover:bg-primaryHoverColor rounded-lg focus:outline-none focus:shadow-outline transition ease-in-out duration-300 transform hover:translate-x-2 cursor-pointer`,
    inactive: `flex items-center block ${
      isOpen ? "px-4 py-2 mt-2" : "px-0 py-1 mt-5 justify-center"
    } text-md font-semibold tracking-wide text-[#7f8296] bg-transparent rounded-lg hover:text-gray-900 hover:bg-[#febc95] transition ease-in-out duration-300 transform hover:translate-x-2 focus:outline-none focus:shadow-outline cursor-pointer`,
  };

  const clickToSelect = (pageName) => {
    navigate(pageName);
    setSetselectedItem(pageName);
  };

  return (
    <React.Fragment>
      {userRole === "admin" && (
        <div>
          <div
            className={
              setselectedItem === "/admin/dashboard"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/dashboard")}
          >
            <BiSolidDashboard size={24} />
            {isOpen && <span className="ml-2">Dashboard</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/invoice"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/invoice")}
          >
            <FaFileInvoice size={24} />
            {isOpen && <span className="ml-2">Invoice</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/client"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/client")}
          >
            <FaUserTie size={24} />
            {isOpen && <span className="ml-2">Client</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/membership"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/membership")}
          >
            <MdCardMembership size={24} />
            {isOpen && <span className="ml-2">Members</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/reports"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/reports")}
          >
            <TbReportSearch size={24} />
            {isOpen && <span className="ml-2">Reports</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/inventory"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/inventory")}
          >
            <MdInventory size={24} />
            {isOpen && <span className="ml-2">Inventory</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/products"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/products")}
          >
            <FaBoxes size={24} />
            {isOpen && <span className="ml-2">Stocks</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/analytics"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/analytics")}
          >
            <IoMdAnalytics size={24} />
            {isOpen && <span className="ml-2">Analytics</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/staff"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/staff")}
          >
            <BiSolidUser size={24} />
            {isOpen && <span className="ml-2">Staffs</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/laser"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/laser")}
          >
            <GiExpense size={23} />
            {isOpen && <span className="ml-2">Ledger</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/issue"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/issue")}
          >
            <FaTriangleExclamation size={24} />
            {isOpen && <span className="ml-2">Issue</span>}
          </div>

          <div
            className={
              setselectedItem === "/admin/expenditure"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/admin/expenditure")}
          >
            <FaMoneyCheckAlt size={24} />
            {isOpen && <span className="ml-2">Expenditure</span>}
          </div>
        </div>
      )}

      {userRole === "staff" && (
        <div>
          {userRoleName?.roleId?.permissions?.dashboard?.sidebarDashboard && (
            <div
              className={
                setselectedItem === "/staff/dashboard"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/dashboard")}
            >
              <BiSolidDashboard size={24} />
              {isOpen && <span className="ml-2">Dashboard</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.invoice?.sidebarInvoice && (
            <div
              className={
                setselectedItem === "/staff/invoice"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/invoice")}
            >
              <FaFileInvoice size={24} />
              {isOpen && <span className="ml-2">Invoice</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.client?.sidebarClient && (
            <div
              className={
                setselectedItem === "/staff/client"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/client")}
            >
              <FaUserTie size={24} />
              {isOpen && <span className="ml-2">Client</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.members?.sidebarMembers && (
            <div
              className={
                setselectedItem === "/staff/membership"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/membership")}
            >
              <MdCardMembership size={24} />
              {isOpen && <span className="ml-2">Members</span>}
            </div>
          )}

          <div
            className={
              setselectedItem === "/staff/reports"
                ? classes.active
                : classes.inactive
            }
            onClick={() => clickToSelect("/staff/reports")}
          >
            <TbReportSearch size={24} />
            {isOpen && <span className="ml-2">Reports</span>}
          </div>

          {userRoleName?.roleId?.permissions?.inventory?.sidebarInventory && (
            <div
              className={
                setselectedItem === "/staff/inventory"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/inventory")}
            >
              <MdInventory size={24} />
              {isOpen && <span className="ml-2">Inventory</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.products?.sidebarProducts && (
            <div
              className={
                setselectedItem === "/staff/products"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/products")}
            >
              <BsFillHandbagFill size={24} />
              {isOpen && <span className="ml-2">Products</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.analytics?.sidebarAnalytics && (
            <div
              className={
                setselectedItem === "/staff/analytics"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/analytics")}
            >
              <IoMdAnalytics size={24} />
              {isOpen && <span className="ml-2">Analytics</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.staff?.sidebarStaff && (
            <div
              className={
                setselectedItem === "/admin/staff"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/admin/staff")}
            >
              <BiSolidUser size={24} />
              {isOpen && <span className="ml-2">Staffs</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.ledger?.sidebarLedger && (
            <div
              className={
                setselectedItem === "/staff/laser"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/laser")}
            >
              <GiExpense size={23} />
              {isOpen && <span className="ml-2">Ledger</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.issue?.sidebarIssue && (
            <div
              className={
                setselectedItem === "/staff/issue"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/issue")}
            >
              <FaTriangleExclamation size={24} />
              {isOpen && <span className="ml-2">Issue</span>}
            </div>
          )}

          {userRoleName?.roleId?.permissions?.expenditure
            ?.sidebarExpenditure && (
            <div
              className={
                setselectedItem === "/staff/expenditure"
                  ? classes.active
                  : classes.inactive
              }
              onClick={() => clickToSelect("/staff/expenditure")}
            >
              <FaMoneyCheckAlt size={24} />
              {isOpen && <span className="ml-2">Expenditure</span>}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default SidebarsContent;
