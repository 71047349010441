import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Plus from "../../../Assets/plusimage.png";
import minus from "../../../Assets/minusIcon.png";
import edit from "../../../Assets/edit.png";
import recyclebin from "../../../Assets/recyclebin.png";
import {
    decryptData,
    getEncryptItems,
} from "../../../Screens/Common/localStorageUtils";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
    allBillData,
    allClients,
    fetchCustomerWithMembership,
    updateBilling,
} from "../../../Screens/Common/initData";
import {
    allBillList,
    allClientList,
    allCustomerWithMembership,
    setBillForm,
    storeDraftBill,
    storeNewBill,
} from "../../../state-management/main/actions";
import { tostifyErr, tostifySuccess } from "../../../Screens/Common/tostifyMessages";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: "800px",
    maxHeight: "80%",
    height: "auto",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    borderRadius: "15px",
    outline: "none",
    p: 4,
    overflowY: "auto",
};

const PaymentEditModal = ({
    singleInvoice,
    isOpen,
    onClose,
    FetchBillingData,
    setIsEdited,
    isEdited
}) => {
    const draftBill = useSelector(state => state?.main?.draftBill);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const initialPaymentMethod = {
        id: 1,
        payment: "",
        paymentDate: new Date()?.toISOString()?.slice(0, 10),
        amount: "",
    };
    const [paymentMethods, setPaymentMethods] = useState([
        initialPaymentMethod
    ]);
    const [paymentError, setPaymentError] = useState("");
    const [isEditClicked, setIsEditClicked] = useState(false);
    const token = "Bearer " + localStorage.getItem("refresh_token");
    const BillData = useSelector(state => state?.main?.billForm);
    const dispatch = useDispatch();
    const clientDuringBill = useSelector(state => state?.main?.clientDuringBill);


    useEffect(() => {
        if (draftBill?.paymentMethods) {
            setPaymentMethods(prevMethods => {
                const updatedMethods = prevMethods?.filter(method =>
                    draftBill?.paymentMethods?.some(draftMethod => draftMethod?.id === method?.id)
                );

                draftBill?.paymentMethods?.forEach(draftMethod => {
                    if (!updatedMethods?.some(method => method?.id === draftMethod?.id)) {
                        updatedMethods.push(draftMethod);
                    }
                });

                if (updatedMethods.length === 1 && updatedMethods[0].payment === "WalletBalance") {
                    updatedMethods.push(initialPaymentMethod);
                }

                return updatedMethods;
            });
        }
    }, [draftBill?.paymentMethods]);


    const getclientData = async () => {
        const data = await allClients();
        dispatch(allClientList(data));
    };

    const getCustmerWithMembership = async () => {
        const data = await fetchCustomerWithMembership();
        dispatch(allCustomerWithMembership(data));
    };

    const totalServiceCharge = draftBill?.servicesIncludedInMembership?.length ? draftBill?.
        serviceBilledOn?.reduce((acc, service) => acc + (service?.price * service?.quantity), 0) : draftBill?.services?.reduce(
            (acc, service) => acc + (service.price),
            0
        );
    const totalProductCharge = draftBill?.products?.reduce((acc, product) => acc + (product.price), 0);
    const totalMembershipPrice = draftBill?.membershipIds?.[0]?.price || 0;

    const getBillData = async () => {
        const data = await allBillData();
        dispatch(allBillList(data));
    };

    const handleChange = (e, index, name) => {
        setIsEditClicked(true);
        const { value } = e.target;
        const updatedPaymentMethods = [...paymentMethods];
        if (name === "payment") {
            if (value === "WalletBalance") {
                return;
            }
            updatedPaymentMethods[index] = {
                ...updatedPaymentMethods[index],
                payment: value,
            };
        } else if (name === "paymentDate") {
            updatedPaymentMethods[index] = {
                ...updatedPaymentMethods[index],
                paymentDate: value,
            };
        } else if (name === "amount") {
            const amount = parseInt(value);
            updatedPaymentMethods[index] = {
                ...updatedPaymentMethods[index],
                amount: amount,
            };
        }
        setPaymentMethods(updatedPaymentMethods);
    };

    const payments = paymentMethods?.map((method) => ({
        paymentMethod: method.payment,
        amount: method.amount,
        paymentDate: method.paymentDate,
    }));
    const allPayment =
        Number(
            payments?.reduce((acc, item) => {
                const amount = Number(item.amount);
                if (!isNaN(amount)) {
                    acc += amount;
                }
                return acc;
            }, 0)
        );

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (allPayment === 0) {
            setPaymentError("Please Enter Payment Amount");
            return;
        }

        const hasIncompletePayment = paymentMethods?.some(method => {
            return !method.payment || !method.amount || !method.paymentDate;
        });

        if (hasIncompletePayment) {
            setPaymentError("Please Enter Payment Details");
            return;
        }
        setIsLoading(true);
        setIsFormFilled(true);
        const updatedBill = await updateBilling(draftBill?._id,
            {
                ...draftBill,
                dueAmount: Math.max(Number(draftBill?.finalPrice - allPayment), 0),
                paymentStatus: Number(draftBill?.finalPrice - allPayment) > 0 ? "due" : "paid",
                paidAmount: `${allPayment}`,
                paymentMethods: paymentMethods,
                serviceBilledOn: BillData?.serviceToBeBilled,
                freeServices: BillData?.freeServices,
                freeServiceApplied: BillData?.freeServiceApplied,
                servicesIncludedInMembership: BillData?.freeServiceApplied ? BillData?.servicesIncludedInMembership : [],
                isDraftInvoice: false,
            })
        await getclientData();
        await getBillData();
        await getCustmerWithMembership();
        if (FetchBillingData) {
            FetchBillingData();
        }
        setIsLoading(false);
        dispatch(storeDraftBill(updatedBill))
    };


    const handleAddPayment = (e) => {
        e.preventDefault();
        setPaymentMethods([...paymentMethods, {
            id: new Date().getTime(),
            payment: "",
            paymentDate: new Date()?.toISOString()?.slice(0, 10),
            amount: "",
        }]);
    };

    const removePaymentField = (index, method) => {
        if (paymentMethods?.length === 1) {
            return;
        }
        if (method.payment === "WalletBalance") {
            alert("You Can't Change the Wallet Balance After Payment");
            return;
        }
        const updatedMethods = paymentMethods.filter((method, i) => i !== index);
        setPaymentMethods(updatedMethods);
    };

    return (
        <React.Fragment>
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={style} className="no-scrollbar">
                    <div className="mx-auto">
                        <div className="btn-wrapper text-center">
                            <span
                                className="absolute top-6 right-5 text-3xl cursor-pointer"
                                onClick={() => {
                                    setPaymentMethods(draftBill?.paymentMethods);
                                    setIsFormFilled(false);
                                    onClose()
                                }}
                            >
                                x
                            </span>
                            <div className="text-xl text-[#2b2f32] font-bold text-center">
                                Add Payment Method To Pay:{" "}
                                <span className="text-red-400">{draftBill?.dueAmount}</span>
                            </div>
                        </div>
                        <div className="flex md:flex-row flex-col justify-center items-center gap-2 md:gap-8">
                            <div className="flex gap-2">
                                <p className="text-[20px] font-bold">
                                    {" "}
                                    Wallet Balance:
                                    <span
                                        className={`text-red-400  ${Number(clientDuringBill?.walletBalance) === 0 ? "text-gray-400" : ""
                                            }`}
                                    >
                                        {clientDuringBill?.walletBalance}
                                    </span>
                                </p>
                            </div>
                            <div className="flex gap-2">
                                <p className="text-[20px] font-bold ">
                                    Due Amount:
                                    <span className="text-red-400">{draftBill?.dueAmount || 0}</span>
                                </p>
                            </div>
                        </div>
                        <div
                            // onSubmit={handleSubmit}
                            className="mt-4">

                            {paymentMethods?.map((method, index) => (
                                <div key={method.id} className="flex flex-col">
                                    {method.payment !== "WalletBalance" && (
                                        <div className="mb-4 flex flex-col md:flex-row md:items-center md:gap-2">
                                            <div className="w-full md:w-1/3">
                                                <label
                                                    className="text-[#132c4a] block text-sm mb-2"
                                                    htmlFor={`payment-${index}`}
                                                >
                                                    Payment Method
                                                </label>
                                                <select
                                                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full"
                                                    id={`payment-${index}`}
                                                    name={`payment-${index}`}
                                                    value={method.payment}
                                                    onChange={(e) => handleChange(e, index, "payment")}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="g-pay">Google Pay</option>
                                                    <option value="phone-pay">Phone Pay</option>
                                                    <option value="paytm">Paytm</option>
                                                    {/* <option value="WalletBalance">WalletBalance</option> */}
                                                    <option value="Creditcard">Credit Card</option>
                                                    <option value="Debitcard">Debit Card</option>
                                                    <option value="Cash">Cash</option>
                                                    <option value="Card">Card</option>
                                                    <option value="UPI">UPI</option>
                                                </select>
                                            </div>
                                            <div className="w-full md:w-1/3">
                                                <label
                                                    className="text-[#132c4a] block text-sm mb-2"
                                                    htmlFor={`paymentDate-${index}`}
                                                >
                                                    Date
                                                </label>
                                                <input
                                                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full"
                                                    type="date"
                                                    id={`paymentDate-${index}`}
                                                    name={`paymentDate-${index}`}
                                                    value={method.paymentDate}
                                                    onChange={(e) => handleChange(e, index, "paymentDate")}
                                                />
                                            </div>
                                            <div className="w-full md:w-1/3">
                                                <label
                                                    className="text-[#132c4a] block text-sm mb-2"
                                                    htmlFor={`amount-${index}`}
                                                >
                                                    Amount
                                                </label>
                                                <input
                                                    className="text-[#132c4a] border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full"
                                                    id={`amount-${index}`}
                                                    name={`amount-${index}`}
                                                    type="number"
                                                    value={method.amount}
                                                    onChange={(e) => handleChange(e, index, "amount")}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {method.payment !== "WalletBalance" && <div className="flex justify-end gap-4">
                                        <img
                                            src={Plus}
                                            alt="Add"
                                            onClick={handleAddPayment}
                                            className="cursor-pointer"
                                        />
                                        <img
                                            src={minus}
                                            alt="Remove"
                                            onClick={() => removePaymentField(index, method)}
                                            className="cursor-pointer"
                                        />
                                    </div>}
                                </div>
                            ))}

                            <div className="flex md:flex-row flex-col justify-between items-center mt-4">
                                <div className="flex flex-col">
                                    <div className="flex items-center gap-3">
                                        <p className="text-lg">
                                            Total Amount:{" "}
                                            <span className="text-green-400">{totalServiceCharge + totalProductCharge + totalMembershipPrice
                                            }</span>
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <p className="text-lg">
                                            Pending Amount:{" "}
                                            <span className="text-red-400">
                                                {Math.max(
                                                    0,
                                                    Number(totalServiceCharge + totalProductCharge + totalMembershipPrice - allPayment))}
                                            </span>
                                        </p>
                                        {paymentError && <span className="text-red-500 text-md tracking-wide">{paymentError}</span>}
                                    </div>
                                </div>
                            </div>

                            {isFormFilled && (
                                <div className="overflow-x-auto mt-4 no-scrollbar">
                                    <table className="w-full mb-4 border-collapse border border-gray-300 overflow-x-auto">
                                        <thead>
                                            <tr>
                                                <th className="border border-gray-300 px-4 py-2">
                                                    Payment Method
                                                </th>
                                                <th className="border border-gray-300 px-4 py-2">
                                                    Date
                                                </th>
                                                <th className="border border-gray-300 px-4 py-2">
                                                    Reference
                                                </th>
                                                <th className="border border-gray-300 px-4 py-2">
                                                    Amount
                                                </th>
                                                <th className="border border-gray-300 px-4 py-2">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentMethods?.map((method, index) => (
                                                <tr key={method.id}>
                                                    <td className="border border-gray-300 px-4 py-2">
                                                        {method?.payment}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2">
                                                        {method?.paymentDate}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2">
                                                        {BillData?.name}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2">
                                                        {method?.amount}
                                                    </td>
                                                    <td className="border border-gray-300 px-4 py-2 flex gap-2">
                                                        {/* <img
                                                            src={edit}
                                                            alt="Edit"
                                                            style={{ width: "20px", height: "20px" }}
                                                        /> */}
                                                        <button
                                                            onClick={() => removePaymentField(index, method)}
                                                        >
                                                            <img
                                                                src={recyclebin}
                                                                alt="Delete"
                                                                style={{ width: "20px", height: "20px" }}
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <div className="w-full flex items-center justify-center mt-8">
                                <button
                                    onClick={(e) => handleSubmit(e)}
                                    disabled={isLoading}
                                    className={`h-12 w-1/2 flex items-center justify-center text-lg font-semibold text-white ${draftBill?.paymentStatus == "due" || isEditClicked
                                        ? "bg-primaryColor hover:bg-primaryHoverColor"
                                        : "bg-green-500"
                                        } rounded-xl shadow-lg transition ease-in-out duration-300`}
                                >
                                    {isLoading ? (
                                        <div className="w-8 h-8 rounded-full animate-spin border-b-4 border-dashed border-white border-t-transparent"></div>
                                    ) : (
                                        <span>{isEditClicked ? "Pay" : draftBill?.paymentStatus == "paid" ? "Paid" : "Pay"}</span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
};

export default PaymentEditModal;
