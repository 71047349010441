import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { FiDownload } from "react-icons/fi";
import { getEncryptItems } from "../../../Screens/Common/localStorageUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  outline: "none",
  p: 4,
};

const BulkImportClientModal = (props) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const branch = getEncryptItems("branchDetails");
  const token = "Bearer " + localStorage.getItem("refresh_token");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const excelDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  };

  const handleUpload = () => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const importedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // console.log("importedData", importedData);

      const mappedData = importedData.slice(1).map((row) => ({
        name: String(row[0] || ""),
        phoneNumber: row[1] || "",
        dateOfBirth: row[2] ? excelDateToJSDate(row[2]).toLocaleDateString() : "",
        dateOfAnniversary: row[3] ? excelDateToJSDate(row[3]).toLocaleDateString() : "",
        remark: row[4] || "",
      }));

      // console.log("mappedData", mappedData);
      setData(mappedData);
      setUploadCompleted(true);
    };
    reader.readAsBinaryString(file);
  };

  // console.log("data+++", data);

  const handleRemoveFile = () => {
    setFile(null);
    setData([]);
    setUploadCompleted(false);
  };

  const handleRegister = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customer/bulkAdd`,
        {
          bulkImportClient: data,
          organizationId: branch?._id,
        },
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );
      console.log("console", response?.data);
      setLoading(false);
      setData([]);
    } catch (error) {
      console.error("API error:", error);
      setLoading(false);
    }
  };

  const downloadExampleFile = () => {
    const exampleData = [
      ["name", "phoneNumber", "dateOfBirth", "dateOfAnniversary", "remark"],
    ];

    const ws = XLSX.utils.aoa_to_sheet(exampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Example Client");
    XLSX.writeFile(wb, "example_services.xlsx");
  };

  return (
    <React.Fragment>
      <Modal
        open={props.openBulkAddClientModal}
        onClose={() => {
          props.setOpenBulkAddClientModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="relative">
          <div className="no-scrollbar min-w-[400px] sm:min-w-[450px] px-4">
            <div className="btn-wrapper text-center">
              <span
                onClick={() => props.setOpenBulkAddClientModal(false)}
                className="absolute top-6  right-5 text-3xl  cursor-pointer"
              >
                x
              </span>
              <div className="text-xl text-[#2b2f32] font-bold">Add Client</div>
            </div>

            {data.length > 0 ? (
              <div className="mt-6 relative">
                <h2 className="text-xl font-semibold mb-2">Imported Data</h2>
                <div className="absolute top-2 right-2">
                  <button
                    className="text-red-500 hover:text-red-600"
                    onClick={handleRemoveFile}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="border overflow-x-auto max-h-[300px]">
                  <table className="w-full border-collapse border border-gray-300">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="border border-gray-300 p-1">Name</th>
                        <th className="border border-gray-300 p-1">Phone</th>
                        <th className="border border-gray-300 p-1">
                          Date OF Birth
                        </th>
                        <th className="border border-gray-300 p-1">
                          Date Of Anniversary
                        </th>
                        <th className="border border-gray-300 p-1">Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((client, index) => (
                        <tr key={index}>
                          <td className="border border-gray-300 p-1">
                            {client.name}
                          </td>
                          <td className="border border-gray-300 p-1">
                            {client.phoneNumber}
                          </td>
                          <td className="border border-gray-300 p-1">
                            {client.dateOfBirth}
                          </td>
                          <td className="border border-gray-300 p-1">
                            {client.dateOfAnniversary}
                          </td>
                          <td className="border border-gray-300 p-1">
                            {client.remark}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center space-y-4 mt-6 px-4">
                <h4
                  onClick={downloadExampleFile}
                  className="mb-6 text-base font-semibold flex gap-2 items-center text-white bg-primaryColor py-2 px-2 rounded-md cursor-pointer"
                >
                  Example Excel File
                  <FiDownload className="w-6 h-6" />
                </h4>
                <label
                  htmlFor="file-upload"
                  className="border border-gray-300 p-2 rounded-lg cursor-pointer"
                >
                  Upload Excel file (.xlsx, .xls) only
                  <input
                    id="file-upload"
                    type="file"
                    accept=".xlsx,.xls"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </label>
                <button
                  className="text-sm text-black hover:text-white font-semibold px-4 py-2 border border-primaryColor rounded hover:bg-primaryColor"
                  onClick={handleUpload}
                >
                  Upload
                </button>
              </div>
            )}

            <div className="flex justify-end mt-6">
              {uploadCompleted && (
                <button
                  className="text-sm font-semibold ml-2 px-4 py-2 border border-primary rounded bg-primaryColor text-white"
                  onClick={handleRegister}
                >
                  {loading ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    "Add"
                  )}
                </button>
              )}

              <button
                className="text-sm text-white font-semibold ml-2 px-4 py-2 rounded bg-primaryColor"
                onClick={() => {
                  props.setOpenBulkAddClientModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default BulkImportClientModal;
