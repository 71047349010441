import React from "react";
import Dashboard from "../Screens/User/Dashboard";
import Login from "../Screens/Auth/Login";
import Client from "../Screens/User/Client";
import Inventory from "../Screens/User/Inventory";
import Staff from "../Screens/User/Staff";
import Subscriptions from "../Screens/User/Subscriptions";
import Membership from "../Screens/User/Membership";
import Analytics from "../Screens/User/Analytics";
import Invoice from "../Screens/User/Invoice";
import Discounts from "../Screens/User/Discounts";
import Logout from "../Screens/Auth/Logout";
import Laser from "../Screens/User/Laser";
import Profile from "../Screens/Common/Profile";
import Products from "../Screens/User/Products";
import Expenditure from "../Screens/User/Expenditure";
import MembershipPackage from "../Screens/User/MembershipPackage";
import AdminIssue from "../Screens/User/AdminIssue";
import AdminNotificationHistory from "../Screens/User/AdminNotificationHistory";
import ProductDetail from "../Screens/User/ProductDetail";
import Packages from "../Screens/User/Packages";
import Report from "../Screens/User/Report";

const userRoutes = [
  { path: "/:userType/dashboard", component: <Dashboard /> },
  { path: "/:userType/client", component: <Client /> },
  { path: "/:userType/inventory", component: <Inventory /> },
  { path: "/:userType/staff", component: <Staff /> },
  { path: "/:userType/subscriptions", component: <Subscriptions /> },
  { path: "/:userType/membership", component: <Membership /> },
  { path: "/:userType/analytics", component: <Analytics /> },
  { path: "/:userType/invoice", component: <Invoice /> },
  { path: "/:userType/discounts", component: <Discounts /> },
  {
    path: "/:userType/membership-package/:orgId",
    component: <MembershipPackage />,
  },
  { path: "/:userType/notification", component: <AdminNotificationHistory /> },
  { path: "/:userType/issue", component: <AdminIssue /> },
  { path: "/:userType/laser", component: <Laser /> },
  { path: "/:userType/profile", component: <Profile /> },
  { path: "/:userType/products", component: <Products /> },
  { path: "/:userType/expenditure", component: <Expenditure /> },
  { path: "/:userType/packages/:id", component: <Packages /> },
  { path: "/:userType/reports", component: <Report /> },
  { path: "/:userType/productDetail/:id", component: <ProductDetail /> },
  // {
  //   path: "/:userType/",
  //   exact: true,
  //   component: <Navigate to="/:userType/dashboard" />,
  // },
];

const authRoutes = [
  { path: "/login", component: <Login /> },
  // { path: "/sign-up", component: <SignUp /> },
  { path: "/logout", component: <Logout /> },
];

export { userRoutes, authRoutes };
