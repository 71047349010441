import React, { useState } from "react";
import EditStaffModal from "./StaffModals/EditStaffModal";
import { RiEdit2Fill, RiDeleteBin5Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import DeleteStaffModal from "./StaffModals/DeleteStaffModal";
import {
  decryptData,
  getEncryptItems,
} from "../../Screens/Common/localStorageUtils";
import user from "../../Assets/bussiness-man.png";

const StaffData = ({ staff, fetchStaffData, showDeleteButton, setStaff }) => {
  const admin = useSelector((state) => state.admin);
  const [openEditStaffModal, setOpenEditStaffModal] = useState(false);
  const [id, setId] = useState("");
  const [initialValue, setInitialValue] = useState();
  const [openDeleteStaffModal, setOpenDeleteStaffModal] = useState(false);
  let userRole = decryptData()?.user?.role;
  const userRoleName = getEncryptItems("customRoles");

  const handleOpenEditStaff = (id) => {
    setId(id);
    const initial = admin?.staffList?.find((stf) => stf._id === id);
    setInitialValue(initial);
    setOpenEditStaffModal(true);
  };

  const handleClear = () => {
    // setSearchData("");
    // setClicked(false);
    // setResults([]);
  };

  return (
    <React.Fragment>
      {initialValue && (
        <EditStaffModal
          openEditStaffModal={openEditStaffModal}
          setOpenEditStaffModal={setOpenEditStaffModal}
          fetchStaffData={fetchStaffData}
          initialValue={initialValue}
          handleOpenEditStaff={handleOpenEditStaff}
          handleClear={handleClear}
          setStaff={setStaff}
          id={id}
          setId={setId}
        />
      )}

      <DeleteStaffModal
        openDeleteStaffModal={openDeleteStaffModal}
        setOpenDeleteStaffModal={setOpenDeleteStaffModal}
        fetchStaffData={fetchStaffData}
        handleClear={handleClear}
        id={id}
        setId={setId}
      />

      {staff ? (
        <div className="mt-4 px-14 py-5 bg-white rounded-md  min-h-[450px]">
          <div className="flex lg:flex-row flex-col gap-16 justify-center items-center">
            <div className="md:w-[30%] w-full flex flex-col justify-center items-center">
              <img
                src={staff?.url || user}
                alt=""
                className="lg:h-40 lg:w-40 w-full h-auto rounded-full p-2 "
              />
              <div className="flex justify-end gap-2 items-center">
                <div className="flex py-4">
                  {(userRole === "admin" ||
                    (userRole === "staff" &&
                      userRoleName?.roleId?.permissions?.staff?.editStaff)) && (
                    <button
                      onClick={() => {
                        handleOpenEditStaff(staff?._id);
                      }}
                      className={`bg-gray-300 flex items-center gap-3 px-4 py-2 rounded-md ${
                        showDeleteButton ? "" : "mr-8"
                      }`}
                    >
                      Edit
                      <RiEdit2Fill
                        size={15}
                        className="mr-4 text-[#43434A] cursor-pointer hover:text-[#232326] transition ease-in-out hover:duration-300"
                      />
                    </button>
                  )}
                </div>
                {showDeleteButton && (
                  <div className="flex py-2">
                    {(userRole === "admin" ||
                      (userRole === "staff" &&
                        userRoleName?.roleId?.permissions?.staff
                          ?.deleteStaff)) && (
                      <button
                        onClick={() => {
                          setId(staff?._id);
                          setOpenDeleteStaffModal(true);
                        }}
                        className="bg-gray-300 flex items-center gap-3 px-4 py-2 rounded-md"
                      >
                        Delete
                        <RiDeleteBin5Fill
                          className="text-[#FF7866] cursor-pointer hover:text-primaryHoverColor transition ease-in-out hover:duration-300"
                          size={15}
                        />
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="md:w-[70%] w-full">
              <div className="flex flex-col gap-4">
                <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-4 gap-12">
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">Name</p>
                    <p className="text-[#282828] font-semibold text-lg capitalize">
                      {staff?.userId?.firstName} {staff?.userId?.lastName}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">
                      Phone No.
                    </p>
                    <p className="text-[#282828] font-semibold text-lg">
                      {staff?.userId?.phone}
                    </p>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-4 gap-12">
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">Email</p>
                    <p className="text-[#282828] font-semibold text-lg">
                      {staff?.userId?.email}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">Password</p>
                    <p className="text-[#282828] font-semibold text-lg">
                      {staff?.userId?.password}
                    </p>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-4 gap-12">
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">Gender</p>
                    <p className="text-[#282828] font-semibold text-lg capitalize">
                      {staff?.gender}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">Status</p>
                    <p className="text-[#282828] font-semibold text-lg capitalize">
                      {staff?.martialStatus}
                    </p>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 grid-cols-1 lg:gap-4 gap-12">
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">
                      Designation
                    </p>
                    <p className="text-[#282828] font-semibold text-lg capitalize">
                      {staff?.userId?.role}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#979797] font-bold text-lg">
                      Blood Group
                    </p>
                    <p className="text-[#282828] font-semibold text-lg capitalize">
                      {staff?.bloodGroup}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-4 px-14 py-2 bg-white rounded-md capitalize text-2xl min-h-[450px] text-[#050F64] text-center content-center shadow-md">
          {/* <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <div className="h-5 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 bg-gray-300 rounded mt-2"></div>
            </div>
            <div className="flex flex-col">
              <div className="h-5 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 bg-gray-300 rounded mt-2"></div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="flex flex-col">
              <div className="h-5 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 bg-gray-300 rounded mt-2"></div>
            </div>
            <div className="flex flex-col">
              <div className="h-5 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 bg-gray-300 rounded mt-2"></div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="flex flex-col">
              <div className="h-5 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 bg-gray-300 rounded mt-2"></div>
            </div>
            <div className="flex flex-col">
              <div className="h-5 bg-gray-300 rounded w-3/4"></div>
              <div className="h-3 bg-gray-300 rounded mt-2"></div>
            </div>
          </div>
          <div className="flex justify-end gap-2 items-center mt-4">
            <div className="flex py-4">
              <div className="bg-gray-300 flex items-center gap-3 px-4 py-2 rounded-md mr-8">
                <div className="text-gray-300 cursor-not-allowed">
                  <div className="w-4 h-4 bg-gray-400 rounded-full"></div>
                </div>
              </div>
            </div>
            <div className="flex py-2">
              <div className="bg-gray-300 flex items-center gap-3 px-4 py-2 rounded-md">
                <div className="text-gray-300 cursor-not-allowed">
                  <div className="w-4 h-4 bg-gray-400 rounded-full"></div>
                </div>
              </div>
            </div>
          </div> */}
          Please Select A Staff To See Details
        </div>
      )}
    </React.Fragment>
  );
};

export default StaffData;
