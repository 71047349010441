const regex = {
  nameRegEx: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
  emailRegExp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  phoneRegEx: /^\d{10}$/,
  passwordRegEx:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&*!])[A-Za-z\d@#$%^&*!]{8,30}$/,
  // Password length must be between 8 and 30 characters.
  // It must contain at least one uppercase letter.
  // It must contain at least one lowercase letter.
  // It must contain at least one digit (0-9).
  // It may contain special characters (e.g., !, @, #, $, %, ^, &, *).
  twelveDigitsAdhaarRegex: /^(\d\s?){12}$/,
  cityRegex: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  genderRegex: /^(male|female|other)$/,
  indianPostalCodeRegex: /^[1-9][0-9]{5}$/,
  indianStateRegex:
    /^(Andhra Pradesh|Arunachal Pradesh|Assam|Bihar|Chhattisgarh|Goa|Gujarat|Haryana|Himachal Pradesh|Jharkhand|Karnataka|Kerala|Madhya Pradesh|Maharashtra|Manipur|Meghalaya|Mizoram|Nagaland|Odisha|Punjab|Rajasthan|Sikkim|Tamil Nadu|Telangana|Tripura|Uttar Pradesh|Uttarakhand|West Bengal)$/i,
  regexBloodGroup: /^(a|b|ab|o)[+-]$/i,
  regexMaritalStatus: /^(single|married|divorced|widowed)$/i,
  dateTimeRegex:
    /^(?:\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}(?::\d{2})?(?:\.\d{1,3})?(?:Z|[+-]\d{2}:\d{2})?)?)$/,
  statusRegex: /^(pending|confirmed|canceled|completed)$/,
  priceRegex:
    /^[$€]?\d{1,3}(?:[,.]\d{3})*(?:\.\d{1,2})?$|^\d{4,7}(?:\.\d{1,2})?$/,
  phoneRegexForOTP: /^\+91\d{10}$/,
};

module.exports = regex;
